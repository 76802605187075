import { Flex, Select, Text, Tooltip } from '@radix-ui/themes';
import './bundle-mockups-list.scss';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { Mockup } from '@/services/types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  clearMockupVariations,
  setCurrentMockup,
  setIsMockupLoading,
} from '@/redux/slices/collection';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useCaptureEvent from '@/hooks/useCaptureEvent';

export const BundleMockupsList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const { collectionId } = useParams();
  const { bundleMockupState } = useAppSelector(
    (state) => state.collectionReducer
  );
  const { currentMockupBundle } = useAppSelector(
    (state) => state.mockupBundlesReducer
  );

  const [activeMockup, setActiveMockup] = useState<Mockup>(null!);

  useEffect(() => {
    if (currentMockupBundle && collectionId) {
      setActiveMockup(
        currentMockupBundle.mockups.find(
          (mockup) => mockup.uuid === collectionId
        ) as Mockup
      );
    }
  }, [collectionId, currentMockupBundle]);

  const setSelectedMockup = (mockupUuid: string) => {
    const selectedMockup = currentMockupBundle.mockups.find(
      (mockup) => mockup.uuid === mockupUuid
    ) as Mockup;
    captureEvent('Create Mockups: Navigate to mockup in batch', {
      batchUUID: currentMockupBundle.mockup_bundle.uuid,
      templateUUID: selectedMockup.uuid,
    });
    dispatch(setIsMockupLoading(true));
    dispatch(setCurrentMockup(selectedMockup));
    dispatch(clearMockupVariations());
    navigate(
      `/batch/${currentMockupBundle.mockup_bundle.uuid}/mockup/${selectedMockup.uuid}/smart-object/${selectedMockup.smart_objects[0].uuid}`
    );
  };

  return (
    <Select.Root
      value={activeMockup?.uuid}
      onValueChange={(mockupUuid) => setSelectedMockup(mockupUuid)}
    >
      <Select.Trigger className="select-wrapper" />
      <Select.Content position="popper" style={{ width: '100%' }}>
        {currentMockupBundle?.mockups.map((mockup) => (
          <Select.Item key={mockup.id} value={mockup.uuid}>
            <Flex className="select-item-wrapper" align={'center'} gap={'2'}>
              <LazyLoadImage
                height={'30px'}
                width={'30px'}
                style={{
                  objectFit: 'cover',
                  filter: 'blur(0)',
                  maxHeight: '100%',
                }}
                effect="blur"
                src={
                  mockup?.thumbnail ||
                  'https://placehold.co/120x120?text=MOCKUP_' + mockup?.id
                }
                delayTime={0}
                placeholderSrc={
                  mockup?.thumbnail ||
                  'https://placehold.co/120x120?text=MOCKUP_' + mockup?.id
                }
              />{' '}
              <Tooltip content={mockup.name}>
                <Text className="mockup-name" size={'1'}>
                  {mockup.name}
                </Text>
              </Tooltip>
            </Flex>
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
    // <ScrollArea scrollbars="vertical" style={{ minWidth: '89px' }}>
    //   <Flex
    //     className="bundle-mockups-list-wrapper"
    //     direction={'column'}
    //     gap={'4'}
    //     p={'4'}
    //   >
    //     {currentMockupBundle?.mockups.length === 0 ? (
    //       <Skeleton key={'skeleton-001'} className={`mockup-item`} />
    //     ) : (
    //       <Flex direction={'column'} gap={'4'}>
    //         {currentMockupBundle?.mockups.map((mockup) => (
    //           <Box
    //             className={`mockup-item ${
    //               activeMockup?.uuid === mockup.uuid ? 'active' : ''
    //             }`}
    //             key={mockup.uuid}
    //             onClick={() => setSelectedMockup(mockup.uuid)}
    //           >
    //             <LazyLoadImage
    //               height={'100%'}
    //               width={'100%'}
    //               style={{
    //                 objectFit: 'cover',
    //                 filter: 'blur(0)',
    //                 maxHeight: '100%',
    //               }}
    //               effect="blur"
    //               src={
    //                 mockup?.thumbnail ||
    //                 'https://placehold.co/120x120?text=MOCKUP_' + mockup?.id
    //               }
    //               delayTime={0}
    //               placeholderSrc={
    //                 mockup?.thumbnail ||
    //                 'https://placehold.co/120x120?text=MOCKUP_' + mockup?.id
    //               }
    //             />
    //           </Box>
    //         ))}
    //       </Flex>
    //     )}
    //     {[...Array(createBundleRestTempData.numberOfIds)].map((index) => (
    //       <Skeleton key={`skeleton-${index}`} className={`mockup-item`} />
    //     ))}
    //   </Flex>
    // </ScrollArea>
  );
};
