import './collections-widget.scss';
import { Button, DropdownMenu, Flex, Text } from '@radix-ui/themes';
import { PlusIcon } from '@radix-ui/react-icons';
import { ReactComponent as FolderIcon } from '@/assets/icons/folder-linear.svg';
import { ReactComponent as MenuDotsBoldIcon } from '@/assets/icons/menu-dots-bold.svg';
import { ReactComponent as WidgetIcon } from '@/assets/icons/widget.svg';
import { EditCollection } from './EditCollection';
import { DeleteCollection } from './DeleteCollection';
import { useEffect, useRef } from 'react';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { CreateCollection } from './CreateCollection';
import { mockupCollectionsApi } from '@/services/mockupCollections';
import { useAppSelector } from '@/hooks';
import { useDispatch } from 'react-redux';
import {
  setActiveMockupCollectionId,
  setAllTemplatesCount,
} from '@/redux/slices/mockupCollections';
import { workspaceApi } from '@/services/workspace';
import { colorsApi } from '@/services/colors';
import { ColorSetsAddRemove } from './ColorSetsAddRemove';
import { useFeatureFlagEnabled } from 'posthog-js/react';

interface ICollectionsWidget {}

export const CollectionsWidget = ({}: ICollectionsWidget) => {
  const dispatch = useDispatch();
  const deleteCollectionDialog = useRef();
  const editCollectionDialog = useRef();
  const createCollectionDialog = useRef();
  const captureEvent = useCaptureEvent();
  const { mockupCollections, activeMockupCollectionId, allTemplatesCount } =
    useAppSelector((state) => state.mockupCollectionsReducer);
  const { colorSets } = useAppSelector((state) => state.colorSetsReducer);
  const colorSetsEnabled = useFeatureFlagEnabled('color-sets');

  mockupCollectionsApi.useGetMockupCollectionsQuery(null!, {
    refetchOnMountOrArgChange: true,
  });
  colorsApi.useGetColorSetsQuery(null!, {
    refetchOnMountOrArgChange: true,
  });

  const geyAllMyProjects = workspaceApi.useGetMyProjectsQuery(
    { collectionId: 0 },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    dispatch(setAllTemplatesCount(geyAllMyProjects.data?.data.length || 0));
  }, [geyAllMyProjects.data?.data]);

  return (
    <Flex
      className="collections-widget"
      p={'4'}
      height={'100%'}
      direction={'column'}
      gap={'2'}
    >
      <Flex className="head" justify={'between'} align={'center'}>
        <Text>COLLECTIONS</Text>
        <CreateCollection ref={createCollectionDialog} />
        <Button
          variant="soft"
          size={'1'}
          color="gray"
          onClick={() => {
            captureEvent('Create collection modal opened');
            (createCollectionDialog?.current as any)?.openDialog();
          }}
        >
          <PlusIcon className="icon white" />
        </Button>
      </Flex>
      <Flex className="collections" direction={'column'} gap={'2'}>
        <Flex
          className={`collection default ${
            activeMockupCollectionId === 0 ? 'active' : ''
          }`}
          onClick={() => {
            dispatch(setActiveMockupCollectionId(0));
          }}
          align={'center'}
          justify={'between'}
          gap={'2'}
          px={'3'}
          py={'2'}
          width={'100%'}
        >
          <Flex align={'center'} gap={'3'}>
            <WidgetIcon className="icon" width={'20px'} height={'20px'} />
            <Text>All Templates</Text>
          </Flex>
          <Text weight={'light'}>{allTemplatesCount}</Text>
        </Flex>
        <Flex direction={'column'} className="collection-items">
          {mockupCollections?.map((collection) => (
            <Flex
              key={`collection-item-${collection.id}`}
              className={`collection ${
                activeMockupCollectionId === collection.id ? 'active' : ''
              }`}
              align={'center'}
              gap={'2'}
              px={'3'}
              width={'100%'}
              justify={'between'}
            >
              <Flex
                align={'center'}
                gap={'2'}
                width={'100%'}
                onClick={() => {
                  dispatch(setActiveMockupCollectionId(collection.id));
                }}
              >
                <FolderIcon className="icon" />
                <Text className="name" title={collection.name}>
                  {collection.name}
                </Text>
              </Flex>
              <>
                <EditCollection
                  key={`edit-collection-dialog-${collection.id}`}
                  ref={editCollectionDialog}
                />
                <DeleteCollection
                  key={`delete-collection-dialog-${collection.id}`}
                  ref={deleteCollectionDialog}
                />
                <Flex className="trigger-wrapper" gap={'2'} align={'center'}>
                  <Text weight={'light'} className="collection-count">
                    {collection.mockups_count}
                  </Text>
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger className="ddl-options-trigger">
                      <Button
                        className="ddl-options"
                        variant="ghost"
                        size={'2'}
                        color="gray"
                      >
                        <MenuDotsBoldIcon className="icon" />
                      </Button>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content>
                      <DropdownMenu.Item
                        onClick={() => {
                          captureEvent('Rename collection modal opened');
                          (editCollectionDialog?.current as any)?.openDialog(
                            collection
                          );
                        }}
                      >
                        Rename
                      </DropdownMenu.Item>
                      {colorSetsEnabled && (
                        <DropdownMenu.Sub>
                          <DropdownMenu.SubTrigger>
                            Color Sets
                          </DropdownMenu.SubTrigger>
                          {colorSets?.length > 0 ? (
                            <DropdownMenu.SubContent>
                              {colorSets?.map((colorSet) => (
                                <ColorSetsAddRemove
                                  key={colorSet.id}
                                  colorSet={colorSet}
                                  collection={collection}
                                />
                              ))}
                            </DropdownMenu.SubContent>
                          ) : (
                            <DropdownMenu.SubContent>
                              <Text size={'2'}>No color sets found</Text>
                            </DropdownMenu.SubContent>
                          )}
                        </DropdownMenu.Sub>
                      )}
                      <DropdownMenu.Separator />
                      <DropdownMenu.Item
                        color="red"
                        onClick={() => {
                          (deleteCollectionDialog?.current as any)?.openDialog(
                            collection
                          );
                        }}
                      >
                        Delete
                      </DropdownMenu.Item>
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                </Flex>
              </>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
