import { useAppDispatch, useAppSelector } from '@/hooks';
import { DropdownMenu, Flex, IconButton, Select, Text } from '@radix-ui/themes';
import { toast } from 'react-toastify';
import { psdEngineApi } from '@/services/psdEngine';
import { useEffect, useRef, useState } from 'react';
import { PrintareaPreset } from '@/services/types';
import { updateActiveSmartObject } from '@/redux/slices/collection';
import { EditPreset } from '@/features/screens/collection/components/editor/components/EditPreset';
import { printareaPresetsApi } from '@/services/printareaPresets';
import { DeletePreset } from './DeletePreset';
import { CreateNewPreset } from './CreateNewPreset';
import { ReactComponent as MenuDotsBoldIcon } from '@/assets/icons/menu-dots-bold.svg';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IPrintAreaPresets {}

export const PrintAreaPresets = ({}: IPrintAreaPresets) => {
  const captureEvent = useCaptureEvent();
  const dispatch = useAppDispatch();
  const { activeSmartObject, activeVariation, activeDesignAsset, mockup } =
    useAppSelector((state) => state.collectionReducer);
  const [deletedPresetId, setDeletedPresetId] = useState<number>(0);

  const [createPresetThumbnail, { isLoading: isCreatingThumbnail }] =
    printareaPresetsApi.useCreatePresetThumbnailMutation();
  const [updatePreset, { isLoading: isUpdating }] =
    printareaPresetsApi.useUpdatePrintareaPresetMutation();
  const [generateSingleMockupVariation] =
    psdEngineApi.useGenerateSingleMockupVariationMutation();

  const applyPreset = async (preset: PrintareaPreset) => {
    try {
      dispatch(
        updateActiveSmartObject({
          print_area: activeSmartObject?.print_area,
          fit: preset.print_area_fit,
          global_asset_top: preset.print_area_top,
          global_asset_left: preset.print_area_left,
          global_asset_width: preset.print_area_width,
          global_asset_height: preset.print_area_height,
          rotate: preset.print_area_rotate,
        })
      );
      // await new Promise((resolve) => setTimeout(resolve, 250));
      await generateSingleMockupVariation({
        mockup_variation_id: activeVariation.id,
        asset_id: activeDesignAsset?.id,
        smart_object_id: activeSmartObject?.id,
        fit: preset.print_area_fit || 'contain',
        design_area_width: preset.print_area_width,
        design_area_height: preset.print_area_height,
        design_area_left: preset.print_area_left,
        design_area_top: preset.print_area_top,
        rotate: preset.print_area_rotate,
        subscription_type_id: 2,
      }).unwrap();
      captureEvent('Print area preset applied', {
        title: preset.name,
      });
      await dispatch(
        psdEngineApi.endpoints.getMockupSmartObjects.initiate(
          { mockup_id: mockup.id },
          {
            forceRefetch: true,
          }
        )
      ).unwrap();
      // toast.success('Preset successfully applied.', {
      //   toastId: 'preset_action_success_apply',
      //   position: 'bottom-right',
      //   autoClose: 5000,
      // });
    } catch (error: any) {
      console.log(error.data);
      toast.error(error.data.message, {
        toastId: 'preset_action_error_apply',
        position: 'bottom-right',
        autoClose: 5000,
      });
    }
  };

  const isApplied = (preset: PrintareaPreset) => {
    if (!preset) {
      return false;
    }
    return (
      activeSmartObject.global_asset_top === preset.print_area_top &&
      activeSmartObject.global_asset_left === preset.print_area_left &&
      activeSmartObject.global_asset_width === preset.print_area_width &&
      activeSmartObject.global_asset_height === preset.print_area_height &&
      activeSmartObject.rotate === preset.print_area_rotate &&
      activeSmartObject.fit === preset.print_area_fit
    );
  };

  const [selectedPrintareaPreset, setSelectedPrintareaPreset] = useState<
    PrintareaPreset | undefined
  >(
    activeSmartObject.print_area_presets.find((preset) => isApplied(preset)) ||
      activeSmartObject.print_area_presets?.[0] ||
      undefined
  );
  const [createdPresetId, setCreatedPresetId] = useState<number>(0);
  const [isCreatingPreset, setIsCreatingPreset] = useState<boolean>(false);

  // useEffect(() => {
  //   if (activeSmartObject) {
  //     const selectedPreset = activeSmartObject.print_area_presets.find(
  //       (preset) => isApplied(preset)
  //     );
  //     if (selectedPreset) {
  //       setSelectedPrintareaPreset(selectedPreset);
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   if (selectedPrintareaPreset) {
  //     console.log('apply preset 1');
  //     applyPreset(selectedPrintareaPreset);
  //     // const selectedPreset = activeSmartObject.print_area_presets.find(
  //     //   (preset) => isApplied(preset)
  //     // );
  //     // if (selectedPreset) {
  //     //   applyPreset(selectedPrintareaPreset);
  //     // }
  //   }
  // }, [selectedPrintareaPreset]);

  useEffect(() => {
    // When delete preset
    if (activeSmartObject && deletedPresetId) {
      const selectedPreset = activeSmartObject.print_area_presets[0];
      if (selectedPreset) {
        setSelectedPrintareaPreset(selectedPreset);
        applyPreset(selectedPreset);
      } else {
        setSelectedPrintareaPreset(undefined);
      }
    }
  }, [deletedPresetId]);

  useEffect(() => {
    // When create new preset
    if (activeSmartObject && createdPresetId) {
      const selectedPreset = activeSmartObject.print_area_presets.find(
        (preset) => preset.id === createdPresetId
      );
      if (selectedPreset) {
        setSelectedPrintareaPreset(selectedPreset);
        applyPreset(selectedPreset);
      }
    }
  }, [createdPresetId]);

  // useEffect(() => {
  //   async function updatePresetAction(
  //     activeSO: SmartObject,
  //     activeVar: MockupVariationElement,
  //     selectedPP: PrintareaPreset
  //   ) {
  //     if (selectedPP && !isApplied(selectedPP as PrintareaPreset)) {
  //       const presetExist = activeSO.print_area_presets.find(
  //         (preset) => preset.id === selectedPP.id
  //       );
  //       if (presetExist) {
  //         // TODO: activeVariation.export_path keeps previous export_path
  //         await updatePreset({
  //           id: selectedPP.id,
  //           print_area_left: activeSO.global_asset_left ?? 1,
  //           print_area_top: activeSO.global_asset_top ?? 1,
  //           print_area_width: activeSO.global_asset_width ?? 1,
  //           print_area_height: activeSO.global_asset_height ?? 1,
  //           print_area_rotate: activeSO.rotate,
  //           print_area_fit: activeSO.fit,
  //           thumbnail_url: activeVar.export_path,
  //         }).unwrap();
  //       }
  //     }
  //   }
  //   if (activeSmartObject) {
  //     if (
  //       !isUpdating &&
  //       selectedPrintareaPreset &&
  //       !isCreatingPreset &&
  //       !isCreatingVariations
  //     ) {
  //       updatePresetAction(
  //         activeSmartObject,
  //         activeVariation,
  //         selectedPrintareaPreset
  //       );
  //     }
  //   }
  // }, [activeSmartObject, activeVariation]);

  const editPresetDialog = useRef();
  const deletePresetDialog = useRef();
  const createPresetDialog = useRef();

  const createThumbnailAction = async () => {
    try {
      if (activeVariation.export_path) {
        await createPresetThumbnail({
          print_area_preset_mockup_id: selectedPrintareaPreset?.id || undefined,
          thumbnail_url: activeVariation.export_path,
          psd_id: mockup.psd_id as number,
        }).unwrap();
        captureEvent('Print area preset thumbnail created', {
          thumbnail: activeVariation.export_path,
        });
        toast.success('Thumbnail for preset and Mockup successfully created.', {
          toastId: 'create_thumbnail_action_success',
          position: 'bottom-right',
          autoClose: 5000,
        });
      } else {
        toast.error(
          'There is no active variation. If this error persists, please contact the support.',
          {
            toastId: 'no_active_variation_error',
            position: 'bottom-right',
            autoClose: 5000,
          }
        );
      }
    } catch (error: any) {
      console.log(error.data);
      toast.error(error.data.message, {
        toastId: 'create_thumbnail_action_error',
        position: 'bottom-right',
        autoClose: 5000,
      });
    }
  };

  const updatePresetAction = async () => {
    try {
      await updatePreset({
        id: selectedPrintareaPreset?.id,
        print_area_left: activeSmartObject.global_asset_left ?? 1,
        print_area_top: activeSmartObject.global_asset_top ?? 1,
        print_area_width: activeSmartObject.global_asset_width ?? 1,
        print_area_height: activeSmartObject.global_asset_height ?? 1,
        print_area_rotate: activeSmartObject.rotate,
        print_area_fit: activeSmartObject.fit,
        thumbnail_url: activeVariation.export_path,
        reflect_to_psd: true,
      }).unwrap();
      captureEvent('Print area preset updated', {
        title: selectedPrintareaPreset?.name,
      });
      const presets = await dispatch(
        psdEngineApi.endpoints.getMockupSmartObjects.initiate(
          { mockup_id: mockup.id },
          {
            forceRefetch: true,
          }
        )
      ).unwrap();
      const updatedPreset = presets.data
        .find((so) => so.id === activeSmartObject.id)
        ?.print_area_presets.find(
          (preset) => preset.id === selectedPrintareaPreset?.id
        );
      setSelectedPrintareaPreset(updatedPreset);
      toast.success('Preset successfully updated.', {
        toastId: 'preset_action_success_update',
        position: 'bottom-right',
        autoClose: 5000,
      });
    } catch (error: any) {
      console.log(error.data);
      toast.error(error.data.message, {
        toastId: 'preset_action_error_update',
        position: 'bottom-right',
        autoClose: 5000,
      });
    }
  };

  return (
    <Flex gap={'3'} align={'center'} width={'100%'}>
      <Select.Root
        value={selectedPrintareaPreset?.id?.toString()}
        disabled={!selectedPrintareaPreset}
      >
        <Select.Trigger style={{ width: '80%' }} />
        <Select.Content position="popper" style={{ width: '100%' }}>
          {activeSmartObject.print_area_presets.map((preset) => (
            <Select.Item
              key={preset.id}
              value={preset.id.toString()}
              onClick={() => {
                const selectedPreset =
                  activeSmartObject.print_area_presets.find(
                    (p) => p.id === preset.id
                  );
                setSelectedPrintareaPreset(selectedPreset);
                applyPreset(selectedPreset as PrintareaPreset);
              }}
              onMouseDown={() => {
                const selectedPreset =
                  activeSmartObject.print_area_presets.find(
                    (p) => p.id === preset.id
                  );
                setSelectedPrintareaPreset(selectedPreset);
                applyPreset(selectedPreset as PrintareaPreset);
              }}
            >
              <Flex align={'center'} justify={'between'} gap={'2'}>
                {isApplied(
                  selectedPrintareaPreset ||
                    activeSmartObject.print_area_presets[0]
                ) ? (
                  <Text>{preset.name}</Text>
                ) : selectedPrintareaPreset?.id === preset.id ? (
                  <i>{preset.name} *</i>
                ) : (
                  <Text>{preset.name}</Text>
                )}

                {/* {preset.reflected_to_psd === 1 && <PhotoshopIcon />} */}
              </Flex>
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>

      <EditPreset
        preset={selectedPrintareaPreset as PrintareaPreset}
        ref={editPresetDialog}
      />
      <DeletePreset
        preset={selectedPrintareaPreset as PrintareaPreset}
        setDeletedPresetId={setDeletedPresetId}
        ref={deletePresetDialog}
      />

      <CreateNewPreset
        setCreatedPresetId={setCreatedPresetId}
        ref={createPresetDialog}
      />

      <DropdownMenu.Root>
        <DropdownMenu.Trigger className="ddl-options-trigger">
          <IconButton variant="ghost" color="gray">
            <MenuDotsBoldIcon className="icon" width={'20px'} />
          </IconButton>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content side="right" sideOffset={25}>
          <DropdownMenu.Item
            // disabled={!selectedPrintareaPreset}
            onClick={() => {
              if (
                selectedPrintareaPreset &&
                !isApplied(
                  selectedPrintareaPreset ||
                    activeSmartObject.print_area_presets[0]
                )
              ) {
                updatePresetAction();
              }
            }}
            disabled={
              !selectedPrintareaPreset ||
              isApplied(
                selectedPrintareaPreset ||
                  activeSmartObject.print_area_presets[0]
              )
            }
          >
            Update Current
          </DropdownMenu.Item>

          <DropdownMenu.Item
            disabled={!selectedPrintareaPreset}
            onClick={() => {
              if (selectedPrintareaPreset) {
                (editPresetDialog?.current as any)?.openDialog();
              }
            }}
          >
            Rename
          </DropdownMenu.Item>

          <DropdownMenu.Item
            onClick={() => {
              (createPresetDialog?.current as any)?.openDialog();
            }}
          >
            Save as New Preset...
          </DropdownMenu.Item>
          <DropdownMenu.Separator />
          <DropdownMenu.Item onClick={() => createThumbnailAction()}>
            Create Thumbnail
          </DropdownMenu.Item>
          <DropdownMenu.Separator />
          <DropdownMenu.Item
            color="red"
            disabled={!selectedPrintareaPreset}
            onClick={() => {
              if (selectedPrintareaPreset) {
                (deletePresetDialog?.current as any)?.openDialog();
              }
            }}
          >
            Remove...
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </Flex>
  );
};
