import {
  AlertDialog,
  Box,
  Button,
  Dialog,
  Flex,
  IconButton,
  ScrollArea,
  Skeleton,
  Tabs,
  Text,
  TextField,
  Tooltip,
} from '@radix-ui/themes';
import { Helmet } from 'react-helmet-async';
import { ReactComponent as TuningLinearIcon } from '@/assets/icons/tuning-linear.svg';
import { mockupEditorEmbedApi } from '@/services/mockupEditorEmbed';
import { Link } from 'react-router-dom';
import { ReactComponent as DCLogoPurpleImage } from '@/assets/design-copilot-logo.svg';
import { ReactComponent as CodeFileLinearIcon } from '@/assets/icons/code-file-linear.svg';
import { FaviconWithFallback } from './FaviconWithFallback';
import { CreateMockupEditor } from './CreateMockupEditor';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { CodeIcon, BarChartIcon } from '@radix-ui/react-icons';
import { MockupEditorIframeIntegration } from '@/redux/slices/types';
import posthog from 'posthog-js';
import { toast } from 'react-toastify';
import { useAppSelector } from '@/hooks';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CodeImplementationExampleReact } from './CodeImplementationExamplReact';
import { CodeImplementationExample } from './CodeImplementationExample';
import { CodeImplementationExampleVue } from './CodeImplementationExampleVue';
import { IntegrationConfig } from './IntegrationConfig';
import { ReactComponent as TrashBin } from '@/assets/icons/trash-bin.svg';
import { ReactComponent as PenIcon } from '@/assets/icons/pen-linear.svg';
import { useForm, SubmitHandler } from 'react-hook-form';
import { DailyRendersLast30Days } from '../api-dashboard/components/report-charts/DailyRendersLast30Days';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.svg';

interface IFormInput {
  websiteKeyName: string;
  whitelistedDomain: string;
  logo: File;
}

export const MockupEditorEmbedIntegrations = () => {
  const captureEvent = useCaptureEvent();
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [analyticsOpen, setAnalyticsOpen] = useState<boolean>(false);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [codeExampleOpen, setCodeExampleOpen] = useState<boolean>(false);
  const [editedWebsiteKey, setEditedWebsiteKey] =
    useState<MockupEditorIframeIntegration>(null!);
  const [codeWebsiteKey, setCodeWebsiteKey] = useState<string>('');
  const { showNotificationBar } = useAppSelector((state) => state.appReducer);
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const { currentWorkspace } = useAppSelector(
    (state) => state.workspaceReducer
  );
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const mockupEditorIframeIntegrationsData =
    mockupEditorEmbedApi.useGetMockupEditorIframeIntegrationsQuery();
  const [deleteMockupEditorIframeIntegration, { isLoading: isDeleting }] =
    mockupEditorEmbedApi.useDeleteMockupEditorIframeIntegrationMutation();
  const [updateMockupEditorIframeIntegration, { isLoading: isUpdating }] =
    mockupEditorEmbedApi.useUpdateMockupEditorIframeIntegrationMutation();
  const [
    useUpdateMockupEditorIframeIntegrationConfig,
    { isLoading: isConfigLoading },
  ] =
    mockupEditorEmbedApi.useUpdateMockupEditorIframeIntegrationConfigMutation();

  const deleteKeyAction = async (item: MockupEditorIframeIntegration) => {
    try {
      await deleteMockupEditorIframeIntegration({ id: item.id });

      const currentDate = new Date();
      const isoDateString = currentDate.toISOString();

      posthog.capture('$groupidentify', {
        $group_type: 'Embedded Editor',
        $group_key: item.website_key,
        $group_set: {
          deletedAt: isoDateString,
        },
      });

      captureEvent('Mockup editor iframe - User deleted Website Key', {
        integration_name: item.integration_name,
        whitelisted_domain: item.whitelisted_domain,
      });
    } catch (error) {
      console.log('error: ', error);
      toast.warning((error as unknown as any).data.message, {
        toastId: 'website_key_action_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  const {
    register: registerUpdateDialog,
    handleSubmit: handleSubmitUpdateWebsiteKey,
    formState: { errors: updateErrors },
    reset: resetUpdateDialog,
    setValue,
  } = useForm<IFormInput>({
    defaultValues: {
      websiteKeyName: editedWebsiteKey?.integration_name,
      whitelistedDomain: editedWebsiteKey?.whitelisted_domain,
    },
  });

  useEffect(() => {
    setValue('websiteKeyName', editedWebsiteKey?.integration_name);
    setValue('whitelistedDomain', editedWebsiteKey?.whitelisted_domain);
  }, [editedWebsiteKey]);

  const onSubmitUpdateWebsiteKey: SubmitHandler<IFormInput> = (data) =>
    updateIntegration(data);

  const updateIntegration = async (data: IFormInput) => {
    try {
      await updateMockupEditorIframeIntegration({
        id: editedWebsiteKey.id,
        integration_name: data.websiteKeyName,
        whitelisted_domain: data.whitelistedDomain,
      });
      const base64PrefixRegex = /^data:(\w+\/[\w+.-]+)?;base64,/;
      await useUpdateMockupEditorIframeIntegrationConfig({
        mockup_editor_iframe_integration_id: editedWebsiteKey.id,
        config: {
          logo: editedWebsiteKey.config?.logo?.match(base64PrefixRegex)
            ? editedWebsiteKey.config?.logo
            : editedWebsiteKey.config?.logo === null
            ? null
            : undefined,
          color: editedWebsiteKey.config?.color || '',
          whitelabel: editedWebsiteKey.config?.whitelabel || false,
          dark_mode: editedWebsiteKey.config?.dark_mode || false,
        },
      });

      posthog.capture('$groupidentify', {
        $group_type: 'Embedded Editor',
        $group_key: editedWebsiteKey.website_key,
        $group_set: {
          websiteName: data.websiteKeyName,
          websiteDomain: data.whitelistedDomain,
          whitelabeled:
            editedWebsiteKey.config?.whitelabel === false
              ? true
              : editedWebsiteKey.config?.whitelabel === true
              ? false
              : false,
        },
      });

      captureEvent('Mockup editor iframe - User edited Website Key', {
        integration_name: data.websiteKeyName,
        whitelisted_domain: data.whitelistedDomain,
      });
      setEditDialogOpen(false);
    } catch (error) {
      console.log('error: ', error);
      toast.warning((error as unknown as any).data.message, {
        toastId: 'website_key_action_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  return (
    <Flex
      className="page-wrapper embed-mockup-editor-page"
      direction={'column'}
      align={'center'}
      gap={'5'}
    >
      <Helmet>
        <title>Dynamic Mockups | Embed Mockup Editor</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <Dialog.Root open={analyticsOpen} onOpenChange={setAnalyticsOpen}>
        <Dialog.Content
          aria-describedby={undefined}
          width={'100%'}
          minHeight={'55svh'}
          maxWidth={'1025px'}
          maxHeight={'75svh'}
        >
          <Dialog.Title>Credits usage analytics</Dialog.Title>
          <Dialog.Description></Dialog.Description>

          <Flex direction="column" gap="3" my={'4'}>
            <DailyRendersLast30Days
              apiKeyUUID={editedWebsiteKey?.api_key_uuid}
            />
          </Flex>

          <Flex gap="3" justify="end">
            <Dialog.Close>
              <Button variant="soft" color="gray">
                Close
              </Button>
            </Dialog.Close>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
      <Dialog.Root open={editDialogOpen} onOpenChange={setEditDialogOpen}>
        <Dialog.Content aria-describedby={undefined} style={{ maxWidth: 450 }}>
          <Dialog.Title>Edit Embed Mockup Editor</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            Edit website name and whitelisted domain.
          </Dialog.Description>
          <form
            onSubmit={handleSubmitUpdateWebsiteKey(onSubmitUpdateWebsiteKey)}
          >
            <Flex direction="column" gap="3" my={'4'}>
              <Flex direction={'column'}>
                <label>
                  <Text size={'1'}>Website name</Text>
                  <TextField.Root
                    {...registerUpdateDialog('websiteKeyName', {
                      required: {
                        value: true,
                        message: 'Website name field is required',
                      },
                    })}
                    placeholder="Dynamic Mockups"
                  />
                </label>
                {updateErrors.websiteKeyName && (
                  <Text role="alert" size={'1'} color="red">
                    {updateErrors.websiteKeyName.message}
                  </Text>
                )}
              </Flex>
              <Flex direction={'column'}>
                <label>
                  <Text size={'1'}>Whitelisted domain</Text>
                  <TextField.Root
                    {...registerUpdateDialog('whitelistedDomain', {
                      required: {
                        value: true,
                        message: 'Whitelisted domain field is required',
                      },
                    })}
                    placeholder="https://dynamicmockups.com"
                  />
                </label>
                {updateErrors.whitelistedDomain && (
                  <Text role="alert" size={'1'} color="red">
                    {updateErrors.whitelistedDomain.message}
                  </Text>
                )}
              </Flex>

              <IntegrationConfig
                editedIntegration={{
                  ...editedWebsiteKey,
                }}
                setData={(data) => {
                  setEditedWebsiteKey((prev) => ({
                    ...prev,
                    ...data,
                  }));
                }}
              />
            </Flex>

            <Flex gap="3" justify="end">
              <Dialog.Close>
                <Button variant="soft" color="gray">
                  Cancel
                </Button>
              </Dialog.Close>
              <Button
                variant="solid"
                color="blue"
                type="submit"
                loading={isUpdating || isConfigLoading}
              >
                Update
              </Button>
            </Flex>
          </form>
        </Dialog.Content>
      </Dialog.Root>
      <Dialog.Root
        open={codeExampleOpen}
        onOpenChange={(open) => {
          setCodeExampleOpen(open);
        }}
      >
        <Dialog.Content
          aria-describedby={undefined}
          width={'100%'}
          maxWidth={'992px'}
          minHeight={'706px'}
        >
          <Dialog.Title>Implementation code example</Dialog.Title>
          <Dialog.Description></Dialog.Description>

          <Flex direction="column" gap="3" my={'4'}>
            <Tabs.Root
              defaultValue="javascript"
              onValueChange={(value) => {
                captureEvent(
                  `Mockup editor iframe - User opened embed code example`,
                  {
                    type: value,
                    integration_name: editedWebsiteKey.integration_name,
                    whitelisted_domain: editedWebsiteKey.whitelisted_domain,
                  }
                );
              }}
            >
              <Tabs.List>
                <Tabs.Trigger value="javascript">Javascript</Tabs.Trigger>
                <Tabs.Trigger value="react">ReactJs</Tabs.Trigger>
                <Tabs.Trigger value="vue">VueJs</Tabs.Trigger>
              </Tabs.List>

              <ScrollArea
                className="scroll-area-wrapper"
                type="hover"
                scrollbars="vertical"
                style={{
                  height: 'calc(68vh)',
                  maxHeight: '100vh',
                  position: 'relative',
                }}
              >
                <Box pt="3">
                  <Tabs.Content value="javascript">
                    <CodeImplementationExample websiteKey={codeWebsiteKey} />
                  </Tabs.Content>

                  <Tabs.Content value="react">
                    <CodeImplementationExampleReact
                      websiteKey={codeWebsiteKey}
                    />
                  </Tabs.Content>

                  <Tabs.Content value="vue">
                    <CodeImplementationExampleVue websiteKey={codeWebsiteKey} />
                  </Tabs.Content>
                </Box>
              </ScrollArea>
            </Tabs.Root>
          </Flex>

          <Flex gap="3" justify="end">
            <Dialog.Close>
              <Button variant="solid" color="blue">
                Done
              </Button>
            </Dialog.Close>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>

      <Flex
        className="header"
        align={'center'}
        justify={'between'}
        maxWidth={'640px'}
        width={'100%'}
      >
        <Text className="page-title">Embed Mockup Editor</Text>

        <Flex align={'center'} gap={'4'}>
          <Link
            to="https://docs.dynamicmockups.com/mockup-editor-sdk/embed-editor"
            target="_blank"
            style={{ lineHeight: '0' }}
          >
            <Button
              variant="ghost"
              color="gray"
              size={'3'}
              onClick={() => captureEvent('Embed Mockup Editor Docs visited')}
            >
              <CodeFileLinearIcon
                className="icon"
                width={'20px'}
                height={'20px'}
              />
              <Text className="button-text">Read Docs</Text>
            </Button>
          </Link>
          <CreateMockupEditor
            setCodeExampleOpen={setCodeExampleOpen}
            setCodeWebsiteKey={setCodeWebsiteKey}
          />
        </Flex>
      </Flex>
      <ScrollArea
        className="scroll-area-page-wrapper"
        style={{ maxWidth: '660px' }}
      >
        <Flex
          className="page-content"
          direction={'column'}
          width={'100%'}
          gap={'2'}
          px={'10px'}
        >
          {mockupEditorIframeIntegrationsData.data?.data
            ?.slice(0)
            ?.reverse()
            ?.map((editor) => (
              <Flex
                key={editor.id}
                className="full-width-box-item"
                align={'center'}
                justify={'between'}
                gap={'4'}
              >
                <Flex
                  className="content"
                  width={'100%'}
                  gap={'4'}
                  align={'center'}
                >
                  <Flex
                    align={'center'}
                    justify={'center'}
                    width={'40px'}
                    height={'40px'}
                  >
                    {editor.config?.logo ? (
                      <Link
                        to={editor.whitelisted_domain}
                        style={{ lineHeight: '0' }}
                        target="_blank"
                      >
                        <img src={editor.config?.logo} className="item-icon" />
                      </Link>
                    ) : (
                      <Link
                        to={editor.whitelisted_domain}
                        style={{ lineHeight: '0' }}
                        target="_blank"
                      >
                        <FaviconWithFallback
                          domain={editor.whitelisted_domain}
                          fallback={<DCLogoPurpleImage className="item-icon" />}
                        />
                      </Link>
                    )}
                  </Flex>
                  <Flex
                    className="text"
                    direction={'column'}
                    justify={'center'}
                    gap={'1'}
                  >
                    <Text className="title">{editor.integration_name}</Text>
                    <Link
                      className="link"
                      to={editor.whitelisted_domain}
                      target="_blank"
                    >
                      <Text className="description">
                        {editor.whitelisted_domain}
                      </Text>
                    </Link>
                  </Flex>
                </Flex>
                <Flex className="actions" gap={'1'}>
                  {/* <Button variant="ghost" color="gray" size={'2'}>
                    <TuningLinearIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />{' '}
                    <Text className="button-text">Configure</Text>
                  </Button> */}
                  <Tooltip content="View embedding code">
                    <Button
                      event-tracker-id="view-embedding-code"
                      size={'1'}
                      variant="soft"
                      color="gray"
                      onClick={() => {
                        captureEvent(
                          'Mockup editor iframe - User visited embed code example',
                          {
                            integration_name: editor.integration_name,
                            whitelisted_domain: editor.whitelisted_domain,
                          }
                        );
                        setEditedWebsiteKey(editor);
                        setCodeExampleOpen(true);
                        setCodeWebsiteKey(editor.website_key);
                      }}
                    >
                      <CodeIcon width={'16px'} height={'16px'} />
                    </Button>
                  </Tooltip>
                  <Tooltip content="Copy Website Key">
                    <Button
                      event-tracker-id="copy-website-key"
                      size={'1'}
                      variant="soft"
                      color="gray"
                      onClick={() => {
                        navigator.clipboard.writeText(editor.website_key);
                        captureEvent(
                          'Mockup editor iframe - User copied Website Key',
                          {
                            integration_name: editor.integration_name,
                            whitelisted_domain: editor.whitelisted_domain,
                          }
                        );
                        toast.success('Website Key copied to clipboard.', {
                          toastId: 'website_key_action_error',
                          position: 'bottom-right',
                          autoClose: 1500,
                        });
                      }}
                    >
                      <CopyIcon
                        className="icon"
                        width={'14px'}
                        height={'14px'}
                      />
                    </Button>
                  </Tooltip>
                  <Tooltip content="Edit key">
                    <Button
                      event-tracker-id="edit-website-key"
                      size={'1'}
                      variant="soft"
                      color="gray"
                      onClick={() => {
                        setEditedWebsiteKey(editor);
                        setEditDialogOpen(true);
                      }}
                    >
                      <PenIcon
                        className="icon"
                        width={'14px'}
                        height={'14px'}
                      />
                    </Button>
                  </Tooltip>
                  <Tooltip content="Analytics">
                    <Button
                      event-tracker-id="open-iframe-embed-key-analytics"
                      size={'1'}
                      variant="soft"
                      color="gray"
                      onClick={() => {
                        setAnalyticsOpen(true);
                        setEditedWebsiteKey(editor);
                        captureEvent('Api analytics modal opened');
                      }}
                    >
                      <BarChartIcon width={'14px'} height={'14px'} />
                    </Button>
                  </Tooltip>
                  <AlertDialog.Root>
                    <Tooltip content="Delete key">
                      <AlertDialog.Trigger>
                        <Button size={'1'} variant="soft" color="gray">
                          <TrashBin
                            className="icon"
                            width={'14px'}
                            height={'14px'}
                          />
                        </Button>
                      </AlertDialog.Trigger>
                    </Tooltip>
                    <AlertDialog.Content style={{ maxWidth: 600 }}>
                      <AlertDialog.Title>
                        Delete Embed Mockup Editor
                      </AlertDialog.Title>
                      <AlertDialog.Description size="2">
                        Are you sure you want to delete Embed Mockup Editor used
                        on '{editor.integration_name}'?
                      </AlertDialog.Description>

                      <Flex gap="3" mt="4" justify="end">
                        <AlertDialog.Cancel>
                          <Button variant="soft" color="gray">
                            Cancel
                          </Button>
                        </AlertDialog.Cancel>
                        <AlertDialog.Cancel>
                          <Button
                            event-tracker-id="delete-website-key"
                            variant="solid"
                            color="red"
                            onClick={() => deleteKeyAction(editor)}
                          >
                            Delete
                          </Button>
                        </AlertDialog.Cancel>
                      </Flex>
                    </AlertDialog.Content>
                  </AlertDialog.Root>
                </Flex>
              </Flex>
            ))}

          {mockupEditorIframeIntegrationsData.isLoading && (
            <Flex direction={'column'} gap={'1'}>
              <Skeleton style={{ height: '80px' }}></Skeleton>
              <Skeleton style={{ height: '80px' }}></Skeleton>
            </Flex>
          )}

          {!mockupEditorIframeIntegrationsData.isLoading &&
            (mockupEditorIframeIntegrationsData.data?.data || []).length ===
              0 && (
              <Flex
                align={'center'}
                justify={'center'}
                p={'3'}
                width={'100%'}
                height={'100%'}
              >
                <Text size={'1'}>There are no active Embed Mockup Editors</Text>
              </Flex>
            )}
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

