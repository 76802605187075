import './left-side-menu.scss';
import {
  Box,
  Text,
  Flex,
  Button,
  DropdownMenu,
  Avatar,
  Tooltip,
} from '@radix-ui/themes';
import { pageSlug } from '../../Workspace';
import { ReactComponent as DesignsIcon } from '@/assets/icons/designs.svg';
import { ReactComponent as AccountIcon } from '@/assets/icons/account.svg';
import { ReactComponent as SlackIcon } from '@/assets/icons/slack.svg';
import { ReactComponent as MenuToggleIcon } from '@/assets/icons/menu-toggle.svg';
import { ReactComponent as ApiCodeIcon } from '@/assets/icons/api-code.svg';
import { ReactComponent as IntegrationsIcon } from '@/assets/icons/integrations.svg';
import { ReactComponent as BulbIcon } from '@/assets/icons/bulb.svg';
import { ReactComponent as BagIcon } from '@/assets/icons/bag.svg';
import { ReactComponent as DCLogoPurpleImage } from '@/assets/design-copilot-logo.svg';
import { ReactComponent as MockupsIcon } from '@/assets/icons/mockups.svg';
import { ReactComponent as PalleteIcon } from '@/assets/icons/pallete-2-linear.svg';
import { ReactComponent as TreeSquaresDefaultIcon } from '@/assets/icons/three-squares-linear-default.svg';
import { ReactComponent as LayersMinimalisticLinearIcon } from '@/assets/icons/layers-minimalistic-linear.svg';
import { ReactComponent as EtsyIcon } from '@/assets/icons/etsy.svg';
import { Link, useNavigate } from 'react-router-dom';
import {
  BoxModelIcon,
  Component1Icon,
  DotsVerticalIcon,
  ExitIcon,
  HamburgerMenuIcon,
  Share1Icon,
} from '@radix-ui/react-icons';
import { useMediaQuery } from 'react-responsive';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { useEffect, useRef, useState } from 'react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { ReactComponent as ProCrownIcon } from '@/assets/icons/pro-crown.svg';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import { setLeftSideMenuWidth } from '@/redux/slices/workspace';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { paymentsApi } from '@/services/payments';
import { formatNumberOfCredits } from '@/helpers';
import Intercom from '@intercom/messenger-js-sdk';
import { ReactComponent as WidgetIcon } from '@/assets/icons/widget.svg';
import { ReactComponent as FileTemplateLinearIcon } from '@/assets/icons/file-template-linear.svg';
import { ReactComponent as LibraryLinearIcon } from '@/assets/icons/library-linear.svg';
import { ReactComponent as ArrowRightLinearIcon } from '@/assets/icons/arrow-right.svg';
import { ReactComponent as PlugCircleLinear } from '@/assets/icons/plug-circle-linear.svg';
import { ReactComponent as DmMenuIcon } from '@/assets/icons/dm-menu-icon.svg';
import { ReactComponent as QuestionCircleLinearIcon } from '@/assets/icons/question-circle-linear.svg';
import { shopsIntegrationsApi } from '@/services/shopsIntegrations';
import { ToggleThemeButton } from '@/features/layout/header/components/ToggleThemeModeButton';
import { setThemeMode } from '@/redux/slices/app';
import { logout } from '@/redux/slices/auth';
import posthog from 'posthog-js';

interface ILeftSideMenu {
  activeSection: pageSlug;
}

export const LeftSideMenu = ({ activeSection }: ILeftSideMenu) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const captureEvent = useCaptureEvent();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { showNotificationBar } = useAppSelector((state) => state.appReducer);
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const { activeIntegrations } = useAppSelector(
    (state) => state.shopsIntegrations
  );
  const { themeMode } = useAppSelector((state) => state.appReducer);

  const [subMenuMockupsOpen, setSubMenuMockupsOpen] = useState(true);

  const [collapsed, setCollapsed] = useState(false);
  const isAppPro = useFeatureFlagEnabled('app-pro');

  const leftSideMenu = useRef(null);
  const userRendersData = paymentsApi.useGetUserRendersQuery();

  shopsIntegrationsApi.useGetActiveIntegrationsQuery(null!, {
    refetchOnMountOrArgChange: true,
  });

  Intercom({ app_id: 's2w7quxz', hide_default_launcher: false });

  useEffect(() => {
    const handleResize = () => {
      if (leftSideMenu.current) {
        dispatch(
          setLeftSideMenuWidth((leftSideMenu.current as any).clientWidth)
        );
      }
    };

    // Create a ResizeObserver instance
    const resizeObserver = new ResizeObserver(handleResize);

    // Start observing the element
    if (leftSideMenu.current) {
      resizeObserver.observe(leftSideMenu.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (leftSideMenu.current) {
        resizeObserver.unobserve(leftSideMenu.current);
      }
    };
  }, []);

  const setThemeAction = (theme: 'dark' | 'light') => {
    dispatch(setThemeMode(theme));
    window.localStorage.setItem('app-theme', theme);
  };

  const getUserNameText = () => {
    if (currentUser?.first_name && currentUser.last_name) {
      return currentUser?.first_name + ' ' + currentUser.last_name;
    } else if (currentUser?.first_name) {
      return currentUser?.first_name;
    } else if (currentUser?.last_name) {
      return currentUser.last_name;
    } else {
      return 'Account';
    }
  };

  const appLogout = () => {
    captureEvent('User Logged Out');
    // reset posthog after logout to unlink any future events made on that device with that user
    posthog.reset();

    dispatch(logout());
    window.location.replace('/login');
  };

  return (
    <>
      {!isMobile && (
        <Flex
          className="left-side-menu-new"
          direction={'column'}
          align={'center'}
          justify={'between'}
          px={'4'}
          py={'4'}
        >
          <Flex direction={'column'} className="top" gap={'4'} width={'100%'}>
            <Flex
              className="logo-wrapper"
              direction={'row'}
              align={'center'}
              gap={'1'}
              p={'2'}
              onClick={() => navigate(`/my-templates`)}
            >
              <DCLogoPurpleImage width={'24px'} height={'24px'} />
              <Text className="app-name" weight={'bold'} size={'3'}>
                Dynamic Mockups
              </Text>
            </Flex>
            <Flex direction={'column'} className="groups" gap={'4'}>
              <Flex className="group" direction={'column'} gap={'2px'}>
                <Flex className="item blank" align={'center'}>
                  <Text className="group-name">Create</Text>
                </Flex>
                <Flex
                  className="item"
                  gap={'12px'}
                  align={'center'}
                  onClick={() => {
                    navigate('/create-mockups');
                    captureEvent('Create Mockups: Create Mockups page opened');
                  }}
                >
                  <LayersMinimalisticLinearIcon
                    className="icon"
                    width="20"
                    height="20"
                  />
                  <Text className="label">Batch Create</Text>
                </Flex>
                <Flex
                  gap={'12px'}
                  align={'center'}
                  className={`item ${
                    activeSection === 'mockup-library' && 'active'
                  }`}
                  onClick={() => navigate('/mockup-library')}
                >
                  <WidgetIcon className="icon" width="20" height="20" />
                  <Text className="label">Public Library</Text>
                </Flex>
              </Flex>
              <Flex className="group" direction={'column'} gap={'2px'}>
                <Flex className="item blank" align={'center'}>
                  <Text className="group-name">My Content</Text>
                </Flex>
                <Flex
                  gap={'12px'}
                  align={'center'}
                  className={`item ${
                    activeSection === 'my-templates' && 'active'
                  }`}
                  onClick={() => navigate('/my-templates')}
                >
                  <FileTemplateLinearIcon
                    className="icon"
                    width="20"
                    height="20"
                  />
                  <Text className="label">Mockup Templates</Text>
                </Flex>
                <Flex
                  gap={'12px'}
                  align={'center'}
                  className={`item ${
                    activeSection === 'brand-assets' && 'active'
                  }`}
                  onClick={() => navigate('/brand-assets')}
                >
                  <LibraryLinearIcon className="icon" width="20" height="20" />
                  <Text className="label">Brand Assets</Text>
                </Flex>
              </Flex>
              <Flex className="group" direction={'column'} gap={'2px'}>
                <Flex className="item blank" align={'center'}>
                  <Text className="group-name">Apps</Text>
                </Flex>
                <Flex
                  gap={'12px'}
                  align={'center'}
                  className={`item ${
                    activeSection === 'dashboard-api' && 'active'
                  }`}
                  onClick={() => navigate('/dashboard-api')}
                >
                  <PlugCircleLinear className="icon" width="20" height="20" />
                  <Text className="label">API Integration</Text>
                </Flex>
                <Flex
                  gap={'12px'}
                  align={'center'}
                  className={`item ${
                    activeSection === 'mockup-editor-embed-integrations' &&
                    'active'
                  }`}
                  onClick={() => navigate('/mockup-editor-embed-integrations')}
                >
                  <DmMenuIcon className="" width="20" height="20" />
                  <Text className="label">Embed Mockup Editor</Text>
                </Flex>

                <Flex
                  gap={'12px'}
                  align={'center'}
                  className={`item ${
                    activeSection === 'integrations' && 'active'
                  }`}
                  onClick={() => navigate('/integrations')}
                >
                  <ArrowRightLinearIcon
                    className="icon"
                    width="16"
                    height="16"
                  />
                  <Text className="label">All Integrations</Text>
                </Flex>
                {activeIntegrations.some(
                  (activeIntegration) => activeIntegration.name === 'Etsy'
                ) && (
                  <Flex
                    gap={'12px'}
                    align={'center'}
                    className={`item ${
                      activeSection === 'shop-products' && 'active'
                    }`}
                    onClick={() => navigate('/shop-products/1')}
                  >
                    <EtsyIcon className="" width="20" height="20" />
                    <Text className="label">Etsy Products</Text>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex
            direction={'column'}
            className="bottom"
            gap={'4'}
            width={'100%'}
          >
            <Flex direction={'column'} className="groups" gap={'4'}>
              <Flex className="group" direction={'column'} gap={'2px'}>
                <Flex
                  gap={'12px'}
                  align={'center'}
                  className={`item`}
                  onClick={() => {
                    captureEvent('Upgrade your plan modal viewed', {
                      source: 'Left sidebar "Credits" CTA',
                    });
                    dispatch(setIsAppPaymentPopupOpen(true));
                  }}
                >
                  <TreeSquaresDefaultIcon
                    className="icon"
                    width="20"
                    height="20"
                  />
                  <Text className="label">
                    {formatNumberOfCredits(
                      userRendersData.data?.data?.[0]?.renders_remaining || 0
                    )}{' '}
                    Credits
                  </Text>
                </Flex>
                <Flex gap={'2'} align={'center'} justify={'between'}>
                  <Flex
                    gap={'12px'}
                    align={'center'}
                    width={'100%'}
                    className={`item ${
                      activeSection === 'account' && 'active'
                    }`}
                    onClick={() => navigate('/account')}
                  >
                    {currentUser?.avatar ? (
                      <Avatar
                        className="user-avatar"
                        src={currentUser?.avatar}
                        size={'1'}
                        radius="full"
                        fallback="DM"
                      />
                    ) : (
                      <AccountIcon className="icon" width="20" height="20" />
                    )}
                    <Tooltip content={getUserNameText()}>
                      <Text className="label username-text">
                        {getUserNameText()}
                      </Text>
                    </Tooltip>
                  </Flex>
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger>
                      <Button size={'1'} variant="ghost" color="gray">
                        <DotsVerticalIcon width="16" height="16" />
                      </Button>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content>
                      <Link
                        className="main-link"
                        to={
                          'https://join.slack.com/t/dynamicmockups/shared_invite/zt-2165e7s2a-sEo2vTTs22fL2tUZdNFUXg'
                        }
                        target="_blank"
                      >
                        <DropdownMenu.Item>
                          <SlackIcon width={'16px'} height={'16px'} /> Join
                          Slack Community
                        </DropdownMenu.Item>
                      </Link>
                      <Link
                        className="main-link"
                        to={'https://dynamicmockups.com/affiliate-program/'}
                        target="_blank"
                      >
                        <DropdownMenu.Item>
                          <Share1Icon width={'16px'} height={'16px'} /> Become
                          Affiliate
                        </DropdownMenu.Item>
                      </Link>
                      <Link
                        className="main-link"
                        to={'https://dynamicmockups.featurebase.app/'}
                        target="_blank"
                      >
                        <DropdownMenu.Item>
                          <BulbIcon
                            className="icon"
                            width={'16px'}
                            height={'16px'}
                          />
                          Request a Feature
                        </DropdownMenu.Item>
                      </Link>

                      <DropdownMenu.Separator />
                      <DropdownMenu.Item
                        onClick={() =>
                          setThemeAction(
                            themeMode === 'light' ? 'dark' : 'light'
                          )
                        }
                      >
                        <ToggleThemeButton /> Toggle theme
                      </DropdownMenu.Item>
                      <DropdownMenu.Item onClick={() => appLogout()}>
                        <ExitIcon width={'16px'} height={'16px'} /> Logout
                      </DropdownMenu.Item>
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}

      {isMobile && (
        <Flex px={'4'} py={'2'} align={'center'} justify={'between'}>
          <Flex
            className="logo-wrapper"
            direction={'row'}
            align={'center'}
            gap={'2'}
            p={'2'}
            mb={'3'}
            onClick={() => navigate(`/my-templates`)}
          >
            <DCLogoPurpleImage width={'32px'} height={'32px'} />
            <Text className="app-name" weight={'bold'} size={'3'}>
              Dynamic Mockups
            </Text>
          </Flex>

          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Button
                className="mobile-ham-menu"
                color="gray"
                variant="soft"
                size={'2'}
              >
                <HamburgerMenuIcon />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content
              className="mobile-menu-wrapper"
              variant="soft"
              color="gray"
            >
              <Flex
                className="left-side-menu-new mobile"
                direction={'column'}
                align={'center'}
                justify={'between'}
                px={'4'}
                py={'4'}
              >
                <Flex
                  direction={'column'}
                  className="top"
                  gap={'4'}
                  width={'100%'}
                >
                  <Flex
                    className="logo-wrapper"
                    direction={'row'}
                    align={'center'}
                    gap={'1'}
                    p={'2'}
                    onClick={() => navigate(`/my-templates`)}
                  >
                    <DCLogoPurpleImage width={'24px'} height={'24px'} />
                    <Text className="app-name" weight={'bold'} size={'3'}>
                      Dynamic Mockups
                    </Text>
                  </Flex>
                  <Flex direction={'column'} className="groups" gap={'4'}>
                    <Flex className="group" direction={'column'} gap={'2px'}>
                      <Flex className="item blank" align={'center'}>
                        <Text className="group-name">Create</Text>
                      </Flex>
                      <Flex
                        className="item"
                        gap={'12px'}
                        align={'center'}
                        onClick={() => {
                          navigate('/create-mockups');
                          captureEvent(
                            'Create Mockups: Create Mockups page opened'
                          );
                        }}
                      >
                        <LayersMinimalisticLinearIcon
                          className="icon"
                          width="20"
                          height="20"
                        />
                        <Text className="label">Batch Create</Text>
                      </Flex>
                      <Flex
                        gap={'12px'}
                        align={'center'}
                        className={`item ${
                          activeSection === 'mockup-library' && 'active'
                        }`}
                        onClick={() => navigate('/mockup-library')}
                      >
                        <WidgetIcon className="icon" width="20" height="20" />
                        <Text className="label">Public Library</Text>
                      </Flex>
                    </Flex>
                    <Flex className="group" direction={'column'} gap={'2px'}>
                      <Flex className="item blank" align={'center'}>
                        <Text className="group-name">My Content</Text>
                      </Flex>
                      <Flex
                        gap={'12px'}
                        align={'center'}
                        className={`item ${
                          activeSection === 'my-templates' && 'active'
                        }`}
                        onClick={() => navigate('/my-templates')}
                      >
                        <FileTemplateLinearIcon
                          className="icon"
                          width="20"
                          height="20"
                        />
                        <Text className="label">Mockup Templates</Text>
                      </Flex>
                      <Flex
                        gap={'12px'}
                        align={'center'}
                        className={`item ${
                          activeSection === 'brand-assets' && 'active'
                        }`}
                        onClick={() => navigate('/brand-assets')}
                      >
                        <LibraryLinearIcon
                          className="icon"
                          width="20"
                          height="20"
                        />
                        <Text className="label">Brand Assets</Text>
                      </Flex>
                    </Flex>
                    <Flex className="group" direction={'column'} gap={'2px'}>
                      <Flex className="item blank" align={'center'}>
                        <Text className="group-name">Apps</Text>
                      </Flex>
                      <Flex
                        gap={'12px'}
                        align={'center'}
                        className={`item ${
                          activeSection === 'dashboard-api' && 'active'
                        }`}
                        onClick={() => navigate('/dashboard-api')}
                      >
                        <PlugCircleLinear
                          className="icon"
                          width="20"
                          height="20"
                        />
                        <Text className="label">API Integration</Text>
                      </Flex>
                      <Flex
                        gap={'12px'}
                        align={'center'}
                        className={`item ${
                          activeSection ===
                            'mockup-editor-embed-integrations' && 'active'
                        }`}
                        onClick={() =>
                          navigate('/mockup-editor-embed-integrations')
                        }
                      >
                        <DmMenuIcon className="" width="20" height="20" />
                        <Text className="label">Embed Mockup Editor</Text>
                      </Flex>

                      <Flex
                        gap={'12px'}
                        align={'center'}
                        className={`item ${
                          activeSection === 'integrations' && 'active'
                        }`}
                        onClick={() => navigate('/integrations')}
                      >
                        <ArrowRightLinearIcon
                          className="icon"
                          width="16"
                          height="16"
                        />
                        <Text className="label">All Integrations</Text>
                      </Flex>
                      {activeIntegrations.some(
                        (activeIntegration) => activeIntegration.name === 'Etsy'
                      ) && (
                        <Flex
                          gap={'12px'}
                          align={'center'}
                          className={`item ${
                            activeSection === 'shop-products' && 'active'
                          }`}
                          onClick={() => navigate('/shop-products/1')}
                        >
                          <EtsyIcon className="" width="20" height="20" />
                          <Text className="label">Etsy Products</Text>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                <Flex
                  direction={'column'}
                  className="bottom"
                  gap={'4'}
                  width={'100%'}
                >
                  <Flex direction={'column'} className="groups" gap={'4'}>
                    <Flex className="group" direction={'column'} gap={'2px'}>
                      <Flex
                        gap={'12px'}
                        align={'center'}
                        className={`item`}
                        onClick={() => {
                          captureEvent('Upgrade your plan modal viewed', {
                            source: 'Left sidebar "Credits" CTA',
                          });
                          dispatch(setIsAppPaymentPopupOpen(true));
                        }}
                      >
                        <TreeSquaresDefaultIcon
                          className="icon"
                          width="20"
                          height="20"
                        />
                        <Text className="label">
                          {formatNumberOfCredits(
                            userRendersData.data?.data?.[0]
                              ?.renders_remaining || 0
                          )}{' '}
                          Credits
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'} justify={'between'}>
                        <Flex
                          gap={'12px'}
                          align={'center'}
                          width={'100%'}
                          className={`item ${
                            activeSection === 'account' && 'active'
                          }`}
                          onClick={() => navigate('/account')}
                        >
                          {currentUser?.avatar ? (
                            <Avatar
                              className="user-avatar"
                              src={currentUser?.avatar}
                              size={'1'}
                              radius="full"
                              fallback="DM"
                            />
                          ) : (
                            <AccountIcon
                              className="icon"
                              width="20"
                              height="20"
                            />
                          )}
                          <Tooltip content={getUserNameText()}>
                            <Text className="label username-text">
                              {getUserNameText()}
                            </Text>
                          </Tooltip>
                        </Flex>
                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger>
                            <Button size={'1'} variant="ghost" color="gray">
                              <DotsVerticalIcon width="16" height="16" />
                            </Button>
                          </DropdownMenu.Trigger>
                          <DropdownMenu.Content>
                            <Link
                              className="main-link"
                              to={
                                'https://join.slack.com/t/dynamicmockups/shared_invite/zt-2165e7s2a-sEo2vTTs22fL2tUZdNFUXg'
                              }
                              target="_blank"
                            >
                              <DropdownMenu.Item>
                                <SlackIcon width={'16px'} height={'16px'} />{' '}
                                Join Slack Community
                              </DropdownMenu.Item>
                            </Link>
                            <Link
                              className="main-link"
                              to={
                                'https://dynamicmockups.com/affiliate-program/'
                              }
                              target="_blank"
                            >
                              <DropdownMenu.Item>
                                <Share1Icon width={'16px'} height={'16px'} />{' '}
                                Become Affiliate
                              </DropdownMenu.Item>
                            </Link>
                            <Link
                              className="main-link"
                              to={'https://dynamicmockups.featurebase.app/'}
                              target="_blank"
                            >
                              <DropdownMenu.Item>
                                <BulbIcon
                                  className="icon"
                                  width={'16px'}
                                  height={'16px'}
                                />
                                Request a Feature
                              </DropdownMenu.Item>
                            </Link>

                            <DropdownMenu.Separator />
                            <DropdownMenu.Item
                              onClick={() =>
                                setThemeAction(
                                  themeMode === 'light' ? 'dark' : 'light'
                                )
                              }
                            >
                              <ToggleThemeButton /> Toggle theme
                            </DropdownMenu.Item>
                            <DropdownMenu.Item onClick={() => appLogout()}>
                              <ExitIcon width={'16px'} height={'16px'} /> Logout
                            </DropdownMenu.Item>
                          </DropdownMenu.Content>
                        </DropdownMenu.Root>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </Flex>
      )}
    </>
  );
};

