import './sync-images.scss';
import { Badge, Box, Flex, ScrollArea, Text } from '@radix-ui/themes';
import { psdEngineApi } from '@/services/psdEngine';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ProductImage, Project } from '@/services/types';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { VariationImage } from './VariationImage';

interface ITemplateSlider {
  project: Project;
  productImageSlots: ProductImage[];
}

export const TemplateSlider = ({
  project,
  productImageSlots,
}: ITemplateSlider) => {
  const captureEvent = useCaptureEvent();
  const { activeIntegrationDetails } = useAppSelector(
    (state) => state.shopsIntegrations
  );
  const { productListing } = useAppSelector(
    (state) => state.shopsProductsReducer
  );
  const { mockupVariations } = useAppSelector(
    (state) => state.collectionReducer
  );
  const getMockupVariations =
    psdEngineApi.useGetSequentialMockupVariationsQuery(
      {
        mockup_id: project?.id,
      },
      { refetchOnMountOrArgChange: true }
    );

  useEffect(() => {
    if (getMockupVariations.isSuccess && project) {
      captureEvent('Template Images Loaded', {
        type: 'Etsy',
        shop_name: activeIntegrationDetails?.shop_name,
        shop_url: activeIntegrationDetails?.shop_url,
        number_of_variation_images: getMockupVariations.data?.data.length,
        template_name: project.name,
        template_id: project.id,
        template_category: project.psd_category_id,
      });
    }
  }, [getMockupVariations]);

  return (
    <ScrollArea
      className="scroll-area-wrapper"
      type="hover"
      scrollbars="vertical"
      style={{
        display: 'flex !important',
        width: 'calc(100vw - 606.5px)',
        height: 'calc(100vh - 213px)',
      }}
    >
      {getMockupVariations.isLoading || getMockupVariations.isFetching ? (
        <Flex gap={'2'} wrap={'wrap'} pb={'5'}>
          {[...Array(6)].map((item, index) => (
            <Skeleton key={`${index}-item`} className="variation-image" />
          ))}
        </Flex>
      ) : (
        <Flex
          gap={'2'}
          wrap={'wrap'}
          pb={'5'}
          style={
            (getMockupVariations.data?.data || []).length > 0
              ? {}
              : {
                  alignContent: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }
          }
        >
          {(mockupVariations || []).length > 0 ? (
            mockupVariations?.map((variation) => (
              <VariationImage
                key={variation.id}
                activeIntegrationDetails={activeIntegrationDetails}
                productImageSlots={productImageSlots}
                productListing={productListing}
                project={project}
                variation={variation}
              />
            ))
          ) : (
            <Text>No Mockup variants found for selected Mockup.</Text>
          )}
        </Flex>
      )}
    </ScrollArea>
  );
};
