import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  CreateBundleRestTempData,
  decreaseNumberOfIdsInCreateBundleRestTempData,
  setCreateBundleRestTempData,
} from '@/redux/slices/mockupBundles';

export const useProcessBundles = (
  createBundleRestTempData: CreateBundleRestTempData,
  mockupBundlesApi: any
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const processBundles = async () => {
      if (createBundleRestTempData.status === 'start') {
        try {
          // Set status to running
          dispatch(
            setCreateBundleRestTempData({
              ...createBundleRestTempData,
              status: 'running',
            })
          );

          const allPromises = [];

          // Process PSD IDs in parallel
          if (createBundleRestTempData.restGroupedPsdIds.length) {
            const psdPromises = createBundleRestTempData.restGroupedPsdIds.map(
              async (ids: number[]) => {
                try {
                  await dispatch(
                    mockupBundlesApi.endpoints.createMockupBundle.initiate({
                      design_ids: createBundleRestTempData.selectedDesignIds,
                      mockup_ids: [],
                      psd_ids: ids,
                      mockup_bundle_uuid:
                        createBundleRestTempData.createdBundleUUID,
                    })
                  ).unwrap();

                  dispatch(
                    decreaseNumberOfIdsInCreateBundleRestTempData(ids.length)
                  );

                  // After each create operation, get the updated bundle
                  return dispatch(
                    mockupBundlesApi.endpoints.getMockupBundle.initiate(
                      {
                        mockupBundleUUID:
                          createBundleRestTempData.createdBundleUUID,
                      },
                      {
                        forceRefetch: true,
                      }
                    )
                  ).unwrap();
                } catch (error) {
                  console.error('Error processing PSD IDs:', error);
                }
              }
            );

            allPromises.push(...psdPromises);
          }

          // Process Template IDs in parallel
          if (createBundleRestTempData.restGroupedTemplateIds.length) {
            const templatePromises =
              createBundleRestTempData.restGroupedTemplateIds.map(
                async (ids: number[]) => {
                  try {
                    await dispatch(
                      mockupBundlesApi.endpoints.createMockupBundle.initiate({
                        design_ids: createBundleRestTempData.selectedDesignIds,
                        mockup_ids: ids,
                        psd_ids: [],
                        mockup_bundle_uuid:
                          createBundleRestTempData.createdBundleUUID,
                      })
                    ).unwrap();

                    dispatch(
                      decreaseNumberOfIdsInCreateBundleRestTempData(ids.length)
                    );

                    // After each create operation, get the updated bundle
                    return dispatch(
                      mockupBundlesApi.endpoints.getMockupBundle.initiate(
                        {
                          mockupBundleUUID:
                            createBundleRestTempData.createdBundleUUID,
                        },
                        {
                          forceRefetch: true,
                        }
                      )
                    ).unwrap();
                  } catch (error) {
                    console.error('Error processing Template IDs:', error);
                  }
                }
              );

            allPromises.push(...templatePromises);
          }

          // Wait for all parallel operations to complete
          await Promise.all(allPromises);

          // After everything is finished, update the status to completed
          dispatch(
            setCreateBundleRestTempData({
              ...createBundleRestTempData,
              numberOfIds: 0,
              status: 'completed',
            })
          );
        } catch (error) {
          console.error('Error in processBundles:', error);
        }
      }
    };

    processBundles();
  }, [createBundleRestTempData, dispatch, mockupBundlesApi.endpoints]);

  useEffect(() => {
    if (createBundleRestTempData.status === 'completed') {
      dispatch(
        setCreateBundleRestTempData({
          status: 'empty',
          numberOfIds: 0,
          createdBundleUUID: '',
          selectedDesignIds: [],
          restGroupedPsdIds: [],
          restGroupedTemplateIds: [],
        })
      );
    }
  }, [createBundleRestTempData]);

  // Return nothing since this is a side-effect hook
  return null;
};
