import './view-wrapper.scss';
import { Grid } from '@radix-ui/themes';
import { SingleTemplate } from './SingleTemplate';
import { workspaceApi } from '@/services/workspace';
import Skeleton from 'react-loading-skeleton';
import { psdManagementApi } from '@/services/psdManagement';
import { useAppSelector } from '@/hooks';

interface IViewWrapper {
  categoryId: number;
}

export const ViewWrapper = ({ categoryId = 0 }: IViewWrapper) => {
  const subscriptionTypes = psdManagementApi.useGetSubscriptionTypesQuery();
  const publicCatalogs = workspaceApi.useGetPublicCatalogsQuery(
    {
      categoryId,
    },
    { refetchOnMountOrArgChange: true }
  );

  return (
    <Grid className="view-wrapper" gap={'2'}>
      {publicCatalogs.isLoading
        ? [...Array(12)].map((item, index) => (
            <Skeleton
              key={`${index}-item`}
              className="single-template"
              height={'250px'}
            />
          ))
        : publicCatalogs.data?.data
            ?.slice(0)
            ?.reverse()
            ?.map((catalog) => (
              <SingleTemplate
                key={catalog.id}
                catalog={catalog}
                subscriptionTypes={subscriptionTypes.data?.data || []}
              />
            ))}
    </Grid>
  );
};
