import { Flex } from '@radix-ui/themes';
import './create-mockup-template.scss';
import { useState } from 'react';
import { ChooseDesignOrMockup } from './components/choose-design-or-mockup/ChooseDesignOrMockup';
import { ChooseMockup } from './components/choose-mockup/ChooseMockup';
import { useAppSelector } from '@/hooks';
import { mockupBundlesApi } from '@/services/mockupBundles';
import { psdManagementApi } from '@/services/psdManagement';

export const CreateMockupTemplate = () => {
  const { designs, myTemplates, publicMockups } = useAppSelector(
    (state) => state.mockupBundlesReducer
  );
  const [step, setStep] = useState<1 | 2>(1);
  const [stepName, setStepName] = useState<'choose' | 'mockups'>('choose');

  mockupBundlesApi.useGetMyProjectsQuery(null!, {
    skip: !!myTemplates.length,
    refetchOnMountOrArgChange: true,
  });
  mockupBundlesApi.useGetPublicCatalogsQuery(null!, {
    skip: !!publicMockups.length,
    refetchOnMountOrArgChange: true,
  });

  mockupBundlesApi.useGetDesignsQuery(null!, {
    skip: !!designs.length,
    refetchOnMountOrArgChange: true,
  });

  const getPrivatePsds = psdManagementApi.useGetPrivatePsdsQuery(null!, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <Flex
      className="create-mockup-template-page-wrapper"
      direction={'column'}
      justify={'between'}
    >
      {step === 1 && stepName === 'choose' && (
        <ChooseDesignOrMockup
          setStep={setStep}
          setStepName={setStepName}
          step={step}
        />
      )}
      {stepName === 'mockups' && (
        <ChooseMockup
          getPrivatePsds={{
            isLoading: getPrivatePsds.isLoading,
            privatePsdsData: getPrivatePsds.data?.data,
          }}
          setStep={setStep}
          setStepName={setStepName}
        />
      )}
    </Flex>
  );
};
