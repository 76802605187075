import { Flex, ScrollArea, Tabs, Text } from '@radix-ui/themes';
import { Helmet } from 'react-helmet-async';
import { useRef, useState } from 'react';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { ColorSets } from './color-sets/ColorSets';
import { AddNewColorSet } from './color-sets/AddNewColorSet';
import './brand-assets.scss';
import { colorsApi } from '@/services/colors';
import { DesignsPage } from './designs/Designs';
import { mockupBundlesApi } from '@/services/mockupBundles';
import { useAppDispatch, useAppSelector } from '@/hooks';
import Uppy from '@uppy/core';
import { addSelectedDesignIds } from '@/redux/slices/mockupBundles';
import { AddNewDesign } from './designs/AddNewDesign';
import { SelectedDesignsOptionsBar } from '../designs/components/view-wrapper/SelectedDesignsOptionsBar';

type tabs = 'designs' | 'colors';

export const BrandAsssets = () => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const { designs, selectedDesignIds } = useAppSelector(
    (state) => state.mockupBundlesReducer
  );

  const [tabs, setTabs] = useState<tabs>('colors');
  const [selectedDesigns, setSelectedDesigns] = useState<number[]>([]);
  const deleteDesignsDialog = useRef();

  const [uppyDashboardInstance, setUppyDashboardInstance] =
    useState<Uppy<Record<string, unknown>, Record<string, unknown>>>();

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const getColorSets = colorsApi.useGetColorSetsQuery(null!, {
    refetchOnMountOrArgChange: true,
  });

  mockupBundlesApi.useGetDesignsQuery(null!, {
    skip: !!designs.length,
    refetchOnMountOrArgChange: true,
  });

  return (
    <Flex
      className="page-wrapper brand-assets-page"
      direction={'column'}
      align={'center'}
      gap={'5'}
      position={'relative'}
    >
      <Helmet>
        <title>Dynamic Mockups | Brand Assets</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <Tabs.Root
        defaultValue="colors"
        value={tabs}
        onValueChange={(value) => setTabs(value as tabs)}
        style={{ width: '100%' }}
      >
        <Tabs.List>
          <Tabs.Trigger value={'colors'}>Colors</Tabs.Trigger>
          <Tabs.Trigger value={'designs'}>Artwork</Tabs.Trigger>
        </Tabs.List>
        <ScrollArea
          className="scroll-area-page-wrapper"
          style={{ paddingTop: '68px' }}
        >
          <Flex direction={'column'} pt="3" height={'100%'}>
            <Tabs.Content value={'designs'}>
              <Flex
                className="page-header header"
                align={'center'}
                justify={'between'}
                maxWidth={'100%'}
                width={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                pt={'12px'}
              >
                <Text className="page-title">Artwork</Text>

                {selectedDesignIds.length > 0 && (
                  <SelectedDesignsOptionsBar
                    selectedDesigns={selectedDesignIds}
                    setSelectedDesigns={(x) => {
                      setSelectedDesigns(x);
                      dispatch(addSelectedDesignIds(x));
                    }}
                    deleteDesignsDialog={deleteDesignsDialog}
                  />
                )}

                {tabs === 'designs' && selectedDesignIds.length === 0 && (
                  <AddNewDesign
                    handleUploadClick={handleClick}
                    hiddenFileInput={hiddenFileInput}
                    uppyDashboardInstance={uppyDashboardInstance}
                  />
                )}
              </Flex>
              <Flex
                className="page-content"
                direction={'column'}
                width={'100%'}
                gap={'2'}
              >
                <DesignsPage
                  setUppyDashboardInstance={setUppyDashboardInstance}
                  handleUploadClick={() => handleClick()}
                  setSelectedDesignIds={addSelectedDesignIds}
                  selectedDesigns={selectedDesigns}
                  deleteDesignsDialog={deleteDesignsDialog}
                  setSelectedDesigns={setSelectedDesigns}
                />
              </Flex>
            </Tabs.Content>
            <Tabs.Content value={'colors'}>
              <Flex
                className="page-header header"
                align={'center'}
                justify={'between'}
                maxWidth={'100%'}
                width={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                pt={'12px'}
              >
                <Text className="page-title">Colors</Text>
                {tabs === 'colors' && <AddNewColorSet />}
              </Flex>
              <Flex
                className="page-content"
                direction={'column'}
                width={'100%'}
                gap={'2'}
              >
                <ColorSets isLoadingColorSets={getColorSets.isLoading} />
              </Flex>
            </Tabs.Content>
          </Flex>
        </ScrollArea>
      </Tabs.Root>
    </Flex>
  );
};
