import './sync-images.scss';
import {
  Box,
  Button,
  Flex,
  Grid,
  ScrollArea,
  Skeleton,
  Text,
  Tooltip,
} from '@radix-ui/themes';
import { ReactComponent as PhotoshopIcon } from '@/assets/icons/photoshop-linear.svg';
import { useEffect, useState } from 'react';
import { workspaceApi } from '@/services/workspace';
import { TemplateSlider } from './TemplateSlider';
import { EmptySlot } from './EmptySlot';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/hooks';
import { ProductImage, Project } from '@/services/types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface ISyncImages {
  setProductImages: (images: ProductImage[]) => void;
}

export const SyncImages = ({ setProductImages }: ISyncImages) => {
  const navigate = useNavigate();
  const { productListing } = useAppSelector(
    (state) => state.shopsProductsReducer
  );

  const geyMyProjects = workspaceApi.useGetMyProjectsQuery(null!, {
    refetchOnMountOrArgChange: true,
  });

  const [productImageSlots, setProductImageSlots] = useState<ProductImage[]>(
    productListing.data.images
  );
  useEffect(() => {
    setProductImageSlots([]);
  }, []);

  useEffect(() => {
    setProductImageSlots(productListing.data.images);
  }, [productListing.data.images]);

  useEffect(() => {
    setProductImages(productImageSlots);
  }, [productImageSlots]);

  const setImage = (data: ProductImage) => {
    setProductImageSlots((old) =>
      old.map((slot) =>
        slot.rank === data.rank
          ? {
              ...slot,
              image_url: data.image_url,
              is_updated: true,
              mockup_variation_id: data.mockup_variation_id,
            }
          : slot
      )
    );
  };

  const deleteImage = (rank: number) => {
    setProductImageSlots((old) =>
      old.map((slot) =>
        slot.rank === rank
          ? {
              ...slot,
              image_url: null,
              is_updated: !!slot.listing_image_id,
              mockup_variation_id: null,
            }
          : slot
      )
    );
  };

  const [activeProject, setActiveProject] = useState<Project>();

  useEffect(() => {
    if (geyMyProjects.data?.data) {
      setActiveProject(
        geyMyProjects.data?.data[geyMyProjects.data?.data.length - 1]
      );
    }
  }, [geyMyProjects]);

  return (
    <Flex
      direction={'column'}
      gap={'5'}
      className="syncimages-wrapper"
      width={'100%'}
    >
      <Flex className="slots-wrapper" gap={'2'} wrap={'wrap'}>
        {productListing.isLoading
          ? [...Array(10)].map((item, index) => (
              <Skeleton key={`${index}-item`} className="slot-image" />
            ))
          : productImageSlots.map((image) => (
              <EmptySlot
                key={image.rank}
                image={image}
                setImage={setImage}
                deleteImage={deleteImage}
              />
            ))}
      </Flex>

      {!geyMyProjects.isLoading && geyMyProjects.data?.data.length === 0 ? (
        <Flex
          direction={'column'}
          align={'center'}
          justify={'center'}
          gap={'4'}
          className="empty-page-state"
          style={{ height: '70vh' }}
        >
          <Flex align={'center'} justify={'center'} className="cercle-wrap">
            <PhotoshopIcon className="svg-icon photoshop" />
          </Flex>
          <Text size="2" weight="regular" align={'center'}>
            Upload your custom Photoshop Template, or choose from Mockup
            Library, and bulk create Mockups
          </Text>
          <Button onClick={() => navigate('/my-templates')} variant="solid">
            <Text size="1" weight="medium">
              My Templates
            </Text>
          </Button>
        </Flex>
      ) : (
        <Flex className="templates-wrapper" direction={'column'}>
          <Grid rows={'1'} columns={'1fr 100%'} gap={'5'} align={'end'}>
            <Flex direction={'column'}>
              <ScrollArea
                className="scroll-area-wrapper"
                type="hover"
                scrollbars="vertical"
                style={{ height: 'calc(100vh - 213px)' }}
              >
                {geyMyProjects.isLoading || geyMyProjects.isFetching ? (
                  <Flex gap={'2'} wrap={'wrap'} pb={'5'}>
                    {[...Array(6)].map((item, index) => (
                      <Skeleton
                        key={`${index}-item`}
                        className="template-wrapper"
                      />
                    ))}
                  </Flex>
                ) : (
                  geyMyProjects.data?.data
                    ?.slice(0)
                    ?.reverse()
                    ?.map((project, index) => (
                      <Flex
                        key={project.id}
                        className={`template-wrapper ${
                          activeProject?.id === project.id ? 'active' : ''
                        }`}
                        direction={'column'}
                        gap={'2'}
                        pb={'5'}
                        onClick={() => setActiveProject(project)}
                      >
                        <Tooltip content={project.name}>
                          <Text
                            className={'template-name'}
                            size={'3'}
                            weight={'bold'}
                          >
                            {project.name}
                          </Text>
                        </Tooltip>

                        <Box className="template-thumbnail">
                          <LazyLoadImage
                            style={{
                              objectFit: 'cover',
                              width: '100%',
                              objectPosition: 'center',
                            }}
                            effect="blur"
                            src={project.thumbnail}
                            delayTime={0}
                            placeholderSrc={project.thumbnail}
                            wrapperProps={{
                              style: { transitionDelay: '0s' },
                            }}
                          />
                          <Box className="hover-outline"></Box>
                        </Box>
                      </Flex>
                    ))
                )}
              </ScrollArea>
            </Flex>

            <TemplateSlider
              project={activeProject as Project}
              productImageSlots={productImageSlots}
            />
          </Grid>
          {/* {geyMyProjects.isLoading ? (
          <Flex gap={'5'} direction={'column'}>
            {[...Array(4)].map((item, index) => (
              <Skeleton key={`${index}-item`} className="templates-loader" />
            ))}
          </Flex>
        ) : (
          geyMyProjects.data?.data?.map((project, index) => (
            <Flex key={project.id} direction={'column'}>
              <TemplateSlider
                index={index}
                project={project}
                productImageSlots={productImageSlots}
              />
            </Flex>
          ))
        )} */}
        </Flex>
      )}
      {/* <Flex
          direction={'column'}
          align={'center'}
          justify={'center'}
          gap={'4'}
          className="empty-page-state"
        >
          <Flex align={'center'} justify={'center'} className="cercle-wrap">
            <PhotoshopIcon className="svg-icon photoshop" />
          </Flex>
          <Text size="2" weight="regular" align={'center'}>
            You need to create Mockups in order to use them on your Products
          </Text>
          <Button onClick={() => navigate('/my-templates')} variant="solid">
            <Text size="1" weight="medium">
              My Templates
            </Text>
          </Button>
        </Flex> */}
    </Flex>
  );
};
