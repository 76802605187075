import { Helmet } from 'react-helmet-async';
import { ViewWrapper } from './components/view-wrapper/ViewWrapper';
import './mockup-catalog.scss';
import { Flex, ScrollArea, Tabs } from '@radix-ui/themes';
import { useState } from 'react';
import { psdManagementApi } from '@/services/psdManagement';

type tabs = 'designs' | 'colors';

export const MockupCatalog = () => {
  const psdCategories = psdManagementApi.useGetPsdCategoriesQuery();

  const [tabs, setTabs] = useState<string>(
    psdCategories.data?.data?.[0]?.id.toString() || '1'
  );

  return (
    <Flex
      className="page-wrapper public-library-page"
      direction={'column'}
      align={'center'}
      gap={'5'}
    >
      <Helmet>
        <title>Dynamic Mockups | Public Library</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <Tabs.Root
        defaultValue="designs"
        value={tabs}
        onValueChange={(value) => setTabs(value as tabs)}
        style={{ width: '100%' }}
      >
        <Tabs.List>
          {psdCategories.data?.data
            ?.filter((item) => !item.is_default && item.is_visible)
            ?.map((category) => (
              <Tabs.Trigger key={category.id} value={category.id.toString()}>
                {category.name} {/* ({category.count}) */}
              </Tabs.Trigger>
            ))}
        </Tabs.List>
        <ScrollArea className="scroll-area-page-wrapper">
          <Flex
            className="view-wrapper-mockup-catalog"
            direction={'column'}
            pt="3"
            height={'100%'}
          >
            {psdCategories.data?.data?.map((category) => (
              <Tabs.Content key={category.id} value={category.id.toString()}>
                <ViewWrapper categoryId={category.id} />
              </Tabs.Content>
            ))}
          </Flex>
        </ScrollArea>
      </Tabs.Root>
    </Flex>
  );
};
