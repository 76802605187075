import './view-wrapper.scss';
import {
  Text,
  ScrollArea,
  Flex,
  Badge,
  Card,
  Button,
  Callout,
  Link,
  Tooltip,
  AlertDialog,
} from '@radix-ui/themes';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { paymentsApi } from '@/services/payments';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { formatDate } from '@/helpers';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { toast } from 'react-toastify';
import { userApi } from '@/services/user';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import { ReactComponent as ProCrownIcon } from '@/assets/icons/pro-crown.svg';

export const Subscription = () => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const { showNotificationBar } = useAppSelector((state) => state.appReducer);
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const { currentWorkspace } = useAppSelector(
    (state) => state.workspaceReducer
  );
  const userRendersData = paymentsApi.useGetUserRendersQuery();
  const isAppPro = useFeatureFlagEnabled('app-pro');

  // const goToBillingPortal = () => {
  //   window.open(
  //     `https://billing.stripe.com/p/login/cN2dU7f840IgafmaEE?prefilled_email=${currentUser?.email}`,
  //     '_blank'
  //   );
  // };

  const getUserBillingStatus = () => {
    if (isAppPro) {
      if (currentWorkspace?.user_id === 5123) {
        return <Badge color="purple">ENTERPRISE 1</Badge>;
      } else {
        if (currentUser?.subscription_trial.status === 'active') {
          return <Badge color="blue">TRIAL</Badge>;
        }
        if (currentUser?.subscription_trial.status === 'cancelled') {
          if (userRendersData.data?.data[0]?.interval === 'free') {
            return <Badge color="orange">TRIAL CANCELLED</Badge>;
          } else {
            return <Badge color="amber">PREMIUM</Badge>;
          }
        }
        if (
          currentUser?.subscription_trial.status === 'completed' ||
          currentUser?.subscription_trial.status === 'inactive'
        ) {
          if (userRendersData.data?.data[0]?.interval === 'free') {
            return <Badge color="amber">PRO</Badge>;
          } else {
            return <Badge color="purple">PREMIUM</Badge>;
          }
        }
      }
    } else {
      return <Badge color="blue">FREE</Badge>;
    }
    return <Badge color="blue">FREE</Badge>;
  };

  const getBillingStatus = () => {
    switch (currentUser?.subscription_billing_status?.status) {
      case 'active':
        return 'Active';
      case 'canceled':
        return 'Canceled';
      case 'on_trial':
        return 'Free Trial';
      case 'grace_period':
        return 'Grace period';
      case 'past_due':
        return 'Past due';
      default:
        return '';
    }
  };

  const [cancelSubscription, { isLoading: isCanceling }] =
    paymentsApi.useCancelSubscriptionMutation();
  const [skipTrial, { isLoading: isSkipping }] =
    paymentsApi.useSkipTrialMutation();
  const [resumeSubscription, { isLoading: isResuming }] =
    paymentsApi.useResumeSubscriptionMutation();

  const cancelSubscriptionAction = async () => {
    try {
      await cancelSubscription().unwrap();
      await dispatch(
        userApi.endpoints.getCurrentUser.initiate(null!, {
          forceRefetch: true,
        })
      ).unwrap();
      captureEvent('User initiated subscription cancellation', {
        cycleEndDate: currentUser?.subscription_billing_status?.cycle_end_date,
        billingStatus: currentUser?.subscription_billing_status.status,
        amount:
          (currentUser?.subscription_billing_status.amount as number) * 100,
        amount_paid:
          currentUser?.subscription_billing_status.status === 'on_trial'
            ? 0
            : (currentUser?.subscription_billing_status.amount as number) * 100,
        period: currentUser?.subscription_billing_status.billing_interval,
      });
      toast.success('Subscription successfully canceled.', {
        toastId: 'subscription_success_cancel',
        position: 'bottom-right',
        autoClose: 5000,
      });
    } catch (error: any) {
      console.log(error.data);
      toast.error(error.data.message, {
        toastId: 'subscription_error_cancel',
        position: 'bottom-right',
        autoClose: 5000,
      });
    }
  };

  const skipTrialAction = async () => {
    try {
      await skipTrial().unwrap();
      await dispatch(
        userApi.endpoints.getCurrentUser.initiate(null!, {
          forceRefetch: true,
        })
      ).unwrap();
      captureEvent('User skipped trial', {
        amount:
          (currentUser?.subscription_billing_status.amount as number) * 100,
        amount_paid:
          (currentUser?.subscription_billing_status.amount as number) * 100,
        period: currentUser?.subscription_billing_status.billing_interval,
      });
      toast.success('Subscription successfully updated.', {
        toastId: 'trial_success_skip',
        position: 'bottom-right',
        autoClose: 5000,
      });
    } catch (error: any) {
      console.log(error.data);
      toast.error(error.data.message, {
        toastId: 'trial_error_skip',
        position: 'bottom-right',
        autoClose: 5000,
      });
    }
  };

  const resumeSubscriptionAction = async () => {
    try {
      await resumeSubscription().unwrap();
      await dispatch(
        userApi.endpoints.getCurrentUser.initiate(null!, {
          forceRefetch: true,
        })
      ).unwrap();
      captureEvent('User reactivated subscription');
      toast.success('Subscription successfully reactivated.', {
        toastId: 'subscription_success_resume',
        position: 'bottom-right',
        autoClose: 5000,
      });
    } catch (error: any) {
      console.log(error.data);
      toast.error(error.data.message, {
        toastId: 'subscription_error_resume',
        position: 'bottom-right',
        autoClose: 5000,
      });
    }
  };

  const getBillingStatusAction = () => {
    switch (currentUser?.subscription_billing_status?.status) {
      case 'active':
        return (
          currentWorkspace?.user_id !== 5123 && (
            <AlertDialog.Root>
              <AlertDialog.Trigger>
                <Button color="red" variant="surface" loading={isCanceling}>
                  Cancel
                </Button>
              </AlertDialog.Trigger>
              <AlertDialog.Content style={{ maxWidth: 450 }}>
                <AlertDialog.Title>Cancel subscription</AlertDialog.Title>
                <AlertDialog.Description size="2">
                  Are you sure you want to proceed? Canceling your subscription
                  will result in the loss of paid features and all credits.
                </AlertDialog.Description>

                <Flex gap="3" mt="4" justify="end">
                  <AlertDialog.Cancel>
                    <Button variant="soft" color="gray">
                      Close
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Cancel>
                    <Button
                      variant="solid"
                      color="red"
                      onClick={() => cancelSubscriptionAction()}
                    >
                      Cancel
                    </Button>
                  </AlertDialog.Cancel>
                </Flex>
              </AlertDialog.Content>
            </AlertDialog.Root>
          )
        );
      case 'on_trial':
        return (
          <Flex direction={'column'} gap={'2'}>
            <AlertDialog.Root>
              <AlertDialog.Trigger>
                <Button color="grass" variant="surface" loading={isSkipping}>
                  Skip Trial
                </Button>
              </AlertDialog.Trigger>
              <AlertDialog.Content style={{ maxWidth: 450 }}>
                <AlertDialog.Title>Skip Trial</AlertDialog.Title>
                <AlertDialog.Description size="2">
                  Are you sure you want to proceed? Skipping the trial will
                  immediately charge you the full amount of the selected plan.
                </AlertDialog.Description>

                <Flex gap="3" mt="4" justify="end">
                  <AlertDialog.Cancel>
                    <Button variant="soft" color="gray">
                      Cancel
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Cancel>
                    <Button
                      variant="solid"
                      color="grass"
                      onClick={() => skipTrialAction()}
                    >
                      Skip Trial
                    </Button>
                  </AlertDialog.Cancel>
                </Flex>
              </AlertDialog.Content>
            </AlertDialog.Root>
            <AlertDialog.Root>
              <AlertDialog.Trigger>
                <Button color="red" variant="surface" loading={isCanceling}>
                  Cancel
                </Button>
              </AlertDialog.Trigger>
              <AlertDialog.Content style={{ maxWidth: 450 }}>
                <AlertDialog.Title>Cancel subscription</AlertDialog.Title>
                <AlertDialog.Description size="2">
                  Are you sure you want to proceed? Canceling your subscription
                  will result in the loss of paid features and all credits.
                </AlertDialog.Description>

                <Flex gap="3" mt="4" justify="end">
                  <AlertDialog.Cancel>
                    <Button variant="soft" color="gray">
                      Close
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Cancel>
                    <Button
                      variant="solid"
                      color="red"
                      onClick={() => cancelSubscriptionAction()}
                    >
                      Cancel
                    </Button>
                  </AlertDialog.Cancel>
                </Flex>
              </AlertDialog.Content>
            </AlertDialog.Root>
          </Flex>
        );
      case 'grace_period':
        return (
          <Button
            color="amber"
            variant="surface"
            onClick={() => resumeSubscriptionAction()}
            loading={isResuming}
          >
            Don't Cancel
          </Button>
        );
      case 'canceled':
        return '';
      case 'past_due':
        return '';
      default:
        return '';
    }
  };

  const getCurrencySymbol = (currency: string) => {
    switch (currency.toUpperCase()) {
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
      case 'CAD':
        return 'C$';
      case 'AUD':
        return 'A$';
      case 'JPY':
        return '¥';
      case 'CNY':
        return '¥';
      case 'INR':
        return '₹';
      case 'CHF':
        return 'CHF';
      case 'SEK':
        return 'kr';
      case 'NOK':
        return 'kr';
      case 'DKK':
        return 'kr';
      case 'MXN':
        return 'Mex$';
      case 'BRL':
        return 'R$';
      case 'ZAR':
        return 'R';
      default:
        return currency.toUpperCase();
    }
  };

  const getBillingDatesAction = () => {
    const cycleEndDate =
      currentUser?.subscription_billing_status?.cycle_end_date || '';
    const currency =
      currentUser?.subscription_billing_status?.currency || 'USD';
    const amount = currentUser?.subscription_billing_status?.amount;
    const billingInterval =
      currentUser?.subscription_billing_status?.billing_interval?.toLowerCase();

    switch (currentUser?.subscription_billing_status?.status) {
      case 'active':
        return (
          <>
            <Text weight={'regular'} size="2" color="gray">
              Next billing date: {formatDate(cycleEndDate)}
            </Text>
            <Flex gap={'2'} align={'center'}>
              <Text weight={'regular'} size="2" color="gray">
                Details: {getCurrencySymbol(currency)}
                {amount} /{billingInterval}
              </Text>
              {currentWorkspace?.user_id !== 5123 && (
                <Button
                  color="amber"
                  size="1"
                  variant="outline"
                  onClick={() => {
                    captureEvent('Upgrade your plan modal viewed', {
                      source: 'Changle plan',
                    });
                    dispatch(setIsAppPaymentPopupOpen(true));
                  }}
                >
                  Change plan
                </Button>
              )}
            </Flex>
          </>
        );
      case 'on_trial':
        return (
          <>
            <Text weight={'regular'} size="2" color="gray">
              Next billing date: {formatDate(cycleEndDate)}
            </Text>
            <Text weight={'regular'} size="2" color="gray">
              Details: {getCurrencySymbol(currency)}
              {amount} /{billingInterval}
            </Text>
          </>
        );
      case 'grace_period':
        return (
          <>
            <Text weight={'regular'} size="2" color="gray">
              Subscription ends at: {formatDate(cycleEndDate)}
            </Text>
            <Text weight={'regular'} size="2" color="gray">
              Details: {getCurrencySymbol(currency)}
              {amount} /{billingInterval}
            </Text>
          </>
        );
      case 'canceled':
        return '';
      case 'past_due':
        return (
          <>
            <Text weight={'regular'} size="2" color="gray">
              Next billing date: {formatDate(cycleEndDate)}
            </Text>
            <Flex gap={'2'} align={'center'}>
              <Text weight={'regular'} size="2" color="gray">
                Details: {getCurrencySymbol(currency)}
                {amount} /{billingInterval}
              </Text>
              {currentWorkspace?.user_id !== 5123 && (
                <Button
                  color="amber"
                  size="1"
                  variant="outline"
                  onClick={() => {
                    captureEvent('Upgrade your plan modal viewed', {
                      source: 'Changle plan',
                    });
                    dispatch(setIsAppPaymentPopupOpen(true));
                  }}
                >
                  Change plan
                </Button>
              )}
            </Flex>
          </>
        );
      default:
        return '';
    }
  };

  return (
    <Flex className="subscription-wrapper" direction={'column'} gap={'3'}>
      {currentUser?.subscription_billing_status?.status === 'past_due' && (
        <Callout.Root color="red">
          <Callout.Icon>
            <InfoCircledIcon />
          </Callout.Icon>
          <Callout.Text>
            Payment Overdue – Action Required to Avoid Service Interruption.
            Please ensure you have sufficient funds on your card and that there
            are no issues with your payment method. You can update your card
            details at{' '}
            <Link
              href="https://billing.stripe.com/p/login/cN2dU7f840IgafmaEE"
              target="_blank"
            >
              <Button variant="surface" size={'1'}>
                Stripe Billing Dashboard{' '}
                <Tooltip content="To access the Stripe billing dashboard, you must enter the email used for the payment, not the one registered in this application, unless they are the same.">
                  <InfoCircledIcon color="red" width={'15px'} height={'15px'} />
                </Tooltip>
              </Button>
            </Link>
          </Callout.Text>
        </Callout.Root>
      )}
      <Card>
        <Flex direction={'column'} gap={'4'}>
          <Flex
            gap="5"
            align={isAppPro ? 'start' : 'center'}
            justify={'between'}
          >
            <Flex direction={'column'}>
              <Text size="2" weight="bold">
                Subscription type: {getUserBillingStatus()}
              </Text>
              {(isAppPro ||
                currentUser?.subscription_billing_status?.status ===
                  'past_due') &&
              getBillingStatus() ? (
                <Text weight={'regular'} size="2" color="gray">
                  Status: {getBillingStatus()}
                </Text>
              ) : (
                ''
              )}

              {(currentUser?.subscription_trial.status === 'active' ||
                currentUser?.subscription_trial.status === 'cancelled') &&
              userRendersData.data?.data[0]?.plan === 'free' &&
              isAppPro ? (
                <Flex direction={'column'}>
                  {/* <Text weight={'regular'} size="2" color="gray">
                      Trial period start:{' '}
                      {formatDate(
                        currentUser?.subscription_trial.trial_starts_at || ''
                      )}
                    </Text> */}
                  <Text weight={'regular'} size="2" color="gray">
                    Trial ends at:{' '}
                    {formatDate(
                      currentUser?.subscription_trial.trial_ends_at || ''
                    )}
                  </Text>
                  <Text weight={'regular'} size="2" color="gray">
                    Details:{' '}
                    {getCurrencySymbol(
                      currentUser?.subscription_billing_status?.currency ||
                        'USD'
                    )}
                    {currentUser?.subscription_billing_status?.amount} /
                    {currentUser?.subscription_billing_status?.billing_interval?.toLowerCase()}
                  </Text>
                </Flex>
              ) : (
                (isAppPro ||
                  currentUser?.subscription_billing_status?.status ===
                    'past_due') &&
                getBillingDatesAction()
              )}
            </Flex>
            <Flex>
              {isAppPro ||
              currentUser?.subscription_billing_status?.status ===
                'past_due' ? (
                getBillingStatusAction()
              ) : (
                <Button
                  className="get-pro-header-button"
                  size={'2'}
                  color="gray"
                  variant="outline"
                  onClick={() => {
                    captureEvent('Upgrade your plan modal viewed', {
                      source: 'Subscription dashboard',
                    });
                    dispatch(setIsAppPaymentPopupOpen(true));
                  }}
                >
                  <ProCrownIcon width="18" height="18" />
                  {currentUser?.subscription_trial.is_available ? (
                    <Text className="">Start Free Trial</Text>
                  ) : (
                    <Text className="">Get Pro</Text>
                  )}
                </Button>
              )}
            </Flex>
          </Flex>
          {(isAppPro ||
            currentUser?.subscription_billing_status?.status === 'past_due') &&
            currentWorkspace?.user_id !== 5123 && (
              <Link
                href="https://billing.stripe.com/p/login/cN2dU7f840IgafmaEE"
                target="_blank"
              >
                <Button variant="surface">
                  Stripe Billing Dashboard{' '}
                  <Tooltip content="To access the Stripe billing dashboard, you must enter the email used for the payment, not the one registered in this application, unless they are the same.">
                    <InfoCircledIcon
                      color="DodgerBlue"
                      width={'20px'}
                      height={'20px'}
                    />
                  </Tooltip>
                </Button>
              </Link>
            )}
        </Flex>
      </Card>

      {/* {currentWorkspace?.user_id === 5123 &&
          currentUser?.subscription === 'free' && (
            <Card style={{ maxWidth: '320px' }}>
              <Flex gap="3" align="center">
                <Flex direction={'column'}>
                  <Text as="div" size="2" weight="bold">
                    Enterprise 1
                  </Text>
                  <Text as="div" size="2" color="gray">
                    1.000.000 monthly credits
                  </Text>
                </Flex>
                <Button onClick={() => getCustomCheckoutPlan()}>
                  Checkout
                </Button>
              </Flex>
            </Card>
          )} */}
    </Flex>
  );
};
