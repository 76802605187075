import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { UserRenders } from '@/redux/slices/types';
import { DataModelArray } from './types';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getUserRenders: builder.query<DataModelArray<UserRenders>, void>({
      query: () => `user_renders`,
    }),
    getCheckoutSession: builder.query<any, { checkoutSessionId: string }>({
      query: ({ checkoutSessionId }) =>
        `stripe/checkout/session/${checkoutSessionId}`,
    }),
    getCheckoutSessionURL: builder.mutation<
      any,
      {
        stripe_price_id: string;
        subscription_plan_type: 'app' | 'api';
        plan_type: 'hobby' | 'pro';
      }
    >({
      query: (payload) => ({
        url: `stripe/checkout/session`,
        method: 'POST',
        body: payload,
      }),
    }),
    getTopUpRendersCheckoutSessionURL: builder.mutation<
      any,
      {
        renders: number;
      }
    >({
      query: (payload) => ({
        url: `stripe/checkout/session/top-up`,
        method: 'POST',
        body: payload,
      }),
    }),
    skipTrial: builder.mutation<any, void>({
      query: (payload) => ({
        url: `stripe/trial/charge-immediately`,
        method: 'POST',
      }),
    }),
    cancelSubscription: builder.mutation<any, void>({
      query: (payload) => ({
        url: `stripe/subscription/cancel`,
        method: 'POST',
      }),
    }),
    resumeSubscription: builder.mutation<any, void>({
      query: (payload) => ({
        url: `stripe/subscription/resume`,
        method: 'POST',
      }),
    }),
    subscriptionSwap: builder.mutation<
      any,
      { price_id: string; subscription_plan_type: 'app' | 'api' }
    >({
      query: (payload) => ({
        url: `stripe/subscription/swap`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {} = paymentsApi;
