import { toast } from 'react-toastify';
import {
  Dialog,
  Button,
  Flex,
  Text,
  Box,
  TextField,
  Select,
  Progress,
  Link,
  Switch,
} from '@radix-ui/themes';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import { ReactComponent as PhotoshopIcon } from '@/assets/icons/photoshop.svg';
import {
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ReactComponent as PhotoshopLargeIcon } from '@/assets/icons/photoshop-large.svg';
import Uppy from '@uppy/core';
import XHR from '@uppy/xhr-upload';
import RemoteSources from '@uppy/remote-sources';
import { Dashboard } from '@uppy/react';
import { COMPANION_URL } from '@uppy/transloadit';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { workspaceApi } from '@/services/workspace';
import {
  PrivatePsd,
  PsdAvailability,
  PsdCategory,
  SubscriptionType,
} from '@/services/types';
import PostProcessPlugin from './PostProcessPlugin';
import { psdManagementApi } from '@/services/psdManagement';
import {
  getProgressColor,
  getProgressText,
  calculateProgress,
} from '@/features/screens/workspace/utils/UploadFilesFlow';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import { useNavigate } from 'react-router-dom';

interface IFormInput {
  mockupName: string;
  mockupCategory: string | number;
  psdAvailability: number;
  subscriptionType: number;
}

interface IUploadPsdDialog {}

interface PsdFileInfo {
  name: string;
  size: number;
}

interface PostProcessData {
  mockupId: number;
  psdId: number;
}

type PsdStatus =
  | 'browse'
  | 'completed'
  | 'uploading'
  | 'processing'
  | 'upload-error'
  | 'processing-error';

export const UploadPsdDialog = forwardRef(({}: IUploadPsdDialog, ref) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const captureEvent = useCaptureEvent();
  const [uploadStepProgress, setUploadStepProgress] = useState<1 | 2>(1);
  const [mockupName, setMockupName] = useState<string>('');
  const [mockupCategory, setMockupCategory] = useState<number | null>(null);
  const [updateMockupIsLoading, setUpdateMockupIsLoading] = useState(false);
  const [psdErrorMessage, setPsdErrorMessage] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [fileUploadProgress, setFileUploadProgress] = useState<number>(0);
  const [psdStatus, setPsdStatus] = useState<PsdStatus>('browse');
  const [postProcessData, setPostProcessData] = useState<PostProcessData>(
    null!
  );
  const [psdFileInfo, setPsdFileInfo] = useState<PsdFileInfo>({
    name: '',
    size: 0,
  });
  const hasCustomPhotoshopMockups = useFeatureFlagEnabled(
    'custom-photoshop-mockups'
  );

  useEffect(() => {
    uppyDashboard.setMeta({ hasCustomPhotoshopMockups });
  }, [hasCustomPhotoshopMockups]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = (data) =>
    updateMockupAction(data);

  const [uppyDashboard] = useState(() =>
    new Uppy({
      restrictions: {
        allowedFileTypes: ['.psd'],
        maxNumberOfFiles: 1,
        maxFileSize: 104857600,
      },
      locale: {
        strings: {
          browseFiles: 'Browse files',
          dropPasteBoth:
            '%{browse} or Drag and Drop your file from your device',

          poweredBy: '',
        },
      },
      onBeforeFileAdded() {
        if (uppyDashboard.getState().meta.hasCustomPhotoshopMockups) {
          return true;
        } else {
          captureEvent('Upgrade your plan modal viewed', {
            source: 'Upload psd mockup button',
          });
          dispatch(setIsAppPaymentPopupOpen(true));

          return false;
        }
      },
    })
      .use(RemoteSources, {
        companionUrl: COMPANION_URL,
        sources: [],
        // sources: ['Dropbox', 'GoogleDrive', 'OneDrive'],
      })
      .on('file-added', (file: { name: string; size: any }) => {
        captureEvent('PSD Mockup upload initiated');

        setPsdFileInfo({
          name: file.name.replace(/\.[^/.]+$/, ''),
          size: file.size,
        });
        setMockupName(file.name.replace(/\.[^/.]+$/, ''));
        uppyDashboard.upload();
        setPsdStatus('uploading');
      })
      .on('upload-progress', (file: any, data: any) => {
        calculateProgress({
          uppyDashboard,
          file,
          data,
          setFileUploadProgress,
        });
      })
      .on('preprocess-progress' as any, () => {
        setPsdStatus('processing');
      })
      .on(
        'restriction-failed',
        (file: { data: { size: number } }, data: { message: any }) => {
          let message = data.message;
          if (
            message.includes(
              'Cannot add the file because onBeforeFileAdded returned false'
            )
          ) {
            message =
              'The user is unable to upload a custom PSD as they are not a paid customer.';
          }
          captureEvent('PSD Mockup upload failed', {
            error: message,
            tried_to_allocate_size: file?.data?.size
              ? Math.round((file?.data?.size as number) / 1024 / 1024)
              : '/',
          });
        }
      )
      .use(XHR, {
        endpoint: `${API_BASE_URL}psd/upload`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        formData: true,
        fieldName: 'psd_file',
      })
      .on('upload-success', () => {
        captureEvent('PSD Mockup upload completed');
        setFileUploadProgress(100);
      })
      .on(
        'upload-error',
        (_: any, error: { message: SetStateAction<string> }) => {
          captureEvent('PSD Mockup upload failed', { error: error.message });
          setPsdErrorMessage(error.message);
          setPsdStatus('upload-error');
        }
      )
      .use(PostProcessPlugin, { id: 'PostProcessPlugin' })
      .on('postprocess-complete' as any, (data: any) => {
        if (data.type === 'preprocess-complete') {
          captureEvent('PSD Mockup processing completed');
          setPostProcessData({
            mockupId: data.message.mockupId,
            psdId: data.message.psdId,
          });
          setPsdStatus('completed');
          setUploadStepProgress(2);
          dispatch(
            workspaceApi.endpoints.getMyProjects.initiate(null!, {
              forceRefetch: true,
            })
          );
          dispatch(
            psdManagementApi.endpoints.getPrivatePsds.initiate(null!, {
              forceRefetch: true,
            })
          );
        }
        if (data.type === 'error') {
          captureEvent('PSD Mockup processing failed', {
            error: data.message,
          });
          setPsdErrorMessage(data.message);
          setPsdStatus('processing-error');
        }
      })
      .on('preprocess-complete', async (result: any) => {
        // await new Promise((resolve) => setTimeout(resolve, 250));
        //(formRef?.current as any)?.scrollIntoView({ behavior: 'smooth' });
        // onTabChange('photoshop-files');
        // dispatch(
        //   psdManagementApi.endpoints.getPrivatePsds.initiate(null!, {
        //     forceRefetch: true,
        //   })
        // );
        // loadMockup({ psd_id: resultResponse.id });
      })
  );
  const formRef = useRef(null);
  const canPublishPrivatePsd = useFeatureFlagEnabled('publish-private-psd'); // just admins
  useImperativeHandle(ref, () => ({
    openDialog() {
      setOpen(true);
    },
    closeDialog() {
      resetPsdProcess();
      setOpen(false);
    },
  }));

  const psdAvailabilitiesData = psdManagementApi.useGetPsdAvailabilitiesQuery();
  const subscriptionTypesData = psdManagementApi.useGetSubscriptionTypesQuery();
  const psdCategories = psdManagementApi.useGetPsdCategoriesQuery();

  useEffect(() => {
    if (psdCategories.data?.data?.length) {
      setMockupCategory(
        psdCategories.data?.data?.filter((category) => category.is_default)?.[0]
          ?.id || 1
      );
    }
  }, [psdCategories.data?.data]);

  useEffect(() => {
    if (psdAvailabilitiesData.data?.data?.length) {
      setPsdAvailability(
        psdAvailabilitiesData.data?.data?.find((pa) => pa.value === 2)
      );
    }
  }, [psdAvailabilitiesData.data?.data]);

  useEffect(() => {
    if (subscriptionTypesData.data?.data?.length) {
      setSubscriptionType(
        subscriptionTypesData.data?.data?.find((st) => st.name === 'Free')
      );
    }
  }, [subscriptionTypesData.data?.data]);

  const [psdAvailability, setPsdAvailability] = useState<
    PsdAvailability | undefined
  >();
  const [subscriptionType, setSubscriptionType] = useState<
    SubscriptionType | undefined
  >();
  const [isAiMockup, setIsAiMockup] = useState<boolean>(false);

  const updateMockupAction = async (formInput: IFormInput) => {
    try {
      setUpdateMockupIsLoading(true);
      const data: PrivatePsd = {
        mockup_id: postProcessData.mockupId,
        psd_id: postProcessData.psdId,
        name: mockupName,
        psd_category_id: mockupCategory as number,
        psd_availability_id: canPublishPrivatePsd
          ? (psdAvailability?.id as number)
          : psdAvailabilitiesData.data?.data?.find((pa) => pa.value === 2)?.id,
        subscription_type_id: canPublishPrivatePsd
          ? (subscriptionType?.id as number)
          : subscriptionTypesData.data?.data?.find((st) => st.name === 'Free')
              ?.id,
        is_ai_mockup: +isAiMockup,
      };
      await dispatch(
        psdManagementApi.endpoints.updatePrivatePsd.initiate(data)
      ).unwrap();

      dispatch(
        workspaceApi.endpoints.getMyProjects.initiate(null!, {
          forceRefetch: true,
        })
      );
      dispatch(
        psdManagementApi.endpoints.getPrivatePsds.initiate(null!, {
          forceRefetch: true,
        })
      );
      captureEvent('PSD Mockup details saved');

      //setOpen(false);
      resetPsdProcess();
      // toast.success('Mockup successfully updated.', {
      //   toastId: 'update_mockup_success',
      //   position: 'bottom-right',
      //   autoClose: 5000,
      // });
      setUpdateMockupIsLoading(false);
      navigate(`/mockup/${postProcessData.psdId}`);
    } catch (error: any) {
      toast.error(error.data.message, {
        toastId: 'update_mockup_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
      setUpdateMockupIsLoading(false);
    }
  };

  const resetPsdProcess = () => {
    uppyDashboard.cancelAll();
    setPsdStatus('browse');
    setPostProcessData(null!);
    setPostProcessData(null!);
    setPsdErrorMessage('');
    setFileUploadProgress(0);
    setUploadStepProgress(1);
    reset();
    setPsdAvailability(
      psdAvailabilitiesData.data?.data?.find((pa) => pa.value === 2)
    );
    setSubscriptionType(
      subscriptionTypesData.data?.data?.find((st) => st.name === 'Free')
    );
    setMockupCategory(
      psdCategories.data?.data?.filter((category) => category.is_default)?.[0]
        ?.id || 1
    );
    setIsAiMockup(false);
    setMockupName('');
    captureEvent('Upload PSD Mockup modal closed');
  };

  const sortCategories = (categories: PsdCategory[]) => {
    const data = [...categories];
    if (!data.length) {
      return [];
    }
    data.sort((a, b) => {
      if (a.name === 'Other') return 1;
      if (b.name === 'Other') return -1;
      return a.name.localeCompare(b.name);
    });
    return data;
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content
        aria-describedby={undefined}
        onPointerDownOutside={(e) => e.preventDefault()}
        style={{ maxWidth: 650, maxHeight: 650 }}
        className="upload-psd-dialog"
      >
        <Dialog.Title>
          <Flex align={'center'} justify={'between'}>
            <Text size={'4'}>Upload Photoshop Template</Text>
            {(psdStatus === 'browse' ||
              psdStatus === 'processing-error' ||
              psdStatus === 'upload-error') && (
              <Dialog.Close>
                <Button
                  size={'3'}
                  color="gray"
                  variant="ghost"
                  onClick={() => resetPsdProcess()}
                >
                  <CloseIcon className="icon black" />
                </Button>
              </Dialog.Close>
            )}
          </Flex>
        </Dialog.Title>

        <Flex pt={'4'} direction={'column'} gap={'2'}>
          <Flex
            p={'4'}
            gap={'6'}
            className="example-wrapper"
            justify={'between'}
          >
            <Flex direction={'column'} gap={'2'}>
              <Flex direction={'row'} align={'center'} gap={'2'}>
                <PhotoshopIcon />
                <Text size={'1'} weight={'bold'}>
                  Photoshop Example
                </Text>
              </Flex>

              <Text
                size={'1'}
                weight={'regular'}
                style={{ paddingRight: '15%' }}
              >
                Download the example PSD file and use it as a reference. Read{' '}
                <a
                  href="https://dynamicmockups.com/knowledge/photoshop-psd-format/"
                  target="_blank"
                  onClick={() => captureEvent('The Formatting Guide visited')}
                >
                  The Formatting Guide
                </a>{' '}
                to ensure a smooth process.
              </Text>
            </Flex>
            <Flex justify={'center'} align={'center'}>
              <Link
                href="https://app-dynamicmockups-production.s3.eu-central-1.amazonaws.com/public/DynamicMockupsPhotoshopTemplateExample.psd"
                download="Dynamic Mockups Photoshop Template Example.psd"
                target="_blank"
              >
                <Button
                  size={'2'}
                  color="gray"
                  variant="outline"
                  onClick={() => captureEvent('PSD example downloaded')}
                >
                  Download
                </Button>
              </Link>
            </Flex>
          </Flex>
          {psdStatus === 'browse' ? (
            <Flex direction={'column'} gap={'2'}>
              <Dashboard
                uppy={uppyDashboard}
                disabled={false}
                fileManagerSelectionType={'both'}
                showProgressDetails={true}
              />
              <Flex justify={'between'} className="footer">
                <Text size={'1'} weight={'regular'}>
                  Supported format: PSD
                </Text>
                <Text size={'1'} weight={'regular'}>
                  Maximum size: 60 MB
                </Text>
              </Flex>
            </Flex>
          ) : (
            <Box pt={'4'}>
              <Flex p={'4'} mb={'4'} gap={'1'} className="processing-wrapper">
                <Flex direction={'column'} gap={'4'} className={'info'}>
                  <Flex justify={'between'}>
                    <Flex direction={'row'} align={'center'} gap={'2'}>
                      <PhotoshopLargeIcon />
                      <Flex direction={'column'} gap={'2'}>
                        <Text size={'1'} weight={'regular'}>
                          {psdFileInfo.name}
                        </Text>
                        <Text
                          size={'1'}
                          weight={'regular'}
                          className="file-size"
                        >
                          {Math.round((psdFileInfo.size / 1024 / 1024) * 10) /
                            10}{' '}
                          MB
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex className={'progress'} direction={'column'} gap={'2'}>
                    <Progress
                      value={fileUploadProgress}
                      size={'3'}
                      color={getProgressColor(psdStatus)}
                    />
                    <Flex justify={'between'}>
                      <Text
                        size={'1'}
                        weight={'regular'}
                        color={getProgressColor(psdStatus)}
                      >
                        {getProgressText(psdStatus, psdErrorMessage)}
                      </Text>
                      {psdStatus === 'uploading' && (
                        <Text size={'1'} weight={'regular'}>
                          {fileUploadProgress}%
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              {psdStatus !== 'processing-error' &&
                psdStatus !== 'upload-error' && (
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    ref={formRef}
                  >
                    <label>
                      <TextField.Slot>
                        <Text weight={'medium'} size={'2'} mb={'1'}>
                          Mockup Name
                        </Text>
                      </TextField.Slot>
                      <TextField.Root
                        placeholder="Mockup Name"
                        value={mockupName}
                        onChange={(e) => setMockupName(e.currentTarget.value)}
                      />
                    </label>
                    {errors.mockupName && (
                      <Text role="alert" size={'1'} color="red">
                        {errors.mockupName.message}
                      </Text>
                    )}
                    <label>
                      <TextField.Slot>
                        <Text weight={'medium'} size={'2'} mt={'4'} mb={'1'}>
                          Category
                        </Text>
                      </TextField.Slot>
                      <Select.Root
                        value={mockupCategory?.toString()}
                        onValueChange={(e) => setMockupCategory(parseInt(e))}
                      >
                        <Select.Trigger style={{ width: '100%' }} />
                        <Select.Content
                          position="popper"
                          style={{ width: '100%' }}
                        >
                          {sortCategories(psdCategories.data?.data || []).map(
                            (category) => (
                              <Select.Item
                                key={category.id}
                                value={category.id.toString()}
                              >
                                {category.name}
                              </Select.Item>
                            )
                          )}
                        </Select.Content>
                      </Select.Root>
                    </label>
                    {errors.mockupCategory && (
                      <Text role="alert" size={'1'} color="red">
                        {errors.mockupCategory.message}
                      </Text>
                    )}
                    {canPublishPrivatePsd && (
                      <>
                        <label>
                          <Flex
                            direction={'row'}
                            align={'end'}
                            justify={'between'}
                          >
                            <TextField.Slot>
                              <Text weight={'medium'} size={'2'} mt={'4'}>
                                Public mockup
                              </Text>
                            </TextField.Slot>
                            <Flex gap="2" align={'center'}>
                              <Switch
                                size="2"
                                onCheckedChange={(val) => {
                                  const paValue: number = val ? 0 : 2;
                                  setPsdAvailability(
                                    (
                                      psdAvailabilitiesData.data?.data || []
                                    )?.find((pa) => pa.value === paValue)
                                  );
                                }}
                                defaultChecked={psdAvailability?.value === 0}
                              />
                            </Flex>
                          </Flex>
                        </label>
                        {errors.psdAvailability && (
                          <Text role="alert" size={'1'} color="red">
                            {errors.psdAvailability.message}
                          </Text>
                        )}
                      </>
                    )}
                    {canPublishPrivatePsd && (
                      <>
                        <label>
                          <Flex
                            direction={'row'}
                            align={'end'}
                            justify={'between'}
                          >
                            <TextField.Slot>
                              <Text weight={'medium'} size={'2'} mt={'4'}>
                                AI mockup
                              </Text>
                            </TextField.Slot>
                            <Flex gap="2" align={'center'}>
                              <Switch
                                size="2"
                                onCheckedChange={(val) => {
                                  setIsAiMockup(val);
                                }}
                                defaultChecked={isAiMockup}
                              />
                            </Flex>
                          </Flex>
                        </label>
                        {errors.subscriptionType && (
                          <Text role="alert" size={'1'} color="red">
                            {errors.subscriptionType.message}
                          </Text>
                        )}
                      </>
                    )}
                    {/* {canPublishPrivatePsd && (
                    <>
                      <label>
                        <Flex
                          direction={'row'}
                          align={'end'}
                          justify={'between'}
                        >
                          <TextField.Slot>
                            <Text weight={'medium'} size={'2'} mt={'4'}>
                              Pro mockup
                            </Text>
                          </TextField.Slot>
                          <Flex gap="2" align={'center'}>
                            <Switch
                              size="2"
                              onCheckedChange={(val) => {
                                const stName: string = val ? 'Pro' : 'Free';
                                setSubscriptionType(
                                  (
                                    subscriptionTypesData.data?.data || []
                                  )?.find((st) => st.name === stName)
                                );
                              }}
                              defaultChecked={subscriptionType?.name === 'Pro'}
                            />
                          </Flex>
                        </Flex>
                      </label>
                      {errors.subscriptionType && (
                        <Text role="alert" size={'1'} color="red">
                          {errors.subscriptionType.message}
                        </Text>
                      )}
                    </>
                  )} */}
                    {psdStatus === 'completed' && (
                      <Flex gap="3" mt="4" justify="end">
                        <Button type="submit" loading={updateMockupIsLoading}>
                          Continue to Mockup
                        </Button>
                      </Flex>
                    )}
                  </form>
                )}
            </Box>
          )}
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
});
