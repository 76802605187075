import { Flex, Text } from '@radix-ui/themes';
import './choose-design-or-mockup.scss';
import { ImageIcon, LayersIcon } from '@radix-ui/react-icons';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IChooseDesignOrMockup {
  setStep: (step: 1 | 2 | 3) => void;
  setStepName: (step: 'choose' | 'mockups' | 'designs') => void;
}

export const ChooseDesignOrMockup = ({
  setStep,
  setStepName,
}: IChooseDesignOrMockup) => {
  const captureEvent = useCaptureEvent();

  return (
    <Flex
      className="choose-design-or-mockup-wrapper"
      gap={'4'}
      justify={'center'}
      align={'center'}
    >
      <Flex
        className="item"
        p={'4'}
        direction={'column'}
        gap={'5'}
        align={'center'}
        onClick={() => {
          setStep(2);
          setStepName('designs');
          captureEvent('Create Mockups: Start with Designs selected');
        }}
      >
        <ImageIcon width="50" height="50" />
        <Flex direction={'column'} gap={'2'}>
          <Text size="4" weight={'bold'} align={'center'}>
            Start with Artwork
          </Text>
          <Text size="2" weight={'regular'} align={'center'}>
            Select multiple artwork first. Then, place them into various mockups
            to see how they look in different settings
          </Text>
        </Flex>
      </Flex>
      <Flex
        className="item"
        p={'4'}
        direction={'column'}
        gap={'5'}
        align={'center'}
        onClick={() => {
          setStep(2);
          setStepName('mockups');
          captureEvent('Create Mockups: Start with Mockups selected');
        }}
      >
        <LayersIcon width="50" height="50" />

        <Flex direction={'column'} gap={'2'}>
          <Text size="4" weight={'bold'} align={'center'}>
            Start with Mockups
          </Text>
          <Text size="2" weight={'regular'} align={'center'}>
            Choose multiple mockups first. Then, upload and apply your artwork
            to see how they fit across different products
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
