import { useAppDispatch, useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { printareaPresetsApi } from '@/services/printareaPresets';
import { psdEngineApi } from '@/services/psdEngine';
import { PrintareaPreset } from '@/services/types';
import { AlertDialog, Button, Flex } from '@radix-ui/themes';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { toast } from 'react-toastify';

interface IDeletePreset {
  preset: PrintareaPreset;
  setDeletedPresetId: (id: number) => void;
}

export const DeletePreset = forwardRef(
  ({ preset, setDeletedPresetId }: IDeletePreset, ref) => {
    const captureEvent = useCaptureEvent();
    const [open, setOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const {
      activeSmartObject,
      activeVariation,
      activeDesignAsset,
      mockup,
      isCreatingVariations,
    } = useAppSelector((state) => state.collectionReducer);

    const [deletePreset, { isLoading: isDeleting }] =
      printareaPresetsApi.useDeletePrintareaPresetMutation();

    useImperativeHandle(ref, () => ({
      openDialog() {
        setOpen(true);
      },
      closeDialog() {
        setOpen(false);
      },
    }));

    const deletePresetAction = async () => {
      try {
        await deletePreset({ id: preset.id }).unwrap();
        await dispatch(
          psdEngineApi.endpoints.getMockupSmartObjects.initiate(
            { mockup_id: mockup.id },
            {
              forceRefetch: true,
            }
          )
        ).unwrap();
        captureEvent('Print area preset removed', {
          title: preset?.name,
        });
        setDeletedPresetId(preset.id);
        toast.success('Preset successfully deleted.', {
          toastId: 'preset_action_success_delete',
          position: 'bottom-right',
          autoClose: 5000,
        });
      } catch (error: any) {
        console.log(error.data);
        toast.error(error.data.message, {
          toastId: 'preset_action_error_delete',
          position: 'bottom-right',
          autoClose: 5000,
        });
      }
    };

    return (
      <AlertDialog.Root open={open} onOpenChange={setOpen}>
        <AlertDialog.Content maxWidth={'450px'}>
          <AlertDialog.Title>Remove print area preset</AlertDialog.Title>
          <AlertDialog.Description size="2">
            Are you sure you want to remove '{preset?.name}' print area preset?
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Cancel>
              <Button
                variant="solid"
                color="red"
                onClick={() => deletePresetAction()}
                loading={isDeleting}
              >
                Remove
              </Button>
            </AlertDialog.Cancel>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    );
  }
);
