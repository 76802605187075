import { Flex, Grid, Spinner, Text } from '@radix-ui/themes';
import Skeleton from 'react-loading-skeleton';
import './loading-creating-batch.scss';

export const LoadingCreatingBatch = () => {
  return (
    <Flex
      className="loading-creating-batch-page"
      width={'100%'}
      height={'100%'}
      align={'center'}
      justify={'center'}
      direction={'column'}
    >
      <Flex
        className="header"
        width={'100%'}
        height={'56px'}
        align={'center'}
        justify={'between'}
        p={'3'}
      >
        <Skeleton height={'30px'} width={'100px'} />
        <Skeleton height={'30px'} width={'100px'} />
      </Flex>
      <Grid
        columns={'1fr 8fr 23fr'}
        rows={'1fr'}
        className="collection-wrapper"
      >
        <Flex
          className="bundle-mockups-list-wrapper"
          direction={'column'}
          align={'center'}
          p={'4'}
          gap={'4'}
        >
          <Skeleton className="item" />
          <Skeleton className="item" />
          <Skeleton className="item" />
        </Flex>
        <Flex className="editor-wrapper" direction={'column'}>
          <Flex className="smart-objects" direction={'column'} p={'2'}>
            <Skeleton className="item" />
          </Flex>
          <Flex className="tools" direction={'column'} p={'2'}>
            <Skeleton className="item" />
            <Skeleton className="item" />
            <Skeleton className="item" />
          </Flex>
        </Flex>
        <Flex
          className="preview"
          width={'100%'}
          height={'100%'}
          align={'center'}
          justify={'center'}
          p={'3'}
        >
          <Skeleton className="item" />
          <Text className="text">
            We’re creating your mockups, please stay tuned. It won’t take long!
          </Text>
        </Flex>
      </Grid>
    </Flex>
  );
};
