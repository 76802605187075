import {
  setActiveColorCombination,
  setActiveDesignAsset,
  setActiveSmartObject,
  setActiveVariation,
  setMobileNavigationActiveTab,
} from '@/redux/slices/collection';
import { ReactComponent as SparkleIcon } from '@/assets/icons/sparkle-24-regular.svg';
import {
  LayersIcon,
  EyeOpenIcon,
  TransformIcon,
  ImageIcon,
  ColorWheelIcon,
  BlendingModeIcon,
} from '@radix-ui/react-icons';
import {
  Tabs,
  Box,
  Flex,
  Button,
  ChevronDownIcon,
  Select,
  ScrollArea,
  Text,
} from '@radix-ui/themes';
import { AddColor } from '../../editor/components/colors/AddColor';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { setActiveView } from '@/redux/slices/editor';
import './mobile-editor-navigation.scss';
import { AdjustmentLayerControls } from '@/features/components/AdjustmentLayerControls';
import { DesignAssetsMobile } from '../../editor/components/DesignAssetsMobile';
import '../../toolbar/accordion.scss';
import { useMediaQuery } from 'react-responsive';
import { psdEngineApi } from '@/services/psdEngine';
import { AiBackgrounds } from '../../editor/components/ai-backgrounds/AiBackgrounds';
import Intercom from '@intercom/messenger-js-sdk';

export type mobileNavigationActiveTab =
  | 'none'
  | 'layers'
  | 'preview'
  | 'edit'
  | 'artwork'
  | 'color'
  | 'adjustment'
  | 'ai-backgrounds';

export const MobileEditorNavigation = () => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const {
    activeSmartObject,
    colorCombinations,
    activeColorCombination,
    mockupVariations,
    isCreatingVariations,
    activeDesignAsset,
    smartObjects,
    designAssets,
    activeVariation,
    mobileNavigationActiveTab,
  } = useAppSelector((state) => state.collectionReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  Intercom({ app_id: 's2w7quxz', hide_default_launcher: true });

  const getAssets = psdEngineApi.useGetMockupSmartObjectAssetsQuery(
    {
      smart_object_id: activeSmartObject?.id,
    },
    {
      skip:
        !activeSmartObject || designAssets?.[activeSmartObject?.id]?.length > 0,
      refetchOnMountOrArgChange: true,
    }
  );

  const getColors = psdEngineApi.useGetMockupSmartObjectColorCombinationsQuery(
    {
      smart_object_id: activeSmartObject?.id,
    },
    {
      skip:
        !activeSmartObject ||
        colorCombinations?.[activeSmartObject?.id]?.length > 0,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    const activeVariationColorId = activeVariation?.variation_elements.find(
      (ve) => ve.smart_object_id === activeSmartObject?.id
    )?.color_id;

    dispatch(
      setActiveColorCombination(
        colorCombinations[activeSmartObject?.id]?.find(
          (color) => color.id === activeVariationColorId
        )
      )
    );
  }, [activeVariation, activeSmartObject, colorCombinations]);

  useEffect(() => {
    const activeVariationAssetId = activeVariation?.variation_elements.find(
      (ve) => ve.smart_object_id === activeSmartObject?.id
    )?.asset_id;

    dispatch(
      setActiveDesignAsset(
        designAssets[activeSmartObject?.id]?.find(
          (asset) => asset.id === activeVariationAssetId
        )
      )
    );
  }, [activeVariation, activeSmartObject, designAssets]);

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    return () => {
      abortControllerRef.current?.abort();
    };
  }, []);

  const changeActiveColorCombination = (id: number) => {
    if (isCreatingVariations) {
      return;
    }

    const variation = activeDesignAsset?.id
      ? mockupVariations.find((mv) =>
          mv.variation_elements.some(
            (ve) =>
              ve?.color_id === id &&
              mv.variation_elements.some(
                (innerVe) => innerVe?.asset_id === activeDesignAsset?.id
              )
          )
        )
      : mockupVariations.find((mv) =>
          mv.variation_elements.some((ve) => ve?.color_id === id)
        );

    if (variation) {
      dispatch(setActiveVariation(variation));
    }
  };

  const changeActiveSmartObject = (id: number) => {
    dispatch(setActiveSmartObject(smartObjects?.find((so) => so.id === id)));
    dispatch(setActiveView('preview'));
  };

  const [tabValue, setTabValue] = useState<string>(
    activeSmartObject?.id.toString() || '0'
  );

  useEffect(() => {
    setTabValue(activeSmartObject?.id.toString() || '0');
  }, [activeSmartObject]);

  return (
    <Tabs.Root
      className="mobile-navigation-wrapper"
      defaultValue={mobileNavigationActiveTab}
      onValueChange={(value) =>
        dispatch(
          setMobileNavigationActiveTab(value as mobileNavigationActiveTab)
        )
      }
    >
      {mobileNavigationActiveTab !== 'none' &&
        mobileNavigationActiveTab !== 'preview' &&
        mobileNavigationActiveTab !== 'edit' && (
          <Box className={`tabs-content-wrapper ${mobileNavigationActiveTab}`}>
            <Tabs.Content value="layers" className="item">
              <Flex
                height={'100%'}
                direction={'column'}
                justify={'between'}
                gap={'2'}
              >
                <Flex
                  className="header"
                  direction={'row'}
                  align={'center'}
                  justify={'between'}
                >
                  <Text size="1" weight="bold" color="gray">
                    LAYERS
                  </Text>
                  <Button
                    onClick={() => setMobileNavigationActiveTab('none')}
                    color="gray"
                    size="1"
                    variant="surface"
                  >
                    <ChevronDownIcon />
                  </Button>
                </Flex>
                <Flex className="content" width={'100%'}>
                  <Select.Root
                    key={tabValue}
                    defaultValue={tabValue}
                    onValueChange={(value) =>
                      changeActiveSmartObject(parseInt(value))
                    }
                  >
                    <Select.Trigger style={{ width: '100%' }} />
                    <Select.Content>
                      <Select.Group>
                        {smartObjects?.map((so) => (
                          <Select.Item key={so.id} value={so.id.toString()}>
                            {so.smart_object_name.includes('dm:ai:background')
                              ? 'AI Scene'
                              : so.smart_object_name}
                          </Select.Item>
                        ))}
                      </Select.Group>
                    </Select.Content>
                  </Select.Root>
                </Flex>
              </Flex>
            </Tabs.Content>
            <Tabs.Content value="artwork" className="item">
              <Flex
                height={'100%'}
                direction={'column'}
                justify={'between'}
                gap={'2'}
                className="design-assets"
              >
                <Flex
                  className="header"
                  direction={'row'}
                  align={'center'}
                  justify={'between'}
                >
                  <Text size="1" weight="bold" color="gray">
                    ARTWORK
                  </Text>
                  <Button
                    onClick={() => setMobileNavigationActiveTab('none')}
                    color="gray"
                    size="1"
                    variant="surface"
                  >
                    <ChevronDownIcon />
                  </Button>
                </Flex>
                <Flex className="content">
                  <DesignAssetsMobile abortController={abortControllerRef} />
                </Flex>
              </Flex>
            </Tabs.Content>
            <Tabs.Content value="color" className="item">
              <Flex
                height={'100%'}
                direction={'column'}
                justify={'between'}
                gap={'2'}
              >
                <Flex
                  className="header"
                  direction={'row'}
                  align={'center'}
                  justify={'between'}
                >
                  <Text size="1" weight="bold" color="gray">
                    COLORS
                  </Text>
                  <Button
                    onClick={() => setMobileNavigationActiveTab('none')}
                    color="gray"
                    size="1"
                    variant="surface"
                  >
                    <ChevronDownIcon />
                  </Button>
                </Flex>
                <Flex className="content" height={'100%'}>
                  <ScrollArea
                    type="always"
                    scrollbars="horizontal"
                    style={{ paddingBottom: '5px' }}
                  >
                    <Flex
                      className="colors-wrapper"
                      direction={'row'}
                      align={'center'}
                      gap={'2'}
                      width={'100%'}
                      height={'100%'}
                    >
                      <Flex align={'center'} justify={'between'}>
                        <Box
                          style={{
                            visibility:
                              !isMobile &&
                              activeSmartObject &&
                              Object.keys(
                                colorCombinations?.[activeSmartObject?.id] || {}
                              ).length === 0
                                ? 'hidden'
                                : 'visible',
                          }}
                          mr={'3'}
                        >
                          <AddColor type={'add'} />
                        </Box>
                      </Flex>
                      <Flex
                        className="colors-list-wrapper"
                        direction={'row'}
                        gap={'1'}
                      >
                        {colorCombinations[activeSmartObject?.id]?.map(
                          (color) => (
                            <Flex
                              key={color.id}
                              align={'center'}
                              justify={'between'}
                              gap={'2'}
                            >
                              <Flex
                                className={`color-wrapper ${
                                  color.id === activeColorCombination?.id
                                    ? 'selected'
                                    : ''
                                }`}
                                gap={'2'}
                                p={'2'}
                                align={'center'}
                                onClick={() =>
                                  changeActiveColorCombination(color.id)
                                }
                              >
                                <AddColor type={'update'} colorToUpdate={color}>
                                  <Flex
                                    className={`color`}
                                    style={{ background: color.hex_code }}
                                    align={'center'}
                                    justify={'center'}
                                  ></Flex>
                                </AddColor>
                              </Flex>
                            </Flex>
                          )
                        )}
                      </Flex>
                    </Flex>
                  </ScrollArea>
                </Flex>
              </Flex>
            </Tabs.Content>

            <Tabs.Content value="adjustment" className="item adjustment">
              <Flex
                height={'100%'}
                direction={'column'}
                justify={'between'}
                gap={'2'}
              >
                <Flex
                  className="header"
                  direction={'row'}
                  align={'center'}
                  justify={'between'}
                >
                  <Text size="1" weight="bold" color="gray">
                    ADJUSTMENTS
                  </Text>
                  <Button
                    onClick={() => setMobileNavigationActiveTab('none')}
                    color="gray"
                    size="1"
                    variant="surface"
                  >
                    <ChevronDownIcon />
                  </Button>
                </Flex>
                <Flex className="content" height={'100%'}>
                  <Flex
                    className="colors-wrapper"
                    direction={'row'}
                    align={'center'}
                    gap={'2'}
                    width={'100%'}
                    height={'100%'}
                  >
                    <AdjustmentLayerControls />
                  </Flex>
                </Flex>
              </Flex>
            </Tabs.Content>

            <Tabs.Content value="ai-backgrounds" className="item">
              <Flex
                height={'100%'}
                direction={'column'}
                justify={'between'}
                gap={'2'}
              >
                <Flex
                  className="header"
                  direction={'row'}
                  align={'center'}
                  justify={'between'}
                >
                  <Text size="1" weight="bold" color="gray">
                    AI SCENE
                  </Text>
                  <Button
                    onClick={() => setMobileNavigationActiveTab('none')}
                    color="gray"
                    size="1"
                    variant="surface"
                  >
                    <ChevronDownIcon />
                  </Button>
                </Flex>
                <Flex className="content" height={'100%'}>
                  <Flex
                    className="artwork-wrapper"
                    direction={'column'}
                    gap={'27px'}
                  >
                    <AiBackgrounds />
                  </Flex>
                </Flex>
              </Flex>
            </Tabs.Content>
          </Box>
        )}
      <ScrollArea
        type="hover"
        scrollbars="horizontal"
        style={{ maxWidth: '100vw' }}
      >
        <Flex className="mobile-navigation" direction={'column'}>
          <Tabs.List justify={'center'}>
            <Tabs.Trigger value="layers">
              <Flex
                direction={'column'}
                height={'100%'}
                align={'center'}
                justify={'between'}
                gap={'1'}
              >
                <LayersIcon />
                <Text size={'1'}>LAYERS</Text>
              </Flex>
            </Tabs.Trigger>

            {!activeSmartObject?.smart_object_name.includes(
              'dm:ai:background'
            ) && (
              <Tabs.Trigger value="preview">
                <Flex
                  direction={'column'}
                  height={'100%'}
                  align={'center'}
                  justify={'between'}
                  gap={'1'}
                >
                  <EyeOpenIcon />
                  <Text size={'1'}>PREVIEW</Text>
                </Flex>
              </Tabs.Trigger>
            )}
            {!activeSmartObject?.smart_object_name.includes(
              'dm:ai:background'
            ) &&
              designAssets?.[activeSmartObject?.id]?.length > 0 && (
                <Tabs.Trigger value="edit">
                  <Flex
                    direction={'column'}
                    height={'100%'}
                    align={'center'}
                    justify={'between'}
                    gap={'1'}
                  >
                    <TransformIcon />
                    <Text size={'1'}>EDIT</Text>
                  </Flex>
                </Tabs.Trigger>
              )}
            {!activeSmartObject?.smart_object_name.includes(
              'dm:ai:background'
            ) && (
              <Tabs.Trigger value="artwork">
                <Flex direction={'column'} align={'center'} gap={'1'}>
                  <ImageIcon />
                  <Text size={'1'}>ARTWORK</Text>
                </Flex>
              </Tabs.Trigger>
            )}
            {!activeSmartObject?.smart_object_name.includes(
              'dm:ai:background'
            ) && (
              <Tabs.Trigger value="color">
                <Flex direction={'column'} align={'center'} gap={'1'}>
                  <ColorWheelIcon />
                  <Text size={'1'}>COLORS</Text>
                </Flex>
              </Tabs.Trigger>
            )}

            {!activeSmartObject?.smart_object_name.includes(
              'dm:ai:background'
            ) &&
              mockupVariations?.length > 0 &&
              !mockupVariations?.some((item) =>
                item.variation_elements.every(
                  (variation) =>
                    variation.color === null && variation.asset === null
                )
              ) && (
                <Tabs.Trigger value="adjustment">
                  <Flex direction={'column'} align={'center'} gap={'1'}>
                    <BlendingModeIcon />
                    <Text size={'1'}>ADJUST</Text>
                  </Flex>
                </Tabs.Trigger>
              )}
            {!!activeSmartObject?.is_background_layer && (
              <Tabs.Trigger value="ai-backgrounds">
                <Flex direction={'column'} align={'center'} gap={'1'}>
                  <SparkleIcon className="" width={'24px'} height={'24px'} />
                  <Text size={'1'}>AI SCENE</Text>
                </Flex>
              </Tabs.Trigger>
            )}
          </Tabs.List>
        </Flex>
      </ScrollArea>
    </Tabs.Root>
  );
};
