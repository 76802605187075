import './collection.scss';
import { Helmet } from 'react-helmet-async';
import { Box, Flex, Grid } from '@radix-ui/themes';
import { ViewWrapper } from './components/view-wrapper/ViewWrapper';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useMediaQuery } from 'react-responsive';
import { ToolsSidebar } from './components/tools-sidebar/ToolsSidebar';
import { Toolbar } from './components/toolbar/Toolbar';
import { mockupBundlesApi } from '@/services/mockupBundles';
import { psdEngineApi } from '@/services/psdEngine';
import { workspaceApi } from '@/services/workspace';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SmartObject } from '@/services/types';
import { useEffect, useState } from 'react';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { setIsMockupLoading } from '@/redux/slices/collection';
import { toast } from 'react-toastify';
import { AppHeader } from '@/features/layout/header/AppHeader';
import { MobileEditorNavigation } from './components/view-wrapper/components/MobileEditorNavigation';
import designs from '@/redux/slices/designs';
import { SingleDesign } from '../workspace/components/mockup-bundles/components/view-wrapper/single-design/SingleDesign';
import { DesignAsset } from './components/editor/components/DesignAsset';
import { AiBackground } from './components/editor/components/AiBackground';

export const Collection = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const captureEvent = useCaptureEvent();
  const { collectionId, bundleId } = useParams();
  const { showNotificationBar } = useAppSelector((state) => state.appReducer);
  const { activeView } = useAppSelector((state) => state.editorReducer);
  const {
    activeSmartObject,
    smartObjects,
    mobileNavigationActiveTab,
    activeDesignAsset,
  } = useAppSelector((state) => state.collectionReducer);
  const { currentMockupBundle } = useAppSelector(
    (state) => state.mockupBundlesReducer
  );
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [soData, setSoData] = useState<{
    data: SmartObject[];
    isLoading: boolean;
  }>({ data: [], isLoading: false });

  const [loadMockup, { isLoading: isLoadingMockup, status }] =
    psdEngineApi.useLoadPublicPsdMockupMutation();
  const geyMyProjects = workspaceApi.useGetMyProjectsQuery(null!, {
    refetchOnMountOrArgChange: true,
  });
  const getCurrentBundle = mockupBundlesApi.useGetMockupBundleQuery(
    { mockupBundleUUID: bundleId || null! },
    {
      skip: !bundleId,
      refetchOnMountOrArgChange: true,
    }
  );
  const getCrrentMockup = psdEngineApi.useGetCurrentMockupQuery(
    {
      mockup_id: parseInt(
        geyMyProjects.data?.data
          .find((project) => project.uuid === collectionId)
          ?.id.toString()!
      ),
    },
    {
      skip: !geyMyProjects.data?.data
        .find((project) => project.uuid === collectionId)
        ?.id.toString(),
      refetchOnMountOrArgChange: true,
    }
  );
  const getSmartObjects = psdEngineApi.useGetMockupSmartObjectsQuery(
    {
      mockup_id:
        parseInt(
          geyMyProjects.data?.data
            .find((project) => project.uuid === collectionId)
            ?.id.toString()!
        ) ||
        parseInt(
          getCurrentBundle.data?.data.mockups
            .find((project) => project.uuid === collectionId)
            ?.id.toString()!
        ),
    },
    {
      skip:
        (!getCrrentMockup.data?.data ||
          !geyMyProjects.data?.data
            .find((project) => project.uuid === collectionId)
            ?.id.toString()) &&
        (!getCurrentBundle.data?.data ||
          !getCurrentBundle.data?.data.mockups
            .find((project) => project.uuid === collectionId)
            ?.id.toString()),
      refetchOnMountOrArgChange: true,
    }
  );

  const isParsableNumber = (value: any) => {
    // Check if the value is a string
    if (typeof value !== 'string') {
      return false;
    }

    // Convert the string to a number
    const number = Number(value);

    // Check if the result is a finite number
    return Number.isFinite(number);
  };

  useEffect(() => {
    async function createBulkTemplate() {
      try {
        const data = await loadMockup({
          psd_id: parseInt(collectionId as string),
        }).unwrap();

        await dispatch(
          psdEngineApi.endpoints.getCurrentMockup.initiate({
            mockup_id: data.data.id,
          })
        ).unwrap();
        if (!activeSmartObject && !smartObjects) {
          setSoData({ data: [], isLoading: true });
          const soFetchData = await dispatch(
            psdEngineApi.endpoints.getMockupSmartObjects.initiate(
              {
                mockup_id: data.data.id,
              },
              { forceRefetch: true }
            )
          ).unwrap();
          setSoData({ data: soFetchData.data, isLoading: false });
        }
        await geyMyProjects.refetch();

        let fromLocation: 'public' | 'private' | 'website' = 'website';
        switch (location.state?.from) {
          case '/mockup-library':
            fromLocation = 'public';
            break;
          case '/custom-mockups':
            fromLocation = 'private';
            break;
          default:
            fromLocation = 'website';
        }

        captureEvent('Mockup Opened', {
          mockup_id: data.data.id,
          mockup_name: data.data.name,
          mockup_category_id: data.data?.psdCategory?.id,
          mockup_category_name: data.data?.psdCategory?.name,
          library: fromLocation,
        });
        navigate(`/mockup/${data.data.uuid}`);
        dispatch(setIsMockupLoading(false));
      } catch (error: any) {
        console.log(error);
        toast.error(error.data.message, {
          toastId: 'open_template_error',
          position: 'bottom-right',
          autoClose: 3000,
        });
      }
    }

    if (
      status !== 'fulfilled' &&
      !isLoadingMockup &&
      isParsableNumber(collectionId) &&
      geyMyProjects.data &&
      !geyMyProjects.data?.data
        .find((project) => project.uuid === collectionId)
        ?.id.toString()
    ) {
      createBulkTemplate();
    }
  }, [geyMyProjects.data?.data]);

  const getColumnsNumber = () => {
    if (currentMockupBundle) {
      if (activeView === 'preview') {
        return '1fr 8fr 23fr';
      } else {
        return '8fr 20fr';
      }
    } else {
      switch (activeView) {
        case 'create-ai-background':
          return '2fr 8fr 22fr';
        case 'preview':
          return activeSmartObject ? '2fr 8fr 22fr' : '1fr 8fr 23fr';
        default:
          return '2fr 8fr 22fr';
      }
    }
  };

  return (
    <>
      {/* {isMobile && (
        <Flex direction={'column'} className="collection-wrapper">
          <Helmet>
            <title>Dynamic Mockups | Mockup</title>
            <meta name="robots" content="noindex, nofollow" />
          </Helmet>

          <ViewWrapper />
        </Flex>
      )} */}

      <Grid
        columns={isMobile ? '1fr' : '2fr 30fr'}
        rows={'1fr'}
        gap={'5'}
        className="collection-wrapper"
      >
        <Helmet>
          <title>Dynamic Mockups | Collection</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        {/* {activeSmartObject && currentMockupBundle?.mockups.length ? (
            <BundleMockupsList />
          ) : (
            currentMockupBundle?.mockups.length && (
              <Flex
                className="bundle-mockups-list-wrapper"
                direction={'column'}
                width={'89px'}
                height={'100vh'}
                gap={'4'}
                p={'4'}
              >
                <Skeleton className={`mockup-item`} />
                <Skeleton className={`mockup-item`} />
                <Skeleton className={`mockup-item`} />
              </Flex>
            )
          )} */}
        {!isMobile && (
          <ToolsSidebar
            smartObjectsData={getSmartObjects.data?.data || soData.data || []}
            isLoadingSmartObjects={
              getSmartObjects.isLoading || soData.isLoading
            }
          />
        )}

        {activeView === 'create-ai-background' && isMobile ? (
          <AiBackground />
        ) : (
          <Flex
            direction={'column'}
            gap={'5'}
            py={'5'}
            pb={isMobile ? '0' : undefined}
            width={'100%'}
            height={'100%'}
          >
            <AppHeader />
            <Grid
              columns={isMobile ? '1fr' : '8fr 22fr'}
              rows={'1fr'}
              gap={'5'}
              width={'100%'}
              height={'100%'}
            >
              {!isMobile && (
                <Toolbar
                  smartObjectsData={
                    getSmartObjects.data?.data || soData.data || []
                  }
                  isLoadingSmartObjects={
                    getSmartObjects.isLoading || soData.isLoading
                  }
                />
              )}

              {isMobile ? (
                <Box width={'100%'} height={'100%'}>
                  <Box
                    width={'100%'}
                    height={'100%'}
                    className={`preview-mobile-wrapper ${
                      mobileNavigationActiveTab === 'edit' ? 'edit' : 'preview'
                    }`}
                  >
                    <ViewWrapper />
                  </Box>
                  {mobileNavigationActiveTab === 'edit' &&
                    activeDesignAsset && (
                      <Box px={'2'} width={'100%'} height={'100%'}>
                        <DesignAsset />
                      </Box>
                    )}
                </Box>
              ) : (
                <ViewWrapper />
              )}

              {isMobile && <MobileEditorNavigation />}
            </Grid>
          </Flex>
        )}
      </Grid>
    </>
  );
};
