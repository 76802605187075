import './choose-public-library.scss';
import { MockupsList } from './MockupsList';

interface IChoosePublicLibrary {
  activePublicLibraryCategoryId: number;
}

export const ChoosePublicLibrary = ({
  activePublicLibraryCategoryId,
}: IChoosePublicLibrary) => {
  return <MockupsList categoryId={activePublicLibraryCategoryId} />;
};
