import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  AlertDialog,
  Button,
  Flex,
  IconButton,
  Popover,
  Text,
} from '@radix-ui/themes';
import { ReactComponent as AddLinearIcon } from '@/assets/icons/add-linear.svg';
import { useCallback, useEffect, useState } from 'react';
import './colors-popover.scss';
import './colors.scss';
import { SubmitHandler, useForm } from 'react-hook-form';
import debounce from 'debounce';
import Chrome from '@uiw/react-color-chrome';
import { useMediaQuery } from 'react-responsive';
import { psdEngineApi } from '@/services/psdEngine';
import { setActiveVariation } from '@/redux/slices/collection';
import { ColorCombination, Mockup, SmartObject } from '@/services/types';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { toast } from 'react-toastify';
import { Color } from './Color';

interface IFormInput {
  hex: string;
}

export interface IAddColor {
  type: 'add' | 'update';
  colorToUpdate?: ColorCombination;
  children?: JSX.Element;
}

export const AddColor = ({ type, colorToUpdate, children }: IAddColor) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const {
    activeColorCombination,
    activeSmartObject,
    mockupVariations,
    isCreatingVariations,
    mockup,
    colorCombinations,
  } = useAppSelector((state) => state.collectionReducer);

  const [localType, setLocalType] = useState<'add' | 'update'>(type);
  const [hex, setHex] = useState<string>(colorToUpdate?.hex_code || '#0190ff');
  const [popoverOpen, setPopoverOpen] = useState(false);

  const [addColor, { isLoading: isAdding }] =
    psdEngineApi.useAddColorCombinationMutation();
  const [updateColor, { isLoading: isUpdating }] =
    psdEngineApi.useUpdateColorCombinationMutation();
  const [removeColor, { isLoading: isDeleting }] =
    psdEngineApi.useDeleteColorVariationMutation();
  const [createMockupVariations, { isLoading: isCreatingMockupVariations }] =
    psdEngineApi.useCreateMockupVariationsMutation();

  useEffect(() => {
    setValue('hex', hex);
  }, [hex]);

  useEffect(() => {
    setLocalType(type);
  }, [type, popoverOpen]);

  const setHexColorAction = useCallback(
    debounce(
      async ({
        hex,
        type,
        activeSmartObject,
        mockup,
        activeColorCombination,
      }: {
        hex: string;
        type: 'add' | 'update';
        activeSmartObject: SmartObject;
        mockup: Mockup;
        activeColorCombination: ColorCombination;
      }) => {
        if (type === 'add') {
          setLocalType('update');
          const addedColor = await addColor({
            smart_object_id: activeSmartObject?.id,
            hex_code: hex,
          }).unwrap();

          const createdVariations = await createMockupVariations({
            payload: {
              mockup_id: mockup?.id,
              smart_object_id: activeSmartObject.id,
              color_id: addedColor.data.id,
            },
          }).unwrap();
          captureEvent('Color added', {
            hex: hex,
            mockupId: mockup.id,
          });

          dispatch(
            setActiveVariation({
              ...createdVariations.data[createdVariations.data.length - 1],
              isFetching: true,
            })
          );
        } else {
          await updateColor({
            id: activeColorCombination?.id,
            smart_object_id: activeSmartObject?.id,
            hex_code: hex,
          });
          captureEvent('Color updated', {
            oldHex: colorToUpdate?.hex_code,
            newHex: hex,
            mockupId: mockup.id,
          });
        }
      },
      150
    ),
    []
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      hex: colorToUpdate?.hex_code || hex || '#0190ff',
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = (data) => hexSubmit(data);

  const hexSubmit = async (data: IFormInput) => {
    try {
      setHex(hex);
      setHexColorAction({
        hex,
        type: localType,
        activeSmartObject,
        mockup,
        activeColorCombination,
      });
    } catch (error: any) {
      console.error(error.data);
    }
  };

  const getNumerOfAssociatedVariations = () => {
    const associatedVariations = mockupVariations.filter((variation) => {
      return variation.variation_elements.some(
        (element) => element.color_id === colorToUpdate?.id
      );
    });

    return associatedVariations.length;
  };

  const removeColorVariant = async (id: number) => {
    try {
      await removeColor({ id: id }).unwrap();
      captureEvent('Color variant removed', {
        id: id,
      });
    } catch (error: any) {
      toast.error(error.data.message, {
        toastId: 'remove_color_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  const addAllColorPresetColors = async (id: number) => {
    const colorPreset = mockup.color_sets.find((cp) => cp.id === id);
    setLocalType('update');

    if (
      !colorPreset ||
      !colorPreset.color_set_colors ||
      !colorPreset.color_set_colors.length
    ) {
      console.error('No colors found in the selected preset');
      return;
    }

    const existingColors = colorCombinations[activeSmartObject.id].map((cc) =>
      cc.hex_code.toLowerCase()
    );

    // Filter out colors that already exist in colorCombinations
    const newColors = colorPreset.color_set_colors.filter((color) => {
      const hexCode = (
        typeof color === 'string' ? color : color.hex_code
      ).toLowerCase();
      return !existingColors.includes(hexCode);
    });

    if (newColors.length === 0) {
      console.warn('All colors from this preset are already added');
      return;
    }

    // Process each color in the preset
    for (const color of newColors) {
      // Add each color to the smart object
      const addedColor = await addColor({
        smart_object_id: activeSmartObject?.id,
        hex_code: color.hex_code, // Assuming color might be a string or an object with hex property
      }).unwrap();

      // Create mockup variations for each color
      const createdVariations = await createMockupVariations({
        payload: {
          mockup_id: mockup?.id,
          smart_object_id: activeSmartObject.id,
          color_id: addedColor.data.id,
        },
      }).unwrap();

      // Set the last created variation as active
      if (color === newColors[newColors.length - 1]) {
        dispatch(
          setActiveVariation({
            ...createdVariations.data[createdVariations.data.length - 1],
            isFetching: true,
          })
        );

        captureEvent('User added all colors from color set', {
          colorSetId: id,
          colors: newColors.map((nc) => nc.hex_code),
        });
      }
    }
  };

  return (
    <Popover.Root
      onOpenChange={(open) => {
        setPopoverOpen(open);
      }}
    >
      <Popover.Trigger>
        <IconButton
          className="add-color-button"
          variant={isMobile ? 'outline' : 'ghost'}
          size={'1'}
          color={popoverOpen ? undefined : 'gray'}
        >
          {children ? (
            children
          ) : (
            <AddLinearIcon
              width="28px"
              height="28px"
              className={`${popoverOpen ? 'blue' : 'gray'} add icon`}
            />
          )}
        </IconButton>
      </Popover.Trigger>
      <Popover.Content
        className="colors-popover"
        size={'2'}
        maxWidth={'264px'}
        minWidth={'200px'}
        side="right"
      >
        <Flex className="color-picker-wrapper" direction={'column'} gap={'2'}>
          <Flex gap={'2'} className="previews">
            <Flex className="color-pickers" direction={'column'} gap={'2'}>
              <Chrome
                color={hex || '#0190ff'}
                showAlpha={false}
                inputType={'hexa' as any}
                onChange={(color) => {
                  setHex(color.hex);
                  setHexColorAction({
                    hex: color.hex,
                    type: localType,
                    activeSmartObject,
                    mockup,
                    activeColorCombination,
                  });
                }}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex gap="5" direction={'column'} p={'9px'}>
          {mockup.color_sets?.map((preset) => (
            <Flex
              key={preset.id}
              className="color-set-wrapper"
              gap="3"
              direction={'column'}
            >
              <Flex gap={'2'} justify={'between'} align={'center'}>
                <Text
                  title={preset.name}
                  className="color-preset-name"
                  size={'1'}
                  weight={'regular'}
                >
                  {preset.name}
                </Text>
                {preset.color_set_colors.length > 1 && (
                  <Button
                    onClick={() => addAllColorPresetColors(preset.id)}
                    size={'1'}
                    variant="soft"
                    loading={isAdding}
                  >
                    Add all
                  </Button>
                )}
              </Flex>
              <Flex className="colors" gap={'2'}>
                {preset.color_set_colors.map((color) => (
                  <Color
                    key={color.id}
                    color={color}
                    type={localType}
                    colorToUpdate={colorToUpdate}
                  />
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>

        {isMobile && type === 'update' && (
          <Flex justify={'end'}>
            <AlertDialog.Root>
              <AlertDialog.Trigger>
                <Button color="red" size={'1'}>
                  Remove color
                </Button>
              </AlertDialog.Trigger>
              <AlertDialog.Content style={{ maxWidth: 450 }}>
                <AlertDialog.Title>Remove Color</AlertDialog.Title>
                <AlertDialog.Description size="2">
                  Are you sure you want to proceed? Removing this color will{' '}
                  {getNumerOfAssociatedVariations() === mockupVariations.length
                    ? 'update'
                    : 'remove'}{' '}
                  {getNumerOfAssociatedVariations()} associated{' '}
                  {getNumerOfAssociatedVariations() === 1
                    ? 'variation.'
                    : 'variations.'}
                </AlertDialog.Description>

                <Flex gap="3" mt="4" justify="end">
                  <AlertDialog.Cancel>
                    <Button variant="soft" color="gray">
                      Cancel
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Cancel>
                    <Button
                      variant="solid"
                      color="red"
                      onClick={() =>
                        removeColorVariant(colorToUpdate?.id as any)
                      }
                      disabled={isCreatingVariations}
                    >
                      Remove
                    </Button>
                  </AlertDialog.Cancel>
                </Flex>
              </AlertDialog.Content>
            </AlertDialog.Root>
          </Flex>
        )}
      </Popover.Content>
    </Popover.Root>
  );
};

