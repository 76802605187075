import { Outlet, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { AlertDialog, Button, Flex, Skeleton, Text } from '@radix-ui/themes';
import './appHeader.scss';
import { useState } from 'react';
import { ReactComponent as ArrowLeft } from '@/assets/icons/arrow-left-linear.svg';
import { GenerateVariationsButton } from '@/features/screens/collection/components/view-wrapper/components/GenerateVariationsButton';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as ProCrownIcon } from '@/assets/icons/pro-crown.svg';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { Onboarding } from './Onboarding';
import { BundleMockupsList } from '@/features/screens/collection/components/bundle-mockups-list/BundleMockupsList';

export const AppHeader = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const captureEvent = useCaptureEvent();
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const { isExportingMockups, activeSmartObject } = useAppSelector(
    (state) => state.collectionReducer
  );
  const { currentMockupBundle } = useAppSelector(
    (state) => state.mockupBundlesReducer
  );
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const isAppPro = useFeatureFlagEnabled('app-pro');
  const { mockup } = useAppSelector((state) => state.collectionReducer);

  const [pageLeaveAlertOpen, setPageLeaveAlertOpen] = useState<boolean>(false);

  return (
    <>
      {/* {!isAppPro && (
        <Flex
          className="notification-banner black-fridady"
          align={'center'}
          justify={'center'}
        >
          <Text
            className="text"
            size={'2'}
            onClick={() => {
              captureEvent('Upgrade your plan modal viewed', {
                source: 'Black Friday banner',
              });
              captureEvent('Black Friday banner clicked');
              dispatch(setIsAppPaymentPopupOpen(true));
            }}
          >
            🚨 Use “<b>BF40</b>” at checkout to save 40% on all plans - Ends
            November 30th! 🚨
          </Text>
        </Flex>
      )} */}
      <Flex
        className={`app-header`}
        justify={'between'}
        align={'center'}
        display={'flex'}
        gap={'4'}
        pr={'5'}
        pl={'2'}
      >
        <Flex gap={'5'} align={'center'}>
          <AlertDialog.Root
            open={pageLeaveAlertOpen}
            onOpenChange={(open) => setPageLeaveAlertOpen(open)}
          >
            <AlertDialog.Content maxWidth="450px">
              <AlertDialog.Title>Leave page</AlertDialog.Title>
              <AlertDialog.Description size="2">
                Are you sure? If you leave this page, your variations currently
                being exported will be canceled.
              </AlertDialog.Description>

              <Flex gap="3" mt="4" justify="end">
                <AlertDialog.Cancel>
                  <Button variant="soft" color="gray">
                    Cancel
                  </Button>
                </AlertDialog.Cancel>
                <Button
                  variant="solid"
                  color="red"
                  onClick={() => {
                    setPageLeaveAlertOpen(false);
                    navigate(`/my-templates`);
                  }}
                >
                  Leave page
                </Button>
              </Flex>
            </AlertDialog.Content>
          </AlertDialog.Root>

          <Button
            variant="ghost"
            color="gray"
            className="back-button"
            size={'1'}
            onClick={() =>
              !isExportingMockups
                ? navigate(`/my-templates`)
                : setPageLeaveAlertOpen(true)
            }
          >
            <ArrowLeft width="24px" height="24px" className="icon" />
          </Button>
          {!isMobile && (
            <Flex gap={'2'} align={'center'}>
              <Text weight={'medium'} color="gray" size={'2'}>
                My Templates
              </Text>
              <Text weight={'medium'} color="gray" size={'2'}>
                /
              </Text>
              <Text weight={'medium'} size={'2'}>
                {mockup?.name}
              </Text>
            </Flex>
          )}
        </Flex>

        <Flex align={'center'}></Flex>

        <Flex direction={'row'} align={'center'} justify={'end'} gap={'2'}>
          {activeSmartObject && currentMockupBundle?.mockups.length ? (
            <BundleMockupsList />
          ) : (
            currentMockupBundle?.mockups.length && (
              <Flex
                className="bundle-mockups-list-wrapper"
                direction={'column'}
                width={'89px'}
                height={'100vh'}
                gap={'4'}
                p={'4'}
              >
                <Skeleton className={`mockup-item`} />
                <Skeleton className={`mockup-item`} />
                <Skeleton className={`mockup-item`} />
              </Flex>
            )
          )}
          <Flex align={'center'} justify={'between'} gap={'2'}>
            {!isAppPro && (
              <Button
                className="get-pro-header-button"
                size={'1'}
                color="gray"
                variant="outline"
                onClick={() => {
                  captureEvent('Upgrade your plan modal viewed', {
                    source: 'Header "Get Pro" CTA',
                  });
                  dispatch(setIsAppPaymentPopupOpen(true));
                }}
              >
                <ProCrownIcon width="18" height="18" />
                {currentUser?.subscription_trial.is_available ? (
                  <Text className="">Start Free Trial</Text>
                ) : (
                  <Text className="">Get Pro</Text>
                )}
              </Button>
            )}
            <GenerateVariationsButton />
          </Flex>
        </Flex>
      </Flex>
      <Outlet />
    </>
  );
};
