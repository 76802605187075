import { PlusIcon } from '@radix-ui/react-icons';
import { Dialog, Button, Flex, TextField, Text } from '@radix-ui/themes';
import { IntegrationConfig } from './IntegrationConfig';
import posthog from 'posthog-js';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { mockupEditorEmbedApi } from '@/services/mockupEditorEmbed';
import { useAppDispatch, useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { MockupEditorIframeIntegration } from '@/redux/slices/types';
import { Dispatch, SetStateAction, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useFeatureFlagEnabled } from 'posthog-js/react';

interface IFormInput {
  websiteKeyName: string;
  whitelistedDomain: string;
  logo: File;
}

interface ICreateMockupEditor {
  setCodeExampleOpen: Dispatch<SetStateAction<boolean>>;
  setCodeWebsiteKey: Dispatch<SetStateAction<string>>;
}

export const CreateMockupEditor = ({
  setCodeExampleOpen,
  setCodeWebsiteKey,
}: ICreateMockupEditor) => {
  const captureEvent = useCaptureEvent();
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [editedWebsiteKey, setEditedWebsiteKey] =
    useState<MockupEditorIframeIntegration>(null!);
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const { currentWorkspace } = useAppSelector(
    (state) => state.workspaceReducer
  );

  const [createMockupEditorIframeIntegration, { isLoading: isCreating }] =
    mockupEditorEmbedApi.useCreateMockupEditorIframeIntegrationMutation();

  const {
    register: registerCreateDialog,
    handleSubmit: handleSubmitCreateWebsiteKey,
    formState: { errors: createErrors },
    reset: resetCreateDialog,
  } = useForm<IFormInput>();

  const onSubmitCreateWebsiteKey: SubmitHandler<IFormInput> = (data) =>
    createIntegration(data);

  const createIntegration = async (data: IFormInput) => {
    try {
      const createdIntegration = await createMockupEditorIframeIntegration({
        integration_name: data.websiteKeyName,
        whitelisted_domain: data.whitelistedDomain,
        config: {
          logo: editedWebsiteKey.config?.logo || null,
          color: editedWebsiteKey.config?.color || '',
          whitelabel: editedWebsiteKey.config?.whitelabel || false,
          dark_mode: editedWebsiteKey.config?.dark_mode || false,
        },
      }).unwrap();
      captureEvent('Mockup editor iframe - User created Website Key', {
        integration_name: data.websiteKeyName,
        whitelisted_domain: data.whitelistedDomain,
        $set: { website_key_created: true },
      });

      const currentDate = new Date();
      const isoDateString = currentDate.toISOString();

      posthog.capture('$groupidentify', {
        $group_type: 'Embedded Editor',
        $group_key: createdIntegration.data.website_key,
        $group_set: {
          websiteName: data.websiteKeyName,
          websiteDomain: data.whitelistedDomain,
          whitelabeled:
            editedWebsiteKey.config?.whitelabel === false
              ? true
              : editedWebsiteKey.config?.whitelabel === true
              ? false
              : false,
          workspaceId: currentWorkspace?.id,
          websiteKey: createdIntegration.data.website_key,
          createdBy: currentUser?.email,
          createdAt: isoDateString,
        },
      });

      setCodeWebsiteKey(createdIntegration.data.website_key);
      setEditedWebsiteKey(createdIntegration.data);
      setCodeExampleOpen(true);
      setCreateDialogOpen(false);
    } catch (error) {
      console.log('error: ', error);
      toast.warning((error as unknown as any)?.data?.message, {
        toastId: 'website_key_action_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  return (
    <Dialog.Root open={createDialogOpen} onOpenChange={setCreateDialogOpen}>
      <Dialog.Trigger>
        <Button
          disabled={isCreating}
          // onClick={(e) => {
          //   if (!hasEmbedMockupEditorAccess) {
          //     e.preventDefault();
          //     captureEvent('Upgrade your plan modal viewed', {
          //       source: 'Create new Website Key',
          //     });
          //     dispatch(setIsAppPaymentPopupOpen(true));
          //   }
          // }}
        >
          <PlusIcon width={'12px'} height={'12px'} className="icon white" />
          <Text size="1" weight="medium">
            Create Mockup Editor
          </Text>
        </Button>
      </Dialog.Trigger>

      <Dialog.Content aria-describedby={undefined} style={{ maxWidth: 450 }}>
        <Dialog.Title>Create Mockup Editor</Dialog.Title>
        <Dialog.Description size="2" mb="4"></Dialog.Description>
        <form onSubmit={handleSubmitCreateWebsiteKey(onSubmitCreateWebsiteKey)}>
          <Flex direction="column" gap="3">
            <Flex direction={'column'}>
              <label>
                <Text size={'1'}>Website name</Text>
                <TextField.Root
                  {...registerCreateDialog('websiteKeyName', {
                    required: {
                      value: true,
                      message: 'Website name field is required',
                    },
                  })}
                  placeholder="Dynamic Mockups"
                />
              </label>
              {createErrors.websiteKeyName && (
                <Text role="alert" size={'1'} color="red">
                  {createErrors.websiteKeyName.message}
                </Text>
              )}
            </Flex>
            <Flex direction={'column'}>
              <label>
                <Text size={'1'}>Whitelisted domain</Text>
                <TextField.Root
                  {...registerCreateDialog('whitelistedDomain', {
                    required: {
                      value: true,
                      message: 'Whitelisted domain field is required',
                    },
                  })}
                  placeholder="https://dynamicmockups.com"
                />
              </label>
              {createErrors.whitelistedDomain && (
                <Text role="alert" size={'1'} color="red">
                  {createErrors.whitelistedDomain.message}
                </Text>
              )}
            </Flex>

            <IntegrationConfig
              setData={(data) => {
                setEditedWebsiteKey((prev) => ({
                  ...prev,
                  ...data,
                }));
              }}
            />
          </Flex>
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </Dialog.Close>
            <Button
              event-tracker-id="create-website-key"
              variant="solid"
              color="blue"
              type="submit"
              loading={isCreating}
            >
              Create
            </Button>
          </Flex>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  );
};
