import { useState, useEffect } from 'react';

interface IFaviconWithFallback {
  domain: string;
  fallback: JSX.Element;
}

export const FaviconWithFallback = ({
  domain,
  fallback,
}: IFaviconWithFallback) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const faviconUrl = ''; //`https://www.google.com/s2/favicons?domain=${domain}`

  useEffect(() => {
    setImageLoaded(false);
    setHasError(false);
  }, [domain]);

  const handleImageLoad = () => {
    return fallback;
    setImageLoaded(true);
  };

  const handleImageError = () => {
    return fallback;
    setHasError(true);
  };

  if (hasError) {
    return fallback;
  }

  return (
    <>
      <img
        src={faviconUrl}
        className="item-icon"
        style={{
          display: imageLoaded ? 'inline-block' : 'none',
          width: '20px',
          height: '20px',
        }}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />
      {!imageLoaded && !hasError && fallback}
    </>
  );
};
