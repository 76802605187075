import { Helmet } from 'react-helmet-async';
import { ViewWrapper } from './components/view-wrapper/ViewWrapper';
import './mockup-templates.scss';
import { Button, Flex, ScrollArea, Text } from '@radix-ui/themes';
import { PlusIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';

export const MockupTemplates = () => {
  const navigate = useNavigate();

  return (
    <>
      <Flex
        className="page-wrapper my-templates-page"
        direction={'column'}
        align={'center'}
        gap={'5'}
      >
        <Helmet>
          <title>Dynamic Mockups | Mockup Templates</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Flex
          className="header"
          align={'center'}
          justify={'between'}
          maxWidth={'100%'}
          width={'100%'}
        >
          <Text className="page-title">Mockup Templates</Text>
          <Button onClick={() => navigate('/create-mockup-template')}>
            <PlusIcon width={'12px'} height={'12px'} className="icon white" />
            <Text size="1" weight="medium">
              Create Mockup Template
            </Text>
          </Button>
        </Flex>

        <ViewWrapper />
      </Flex>
    </>
  );
};
