import './view-wrapper.scss';
import { Button, Flex, Grid, ScrollArea, Text } from '@radix-ui/themes';
import { SingleTemplate } from './SingleTemplate';
import { workspaceApi } from '@/services/workspace';
import Skeleton from 'react-loading-skeleton';
import { useAppSelector } from '@/hooks';
import { ReactComponent as TreeSquaresIcon } from '@/assets/icons/three-squares-linear.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { CollectionsWidget } from './CollectionsWidget';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveMockupCollectionId } from '@/redux/slices/mockupCollections';
import { useMediaQuery } from 'react-responsive';

export const ViewWrapper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { myProjects } = useAppSelector((state) => state.workspaceReducer);
  const { activeMockupCollectionId, mockupCollections } = useAppSelector(
    (state) => state.mockupCollectionsReducer
  );

  useEffect(() => {
    dispatch(setActiveMockupCollectionId(0));
  }, []);

  const geyMyProjects = workspaceApi.useGetMyProjectsQuery(
    { collectionId: activeMockupCollectionId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (location.pathname.includes('/my-templates')) {
      if (activeMockupCollectionId !== 0) {
        navigate(
          `/my-templates/${
            mockupCollections.find(
              (collection) => collection.id === activeMockupCollectionId
            )?.uuid
          }`
        );
      } else {
        navigate(`/my-templates`);
      }
    }
  }, [activeMockupCollectionId]);

  return (
    <Flex gap={'5'} className="widget-view-wrapper" width={'100%'}>
      <CollectionsWidget />

      <ScrollArea
        className="scroll-area-page-wrapper"
        type="hover"
        scrollbars="vertical"
        style={{
          height: `100%`,
        }}
      >
        <Flex
          className="page-content mockup-templates"
          direction={'column'}
          width={'100%'}
          gap={'2'}
        >
          <Grid
            className="view-wrapper"
            gap={'2'}
            pr={'10px'}
            style={{
              display:
                !geyMyProjects.isLoading && myProjects.length === 0
                  ? 'flex'
                  : 'grid',
            }}
          >
            {geyMyProjects.isLoading || geyMyProjects.isFetching
              ? [...Array(12)].map((item, index) => (
                  <Skeleton
                    key={`${index}-item`}
                    className="single-template"
                    height={'250px'}
                  />
                ))
              : myProjects
                  ?.slice(0)
                  .reverse()
                  .map((project) => (
                    <SingleTemplate key={project.id} project={project} />
                  ))}

            {activeMockupCollectionId !== 0 &&
              !geyMyProjects.isLoading &&
              myProjects.length === 0 && (
                <Flex
                  direction={'column'}
                  align={'center'}
                  justify={'center'}
                  gap={'4'}
                  className="empty-page-state"
                >
                  <Flex
                    align={'center'}
                    justify={'center'}
                    className="cercle-wrap"
                  >
                    <TreeSquaresIcon className="svg-icon" />
                  </Flex>
                  <Text size="2" weight="regular" align={'center'}>
                    Organize Templates in Collections by adding them from the
                    All Templates folder.
                  </Text>

                  <Button
                    variant="solid"
                    event-tracker-id="upload-psd-button"
                    onClick={() => dispatch(setActiveMockupCollectionId(0))}
                  >
                    <Text size="1" weight="medium">
                      All Templates
                    </Text>
                  </Button>
                </Flex>
              )}
            {activeMockupCollectionId === 0 &&
              !geyMyProjects.isLoading &&
              myProjects.length === 0 && (
                <Flex
                  direction={'column'}
                  align={'center'}
                  justify={'center'}
                  gap={'4'}
                  className="empty-page-state"
                >
                  <Flex
                    align={'center'}
                    justify={'center'}
                    className="cercle-wrap"
                  >
                    <TreeSquaresIcon className="svg-icon" />
                  </Flex>
                  <Text size="2" weight="regular" align={'center'}>
                    Choose Mockup from Public Library, and bulk create Mockups.
                  </Text>

                  <Button
                    variant="solid"
                    event-tracker-id="upload-psd-button"
                    onClick={() => navigate('/mockup-library')}
                  >
                    <Text size="1" weight="medium">
                      Public Library
                    </Text>
                  </Button>
                </Flex>
              )}
          </Grid>
        </Flex>
      </ScrollArea>
    </Flex>
  );
};
