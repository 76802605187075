import { Flex, Box } from '@radix-ui/themes';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as Loader } from '@/assets/loader.svg';
import { useMediaQuery } from 'react-responsive';

interface IMainImagePreview {
  image: string;
  isFetching: boolean;
  height?: string;
  isVariationPreview: boolean;
}

export const MainImagePreview = ({
  image,
  isFetching,
  height,
  isVariationPreview,
}: IMainImagePreview) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <>
      {image !== null && image !== undefined && (
        <Flex
          className="main-image-preview"
          align={'center'}
          justify={'center'}
        >
          {isFetching && <Loader className="animate-spin" />}
          <LazyLoadImage
            height={'auto'}
            width={'auto'}
            style={{
              objectFit: 'contain',
              filter: isFetching ? 'blur(3px)' : 'blur(0)',
              height: isMobile ? '44vh' : height || '100%',
            }}
            effect="blur"
            src={image}
            delayTime={0}
            placeholderSrc={image}
          />
        </Flex>
      )}
      {(image === null || image === undefined) && (
        <Box className="swiper-slide-transform" width={'100%'} height={'100%'}>
          <Skeleton
            width={'100%'}
            height={'100%'}
            className="swiper-slide-skeleton"
          />
        </Box>
      )}
    </>
  );
};
