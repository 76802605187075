import { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Flex,
  Button,
  Switch,
  AlertDialog,
  IconButton,
} from '@radix-ui/themes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { setActiveVariation } from '@/redux/slices/collection';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import '../editor.scss';
import { useMediaQuery } from 'react-responsive';
import { psdEngineApi } from '@/services/psdEngine';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { toast } from 'react-toastify';
import { AddColor } from './colors/AddColor';
import { ReactComponent as MinusLinearIcon } from '@/assets/icons/minus-linear.svg';

interface IColorCombinations {}

export const ColorCombinations = ({}: IColorCombinations) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const {
    colorCombinations,
    activeColorCombination,
    activeDesignAsset,
    activeSmartObject,
    mockupVariations,
    isCreatingVariations,
    isExportingMockups,
    mockup,
  } = useAppSelector((state) => state.collectionReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const iframeEmbedTools = useFeatureFlagEnabled('iframe-embed-tools');

  const [removeColor, { isLoading: isDeletingColorVariant }] =
    psdEngineApi.useDeleteColorVariationMutation();

  const changeActiveColorCombination = (id: number) => {
    if (isCreatingVariations) {
      return;
    }

    const variation = activeDesignAsset?.id
      ? mockupVariations.find((mv) =>
          mv.variation_elements.some(
            (ve) =>
              ve?.color_id === id &&
              mv.variation_elements.some(
                (innerVe) => innerVe?.asset_id === activeDesignAsset?.id
              )
          )
        )
      : mockupVariations.find((mv) =>
          mv.variation_elements.some((ve) => ve?.color_id === id)
        );

    if (variation) {
      dispatch(setActiveVariation(variation));
    }
  };

  const removeColorVariant = async (id: number) => {
    try {
      await removeColor({ id: id }).unwrap();
      captureEvent('Color variant removed', {
        id: id,
      });
    } catch (error: any) {
      toast.error(error.data.message, {
        toastId: 'remove_color_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  const getNumerOfAssociatedVariations = () => {
    const associatedVariations = mockupVariations.filter((variation) => {
      return variation.variation_elements.some(
        (element) => element.color_id === activeColorCombination?.id
      );
    });

    return associatedVariations.length;
  };

  const [updateSmartObjectInputOptions] =
    psdEngineApi.useUpdateSmartObjectInputOptionsMutation();

  const [embedColorEnabled, setEmbedColorEnabled] = useState<boolean>(
    !!activeSmartObject?.input_options.is_color
  );

  useEffect(() => {
    setEmbedColorEnabled(!!activeSmartObject?.input_options.is_color);
  }, [activeSmartObject]);

  const updateSmartObjectInputOptionsActionColor = async (checked: boolean) => {
    setEmbedColorEnabled(checked);
    await updateSmartObjectInputOptions({
      soId: activeSmartObject.id,
      data: {
        is_artwork: activeSmartObject.input_options.is_artwork,
        is_color: +checked,
        is_text: activeSmartObject.input_options.is_text,
      },
    });
    toast.success(
      `Embed color ${
        checked ? 'enabled' : 'disabled'
      } for current smart object.`,
      {
        toastId: 'successfull_payment',
        position: 'bottom-right',
        autoClose: 5000,
      }
    );
    await dispatch(
      psdEngineApi.endpoints.getMockupSmartObjects.initiate(
        {
          mockup_id: mockup.id,
        },
        { forceRefetch: true }
      )
    ).unwrap();
  };

  return (
    <Flex className="artwork-wrapper" direction={'column'} gap={'27px'}>
      {!isMobile && (
        <Flex align={'center'} justify={'between'} width={'100%'}>
          {iframeEmbedTools && (
            <Text weight={'medium'} size={'1'}>
              Show smart object on iFrame:
            </Text>
          )}
          {iframeEmbedTools && (
            <Switch
              title="Show smart object on iFrame"
              checked={embedColorEnabled}
              onCheckedChange={(checked: boolean) =>
                updateSmartObjectInputOptionsActionColor(checked)
              }
            />
          )}
        </Flex>
      )}

      {!isMobile &&
      activeSmartObject &&
      colorCombinations?.[activeSmartObject?.id]?.length > 0 ? (
        <Flex
          className="text-header"
          align={'center'}
          justify={'between'}
          mb={'1'}
        >
          <Text size={'2'}>Color</Text>

          <AddColor type={'add'} />
        </Flex>
      ) : (
        <Flex
          className="text-header"
          align={'center'}
          justify={'between'}
          mb={'1'}
        >
          <Text size={'2'}>Color</Text>
          <Box></Box>
        </Flex>
      )}
      <Flex className="colors-list-wrapper" direction={'column'} gap={'1'}>
        {activeSmartObject &&
          Object.keys(colorCombinations?.[activeSmartObject?.id] || {})
            .length === 0 && (
            <Flex className="no-colors-button-wrapper">
              <AddColor type={'add'} />
            </Flex>
          )}

        {activeSmartObject &&
          colorCombinations[activeSmartObject?.id]?.map((cc) => (
            <Flex key={cc.id} align={'center'} justify={'between'} gap={'2'}>
              <Flex
                className={`color-wrapper ${
                  cc.id === activeColorCombination?.id ? 'active' : ''
                }`}
                gap={'2'}
                p={'2'}
                align={'center'}
                onClick={() => changeActiveColorCombination(cc.id)}
              >
                <AddColor type={'update'} colorToUpdate={cc}>
                  <Flex
                    className={`color`}
                    style={{ background: cc.hex_code }}
                    align={'center'}
                    justify={'center'}
                  ></Flex>
                </AddColor>

                <Text className="color-name" weight={'regular'}>
                  {cc.hex_code}
                </Text>
              </Flex>

              <AlertDialog.Root>
                <AlertDialog.Trigger>
                  <IconButton
                    className="remove-color-button"
                    variant={'ghost'}
                    size={'1'}
                    color="gray"
                    disabled={isDeletingColorVariant}
                    loading={
                      isDeletingColorVariant ||
                      isCreatingVariations ||
                      isExportingMockups
                    }
                  >
                    <MinusLinearIcon
                      width="26px"
                      height="26px"
                      className="icon"
                    />
                  </IconButton>
                </AlertDialog.Trigger>
                <AlertDialog.Content style={{ maxWidth: 450 }}>
                  <AlertDialog.Title>Remove Color</AlertDialog.Title>
                  <AlertDialog.Description size="2">
                    Are you sure you want to proceed? Removing this color will{' '}
                    {getNumerOfAssociatedVariations() ===
                    mockupVariations.length
                      ? 'update'
                      : 'remove'}{' '}
                    {getNumerOfAssociatedVariations()} associated{' '}
                    {getNumerOfAssociatedVariations() === 1
                      ? 'variation.'
                      : 'variations.'}
                  </AlertDialog.Description>

                  <Flex gap="3" mt="4" justify="end">
                    <AlertDialog.Cancel>
                      <Button variant="soft" color="gray">
                        Cancel
                      </Button>
                    </AlertDialog.Cancel>
                    <AlertDialog.Cancel>
                      <Button
                        variant="solid"
                        color="red"
                        onClick={() => removeColorVariant(cc.id)}
                        disabled={isCreatingVariations}
                      >
                        Remove
                      </Button>
                    </AlertDialog.Cancel>
                  </Flex>
                </AlertDialog.Content>
              </AlertDialog.Root>
            </Flex>
          ))}
      </Flex>
    </Flex>
  );
};
