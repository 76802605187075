import { psdEngineApi } from '@/services/psdEngine';
import { Flex } from '@radix-ui/themes';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useInView } from 'react-intersection-observer';
import { MockupVariationElement } from '@/services/types';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  setActiveDesignAsset,
  setActiveVariation,
} from '@/redux/slices/collection';
import { ReactComponent as Loader } from '@/assets/loader.svg';
import { useMediaQuery } from 'react-responsive';

interface ISingleAssetItem {
  variation: MockupVariationElement;
}

export const SingleAssetItem = ({ variation }: ISingleAssetItem) => {
  const dispatch = useAppDispatch();
  const { activeSmartObject, designAssets } = useAppSelector(
    (state) => state.collectionReducer
  );
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.33,
    delay: 250,
  });

  psdEngineApi.useGetSingleMockupVariationQuery(
    {
      variation_id: variation.id,
      // subscription_type_id: isAppPro ? 2 : 1,
      subscription_type_id: 2,
    },
    {
      skip:
        variation?.isInitiator ||
        !variation?.id ||
        (variation.export_path !== null && !variation.isFetching),
      // ||
      // (!isMobile
      //   ? activeMockupMenuItem === 'artwork' && variation.export_path !== null
      //   : mobileNavigationActiveTab === 'edit' &&
      //     variation.export_path !== null),
      refetchOnMountOrArgChange: true,
    }
  );

  const setActiveVariationAction = () => {
    dispatch(setActiveVariation(variation));

    const activeVariationAssetId = variation.variation_elements.find(
      (ve) => ve.smart_object_id === activeSmartObject?.id
    )?.asset_id;

    dispatch(
      setActiveDesignAsset(
        designAssets[activeSmartObject?.id]?.find(
          (asset) => asset.id === activeVariationAssetId
        )
      )
    );
  };

  return (
    <>
      {variation.export_path !== null && (
        <Flex
          className="single-asset-item-wrapper"
          ref={ref}
          align={'center'}
          justify={'center'}
          onClick={() => setActiveVariationAction()}
        >
          {/* {mockup?.subscription_type_id === 2 && (
            <ProCrownIcon className="pro-icon" width="24px" height="24px" />
          )} */}
          {/* {!isAppPro && (
            <Flex
              className="watermark-button"
              onClick={() => {
                dispatch(setIsAppPaymentPopupOpen(true));
                captureEvent('Upgrade your plan modal viewed', {
                  source: 'Watermark',
                });
              }}
            ></Flex>
          )} */}

          {variation.isFetching && <Loader className="animate-spin" />}
          <img
            height={'100%'}
            width={'auto'}
            style={{
              objectFit: 'contain',
              filter: variation.isFetching ? 'blur(3px)' : 'blur(0)',
              transition: 'filter 0.5s ease 0.1s',
              height: '100%',
              maxHeight: '100% !important',
            }}
            // effect="blur"
            src={variation.export_path}
            // placeholderSrc={variation.export_path}
          />
        </Flex>
      )}

      {variation.export_path === null && (
        <Flex
          className="single-asset-item-wrapper"
          ref={ref}
          align={'center'}
          justify={'center'}
          style={{ lineHeight: 'unset' }}
        >
          <Skeleton
            width={'100%'}
            height={'100%'}
            style={{
              lineHeight: 'unset',
              minHeight: isMobile ? '220px' : '80vh',
              maxHeight: isMobile ? '220px' : undefined,
              minWidth: isMobile ? '90vw' : '50vw',
              borderRadius: '8px',
            }}
          />
        </Flex>
      )}
    </>
  );
};
