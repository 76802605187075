import { PrivatePhotoshopFile, PublicCatalog } from '@/services/types';
import { Flex, Tooltip, Text } from '@radix-ui/themes';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './single-mockup.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  addSelectedPsdId,
  addSelectedTemplateId,
  removeSelectedPsdId,
  removeSelectedTemplateId,
} from '@/redux/slices/mockupBundles';
import { Link } from 'react-router-dom';

interface ISingleMockup {
  publicMockup?: PublicCatalog;
}

export const SingleMockup = ({ publicMockup }: ISingleMockup) => {
  const dispatch = useAppDispatch();

  return (
    <Link
      className="item-link"
      to={{
        pathname: `/mockup/${publicMockup?.id}`,
      }}
      state={{ from: location.pathname }}
    >
      <Flex onClick={() => {}} className={`single-mockup`} align={'end'}>
        <LazyLoadImage
          alt={`${publicMockup?.name}`}
          className=""
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            objectPosition: 'center',
          }}
          effect="blur"
          src={
            publicMockup?.thumbnail ||
            'https://placehold.co/300x300?text=MOCKUP'
          }
          delayTime={0}
          placeholderSrc={
            publicMockup?.thumbnail ||
            'https://placehold.co/300x300?text=MOCKUP'
          }
          wrapperProps={{
            style: { transitionDelay: '0s' },
          }}
        />

        <Flex className="text-wrapper" align={'end'} p={'4'}>
          <Tooltip content={publicMockup?.name}>
            <Text size={'2'} weight={'regular'} style={{ color: '#fff' }}>
              {publicMockup?.name}
            </Text>
          </Tooltip>
        </Flex>
      </Flex>
    </Link>
  );
};
