import { Button, Flex, ScrollArea, Text } from '@radix-ui/themes';
import './choose-mockup.scss';
import { ReactComponent as MockupsIcon } from '@/assets/icons/mockups.svg';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as MenuToggleIcon } from '@/assets/icons/menu-toggle.svg';
import { ReactComponent as WidgetIcon } from '@/assets/icons/widget.svg';
import { ReactComponent as FolderIcon } from '@/assets/icons/folder-linear.svg';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { setActiveMockupCollectionId } from '@/redux/slices/mockupCollections';
import { mockupCollectionsApi } from '@/services/mockupCollections';
import { workspaceApi } from '@/services/workspace';
import { ChoosePublicLibrary } from './ChoosePublicLibrary';
import { psdManagementApi } from '@/services/psdManagement';
import { ChooseMyTemplates } from './ChooseMyTemplates';
import Skeleton from 'react-loading-skeleton';
import { PrivatePhotoshopFile, PsdCategory } from '@/services/types';
import { ReactComponent as ArrowLeft } from '@/assets/icons/arrow-left.svg';

interface IChooseMockup {
  setStep: (step: 1 | 2) => void;
  setStepName: (step: 'choose' | 'mockups') => void;
  getPrivatePsds: {
    isLoading: boolean;
    privatePsdsData: PrivatePhotoshopFile[] | undefined;
  };
}

export const ChooseMockup = ({
  getPrivatePsds,
  setStep,
  setStepName,
}: IChooseMockup) => {
  const dispatch = useAppDispatch();
  const [subMenuMockupsOpen, setSubMenuMockupsOpen] = useState(true);
  const [subMenuLibraryOpen, setSubMenuLibraryOpen] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [categories, setCategories] = useState<PsdCategory[]>([]);
  const leftSideMenu = useRef(null);
  const { photoshopFiles, photoshopFilesCategories } = useAppSelector(
    (state) => state.psdManagementReducer
  );
  const { mockupCollections, activeMockupCollectionId, allTemplatesCount } =
    useAppSelector((state) => state.mockupCollectionsReducer);

  const psdCategories = psdManagementApi.useGetPsdCategoriesQuery(null!, {
    refetchOnMountOrArgChange: true,
  });
  const getMockupCollections =
    mockupCollectionsApi.useGetMockupCollectionsQuery(null!, {
      refetchOnMountOrArgChange: true,
    });

  const geyAllMyProjects = workspaceApi.useGetMyProjectsQuery(
    { collectionId: 0 },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (psdCategories.data?.data) {
      const data = psdCategories.data?.data.filter((cat) =>
        photoshopFilesCategories.includes(cat.id)
      );
      setCategories(data);
    }
  }, [psdCategories, photoshopFilesCategories, photoshopFiles]);

  const [selectedMockupsPage, setSelectedMockupsPage] = useState<
    'public-library' | 'custom-mockups'
  >('public-library');

  useEffect(() => {
    setSelectedMockupsPage('public-library');
    dispatch(setActiveMockupCollectionId(null));
  }, []);

  const [activePublicLibraryCategoryId, setActivePublicLibraryCategoryId] =
    useState<number>(psdCategories.data?.data?.[0]?.id || 1);

  return (
    <Flex className="choose-mockup-wrapper">
      <Flex className="" direction={'column'} gap={'1'}>
        <Flex align={'center'} px={'4'}>
          <Button
            variant="soft"
            color="gray"
            size={'2'}
            onClick={() => {
              setStepName('choose');
              setStep(1);
            }}
          >
            <ArrowLeft width="14px" height="14px" className="icon" />{' '}
            <Text weight={'medium'} size={'2'}>
              Go Back
            </Text>
          </Button>
        </Flex>
        <ScrollArea className="side-menu-wraper scroll-area-page-wrapper">
          <Flex className="side-menu" p={'4'}>
            <Sidebar
              ref={leftSideMenu}
              collapsed={collapsed}
              collapsedWidth={'44px'}
              width={'280px'}
              className="left-side-menu"
              onMouseEnter={() => setCollapsed(false)}
              style={{
                minHeight: `calc(100vh - 24px)`,
              }}
            >
              <Menu className="menu">
                <SubMenu
                  className={`sub-item`}
                  label="Public Library"
                  open={subMenuLibraryOpen}
                  onClick={() => setSubMenuLibraryOpen(!subMenuLibraryOpen)}
                  icon={
                    <>
                      <MockupsIcon
                        className="icon"
                        width={'20px'}
                        height={'20px'}
                      />
                      <MenuToggleIcon
                        className="icon nav-arrow"
                        width="16"
                        height="16"
                      />
                    </>
                  }
                >
                  {psdCategories.isLoading ? (
                    <MenuItem className={`item`}>
                      <Skeleton
                        style={{
                          width: '150px',
                          borderRadius: '8px',
                          height: '36px',
                        }}
                      />
                    </MenuItem>
                  ) : (
                    psdCategories.data?.data
                      ?.filter((item) => !item.is_default && item.is_visible)
                      ?.map((category) => (
                        <MenuItem
                          key={`collection-item-${category.id}`}
                          className={`item  ${
                            activePublicLibraryCategoryId === category.id &&
                            selectedMockupsPage === 'public-library'
                              ? 'active'
                              : ''
                          }`}
                          onClick={() => {
                            setActivePublicLibraryCategoryId(category.id);
                            setSelectedMockupsPage('public-library');
                          }}
                        >
                          <FolderIcon
                            className="icon"
                            width={'15px'}
                            height={'15px'}
                          />{' '}
                          <Text className="label" weight={'medium'}>
                            {category.name}
                          </Text>{' '}
                          {/* ({category.count}) */}
                        </MenuItem>
                      ))
                  )}
                </SubMenu>

                <SubMenu
                  className={`sub-item`}
                  label="Custom Mockups"
                  open={subMenuMockupsOpen}
                  onClick={() => setSubMenuMockupsOpen(!subMenuMockupsOpen)}
                  icon={
                    <>
                      <WidgetIcon
                        className="icon"
                        width={'20px'}
                        height={'20px'}
                      />
                      <MenuToggleIcon
                        className="icon nav-arrow"
                        width="16"
                        height="16"
                      />
                    </>
                  }
                >
                  {psdCategories.isLoading ? (
                    <MenuItem className={`item`}>
                      <Skeleton
                        style={{
                          width: '150px',
                          borderRadius: '8px',
                          height: '36px',
                        }}
                      />
                    </MenuItem>
                  ) : (
                    categories?.map((category) => (
                      <MenuItem
                        key={`collection-item-${category.id}`}
                        className={`item  ${
                          activeMockupCollectionId === category.id &&
                          selectedMockupsPage === 'custom-mockups'
                            ? 'active'
                            : ''
                        }`}
                        onClick={() => {
                          dispatch(setActiveMockupCollectionId(category.id));
                          setSelectedMockupsPage('custom-mockups');
                        }}
                      >
                        <FolderIcon
                          className="icon"
                          width={'15px'}
                          height={'15px'}
                        />{' '}
                        <Flex justify={'between'} width={'100%'}>
                          <Text className="label" weight={'medium'}>
                            {category.name}
                          </Text>
                          <Text className="label" weight={'medium'}>
                            (
                            {
                              photoshopFiles?.filter(
                                (pf) => pf.psd_category_id === category.id
                              )?.length
                            }
                            )
                          </Text>
                        </Flex>
                      </MenuItem>
                    ))
                  )}
                </SubMenu>
              </Menu>
            </Sidebar>
          </Flex>
        </ScrollArea>
      </Flex>
      <Flex className="content-inner">
        {selectedMockupsPage === 'public-library' && (
          <ChoosePublicLibrary
            activePublicLibraryCategoryId={activePublicLibraryCategoryId}
          />
        )}

        {selectedMockupsPage === 'custom-mockups' && (
          <ChooseMyTemplates
            activeMockupCollectionId={activeMockupCollectionId}
            getPrivatePsds={{
              isLoading: getPrivatePsds.isLoading,
              privatePsdsData: getPrivatePsds.privatePsdsData,
            }}
          />
        )}
      </Flex>
    </Flex>
  );
};
