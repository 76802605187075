import { Text, Flex, Select } from '@radix-ui/themes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { EditorV2 } from '@/features/components/EditorV2';
import { useEffect, useState } from 'react';
import { psdEngineApi } from '@/services/psdEngine';
import { updateActiveSmartObject } from '@/redux/slices/collection';
import { assetFit } from '@/services/types';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { PrintAreaPresets } from './PrintAreaPresets';
import { ReactComponent as StructureIcon } from '@/assets/icons/structure-linear.svg';
import '../editor.scss';

export interface IAssetFileConfig {
  url: string;
  width: number;
  height: number;
  transformX: number;
  transformY: number;
  smartObjectWidth: number;
  smartObjectHeight: number;
  design_area_width: number;
  design_area_height: number;
  design_area_left: number;
  design_area_top: number;
  rotate: number;
}

export const DesignAsset = () => {
  const dispatch = useAppDispatch();
  const {
    activeDesignAsset,
    activeSmartObject,
    activeVariation,
    mockupVariations,
    designAssets,
  } = useAppSelector((state) => state.collectionReducer);
  const hasPrintareaPresets = useFeatureFlagEnabled('printarea-presets');

  const [generateSingleMockupVariation] =
    psdEngineApi.useGenerateSingleMockupVariationMutation();

  const [assetFileConfig, setAssetFileConfig] = useState<IAssetFileConfig>({
    width: activeDesignAsset.width || 50,
    height: activeDesignAsset.height || 50,
    transformX: activeDesignAsset.left || 0,
    transformY: activeDesignAsset.top || 0,
    design_area_width: activeSmartObject.global_asset_width || 50,
    design_area_height: activeSmartObject.global_asset_height || 50,
    design_area_left: activeSmartObject.global_asset_left || 0,
    design_area_top: activeSmartObject.global_asset_top || 0,
    smartObjectWidth: activeSmartObject?.width,
    smartObjectHeight: activeSmartObject?.height,
    url: activeDesignAsset?.asset_path,
    rotate: activeDesignAsset?.rotate || 0,
  });

  useEffect(() => {
    setAssetFileConfig({
      width: activeDesignAsset.width || 50,
      height: activeDesignAsset.height || 50,
      transformX: activeDesignAsset.left || 0,
      transformY: activeDesignAsset.top || 0,
      design_area_width: activeSmartObject.global_asset_width || 50,
      design_area_height: activeSmartObject.global_asset_height || 50,
      design_area_left: activeSmartObject.global_asset_left || 0,
      design_area_top: activeSmartObject.global_asset_top || 0,
      smartObjectWidth: activeSmartObject?.width,
      smartObjectHeight: activeSmartObject?.height,
      url: activeDesignAsset?.asset_path,
      rotate: activeDesignAsset?.rotate || 0,
    });
  }, [activeDesignAsset]);

  const apiCallUpdateAsset = async (data: Partial<IAssetFileConfig>) => {
    setAssetFileConfig((prevObj) => {
      return {
        ...prevObj,
        ...data,
      };
    });

    if (activeSmartObject.print_area) {
      dispatch(
        updateActiveSmartObject({
          print_area: activeSmartObject?.print_area,
          fit: activeSmartObject?.fit,
          global_asset_top: data.design_area_top,
          global_asset_left: data.design_area_left,
          global_asset_width: data.design_area_width,
          global_asset_height: data.design_area_height,
        })
      );
    }
    await generateSingleMockupVariation({
      mockup_variation_id: activeVariation.id,
      asset_id: activeDesignAsset?.id,
      smart_object_id: activeSmartObject?.id,
      design_area_height: (data.design_area_height as number) || 1,
      design_area_width: (data.design_area_width as number) || 1,
      design_area_top: (data.design_area_top as number) || 1,
      design_area_left: (data.design_area_left as number) || 1,
      height: data.height as number,
      width: data.width as number,
      top: data.transformY as number,
      left: data.transformX as number,
      rotate: data.rotate as number,
      fit: activeSmartObject?.fit || 'contain',
      // subscription_type_id: isAppPro ? 2 : 1,
      subscription_type_id: 2,
    });
  };

  const setPrintAreaFit = async (fit: assetFit) => {
    dispatch(
      updateActiveSmartObject({
        print_area: activeSmartObject?.print_area,
        fit: fit,
        global_asset_top: activeSmartObject.global_asset_top,
        global_asset_left: activeSmartObject.global_asset_left,
        global_asset_width: activeSmartObject.global_asset_width,
        global_asset_height: activeSmartObject.global_asset_height,
      })
    );
    await generateSingleMockupVariation({
      mockup_variation_id: activeVariation.id,
      asset_id: activeDesignAsset?.id,
      smart_object_id: activeSmartObject?.id,
      print_area: activeSmartObject?.print_area,
      fit: fit,
      design_area_width: assetFileConfig.design_area_width || 1,
      design_area_height: assetFileConfig.design_area_height || 1,
      design_area_left: assetFileConfig.design_area_left || 1,
      design_area_top: assetFileConfig.design_area_top || 1,
      rotate: assetFileConfig.rotate,
      // subscription_type_id: isAppPro ? 2 : 1,
      subscription_type_id: 2,
    });
  };

  return (
    <Flex direction={'column'} align={'start'} gap={'2'} height={'100%'}>
      {hasPrintareaPresets &&
        mockupVariations.length > 0 &&
        designAssets?.[activeSmartObject?.id]?.length > 0 && (
          <Flex align={'center'} justify={'between'} gap={'4'} width={'100%'}>
            <Flex direction={'column'} gap={'1'}>
              <Flex align={'center'} gap={'3'}>
                <Flex align={'center'} gap={'1'}>
                  <StructureIcon className="icon" width="18" height="19" />
                  <Text size={'2'} weight={'bold'}>
                    Print Area
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <Flex width={'45%'}>
              <PrintAreaPresets />
            </Flex>
          </Flex>
        )}

      <EditorV2
        assetFileConfig={assetFileConfig}
        apiCallUpdateAsset={apiCallUpdateAsset}
        setPrintAreaFit={setPrintAreaFit}
      />
      <Flex>
        {!!activeSmartObject?.print_area && (
          <Flex gap={'3'} align={'center'} className="print-area-fit-wrapper">
            <Select.Root
              value={activeSmartObject?.fit}
              onValueChange={(e) => setPrintAreaFit(e as assetFit)}
            >
              <Select.Trigger className="print-area-fit-select" />
              <Select.Content position="popper" style={{ width: '100%' }}>
                <Select.Item value="contain">Contain</Select.Item>
                <Select.Item value="cover">Cover</Select.Item>
                <Select.Item value="stretch">Stretch</Select.Item>
              </Select.Content>
            </Select.Root>
            {activeSmartObject?.fit === 'contain' && (
              <Text size={'1'} weight={'regular'} className="fit-mode-text">
                Artwork fits within Print Area, preserving aspect ratio.
              </Text>
            )}
            {activeSmartObject?.fit === 'cover' && (
              <Text size={'1'} weight={'regular'} className="fit-mode-text">
                Artwork covers Print Area, preserving aspect ratio.
              </Text>
            )}
            {activeSmartObject?.fit === 'stretch' && (
              <Text size={'1'} weight={'regular'} className="fit-mode-text">
                Artwork fully covers Print Area, ignoring aspect ratio.
              </Text>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

