import { Button, Flex, Text } from '@radix-ui/themes';
import './choose-design.scss';
import { PlusIcon } from '@radix-ui/react-icons';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import Uppy from '@uppy/core';
import { useState, useRef } from 'react';
import { ViewWrapper } from '../../../components/designs/components/view-wrapper/ViewWrapper';
import { addSelectedDesignIds } from '@/redux/slices/mockupBundles';

interface IChooseDesign {
  setStep: (step: 1 | 2 | 3) => void;
  setStepName: (step: 'choose' | 'mockups' | 'designs') => void;
}

export const ChooseDesign = ({ setStep, setStepName }: IChooseDesign) => {
  const captureEvent = useCaptureEvent();
  const [uppyDashboardInstance, setUppyDashboardInstance] =
    useState<Uppy<Record<string, unknown>, Record<string, unknown>>>();

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    captureEvent('Upload Design initiated', {
      type: 'button',
    });

    uppyDashboardInstance?.addFiles(event.target.files as any);
  };

  return (
    <Flex
      className="choose-design-wrapper"
      gap={'2'}
      justify={'center'}
      align={'start'}
      direction={'column'}
      p={'4'}
      pb={'0px'}
    >
      <Flex
        className="designs-header"
        align={'center'}
        justify={'between'}
        width={'100%'}
      >
        <Text weight={'bold'} size={'5'}></Text>

        <Button onClick={() => handleClick()}>
          <PlusIcon className="icon white" />
          <Text size="1" weight="medium">
            Add Artwork
          </Text>
        </Button>

        <input
          type="file"
          accept=".png, .jpg, .jpeg, .webp" // , .webp, .gif
          multiple
          onChange={(event) => onFileUpload(event)}
          onClick={(event) => {
            (event.target as any).value = null;
          }}
          ref={hiddenFileInput}
          style={{ display: 'none' }}
        />
      </Flex>

      <Flex
        className="designs-content"
        width={'100%'}
        height={'100%'}
        direction={'column'}
        id="selection-box-container"
      >
        <ViewWrapper
          setUppyDashboardInstance={setUppyDashboardInstance}
          handleUploadClick={() => handleClick()}
          setSelectedDesignIds={addSelectedDesignIds}
        />
      </Flex>
    </Flex>
  );
};
