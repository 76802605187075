import { useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { PlusIcon } from '@radix-ui/react-icons';
import { Button, Text } from '@radix-ui/themes';
import Uppy from '@uppy/core';
import { RefObject } from 'react';

interface IAddNewDesign {
  uppyDashboardInstance:
    | Uppy<Record<string, unknown>, Record<string, unknown>>
    | undefined;
  hiddenFileInput: RefObject<HTMLInputElement>;
  handleUploadClick: () => void;
}

export const AddNewDesign = ({
  hiddenFileInput,
  uppyDashboardInstance,
  handleUploadClick,
}: IAddNewDesign) => {
  const captureEvent = useCaptureEvent();
  const { designs } = useAppSelector((state) => state.mockupBundlesReducer);

  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    captureEvent('Upload Design initiated', {
      type: 'button',
    });

    uppyDashboardInstance?.addFiles(event.target.files as any);
  };

  return (
    <>
      <Button onClick={() => handleUploadClick()}>
        <PlusIcon width={'12px'} height={'12px'} className="icon white" />
        <Text size="1" weight="medium">
          Add Artwork
        </Text>
      </Button>

      <input
        type="file"
        accept=".png, .jpg, .jpeg, .webp" // , .webp, .gif
        multiple
        onChange={(event) => onFileUpload(event)}
        onClick={(event) => {
          (event.target as any).value = null;
        }}
        ref={hiddenFileInput}
        style={{ display: 'none' }}
      />
    </>
  );
};
