import { Flex, ScrollArea, Text } from '@radix-ui/themes';
import { Helmet } from 'react-helmet-async';
import { ReactComponent as SlackIcon } from '@/assets/icons/slack.svg';
import './help.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as BulbIcon } from '@/assets/icons/bulb.svg';
import { Share1Icon } from '@radix-ui/react-icons';

export const Help = () => {
  return (
    <Flex
      className="page-wrapper help-page"
      direction={'column'}
      align={'center'}
      gap={'5'}
    >
      <Helmet>
        <title>Dynamic Mockups | Help</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Flex
        className="header"
        align={'center'}
        justify={'between'}
        maxWidth={'640px'}
        width={'100%'}
      >
        <Text className="page-title">Help</Text>
      </Flex>
      <ScrollArea
        className="scroll-area-page-wrapper"
        style={{ maxWidth: '660px' }}
      >
        <Flex
          className="page-content"
          direction={'column'}
          width={'100%'}
          gap={'2'}
          px={'10px'}
        >
          <Link
            className="main-link"
            to={
              'https://join.slack.com/t/dynamicmockups/shared_invite/zt-2165e7s2a-sEo2vTTs22fL2tUZdNFUXg'
            }
            target="_blank"
          >
            <Flex
              className="full-width-box-item"
              align={'center'}
              justify={'between'}
              gap={'4'}
            >
              <Flex className="content" width={'100%'} gap={'4'}>
                <SlackIcon className="item-icon" />
                <Flex
                  className="text"
                  direction={'column'}
                  justify={'center'}
                  gap={'1'}
                >
                  <Text className="title">Join Slack Community</Text>
                </Flex>
              </Flex>
              <Flex className="actions"></Flex>
            </Flex>
          </Link>

          <Link
            className="main-link"
            to={'https://dynamicmockups.com/affiliate-program/'}
            target="_blank"
          >
            <Flex
              className="full-width-box-item"
              align={'center'}
              justify={'between'}
              gap={'4'}
            >
              <Flex className="content" width={'100%'} gap={'4'}>
                <Share1Icon className="item-icon radix" />
                <Flex
                  className="text"
                  direction={'column'}
                  justify={'center'}
                  gap={'1'}
                >
                  <Text className="title">Become Affiliate</Text>
                </Flex>
              </Flex>
              <Flex className="actions"></Flex>
            </Flex>
          </Link>
          <Link
            className="main-link"
            to={'https://dynamicmockups.featurebase.app/'}
            target="_blank"
          >
            <Flex
              className="full-width-box-item"
              align={'center'}
              justify={'between'}
              gap={'4'}
            >
              <Flex className="content" width={'100%'} gap={'4'}>
                <BulbIcon className="item-icon icon" />
                <Flex
                  className="text"
                  direction={'column'}
                  justify={'center'}
                  gap={'1'}
                >
                  <Text className="title">Request a Feature</Text>
                </Flex>
              </Flex>
              <Flex className="actions"></Flex>
            </Flex>
          </Link>
        </Flex>
      </ScrollArea>
    </Flex>
  );
};
