import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { User } from '@/redux/slices/types';
import { DataModel } from './types';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getCurrentUser: builder.query<DataModel<User>, void>({
      query: () => `user/info`,
      // transformResponse: (response: DataModel<User>) => {
      //   console.log(response);

      //   const updatedResponse: User = {
      //     ...response.data,
      //     subscription_billing_status: {
      //       status: 'active',
      //       cycle_end_date: '2024-03-21 12:58:10',
      //       amount: 6741,
      //       currency: 'USD',
      //       billing_interval: 'Year',
      //       price_id: 'price_1Pso9kFy4AZwJXsp8Fyxa5Ut',
      //     },
      //   };

      //   return {
      //     data: updatedResponse,
      //     message: '',
      //     success: true,
      //   };
      // },
    }),
    updateUserDetails: builder.mutation<DataModel<any>, any>({
      query: (payload) => ({
        url: `user/update-profile`,
        method: 'POST',
        body: payload,
      }),
    }),
    changePassword: builder.mutation<DataModel<any>, any>({
      query: (payload) => ({
        url: `user/change-password`,
        method: 'POST',
        body: payload,
      }),
    }),
    sendVerificationCode: builder.mutation<any, void>({
      query: (payload) => ({
        url: `verification-code/send/change-password`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {} = userApi;
