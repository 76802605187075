import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  setActiveDesignAsset,
  setActiveVariation,
} from '@/redux/slices/collection';
import '../editor.scss';
import {
  Text,
  Flex,
  Button,
  AlertDialog,
  Tooltip,
  Switch,
  IconButton,
  Box,
} from '@radix-ui/themes';
import { useState, useEffect, MutableRefObject } from 'react';
import { psdEngineApi } from '@/services/psdEngine';
import Skeleton from 'react-loading-skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import { AddDesignAssetDialog } from './AddDesignAssetDialog';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { toast } from 'react-toastify';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { ReactComponent as MinusLinearIcon } from '@/assets/icons/minus-linear.svg';
import { ReactComponent as AddLinearIcon } from '@/assets/icons/add-linear.svg';
import { DesignAsset } from './DesignAsset';

interface IDesignAssets {
  abortController: MutableRefObject<AbortController | null>;
  isLoadingGetAssets: boolean;
}

export const DesignAssets = ({
  abortController,
  isLoadingGetAssets,
}: IDesignAssets) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const {
    designAssets,
    activeDesignAsset,
    activeColorCombination,
    activeVariation,
    mockupVariations,
    isCreatingVariations,
    isExportingMockups,
    mockup,
    activeSmartObject,
  } = useAppSelector((state) => state.collectionReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [numberOfDesignAssetsAdding, setNumberOfDesignAssetsAdding] =
    useState<number>(0);
  const iframeEmbedTools = useFeatureFlagEnabled('iframe-embed-tools');

  useEffect(() => {
    const activeVariationAssetId = activeVariation?.variation_elements.find(
      (ve) => ve.smart_object_id === activeSmartObject?.id
    )?.asset_id;

    dispatch(
      setActiveDesignAsset(
        designAssets[activeSmartObject?.id]?.find(
          (asset) => asset.id === activeVariationAssetId
        )
      )
    );
  }, [activeVariation, activeSmartObject, designAssets]);

  const changeActiveDesignAsset = (id: number) => {
    if (isCreatingVariations) {
      return;
    }

    const variation = activeColorCombination?.id
      ? mockupVariations.find((mv) =>
          mv.variation_elements.some(
            (ve) =>
              ve?.asset_id === id &&
              mv.variation_elements.some(
                (innerVe) => innerVe?.color_id === activeColorCombination?.id
              )
          )
        )
      : mockupVariations.find((mv) =>
          mv.variation_elements.some((ve) => ve?.asset_id === id)
        );

    dispatch(setActiveVariation(variation));
  };

  const [removeAsset, { isLoading: isDeletingAsset }] =
    psdEngineApi.useDeleteAssetVariationMutation();

  const [isAdding, setIsAdding] = useState<boolean>(false);

  const getNumerOfAssociatedVariations = (id: number) => {
    const associatedVariations = mockupVariations.filter((variation) => {
      return variation.variation_elements.some(
        (element) => element.asset_id === id
      );
    });

    return associatedVariations.length;
  };

  const canDeleteDesignAsset = (id: number) => {
    let isGeneratingSingleMockupVariation = false;
    mockupVariations.forEach((variation) => {
      variation.variation_elements.find((ve) => {
        if (
          ve.asset_id === id &&
          typeof variation.isGettingSingleMockupVariation !== 'undefined'
        ) {
          isGeneratingSingleMockupVariation =
            variation.isGettingSingleMockupVariation;
        }
      });
    });
    return !isGeneratingSingleMockupVariation && !isDeletingAsset;
  };

  const removeDesignAsset = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => {
    e.stopPropagation();
    try {
      await removeAsset({ id }).unwrap();
      captureEvent('Design asset removed', {
        id,
      });
    } catch (error: any) {
      toast.error(error.data.message, {
        toastId: 'remove_design_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  const [embedAssetEnabled, setEmbedAssetEnabled] = useState<boolean>(
    !!activeSmartObject?.input_options.is_artwork
  );

  useEffect(() => {
    setEmbedAssetEnabled(!!activeSmartObject?.input_options.is_artwork);
  }, [activeSmartObject]);

  const [updateSmartObjectInputOptions] =
    psdEngineApi.useUpdateSmartObjectInputOptionsMutation();

  const updateSmartObjectInputOptionsAction = async (checked: boolean) => {
    setEmbedAssetEnabled(checked);
    await updateSmartObjectInputOptions({
      soId: activeSmartObject.id,
      data: {
        is_artwork: +checked,
        is_color: activeSmartObject.input_options.is_color,
        is_text: activeSmartObject.input_options.is_text,
      },
    });
    toast.success(
      `Embed asset ${
        checked ? 'enabled' : 'disabled'
      } for current smart object.`,
      {
        toastId: 'successfull_payment',
        position: 'bottom-right',
        autoClose: 5000,
      }
    );
    await dispatch(
      psdEngineApi.endpoints.getMockupSmartObjects.initiate(
        {
          mockup_id: mockup.id,
        },
        { forceRefetch: true }
      )
    ).unwrap();
  };

  return (
    <Flex className="artwork-wrapper" direction={'column'} gap={'27px'}>
      {!isMobile && (
        <Flex
          className="info"
          direction={'row'}
          align={'center'}
          justify={'between'}
          gap={'2'}
        >
          {iframeEmbedTools && (
            <Text weight={'medium'} size={'1'}>
              Show smart object on iFrame:
            </Text>
          )}

          {iframeEmbedTools && (
            <Switch
              title="Show smart object on iFrame:"
              checked={embedAssetEnabled}
              onCheckedChange={(checked: boolean) =>
                updateSmartObjectInputOptionsAction(checked)
              }
            />
          )}
        </Flex>
      )}

      {activeDesignAsset && <DesignAsset />}

      {!activeSmartObject?.is_background_layer &&
        !isMobile &&
        (!isLoadingGetAssets && activeSmartObject ? (
          <Flex className="design-assets" direction={'column'} align={'start'}>
            {!isMobile && (
              <Flex
                className="asset-items"
                wrap={'wrap'}
                direction={'column'}
                width={'100%'}
              >
                {!isMobile &&
                designAssets?.[activeSmartObject?.id]?.length > 0 ? (
                  <Flex
                    className="text-header"
                    align={'center'}
                    justify={'between'}
                    mb={'1'}
                  >
                    <Text size={'2'}>Artwork</Text>
                    <AddDesignAssetDialog
                      setNumberOfDesignAssetsAdding={(data) =>
                        setNumberOfDesignAssetsAdding(data)
                      }
                      setIsAdding={(isAdding) => setIsAdding(isAdding)}
                      abortController={abortController}
                    >
                      <IconButton
                        className="add-artwork-button"
                        variant={'ghost'}
                        size={'1'}
                        color={'gray'}
                        event-tracker-id="add-new-asset-action"
                        onClick={() =>
                          captureEvent('Upload Dessign Mockup modal viewed')
                        }
                      >
                        <AddLinearIcon
                          width="28px"
                          height="28px"
                          className={`gray add icon`}
                        />
                      </IconButton>
                    </AddDesignAssetDialog>
                  </Flex>
                ) : (
                  <Flex
                    className="text-header"
                    align={'center'}
                    justify={'between'}
                    mb={'1'}
                  >
                    <Text size={'2'}>Artwork</Text>
                    <Box></Box>
                  </Flex>
                )}

                {!isAdding &&
                  designAssets?.[activeSmartObject?.id]?.length === 0 &&
                  [...Array(1)].map((_, index) => (
                    <AddDesignAssetDialog
                      key={index}
                      setNumberOfDesignAssetsAdding={(data) =>
                        setNumberOfDesignAssetsAdding(data)
                      }
                      setIsAdding={(isAdding) => setIsAdding(isAdding)}
                      abortController={abortController}
                    >
                      <Button
                        className="new"
                        variant="surface"
                        color="gray"
                        event-tracker-id="add-new-asset-action"
                        onClick={() =>
                          captureEvent('Upload Dessign Mockup modal viewed')
                        }
                      >
                        <PlusIcon
                          width={'24px'}
                          height={'24px'}
                          className="icon"
                        />
                      </Button>
                    </AddDesignAssetDialog>
                  ))}

                {designAssets[activeSmartObject.id]
                  ?.filter((da) => !da.is_ai_generated)
                  ?.map((da) => (
                    <Flex key={da.id} gap={'2'} align={'center'}>
                      <Flex
                        className={`asset-item ${
                          da.id === activeDesignAsset?.id && 'active'
                        }`}
                        align={'center'}
                        justify={'between'}
                        id="asset-item"
                        p={'2'}
                        gap={'2'}
                        onClick={() => changeActiveDesignAsset(da.id)}
                      >
                        <Flex gap={'2'} align={'center'}>
                          <LazyLoadImage
                            className="asset"
                            style={{
                              objectFit: 'contain',
                            }}
                            effect="blur"
                            src={da.asset_path}
                            delayTime={0}
                            placeholderSrc={da.asset_path}
                            wrapperProps={{
                              style: { transitionDelay: '0s' },
                            }}
                          />
                          <Tooltip content={da.name}>
                            <Text
                              size={'1'}
                              weight={'regular'}
                              className="asset-name"
                            >
                              {da.name}
                            </Text>
                          </Tooltip>
                        </Flex>
                      </Flex>
                      <Flex gap={'4'} align={'center'} className="actions">
                        <AlertDialog.Root>
                          <AlertDialog.Trigger>
                            <IconButton
                              className="remove-asset-button"
                              variant={'ghost'}
                              size={'1'}
                              color="gray"
                              onClick={(e) => e.stopPropagation()}
                              loading={
                                !canDeleteDesignAsset(da.id) ||
                                isCreatingVariations ||
                                isExportingMockups
                              }
                            >
                              <MinusLinearIcon
                                width="26px"
                                height="26px"
                                className="icon"
                              />
                            </IconButton>
                          </AlertDialog.Trigger>
                          <AlertDialog.Content style={{ maxWidth: 450 }}>
                            <AlertDialog.Title>
                              Remove artwork asset
                            </AlertDialog.Title>
                            <AlertDialog.Description size="2">
                              Are you sure you want to proceed? Removing this
                              artwork asset will{' '}
                              {getNumerOfAssociatedVariations(da.id) ===
                              mockupVariations.length
                                ? 'update'
                                : 'remove'}{' '}
                              {getNumerOfAssociatedVariations(da.id)} associated{' '}
                              {getNumerOfAssociatedVariations(da.id) === 1
                                ? 'variation.'
                                : 'variations.'}
                            </AlertDialog.Description>

                            <Flex gap="3" mt="4" justify="end">
                              <AlertDialog.Cancel>
                                <Button variant="soft" color="gray">
                                  Cancel
                                </Button>
                              </AlertDialog.Cancel>
                              <AlertDialog.Cancel>
                                <Button
                                  variant="solid"
                                  color="red"
                                  onClick={(e) => removeDesignAsset(e, da.id)}
                                >
                                  Remove
                                </Button>
                              </AlertDialog.Cancel>
                            </Flex>
                          </AlertDialog.Content>
                        </AlertDialog.Root>
                      </Flex>
                    </Flex>
                  ))}
                {isAdding &&
                  [...Array(numberOfDesignAssetsAdding)].map((_, index) => (
                    <Skeleton
                      key={index}
                      height={'48px'}
                      width={'100%'}
                      style={{
                        borderRadius: '8px',
                      }}
                    />
                  ))}
              </Flex>
            )}
          </Flex>
        ) : (
          <Flex direction={'column'} align={'start'} gap={'4'} px={'4'}>
            <Box>
              <Skeleton
                height={'44px'}
                width={'100%'}
                style={{
                  minWidth: '325px',
                }}
              />
            </Box>
            <Flex style={{ gap: '7px' }}>
              {[...Array(4)].map((_, index) => (
                <Skeleton
                  key={index}
                  height={'76px'}
                  width={'76px'}
                  style={{
                    borderRadius: '8px',
                  }}
                />
              ))}
            </Flex>
          </Flex>
        ))}
    </Flex>
  );
};
