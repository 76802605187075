import { useAppDispatch } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import { colorsApi } from '@/services/colors';
import { PlusIcon } from '@radix-ui/react-icons';
import { Dialog, Button, Flex, TextField, Text } from '@radix-ui/themes';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useState } from 'react';
import { toast } from 'react-toastify';

export const AddNewColorSet = () => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();

  const [colorSetName, setColorSetName] = useState<string>('');
  const hasColorPresets = useFeatureFlagEnabled('color-presets');

  const [createColorSet, { isLoading: isCreating }] =
    colorsApi.useCreateColorSetMutation();

  const createColorSetAction = async () => {
    try {
      await createColorSet({
        name: colorSetName,
      }).unwrap();
      captureEvent('User Created Color Set', {
        name: colorSetName,
      });
      toast.success('Color Set successfully created.', {
        toastId: 'color_set_action_success',
        position: 'bottom-right',
        autoClose: 5000,
      });
      await dispatch(colorsApi.endpoints.getColorSets.initiate()).unwrap();
    } catch (error) {
      console.log('error: ', error);
      toast.warning((error as unknown as any).data.message, {
        toastId: 'color_set_action_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <Button
          onClick={(e) => {
            if (!hasColorPresets) {
              e.preventDefault();
              captureEvent('Upgrade your plan modal viewed', {
                source: 'New Color Set',
              });
              dispatch(setIsAppPaymentPopupOpen(true));
            }
          }}
        >
          <PlusIcon width={'12px'} height={'12px'} className="icon white" />
          <Text size="1" weight="medium">
            New Color Set
          </Text>
        </Button>
      </Dialog.Trigger>

      <Dialog.Content maxWidth="450px" aria-describedby={undefined}>
        <Dialog.Title>Create new Color Set</Dialog.Title>

        <Flex direction="column" gap="3">
          <label>
            <TextField.Root
              onChange={(e) => setColorSetName(e.target.value)}
              value={colorSetName}
              placeholder="Color Set Name"
            />
          </label>
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button
              variant="solid"
              color="blue"
              onClick={() => createColorSetAction()}
            >
              Create
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};
