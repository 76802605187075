import { Dialog, Button, Flex, Text, ScrollArea } from '@radix-ui/themes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import {
  setIsCreatingVariations,
  setActiveVariation,
} from '@/redux/slices/collection';
import { psdEngineApi } from '@/services/psdEngine';
import { DesignsUploader } from '@/features/screens/workspace/components/designs/components/DesignsUploader';
import Uppy from '@uppy/core';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { SelectedDesignsOptionsBar } from '@/features/screens/workspace/components/designs/components/view-wrapper/SelectedDesignsOptionsBar';
import { DeleteDesign } from '@/features/screens/workspace/components/designs/components/view-wrapper/DeleteDesign';

interface IAddDesignAssetDialog {
  setNumberOfDesignAssetsAdding: (data: number) => void;
  setIsAdding: (data: boolean) => void;
  abortController: MutableRefObject<AbortController | null>;
  children: JSX.Element;
}

export const AddDesignAssetDialog = ({
  setNumberOfDesignAssetsAdding,
  setIsAdding,
  abortController,
  children,
}: IAddDesignAssetDialog) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const { activeSmartObject, mockup } = useAppSelector(
    (state) => state.collectionReducer
  );
  const [addAsset, { isLoading: isAdding }] =
    psdEngineApi.useAddDesignAssetMutation();
  const [createMockupVariations] =
    psdEngineApi.useCreateMockupVariationsMutation();

  const { designs } = useAppSelector((state) => state.designsReducer);

  useEffect(() => {
    setIsAdding(isAdding);
  }, [isAdding]);

  const addDesignsToMockup = async () => {
    const designFiles = designs.filter((design) =>
      selectedDesigns.includes(design.id)
    );

    if (!selectedDesigns.length) return;

    dispatch(setIsCreatingVariations(true));
    const createdMockupVariations = [];

    // Create an AbortController to handle cancellation
    abortController.current = new AbortController();

    try {
      for (let i = 0; i < designFiles.length; ++i) {
        const assetInfo = designFiles[i];

        const formData = new FormData();
        formData.append('smart_object_id', activeSmartObject?.id.toString());
        formData.append(`assets[${0}][design_id]`, assetInfo.id.toString());
        formData.append(
          `assets[${0}][top]`,
          Math.floor(
            (activeSmartObject?.height - assetInfo.size.height) / 2
          ).toString()
        );
        formData.append(
          `assets[${0}][left]`,
          Math.floor(
            (activeSmartObject?.width - assetInfo.size.width) / 2
          ).toString()
        );
        formData.append(`assets[${0}][width]`, assetInfo.size.width.toString());
        formData.append(
          `assets[${0}][height]`,
          assetInfo.size.height.toString()
        );
        setNumberOfDesignAssetsAdding(1);

        const addedAsset = await addAsset({
          payload: formData,
          signal: abortController.current.signal,
        }).unwrap();

        const response = await createMockupVariations({
          payload: {
            mockup_id: mockup?.id,
            smart_object_id: activeSmartObject.id,
            asset_id: addedAsset.data[0].id,
          },
          signal: abortController.current.signal,
        }).unwrap();
        setSelectedDesigns([]);
        createdMockupVariations.push(response.data);
        setNumberOfDesignAssetsAdding(0);
      }

      dispatch(setIsCreatingVariations(false));

      const firstOfCreatedVariations = createdMockupVariations[0].filter(
        (variation) => variation.isFetching
      )[0];

      await new Promise((resolve) => setTimeout(resolve, 250));

      dispatch(
        setActiveVariation(
          firstOfCreatedVariations || createdMockupVariations[0][0]
        )
      );
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.warn('Request was aborted');
      } else {
        console.warn('Error processing designs:', error);
      }
      dispatch(setIsCreatingVariations(false));
    }
  };

  const [selectedDesigns, setSelectedDesigns] = useState<number[]>([]);

  const selectDesign = (id: number, isSelected: boolean) => {
    if (isSelected) {
      setSelectedDesigns((old) => [...old, id]);
    } else {
      setSelectedDesigns((old) => old.filter((item) => item !== id));
    }
  };

  const [uppyDashboardInstance, setUppyDashboardInstance] =
    useState<Uppy<Record<string, unknown>, Record<string, unknown>>>();

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    captureEvent('Upload Design initiated', {
      type: 'button',
    });
    uppyDashboardInstance?.addFiles(event.target.files as any);
  };
  const deleteDesignsDialog = useRef();

  return (
    <Dialog.Root>
      <Dialog.Trigger>{children}</Dialog.Trigger>

      <Dialog.Content
        aria-describedby={undefined}
        className="choose-design-dialog"
        onPointerDownOutside={() => captureEvent('Upload Dessign modal closed')}
        id="selection-box-container"
      >
        <Flex
          direction={'row'}
          align={'center'}
          justify={'between'}
          className="header"
        >
          <Dialog.Title as="h1">Choose Artwork</Dialog.Title>
          {designs?.length > 0 && (
            <Button onClick={() => handleClick()}>
              <PlusIcon className="icon white" />
              <Text size="1" weight="medium">
                Add Artwork
              </Text>
            </Button>
          )}
          <input
            type="file"
            accept=".png, .jpg, .jpeg, .webp" // , .webp, .gif
            multiple
            onChange={(event) => onFileUpload(event)}
            onClick={(event) => {
              (event.target as any).value = null;
            }}
            ref={hiddenFileInput}
            style={{ display: 'none' }}
          />
        </Flex>
        <Flex
          className="view-wrapper-design-templates body"
          direction={'column'}
          position={'relative'}
        >
          <DeleteDesign
            ref={deleteDesignsDialog}
            setSelectedDesigns={setSelectedDesigns}
          />
          <SelectedDesignsOptionsBar
            selectedDesigns={selectedDesigns}
            setSelectedDesigns={setSelectedDesigns}
            deleteDesignsDialog={deleteDesignsDialog}
          />
          <ScrollArea
            className="scroll-area-wrapper"
            type="hover"
            scrollbars="vertical"
            style={{ height: 'calc(67vh)', position: 'relative' }}
            id="scroll-container"
          >
            <DesignsUploader
              selectDesign={selectDesign}
              setUppyDashboardInstance={setUppyDashboardInstance}
              handleUploadClick={() => handleClick()}
              setSelectedDesigns={setSelectedDesigns}
              selectedDesigns={selectedDesigns}
              isDesignsLibrary={false}
              deleteDesignsDialog={deleteDesignsDialog}
            />
          </ScrollArea>
        </Flex>

        <Flex gap="3" justify="end" py={'4'} px={'5'} className="footer">
          <Dialog.Close>
            <Button
              variant="soft"
              color="gray"
              onClick={() => captureEvent('Upload Dessign modal closed')}
            >
              Cancel
            </Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button
              onClick={() => addDesignsToMockup()}
              disabled={!selectedDesigns.length}
            >
              Add to Mockup{' '}
              {selectedDesigns.length > 0 ? `(${selectedDesigns.length})` : ''}
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};
