import {
  Flex,
  IconButton,
  ScrollArea,
  Separator,
  Skeleton,
  Text,
  Box,
} from '@radix-ui/themes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import * as Accordion from '@radix-ui/react-accordion';
import { ReactComponent as AltArrowDownLinear } from '@/assets/icons/alt-arrow-down-linear.svg';
import { setActiveView } from '@/redux/slices/editor';
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { SmartObject } from '@/services/types';
import {
  setActiveColorCombination,
  setActiveSmartObject,
} from '@/redux/slices/collection';
import './accordion.scss';
import { DesignAssets } from '../editor/components/DesignAssets';
import { psdEngineApi } from '@/services/psdEngine';
import { AiBackgrounds } from '../editor/components/ai-backgrounds/AiBackgrounds';
import { AiBackground } from '../editor/components/AiBackground';
import { AdjustmentLayerControls } from '@/features/components/AdjustmentLayerControls';
import { ColorCombinations } from '../editor/components/ColorCombinations';

interface IToolbar {
  smartObjectsData: SmartObject[];
  isLoadingSmartObjects: boolean;
}

export const Toolbar = ({
  smartObjectsData,
  isLoadingSmartObjects,
}: IToolbar) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { collectionId } = useParams();
  const {
    activeSmartObject,
    smartObjects,
    activeMockupMenuItem,
    designAssets,
    colorCombinations,
    activeVariation,
  } = useAppSelector((state) => state.collectionReducer);
  const { activeView } = useAppSelector((state) => state.editorReducer);

  const { currentMockupBundle } = useAppSelector(
    (state) => state.mockupBundlesReducer
  );
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {}, [activeMockupMenuItem]);

  const getAssets = psdEngineApi.useGetMockupSmartObjectAssetsQuery(
    {
      smart_object_id: activeSmartObject?.id,
    },
    {
      skip:
        !activeSmartObject || designAssets?.[activeSmartObject?.id]?.length > 0,
      refetchOnMountOrArgChange: true,
    }
  );

  const getColors = psdEngineApi.useGetMockupSmartObjectColorCombinationsQuery(
    {
      smart_object_id: activeSmartObject?.id,
    },
    {
      skip:
        !activeSmartObject ||
        colorCombinations?.[activeSmartObject?.id]?.length > 0,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (currentMockupBundle) {
      if (activeSmartObject) {
        navigate(
          `/batch/${currentMockupBundle.mockup_bundle.uuid}/mockup/${collectionId}/smart-object/${activeSmartObject.uuid}`
        );
        setTabValue(activeSmartObject.id.toString());
      }
    } else {
      if (activeSmartObject) {
        navigate(
          `/mockup/${collectionId}/smart-object/${activeSmartObject.uuid}`
        );
        setTabValue(activeSmartObject.id.toString());
      }
    }
  }, [activeSmartObject, collectionId]);

  useEffect(() => {
    if (currentMockupBundle) {
      if (activeSmartObject && !location.pathname.includes('smart-object')) {
        navigate(
          `/batch/${currentMockupBundle.mockup_bundle.uuid}/mockup/${collectionId}/smart-object/${activeSmartObject.uuid}`
        );
        setTabValue(activeSmartObject.id.toString());
      }
    } else {
      if (activeSmartObject && !location.pathname.includes('smart-object')) {
        navigate(
          `/mockup/${collectionId}/smart-object/${activeSmartObject.uuid}`
        );
        setTabValue(activeSmartObject.id.toString());
      }
    }
  }, [location]);

  useEffect(() => {
    const activeVariationColorId = activeVariation?.variation_elements.find(
      (ve) => ve.smart_object_id === activeSmartObject?.id
    )?.color_id;

    dispatch(
      setActiveColorCombination(
        colorCombinations[activeSmartObject?.id]?.find(
          (color) => color.id === activeVariationColorId
        )
      )
    );
  }, [activeVariation, activeSmartObject, colorCombinations]);

  // psdEngineApi.useGetMockupSmartObjectAssetsQuery(
  //   {
  //     smart_object_id: activeSmartObject?.id,
  //   },
  //   {
  //     skip: !activeSmartObject?.id,
  //     refetchOnMountOrArgChange: true,
  //   }
  // );

  const [tabValue, setTabValue] = useState<string>(
    activeSmartObject?.id.toString() || '0'
  );

  const changeActiveSmartObject = (id: number) => {
    dispatch(setActiveSmartObject(smartObjects?.find((so) => so.id === id)));
    dispatch(setActiveView('preview'));
  };

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    return () => {
      abortControllerRef.current?.abort();
    };
  }, []);

  return (
    <Flex direction={'column'} className="editor-toolbar">
      <ScrollArea
        className="scroll-area-wrapper editor-toolbar-scroll"
        type="hover"
        scrollbars="vertical"
      >
        <Flex className="content" direction={'column'} gap={'5'}>
          {activeMockupMenuItem === 'artwork' && (
            <Accordion.Root
              key={tabValue}
              className="accordion-root"
              type="single"
              defaultValue={tabValue}
              onValueChange={(val) => {
                changeActiveSmartObject(parseInt(val));
              }}
            >
              {!isLoadingSmartObjects && (smartObjectsData || []).length > 0
                ? (smartObjectsData || []).length > 0 &&
                  smartObjectsData.map((smartObject) =>
                    !smartObject?.is_background_layer ? (
                      <Accordion.Item
                        key={`artwork-${smartObject.smart_object_uuid}`}
                        className="accordion-item"
                        value={smartObject.id.toString()}
                      >
                        <Accordion.AccordionTrigger className="accordion-trigger">
                          <Text className="trigger-text" size={'2'}>
                            {smartObject.smart_object_name}
                          </Text>
                          <IconButton
                            asChild
                            color="gray"
                            size={'1'}
                            variant="ghost"
                          >
                            <AltArrowDownLinear
                              className="icon accordion-chevron"
                              width={'24px'}
                              height={'24px'}
                            />
                          </IconButton>
                        </Accordion.AccordionTrigger>
                        <Accordion.AccordionContent className="accordion-content">
                          <Flex pb={'4'} direction={'column'} width={'100%'}>
                            <DesignAssets
                              abortController={abortControllerRef}
                              isLoadingGetAssets={getAssets.isLoading}
                            />
                          </Flex>
                        </Accordion.AccordionContent>
                        <Separator />
                      </Accordion.Item>
                    ) : (
                      ''
                    )
                  )
                : [...Array(3)].map((_, index) => (
                    <Skeleton
                      key={index}
                      width={'100%'}
                      height={'40px'}
                      mb={'2'}
                    ></Skeleton>
                  ))}
            </Accordion.Root>
          )}

          {activeMockupMenuItem === 'color' && (
            <Accordion.Root
              key={tabValue}
              className="accordion-root"
              type="single"
              defaultValue={tabValue}
              onValueChange={(val) => {
                changeActiveSmartObject(parseInt(val));
              }}
            >
              {!isLoadingSmartObjects && (smartObjectsData || []).length > 0
                ? (smartObjectsData || []).length > 0 &&
                  smartObjectsData.map((smartObject) =>
                    !smartObject?.is_background_layer ? (
                      <Accordion.Item
                        key={`color-${smartObject.smart_object_uuid}`}
                        className="accordion-item"
                        value={smartObject.id.toString()}
                      >
                        <Accordion.AccordionTrigger className="accordion-trigger">
                          <Text className="trigger-text" size={'2'}>
                            {smartObject.smart_object_name}
                          </Text>
                          <IconButton
                            asChild
                            color="gray"
                            size={'1'}
                            variant="ghost"
                          >
                            <AltArrowDownLinear
                              className="icon accordion-chevron"
                              width={'24px'}
                              height={'24px'}
                            />
                          </IconButton>
                        </Accordion.AccordionTrigger>
                        <Accordion.AccordionContent className="accordion-content">
                          <Flex pb={'4'} direction={'column'} width={'100%'}>
                            <ColorCombinations />
                          </Flex>
                        </Accordion.AccordionContent>
                        <Separator />
                      </Accordion.Item>
                    ) : (
                      ''
                    )
                  )
                : [...Array(3)].map((_, index) => (
                    <Skeleton
                      key={index}
                      width={'100%'}
                      height={'40px'}
                      mb={'2'}
                    ></Skeleton>
                  ))}
            </Accordion.Root>
          )}

          {activeMockupMenuItem === 'adjustments' && (
            <Accordion.Root
              key={tabValue}
              className="accordion-root"
              type="single"
              defaultValue={tabValue}
              onValueChange={(val) => {
                changeActiveSmartObject(parseInt(val));
              }}
            >
              {!isLoadingSmartObjects && (smartObjectsData || []).length > 0
                ? (smartObjectsData || []).length > 0 &&
                  smartObjectsData.map((smartObject) =>
                    !smartObject?.is_background_layer ? (
                      <Accordion.Item
                        key={`ai-scene-${smartObject.smart_object_uuid}`}
                        className="accordion-item"
                        value={smartObject.id.toString()}
                      >
                        <Accordion.AccordionTrigger className="accordion-trigger">
                          <Text className="trigger-text" size={'2'}>
                            {smartObject.smart_object_name}
                          </Text>
                          <IconButton
                            asChild
                            color="gray"
                            size={'1'}
                            variant="ghost"
                          >
                            <AltArrowDownLinear
                              className="icon accordion-chevron"
                              width={'24px'}
                              height={'24px'}
                            />
                          </IconButton>
                        </Accordion.AccordionTrigger>
                        <Accordion.AccordionContent className="accordion-content">
                          <Flex pb={'4'} direction={'column'} width={'100%'}>
                            <Flex
                              className="artwork-wrapper"
                              direction={'column'}
                              gap={'27px'}
                            >
                              {!activeSmartObject?.is_background_layer &&
                                !isMobile &&
                                (!getAssets.isLoading && activeSmartObject ? (
                                  <AdjustmentLayerControls />
                                ) : (
                                  <Flex
                                    direction={'column'}
                                    align={'start'}
                                    gap={'4'}
                                    px={'4'}
                                  >
                                    <Box>
                                      <Skeleton
                                        height={'44px'}
                                        width={'100%'}
                                        style={{
                                          minWidth: '325px',
                                        }}
                                      />
                                    </Box>
                                    <Flex style={{ gap: '7px' }}>
                                      {[...Array(4)].map((_, index) => (
                                        <Skeleton
                                          key={index}
                                          height={'76px'}
                                          width={'76px'}
                                          style={{
                                            borderRadius: '8px',
                                          }}
                                        />
                                      ))}
                                    </Flex>
                                  </Flex>
                                ))}
                            </Flex>
                          </Flex>
                        </Accordion.AccordionContent>
                        <Separator />
                      </Accordion.Item>
                    ) : (
                      ''
                    )
                  )
                : [...Array(3)].map((_, index) => (
                    <Skeleton
                      key={index}
                      width={'100%'}
                      height={'40px'}
                      mb={'2'}
                    ></Skeleton>
                  ))}
            </Accordion.Root>
          )}

          {activeMockupMenuItem === 'ai-scene' && activeView === 'preview' && (
            <Accordion.Root
              key={tabValue}
              className="accordion-root"
              type="single"
              defaultValue={tabValue}
              onValueChange={(val) => {
                changeActiveSmartObject(parseInt(val));
              }}
            >
              {!isLoadingSmartObjects && (smartObjectsData || []).length > 0
                ? (smartObjectsData || []).length > 0 &&
                  smartObjectsData.map((smartObject) =>
                    smartObject?.is_background_layer ? (
                      <Accordion.Item
                        key={`ai-scene-${smartObject.smart_object_uuid}`}
                        className="accordion-item"
                        value={smartObject.id.toString()}
                      >
                        <Accordion.AccordionTrigger className="accordion-trigger">
                          <Text className="trigger-text" size={'2'}>
                            ✨ AI Scene
                          </Text>
                          <IconButton
                            asChild
                            color="gray"
                            size={'1'}
                            variant="ghost"
                          >
                            <AltArrowDownLinear
                              className="icon accordion-chevron"
                              width={'24px'}
                              height={'24px'}
                            />
                          </IconButton>
                        </Accordion.AccordionTrigger>
                        <Accordion.AccordionContent className="accordion-content">
                          <Flex pb={'4'} direction={'column'} width={'100%'}>
                            <Flex
                              className="artwork-wrapper"
                              direction={'column'}
                              gap={'27px'}
                            >
                              {activeSmartObject?.is_background_layer &&
                                !isMobile &&
                                (!getAssets.isLoading && activeSmartObject ? (
                                  <AiBackgrounds />
                                ) : (
                                  <Flex
                                    direction={'column'}
                                    align={'start'}
                                    gap={'4'}
                                    px={'4'}
                                  >
                                    <Box>
                                      <Skeleton
                                        height={'44px'}
                                        width={'100%'}
                                        style={{
                                          minWidth: '325px',
                                        }}
                                      />
                                    </Box>
                                    <Flex style={{ gap: '7px' }}>
                                      {[...Array(4)].map((_, index) => (
                                        <Skeleton
                                          key={index}
                                          height={'76px'}
                                          width={'76px'}
                                          style={{
                                            borderRadius: '8px',
                                          }}
                                        />
                                      ))}
                                    </Flex>
                                  </Flex>
                                ))}
                            </Flex>
                          </Flex>
                        </Accordion.AccordionContent>
                        <Separator />
                      </Accordion.Item>
                    ) : (
                      ''
                    )
                  )
                : [...Array(3)].map((_, index) => (
                    <Skeleton
                      key={index}
                      width={'100%'}
                      height={'40px'}
                      mb={'2'}
                    ></Skeleton>
                  ))}
            </Accordion.Root>
          )}

          {activeMockupMenuItem === 'ai-scene' &&
            activeView === 'create-ai-background' && <AiBackground />}
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

