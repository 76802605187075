import { Box, Flex, Text } from '@radix-ui/themes';
import { useEffect } from 'react';
import { SingleAssetItem } from './SingleAssetItem';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { psdEngineApi } from '@/services/psdEngine';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {
  setActiveColorCombination,
  setActiveVariation,
  setBundleMockupState,
  setIsMockupLoading,
} from '@/redux/slices/collection';
import 'swiper/css';
import 'swiper/css/navigation';
import { MockupVariationElement } from '@/services/types';
import { workspaceApi } from '@/services/workspace';
import { useMediaQuery } from 'react-responsive';

interface IMainPreview {}

export const MainPreview = ({}: IMainPreview) => {
  const { collectionId } = useParams();
  const dispatch = useAppDispatch();
  const {
    activeSmartObject,
    mockupVariations,
    mockup,
    isCreatingVariations,
    activeVariation,
    isMockupLoading,
    bundleMockupState,
  } = useAppSelector((state) => state.collectionReducer);
  const { currentMockupBundle } = useAppSelector(
    (state) => state.mockupBundlesReducer
  );
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    dispatch(setActiveColorCombination(null));
  }, [collectionId]);

  useEffect(() => {
    if (currentMockupBundle && mockup && activeVariation) {
      dispatch(setBundleMockupState({ [mockup.uuid]: activeVariation }));
    }
  }, [activeVariation]);

  const geyMyProjects = workspaceApi.useGetMyProjectsQuery(null!, {
    refetchOnMountOrArgChange: true,
  });

  const getCurrentMockupActiveVariation = () => {
    return Object.values(bundleMockupState)
      .flatMap((mockup) => Object.entries(mockup))
      .find(([key]) => key === mockup.uuid)?.[1];
  };

  const isParsableNumber = (value: any) => {
    // Check if the value is a string
    if (typeof value !== 'string') {
      return false;
    }

    // Convert the string to a number
    const number = Number(value);

    // Check if the result is a finite number
    return Number.isFinite(number);
  };

  const getMockupVariations = psdEngineApi.useGetMockupVariationsQuery(
    {
      mockup_id: mockup?.id,
    },
    {
      skip: mockupVariations.length > 0 || !mockup?.id,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (!isParsableNumber(collectionId)) {
      if (mockupVariations.length) {
        if (activeVariation) {
          dispatch(setIsMockupLoading(false));
        }
      } else {
        dispatch(setIsMockupLoading(false));
      }
    }
  }, [collectionId, activeVariation]);

  useEffect(() => {
    if (!isParsableNumber(collectionId) && activeVariation) {
      dispatch(setIsMockupLoading(false));
    }
  }, [collectionId, activeVariation]);

  const setActiveVariationAction = (variation: MockupVariationElement) => {
    dispatch(setActiveVariation(variation));
  };

  useEffect(() => {
    if (!activeVariation) {
      if (!currentMockupBundle) {
        setActiveVariationAction(mockupVariations[0]);
      }
    }
  }, [mockupVariations, activeVariation]);

  return (
    <>
      {/* <Flex></Flex> */}

      <Flex className="main-slide-center" align={'center'} justify={'center'}>
        {!getMockupVariations.isLoading &&
        !isMockupLoading &&
        activeSmartObject ? (
          mockupVariations.length > 0 ? (
            !isCreatingVariations ? (
              mockupVariations.length > 0 && activeVariation ? (
                <SingleAssetItem
                  variation={
                    getCurrentMockupActiveVariation() || activeVariation
                  }
                />
              ) : (
                <Flex
                  className="single-asset-item-wrapper"
                  justify={'center'}
                  align={'center'}
                >
                  <LazyLoadImage
                    height={'100%'}
                    width={'100%'}
                    style={{
                      objectFit: 'contain',
                    }}
                    effect="blur"
                    src={mockup?.thumbnail}
                    delayTime={0}
                    placeholderSrc={mockup?.thumbnail}
                  />
                </Flex>
              )
            ) : (
              <Flex
                className="single-asset-item-wrapper"
                align={'center'}
                justify={'center'}
                style={{ lineHeight: 'unset' }}
              >
                <Skeleton
                  width={'100%'}
                  height={'100%'}
                  style={{
                    lineHeight: 'unset',
                    minHeight: isMobile ? '220px' : '60vh',
                    maxHeight: isMobile ? '220px' : undefined,
                    minWidth: isMobile ? '90vw' : '100px',
                  }}
                />
              </Flex>
            )
          ) : (
            <Flex
              className="single-asset-item-wrapper"
              align={'center'}
              justify={'center'}
            >
              <img
                height={'100%'}
                width={'auto'}
                style={{
                  objectFit: 'contain',
                  filter: activeVariation?.isFetching ? 'blur(3px)' : 'blur(0)',
                  transition: 'filter 0.5s ease 0.1s',
                  height: '100%',
                  maxHeight: '100% !important',
                }}
                src={
                  getCurrentMockupActiveVariation()?.export_path ||
                  mockup?.thumbnail
                }
              />
            </Flex>
          )
        ) : (
          <Box width={'100%'} height={'100%'} style={{ position: 'relative' }}>
            <Skeleton
              width={'100%'}
              height={'100%'}
              style={{
                lineHeight: 'unset',
                height: '100%',
                minHeight: '50svh',
              }}
            />
            {collectionId == 'loading' && (
              <Text
                style={{
                  position: 'absolute',
                  zIndex: '200',
                  color: '#6F6F6F',
                  fontSize: '14px',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                Please wait while the Mockup loads 🙏
              </Text>
            )}
          </Box>
        )}
      </Flex>
    </>
  );
};
