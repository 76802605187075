import { Button, Flex, Text } from '@radix-ui/themes';
import './choose-design-or-mockup.scss';
import { ImageIcon } from '@radix-ui/react-icons';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { ReactComponent as PhotoshopIcon } from '@/assets/icons/photoshop-large.svg';
import { useRef } from 'react';
import { UploadPsdDialog } from '../../../components/custom-mockups/components/UploadPsdDialog';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/hooks';
import { ReactComponent as ArrowLeft } from '@/assets/icons/arrow-left.svg';

interface IChooseDesignOrMockup {
  setStep: (step: 1 | 2) => void;
  setStepName: (step: 'choose' | 'mockups') => void;
  step: 1 | 2;
}

export const ChooseDesignOrMockup = ({
  setStep,
  setStepName,
  step,
}: IChooseDesignOrMockup) => {
  const navigate = useNavigate();
  const captureEvent = useCaptureEvent();

  const uploadPsdDialog = useRef();

  return (
    <Flex
      className="choose-design-or-mockup-wrapper"
      gap={'4'}
      justify={'start'}
      align={'center'}
      height={'100%'}
      direction={'column'}
    >
      <Flex className="back-wrapper" width={'100%'} gap={'3'} px={'4'}>
        <Button
          variant="soft"
          color="gray"
          size={'2'}
          onClick={() => {
            navigate(`/my-templates`);
          }}
        >
          <ArrowLeft width="14px" height="14px" className="icon" />{' '}
          <Text weight={'medium'} size={'2'}>
            Mockup Templates
          </Text>
        </Button>
      </Flex>
      <UploadPsdDialog ref={uploadPsdDialog} />
      <Flex gap={'4'} mt={'auto'} mb={'auto'} className="choose-wrapper">
        <Flex
          className="item"
          p={'4'}
          direction={'column'}
          gap={'5'}
          align={'center'}
          onClick={() => {
            setStep(2);
            setStepName('mockups');
            captureEvent('Create Mockups: Start with Mockups selected');
          }}
        >
          <ImageIcon width="50" height="50" />

          <Flex direction={'column'} gap={'2'}>
            <Text size="4" weight={'bold'} align={'center'}>
              Choose From Library
            </Text>
            <Text size="2" weight={'regular'} align={'center'}>
              Browse mockups from our library or your own uploads. Select the
              ones you need, customize them with your artwork, and export in
              bulk.
            </Text>
          </Flex>
        </Flex>

        <Flex
          className="item"
          p={'4'}
          direction={'column'}
          gap={'5'}
          align={'center'}
          event-tracker-id="upload-psd-button"
          onClick={() => {
            captureEvent('Create Mockup Template: Upload Photoshop Template');
            captureEvent('Upload PSD Mockup modal viewed');
            (uploadPsdDialog?.current as any)?.openDialog();
          }}
        >
          <PhotoshopIcon width="50" height="50" />
          <Flex direction={'column'} gap={'2'}>
            <Text size="4" weight={'bold'} align={'center'}>
              Upload Photoshop Template
            </Text>
            <Text size="2" weight={'regular'} align={'center'}>
              Upload your PSD file to turn it into a reusable template.
              Customize it with artworks and colors, then bulk export your
              mockups with ease.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
