import { Cross1Icon, TrashIcon } from '@radix-ui/react-icons';
import { Flex, Tooltip, IconButton, Text, Box } from '@radix-ui/themes';
import { MutableRefObject } from 'react';

interface ISelectedDesignsOptionsBar {
  selectedDesigns: number[];
  setSelectedDesigns: (x: number[]) => void;
  deleteDesignsDialog?: MutableRefObject<undefined>;
}

export const SelectedDesignsOptionsBar = ({
  selectedDesigns,
  setSelectedDesigns,
  deleteDesignsDialog,
}: ISelectedDesignsOptionsBar) => {
  return selectedDesigns.length > 0 ? (
    <Flex
      p={'2'}
      pr={'6'}
      pl={'2'}
      mx={'6'}
      my={'1'}
      gap={'2'}
      align={'center'}
      justify={'start'}
      className={'designs-selection-bar'}
      width={'auto'}
    >
      <Flex gap={'3'} align={'center'} width={'135px'}>
        <Tooltip content="Clear selection">
          <IconButton
            variant="ghost"
            color="gray"
            onClick={() => setSelectedDesigns([])}
          >
            <Cross1Icon />
          </IconButton>
        </Tooltip>
        <Text size={'1'} color="gray" weight={'medium'}>
          {selectedDesigns.length} selected
        </Text>
      </Flex>
      {deleteDesignsDialog && (
        <Tooltip content="Delete selected artwork">
          <IconButton
            size={'1'}
            color="gray"
            variant="ghost"
            onClick={() => {
              (deleteDesignsDialog?.current as any)?.openDialog(
                selectedDesigns
              );
            }}
          >
            <TrashIcon />
          </IconButton>
        </Tooltip>
      )}
    </Flex>
  ) : (
    <Box className="designs-options-bar-placeholder" height={'47.5px'}></Box>
  );
};
