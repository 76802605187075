import {
  Dialog,
  Flex,
  Grid,
  Text,
  Button,
  Box,
  VisuallyHidden,
  Slider,
  Tooltip,
  IconButton,
} from '@radix-ui/themes';
import './appPpaymentPopup.scss';
import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { ReactComponent as CheckmarkIcon } from '@/assets/icons/check-linear.svg';
import { ReactComponent as DcLogoGoldenImage } from '@/assets/dc-logo-golden.svg';
import { ReactComponent as ArrowAboveIcon } from '@/assets/icons/arrow-above.svg';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import { paymentsApi } from '@/services/payments';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { EnterpriseContactUs } from './EnterpriseContactUs';
import { toast } from 'react-toastify';
import { Cross1Icon } from '@radix-ui/react-icons';
import { userApi } from '@/services/user';
import { DEV_PRICE_MAP, PROD_PRICE_MAP } from './plansData';
import posthog from 'posthog-js';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as TreeSquaresDefaultIcon } from '@/assets/icons/three-squares-linear-default.svg';

// Define render count steps and corresponding prices
const RENDER_STEPS = [
  100, 300, 1000, 2500, 5000, 10000, 50000, 100000, 250000, 500000, 1000000,
];

// Maximum renders for each plan
const MAX_BASIC_RENDERS = 1000;
const MAX_PRO_RENDERS = 10000;

// Helper function to format numbers without rounding
const formatNumber = (num: number) => {
  if (num >= 1000000)
    return `${(num / 1000000).toFixed(num % 1000000 === 0 ? 0 : 1)}M+`;
  if (num >= 1000) return `${(num / 1000).toFixed(num % 1000 === 0 ? 0 : 1)}k`;
  return num.toString();
};

// Helper function to find plan info by price_id
const findPlanInfoByPriceId = (priceId: string) => {
  const priceMap = !!parseInt(IS_PRODUCTION) ? PROD_PRICE_MAP : DEV_PRICE_MAP;

  // Search through all plan types and render counts
  for (const planType in priceMap) {
    const planCategory = priceMap[planType as keyof typeof priceMap];
    for (const renderCount in planCategory) {
      const plan =
        planCategory[renderCount as unknown as keyof typeof planCategory];
      if (plan.price_id === priceId) {
        return plan;
      }
    }
  }
  // Return default values if not found
  return {
    price: 0,
    price_id: '',
    plan_type: '',
    billing_cycle: '',
    renders: 0,
  };
};

export const NewAppPaymentPopup = forwardRef(({}, ref) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const { currentUser } = useAppSelector((state) => state.userReducer);

  const sliderBubbleRef = useRef<HTMLDivElement>(null);
  const [isAnnual, setIsAnnual] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [renderCount, setRenderCount] = useState<number>(100);
  const [sliderIndex, setSliderIndex] = useState<number>(0);
  const [selectedPlan, setSelectedPlan] = useState<
    'hobby' | 'pro' | 'business'
  >('hobby');
  const [showSliderHelper, setShowSliderHelper] = useState<boolean>(true);

  // Get current price ID from user subscription
  const currentActivePriceId =
    currentUser?.subscription_billing_status.status === 'canceled'
      ? null
      : currentUser?.subscription_billing_status?.price_id || null;

  // Get current plan info
  const currentPlanInfo = currentActivePriceId
    ? findPlanInfoByPriceId(currentActivePriceId)
    : null;

  useEffect(() => {
    dispatch(setIsAppPaymentPopupOpen(open));
  }, [open]);

  // Animation states for prices
  const [animatedBasicPrice, setAnimatedBasicPrice] = useState(0);
  const [animatedProPrice, setAnimatedProPrice] = useState(0);

  // Handle slider change
  const handleSliderChange = (value: number[]) => {
    const index = value[0];
    setSliderIndex(index);

    // Store current prices before they change
    setAnimatedBasicPrice(basicPrice);
    setAnimatedProPrice(proPrice);

    // Update render count which will trigger price recalculation
    setRenderCount(RENDER_STEPS[index]);

    captureEvent('Renders slider used', { renderStep: RENDER_STEPS[index] });

    // Show helper text only when slider is at 100
    setShowSliderHelper(index === 0);
  };

  // Update selected plan based on render count
  useEffect(() => {
    if (renderCount <= 300) {
      setSelectedPlan('hobby');
    } else if (renderCount <= 10000) {
      setSelectedPlan('pro');
    } else {
      setSelectedPlan('business');
    }
  }, [renderCount]);

  useImperativeHandle(ref, () => ({
    openDialog() {
      setActiveView('paywall');
      setOpen(true);
    },
    closeDialog() {
      setOpen(false);
    },
  }));

  const [activeView, setActiveView] = useState<'paywall' | 'contact-us-form'>(
    'paywall'
  );

  const enterpriseContactUsFormOpen = () => {
    captureEvent('Enterprise Plan Form opened');
    setActiveView('contact-us-form');
  };

  const goToPayWall = () => {
    captureEvent('Enterprise Plan Form closed');
    setActiveView('paywall');
  };

  const closeFormModal = () => {
    setActiveView('contact-us-form');
    captureEvent('Enterprise Plan Form closed');
    setOpen(false);
    toast.success('Thank you for contacting us.', {
      toastId: 'successfull_contact_us',
      position: 'bottom-right',
      autoClose: 5000,
    });
  };

  // Get basic plan price and price_id based on render count and billing cycle
  const getBasicPriceInfo = () => {
    const priceMap = !!parseInt(IS_PRODUCTION) ? PROD_PRICE_MAP : DEV_PRICE_MAP;
    const planType = isAnnual ? 'basic_yearly' : 'basic';
    return (
      priceMap[planType as keyof typeof priceMap][
        renderCount as keyof (typeof priceMap)['basic']
      ] ?? {
        price: 24,
        price_id: 'price_default_basic',
        plan_type: 'basic',
        billing_cycle: isAnnual ? 'yearly' : 'monthly',
        renders: renderCount,
      }
    );
  };

  // Get pro plan price and price_id based on render count and billing cycle
  const getProPriceInfo = () => {
    const priceMap = !!parseInt(IS_PRODUCTION) ? PROD_PRICE_MAP : DEV_PRICE_MAP;
    const planType = isAnnual ? 'pro_yearly' : 'pro';
    return (
      priceMap[planType as keyof typeof priceMap][
        renderCount as keyof (typeof priceMap)['pro']
      ] ?? {
        price: 249,
        price_id: 'price_default_pro',
        plan_type: 'pro',
        billing_cycle: isAnnual ? 'yearly' : 'monthly',
        renders: renderCount,
      }
    );
  };

  const basicPriceInfo = getBasicPriceInfo();
  const proPriceInfo = getProPriceInfo();
  const basicPrice = basicPriceInfo.price;
  const proPrice = proPriceInfo.price;
  const yearlyBasicTotal = Math.round(basicPrice * 12)
    .toLocaleString()
    .replace(/,/g, '.');
  const yearlyProTotal = Math.round(proPrice * 12)
    .toLocaleString()
    .replace(/,/g, '.');

  // Check if plans should be disabled
  const isBasicDisabled = renderCount >= 2500;
  const isProDisabled = renderCount >= 50000;

  // Setup price animations using react-spring
  const basicPriceAnimation = useSpring({
    number: basicPrice,
    from: { number: animatedBasicPrice },
    config: { duration: 300 },
    onRest: () => setAnimatedBasicPrice(basicPrice),
  });

  const proPriceAnimation = useSpring({
    number: proPrice,
    from: { number: animatedProPrice },
    config: { duration: 300 },
    onRest: () => setAnimatedProPrice(proPrice),
  });

  // Get the appropriate render count to display for each plan
  const getBasicRenderDisplay = () => {
    if (renderCount <= 100) {
      return 'Free forever & 100 credits';
    } else if (renderCount <= MAX_BASIC_RENDERS) {
      return `${formatNumber(renderCount)} monthly credits`;
    } else {
      return `${formatNumber(MAX_BASIC_RENDERS)} monthly credits`;
    }
  };

  const getProRenderDisplay = () => {
    if (renderCount <= MAX_PRO_RENDERS) {
      return `${formatNumber(renderCount)} monthly credits`;
    } else {
      return `${formatNumber(MAX_PRO_RENDERS)} monthly credits`;
    }
  };

  const [isHobbySwapping, setIsHobbySwapping] = useState<boolean>(false);
  const [isProSwapping, setIsProSwapping] = useState<boolean>(false);

  const [swapSubscription, { isLoading: isSwapping }] =
    paymentsApi.useSubscriptionSwapMutation();

  const goToSwipeStripePlanCheckout = async (
    type: 'hobby' | 'pro',
    priceId: string
  ) => {
    try {
      if (type === 'hobby') {
        setIsHobbySwapping(true);
      } else {
        setIsProSwapping(true);
      }
      await swapSubscription({
        price_id: priceId,
        subscription_plan_type: 'api',
      }).unwrap();
      await dispatch(
        userApi.endpoints.getCurrentUser.initiate(null!, {
          forceRefetch: true,
        })
      ).unwrap();
      if (type === 'hobby') {
        setIsHobbySwapping(false);
      } else {
        setIsProSwapping(false);
      }
      await new Promise((resolve) => setTimeout(resolve, 250));
      posthog.reloadFeatureFlags();

      captureEvent('Payment initiated');
      toast.success('Plan successfully swapped.', {
        toastId: 'plan_swap_success',
        position: 'bottom-right',
        autoClose: 5000,
      });
    } catch (error: any) {
      captureEvent('Payment initiation failed');
      if (type === 'hobby') {
        setIsHobbySwapping(false);
      } else {
        setIsProSwapping(false);
      }
      toast.error(error.data.message, {
        toastId: 'plan_swap_error',
        position: 'bottom-right',
        autoClose: 5000,
      });
      console.log(error);
    }
  };

  const goToStripePlanCheckout = async (
    type: 'hobby' | 'pro',
    priceId: string
  ) => {
    try {
      const url = await dispatch(
        paymentsApi.endpoints.getCheckoutSessionURL.initiate({
          stripe_price_id: priceId,
          subscription_plan_type: 'api',
          plan_type: type,
        })
      ).unwrap();
      captureEvent('Payment initiated');
      window.location.replace(url.data.checkout_url);
    } catch (error) {
      captureEvent('Payment initiation failed');
      console.log(error);
    }
  };

  // Handle plan selection with price_id
  const handleBasicPlanSelection = () => {
    const priceId = basicPriceInfo.price_id;

    if (!priceId) {
      captureEvent('Upgrade your plan modal closed');
      setOpen(false);
      return;
    }

    if (
      currentUser?.subscription_billing_status?.price_id &&
      currentUser?.subscription_billing_status.status !== 'canceled'
    ) {
      goToSwipeStripePlanCheckout('hobby', priceId);
    } else {
      goToStripePlanCheckout('hobby', priceId);
    }
  };

  const handleProPlanSelection = () => {
    const priceId = proPriceInfo.price_id;

    if (!priceId) {
      captureEvent('Upgrade your plan modal closed');
      setOpen(false);
      return;
    }

    if (
      currentUser?.subscription_billing_status?.price_id &&
      currentUser?.subscription_billing_status.status !== 'canceled'
    ) {
      goToSwipeStripePlanCheckout('pro', priceId);
    } else {
      goToStripePlanCheckout('pro', priceId);
    }
  };

  // Determine if the selected plan is an upgrade, downgrade, or current plan
  const getBasicButtonText = () => {
    // if (
    //   currentUser?.subscription_trial.is_available === 1 &&
    //   !currentActivePriceId
    // ) {
    //   return 'Start Free Trial';
    // }

    if (!currentActivePriceId) {
      if (renderCount === 100) {
        return 'Stay Hobby';
      } else {
        return 'Get Hobby';
      }
    }

    // If current plan is Pro, any Hobby plan is a downgrade
    if (currentPlanInfo?.plan_type === 'pro') {
      return 'Downgrade to Hobby';
    }

    // If current plan is already Hobby
    if (currentPlanInfo?.plan_type === 'basic') {
      // If it's the same render count, it's the current plan
      if (currentPlanInfo?.renders === renderCount) {
        // Check if billing cycle matches
        if (
          currentPlanInfo?.billing_cycle === (isAnnual ? 'yearly' : 'monthly')
        ) {
          return 'Current Plan';
        }
        return isAnnual ? 'Switch to Yearly' : 'Switch to Monthly';
      }
      // If free tier
      if (basicPrice === 0) {
        return 'Stay Hobby';
      }
      // Otherwise it's a different Hobby plan
      return 'Choose Hobby';
    }

    return 'Choose Hobby';
  };

  const getProButtonText = () => {
    if (
      currentUser?.subscription_trial.is_available === 1 &&
      !currentActivePriceId
    ) {
      return 'Start Free Trial';
    }
    if (!currentActivePriceId) {
      return 'Get Pro';
    }
    // If current plan is Hobby, any Pro plan is an upgrade
    if (currentPlanInfo?.plan_type === 'basic') {
      return 'Upgrade to Pro';
    }

    // If current plan is already Pro
    if (currentPlanInfo?.plan_type === 'pro') {
      // If it's the same render count, it's the current plan
      if (currentPlanInfo?.renders === renderCount) {
        // If billing cycle is different, it's an upgrade/downgrade
        if (
          currentPlanInfo?.billing_cycle !== (isAnnual ? 'yearly' : 'monthly')
        ) {
          return isAnnual ? 'Switch to Yearly' : 'Switch to Monthly';
        }
        return 'Current Plan';
      }

      // If selected render count is higher, it's an upgrade
      if (renderCount > currentPlanInfo?.renders) {
        return 'Upgrade Plan';
      }

      // If selected render count is lower, it's a downgrade
      return 'Downgrade Plan';
    }

    return 'Upgrade to Pro';
  };

  const getCssValue = (step: number) => {
    if (step <= 4) {
      return `${Math.max(0, 8 - 2 * step)}px`;
    } else {
      return `${Math.min(0, -2 * (step - 5))}px`;
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content
        aria-describedby={undefined}
        size={'4'}
        className="app-payment-popup-wrapper"
        style={{
          maxWidth: activeView === 'paywall' ? '100%' : '490px',
          maxHeight: activeView === 'paywall' ? '100%' : '760px',
          height: activeView === 'paywall' ? '100svh' : '100%',
          minHeight: '464px',
          padding: activeView === 'paywall' ? '0px' : '24px',
          borderRadius: '0',
        }}
        width={'100%'}
        onPointerDownOutside={() => {
          captureEvent('Enterprise Plan Form closed');
          captureEvent('Upgrade your plan modal closed');
        }}
      >
        <VisuallyHidden>
          <Dialog.Title></Dialog.Title>
        </VisuallyHidden>
        {activeView === 'contact-us-form' && (
          <EnterpriseContactUs
            goToPayWall={goToPayWall}
            closeFormModal={closeFormModal}
          />
        )}
        {activeView === 'paywall' && (
          <Flex
            height={'100%'}
            direction={isMobile ? 'column' : 'row'}
            gap={'5'}
          >
            {/* Left side - Promotional content */}
            <Box
              className="promo-content"
              style={{
                flex: 1,
                padding: '24px',
                color: 'white',
              }}
            >
              <Flex
                direction="column"
                height="100%"
                justify="between"
                align={'center'}
              >
                <Flex height={'100%'} align={'center'} justify={'center'}>
                  <DcLogoGoldenImage width={256} height={256} />
                </Flex>

                <Box>
                  <Text size="9" weight="bold" className="title-wrapper">
                    <Text className="title-1">Unlock</Text>
                    <Text className="title-2">All Features</Text>
                  </Text>
                  <Text className="description">
                    You will get all premium content and features. Unlock your
                    creativity and start working without limits.
                  </Text>
                </Box>
              </Flex>
            </Box>

            {/* Right side - Plan selection */}
            <Flex
              className="plans-section"
              direction="column"
              gap="4"
              py={'5'}
              pr={'5'}
              style={{ flex: 2 }}
            >
              <Flex justify="between" align="center">
                <Text size="5" weight="bold">
                  Upgrade your plan
                </Text>
                <Button
                  variant="ghost"
                  size={'3'}
                  color="gray"
                  onClick={() => {
                    captureEvent('Upgrade your plan modal closed');
                    setOpen(false);
                  }}
                >
                  <Cross1Icon />
                </Button>
              </Flex>

              {/* Render slider */}
              <Flex
                className="slide-wrapper-wrapper"
                direction={'column'}
                justify={'center'}
              >
                <Box className="slide-wrapper">
                  <Slider
                    value={[sliderIndex]}
                    onValueChange={handleSliderChange}
                    min={0}
                    max={RENDER_STEPS.length - 1}
                    step={1}
                    style={{ width: '100%' }}
                    size={'3'}
                  />
                  <Box
                    ref={sliderBubbleRef}
                    className="slider-bubble"
                    style={{
                      left: `calc(${
                        (sliderIndex / (RENDER_STEPS.length - 1)) * 100
                      }% + ${getCssValue(sliderIndex)})`,
                    }}
                  >
                    <TreeSquaresDefaultIcon
                      className="icon"
                      width="14"
                      height="14"
                    />{' '}
                    {formatNumber(renderCount)}
                  </Box>

                  <Flex
                    className={`slider-helper-wrapper ${
                      showSliderHelper ? 'show' : 'hidden'
                    }`}
                    justify="start"
                    align="baseline"
                    gap="1"
                    mt={'5'}
                    pl={'1'}
                  >
                    <ArrowAboveIcon className="icon-arrow-up" />
                    <Text className="text" size="2">
                      Select the number of monthly credits
                    </Text>
                  </Flex>
                </Box>
              </Flex>

              {/* Billing toggle */}
              <Flex gap="2" justify="start">
                <Flex className="billing-toggle">
                  <Box
                    className={`billing-option ${!isAnnual ? 'active' : ''}`}
                    style={{
                      background: !isAnnual ? 'var(--slate-1)' : 'transparent',
                    }}
                    onClick={() => {
                      setIsAnnual(false);
                      captureEvent('Monthly plans view choosen', {
                        renderStep: renderCount,
                      });
                    }}
                  >
                    <Text size="1">Billed Monthly</Text>
                  </Box>
                  <Box
                    className={`billing-option ${isAnnual ? 'active' : ''}`}
                    style={{
                      background: isAnnual ? 'var(--slate-1)' : 'transparent',
                    }}
                    onClick={() => {
                      setIsAnnual(true);
                      captureEvent('Annual plans view choosen', {
                        renderStep: renderCount,
                      });
                    }}
                  >
                    <Text size="1">Billed Yearly 25% Off</Text>
                  </Box>
                </Flex>
              </Flex>

              {/* Plan cards */}
              <Grid
                columns={isMobile ? '1' : '3'}
                gap="4"
                className="plans-wrapper"
              >
                {/* Hobby Plan */}
                <Flex
                  direction="column"
                  className={`item-card ${
                    selectedPlan === 'hobby' ? 'active' : ''
                  } ${isBasicDisabled ? 'disabled' : ''}`}
                >
                  <Box p="4" className="header">
                    <Text className="title">Hobby</Text>
                    <Flex align="baseline" gap="1" mt={'4'} mb={'1'}>
                      <Text size="8" weight="bold" className="price">
                        $
                        <animated.span>
                          {basicPriceAnimation.number.to((n) => Math.floor(n))}
                        </animated.span>
                      </Text>
                      <Text size="2">/ month</Text>
                    </Flex>
                    <Text size="2" className="description">
                      {isAnnual && basicPrice > 0
                        ? `$${yearlyBasicTotal} billed yearly for ${getBasicRenderDisplay()}`
                        : getBasicRenderDisplay()}
                    </Text>
                  </Box>
                  <Box px="4" pt={'4'} className="cta-button-wrapper">
                    <Button
                      variant="outline"
                      disabled={
                        (isHobbySwapping ||
                          isBasicDisabled ||
                          (!!currentPlanInfo &&
                            currentPlanInfo?.plan_type === 'basic' &&
                            currentPlanInfo?.renders === renderCount &&
                            currentPlanInfo?.billing_cycle ===
                              (isAnnual ? 'yearly' : 'monthly'))) ??
                        true
                      }
                      loading={isHobbySwapping}
                      onClick={handleBasicPlanSelection}
                    >
                      {getBasicButtonText()}
                    </Button>
                  </Box>
                  <Box className="content" p="4" pt={'5'} style={{ flex: 1 }}>
                    <Flex direction="column" gap="2">
                      <Flex gap="2" align="center">
                        <CheckmarkIcon />
                        <Text size="2">1,000+ Library of Mockups</Text>
                      </Flex>
                      <Flex gap="2" align="center">
                        <CheckmarkIcon />
                        <Text size="2">Unlimited downloads</Text>
                      </Flex>
                      <Flex gap="2" align="center">
                        <CheckmarkIcon />
                        <Flex gap={'1'} align={'center'}>
                          <Text size="2">Batch Create Mockups</Text>{' '}
                          <Tooltip content={'1 credit per download'}>
                            <span>
                              <TreeSquaresDefaultIcon
                                className="icon"
                                width="14"
                                height="14"
                              />
                            </span>
                          </Tooltip>
                        </Flex>
                      </Flex>
                      <Flex gap="2" align="center">
                        <CheckmarkIcon />
                        <Text size="2">High-quality Exports</Text>
                      </Flex>
                      <Flex gap="2" align="center">
                        <CheckmarkIcon />
                        <Text size="2">No Watermark</Text>
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>

                {/* Pro Plan */}
                <Flex
                  direction="column"
                  className={`item-card pro ${
                    selectedPlan === 'pro' ? 'active' : ''
                  } ${isProDisabled ? 'disabled' : ''}`}
                >
                  <Box p="4" className="header">
                    <Text size="5" className="title">
                      Pro
                    </Text>
                    <Flex align="baseline" gap="1" mt={'4'} mb={'1'}>
                      <Text size="8" weight="bold" className="price">
                        $
                        <animated.span>
                          {proPriceAnimation.number.to((n) => Math.floor(n))}
                        </animated.span>
                      </Text>
                      <Text size="2">/ month</Text>
                    </Flex>
                    <Text size="2" className="description">
                      {isAnnual
                        ? `$${yearlyProTotal} billed yearly for ${getProRenderDisplay()}`
                        : getProRenderDisplay()}
                    </Text>
                  </Box>
                  <Box px="4" pt={'4'} className="cta-button-wrapper">
                    <Button
                      variant="solid"
                      color="blue"
                      disabled={
                        (isProSwapping ||
                          isProDisabled ||
                          (!!currentPlanInfo &&
                            currentPlanInfo.plan_type === 'pro' &&
                            currentPlanInfo.renders === renderCount &&
                            currentPlanInfo.billing_cycle ===
                              (isAnnual ? 'yearly' : 'monthly'))) ??
                        true
                      }
                      loading={isProSwapping}
                      onClick={handleProPlanSelection}
                    >
                      {getProButtonText()}
                    </Button>
                  </Box>
                  <Box className="content" p="4" pt={'5'} style={{ flex: 1 }}>
                    <Flex direction="column" gap="2">
                      <Flex gap="2" align="center">
                        <CheckmarkIcon />
                        <Text size="2">Custom Photoshop Mockups</Text>
                      </Flex>
                      <Flex gap="2" align="center">
                        <CheckmarkIcon />
                        <Flex gap={'1'} align={'center'}>
                          <Text size="2">Embed Mockup Editor</Text>{' '}
                          <Tooltip content={'1 credit per download'}>
                            <span>
                              <TreeSquaresDefaultIcon
                                className="icon"
                                width="14"
                                height="14"
                              />
                            </span>
                          </Tooltip>
                        </Flex>
                      </Flex>
                      <Flex gap="2" align="center">
                        <CheckmarkIcon />
                        <Flex gap={'1'} align={'center'}>
                          <Text size="2">AI Backgrounds</Text>{' '}
                          <Tooltip content={'10 credits per download'}>
                            <span>
                              <TreeSquaresDefaultIcon
                                className="icon"
                                width="14"
                                height="14"
                              />
                            </span>
                          </Tooltip>
                        </Flex>
                      </Flex>
                      <Flex gap="2" align="center">
                        <CheckmarkIcon />
                        <Text size="2">Color Presets</Text>
                      </Flex>
                      <Flex gap="2" align="center">
                        <CheckmarkIcon />
                        <Flex gap={'1'} align={'center'}>
                          <Text size="2">API Access</Text>{' '}
                          <Tooltip content={'1 credit per download'}>
                            <span>
                              <TreeSquaresDefaultIcon
                                className="icon"
                                width="14"
                                height="14"
                              />
                            </span>
                          </Tooltip>
                        </Flex>
                      </Flex>
                      <Flex gap="2" align="center">
                        <CheckmarkIcon />
                        <Text size="2">Priority Customer Support</Text>
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>

                {/* Business Plan */}
                <Flex
                  direction="column"
                  className={`item-card enterprise ${
                    selectedPlan === 'business' ? 'active' : ''
                  }`}
                >
                  <Box p="4" className="header">
                    <Text size="5" className="title">
                      Business
                    </Text>
                    <Flex align="baseline" gap="1" mt={'4'} mb={'1'}>
                      <Text size="8" weight="bold" className="price">
                        Let's talk
                      </Text>
                    </Flex>
                    <Text size="2" className="description">
                      Custom solution
                    </Text>
                  </Box>
                  <Box px="4" pt={'4'} className="cta-button-wrapper">
                    <Button
                      variant="outline"
                      onClick={enterpriseContactUsFormOpen}
                    >
                      Contact Us
                    </Button>
                  </Box>
                  <Box className="content" p="4" pt={'5'} style={{ flex: 1 }}>
                    <Flex direction="column" gap="2">
                      <Flex gap="2" align="center">
                        <CheckmarkIcon />
                        <Text size="2">Volume based discounts</Text>
                      </Flex>
                      <Flex gap="2" align="center">
                        <CheckmarkIcon />
                        <Text size="2">Scalable pricing</Text>
                      </Flex>
                      <Flex gap="2" align="center">
                        <CheckmarkIcon />
                        <Text size="2">White Label</Text>
                      </Flex>
                      <Flex gap="2" align="center">
                        <CheckmarkIcon />
                        <Text size="2">Hands-on onboarding</Text>
                      </Flex>
                      <Flex gap="2" align="center">
                        <CheckmarkIcon />
                        <Text size="2">Enterprise Support</Text>
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
              </Grid>
            </Flex>
          </Flex>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
});
