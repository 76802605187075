import { Helmet } from 'react-helmet-async';
import './workspace-settings.scss';
import { Flex, ScrollArea, Tabs, Text } from '@radix-ui/themes';
import { useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { useState } from 'react';
import { Subscription } from './components/view-wrapper/Subscription';
import { ViewWrapper } from './components/view-wrapper/ViewWrapper';

type tabs = 'profile' | 'subscription';

export const WorkspaceSettings = () => {
  const captureEvent = useCaptureEvent();
  const [tabs, setTabs] = useState<tabs>('profile');

  return (
    <Flex
      className="page-wrapper workspace-settings-page"
      direction={'column'}
      align={'center'}
      gap={'5'}
    >
      <Helmet>
        <title>Dynamic Mockups | Account</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Flex
        className="header"
        align={'center'}
        justify={'between'}
        maxWidth={'640px'}
        width={'100%'}
      >
        <Text className="page-title">Account</Text>
      </Flex>

      <Tabs.Root
        defaultValue="profile"
        value={tabs}
        onValueChange={(value) => setTabs(value as tabs)}
        style={{ maxWidth: '660px' }}
      >
        <Tabs.List>
          <Tabs.Trigger value={'profile'}>Profile</Tabs.Trigger>
          <Tabs.Trigger value={'subscription'}>Subscription</Tabs.Trigger>
        </Tabs.List>
        <ScrollArea className="scroll-area-page-wrapper">
          <Flex direction={'column'} pt="3">
            <Tabs.Content value={'profile'}>
              <Flex
                className="page-content"
                direction={'column'}
                width={'100%'}
                gap={'2'}
                height={'unset'}
              >
                <ViewWrapper />
              </Flex>
            </Tabs.Content>
            <Tabs.Content value={'subscription'}>
              <Flex
                className="page-content"
                direction={'column'}
                width={'100%'}
                gap={'2'}
              >
                <Subscription />
              </Flex>
            </Tabs.Content>
          </Flex>
        </ScrollArea>
      </Tabs.Root>
    </Flex>
  );
};
