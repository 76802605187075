import { Helmet } from 'react-helmet-async';
import './api-dashboard.scss';
import {
  Flex,
  ScrollArea,
  Text,
  Button,
  Dialog,
  TextField,
  Tooltip,
  AlertDialog,
  Separator,
} from '@radix-ui/themes';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.svg';
import { apiKeyApi } from '@/services/apiKey';
import { ReactComponent as TrashBin } from '@/assets/icons/trash-bin.svg';
import { ReactComponent as PenIcon } from '@/assets/icons/pen-linear.svg';
import { toast } from 'react-toastify';
import { ApiKey, User, Workspace } from '@/redux/slices/types';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useMediaQuery } from 'react-responsive';
import { paymentsApi } from '@/services/payments';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { BarChartIcon, Cross1Icon } from '@radix-ui/react-icons';
import { DailyRendersLast30Days } from './components/report-charts/DailyRendersLast30Days';
import { formatNumberWithThousands } from '@/helpers';
import Skeleton from 'react-loading-skeleton';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import { ReactComponent as CodeFileLinearIcon } from '@/assets/icons/code-file-linear.svg';
import { ReactComponent as DCLogoPurpleImage } from '@/assets/design-copilot-logo.svg';

export const ApiDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const captureEvent = useCaptureEvent();
  const isAppPro = useFeatureFlagEnabled('app-pro');
  const [open, setOpen] = useState<boolean>(false);
  const [analyticsOpen, setAnalyticsOpen] = useState<boolean>(false);

  const [openTopUpDialog, setOpenTopUpDialog] = useState<boolean>(false);
  const [editedApiKey, setEditedApiKey] = useState<ApiKey>(null!);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const { userRenders } = useAppSelector((state) => state.paymentsReducer);
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const { currentWorkspace } = useAppSelector(
    (state) => state.workspaceReducer
  );
  const haveTrialProSubscription = useFeatureFlagEnabled(
    'trial-pro-subscription'
  );

  const [searchParams, setSearchParams] = useSearchParams({});

  const apiKeysData = apiKeyApi.useGetApiKeysQuery();
  const userRendersData = paymentsApi.useGetUserRendersQuery(undefined, {
    pollingInterval: searchParams.get('checkout_session_id')
      ? userRenders !== undefined && userRenders !== null
        ? 0
        : 500
      : 0,
    refetchOnMountOrArgChange: true,
  });

  const [apiKeyName, setApiKeyName] = useState<string>('');
  const [topUpRendersNumber, setTopUpRendersNumber] = useState<number>(500);

  const [createApiKey, { isLoading: isCreating }] =
    apiKeyApi.useCreateApiKeyMutation();
  const [deleteApiKey, { isLoading: isDeleting }] =
    apiKeyApi.useDeleteApiKeyMutation();
  const [updateApiKey, { isLoading: isUpdating }] =
    apiKeyApi.useUpdateApiKeyMutation();
  const [topUpRendersCheckoutSession, { isLoading: isGettingTopUpUrl }] =
    paymentsApi.useGetTopUpRendersCheckoutSessionURLMutation();

  const createApiKeyAction = async () => {
    try {
      await createApiKey({
        name: apiKeyName,
      }).unwrap();
      captureEvent('User Created API Key', {
        name: apiKeyName,
        $set: { api_key_created: true },
      });
    } catch (error) {
      console.log('error: ', error);
      toast.warning((error as unknown as any).data.message, {
        toastId: 'api_key_action_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  const deleteKeyAction = async (uuid: string) => {
    try {
      await deleteApiKey({ uuid });
    } catch (error) {
      console.log('error: ', error);
      toast.warning((error as unknown as any).data.message, {
        toastId: 'api_key_action_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  const updateApiKeyAction = async () => {
    try {
      await updateApiKey(editedApiKey);
    } catch (error) {
      console.log('error: ', error);
      toast.warning((error as unknown as any).data.message, {
        toastId: 'api_key_action_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  const filterPlanName = (
    currentUser: User | null,
    currentWorkspace: Workspace | null
  ) => {
    if (isAppPro) {
      if (currentUser?.subscription_trial.status === 'active') {
        return 'Free Trial';
      }

      if (currentUser?.subscription_trial.status === 'cancelled') {
        if (userRendersData.data?.data[0]?.interval === 'free') {
          return 'Trial Cancelled';
        } else {
          if (currentWorkspace?.user_id === 5123) {
            return `Enterprise 1`;
          } else {
            return `Premium (${formatNumberWithThousands(
              (userRendersData.data?.data?.[0]?.plan as number) || 1
            )})`;
          }
        }
      }
      if (
        currentUser?.subscription_trial.status === 'completed' ||
        currentUser?.subscription_trial.status === 'inactive'
      ) {
        if (userRendersData.data?.data[0]?.interval === 'free') {
          return 'Pro';
        } else {
          if (currentWorkspace?.user_id === 5123) {
            return `Enterprise 1`;
          } else {
            return `Premium (${formatNumberWithThousands(
              (userRendersData.data?.data?.[0]?.plan as number) || 1
            )})`;
          }
        }
      }
    } else {
      return 'Free';
    }
    return 'Free';
  };

  const apiPaymentDialog = useRef();

  useEffect(() => {
    async function openPaymentDialog() {
      const showBuyDialog = searchParams.get('show-buy-dialog') as string;
      const showTopUpDialog = searchParams.get('show-topUp-dialog') as string;

      if (showTopUpDialog === 'yes') {
        await new Promise((resolve) => setTimeout(resolve, 500));
        setOpenTopUpDialog(true);
        searchParams.delete('show-topUp-dialog');

        setSearchParams(searchParams);
      }

      if (showBuyDialog === 'yes') {
        await new Promise((resolve) => setTimeout(resolve, 500));
        (apiPaymentDialog?.current as any)?.openDialog();
        searchParams.delete('show-buy-dialog');

        setSearchParams(searchParams);
      }
    }

    if (
      searchParams.get('show-buy-dialog') ||
      searchParams.get('show-topUp-dialog')
    ) {
      openPaymentDialog();
    }
  }, []);

  const topUpRendersCheckoutRequest = async () => {
    try {
      if (topUpRendersNumber < 5) return;
      const url = await topUpRendersCheckoutSession({
        renders: topUpRendersNumber as number,
      }).unwrap();
      captureEvent('Payment initiated');
      window.location.replace(url.data.checkout_url);
    } catch (error) {
      console.log('error: ', error);
      captureEvent('User top up renders error', {
        error: JSON.stringify(error),
      });
      toast.warning((error as unknown as any).data.message, {
        toastId: 'top_up_renders_action_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  return (
    <Flex
      className="page-wrapper api-integration-page"
      direction={'column'}
      align={'center'}
      gap={'5'}
    >
      <Helmet>
        <title>Dynamic Mockups | API Integration</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Content aria-describedby={undefined}>
          <Dialog.Title>Edit App Name</Dialog.Title>
          <Dialog.Description>
            Edit name of your App for which you use this API Integration.
          </Dialog.Description>

          <Flex direction="column" gap="3" my={'4'}>
            <label>
              <TextField.Root
                onChange={(e) =>
                  setEditedApiKey({
                    ...editedApiKey,
                    name: e.target.value,
                  })
                }
                value={editedApiKey?.name}
                placeholder="App Name"
              />
            </label>
          </Flex>

          <Flex gap="3" justify="end">
            <Dialog.Close>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </Dialog.Close>
            <Dialog.Close>
              <Button
                variant="solid"
                color="blue"
                onClick={() => updateApiKeyAction()}
              >
                Update
              </Button>
            </Dialog.Close>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
      <Dialog.Root open={analyticsOpen} onOpenChange={setAnalyticsOpen}>
        <Dialog.Content
          aria-describedby={undefined}
          width={'100%'}
          minHeight={'55svh'}
          maxWidth={'1025px'}
          maxHeight={'75svh'}
        >
          <Dialog.Title>API usage analytics</Dialog.Title>
          <Dialog.Description></Dialog.Description>

          <Flex direction="column" gap="3" my={'4'}>
            <DailyRendersLast30Days apiKeyUUID={editedApiKey?.uuid} />
          </Flex>

          <Flex gap="3" justify="end">
            <Dialog.Close>
              <Button variant="soft" color="gray">
                Close
              </Button>
            </Dialog.Close>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>

      <Flex
        className="header"
        align={'center'}
        justify={'between'}
        maxWidth={'640px'}
        width={'100%'}
      >
        <Text className="page-title">API Integration</Text>

        <Flex align={'center'} gap={'4'}>
          <Link
            to="https://docs.dynamicmockups.com"
            target="_blank"
            style={{ lineHeight: '0' }}
          >
            <Button
              variant="ghost"
              color="gray"
              size={'3'}
              onClick={() => captureEvent('API Docs visited')}
            >
              <CodeFileLinearIcon
                className="icon"
                width={'20px'}
                height={'20px'}
              />
              <Text className="button-text">Read Docs</Text>
            </Button>
          </Link>

          <Dialog.Root>
            <Dialog.Trigger>
              <Button>
                <PlusIcon
                  width={'20px'}
                  height={'20px'}
                  className="icon white"
                />
                <Text size="1" weight="medium">
                  Create API Key
                </Text>
              </Button>
            </Dialog.Trigger>

            <Dialog.Content
              aria-describedby={undefined}
              style={{ maxWidth: 450 }}
            >
              <Dialog.Title>Name your App</Dialog.Title>
              <Dialog.Description size="2" mb="4">
                Name your App for which you want to create an API Integration.
              </Dialog.Description>

              <Flex direction="column" gap="3">
                <label>
                  <TextField.Root
                    onChange={(e) => setApiKeyName(e.target.value)}
                    value={apiKeyName}
                    placeholder="App Name"
                  />
                </label>
              </Flex>

              <Flex gap="3" mt="4" justify="end">
                <Dialog.Close>
                  <Button variant="soft" color="gray">
                    Cancel
                  </Button>
                </Dialog.Close>
                <Dialog.Close>
                  <Button
                    event-tracker-id="create-api-key"
                    variant="solid"
                    color="blue"
                    onClick={() => createApiKeyAction()}
                  >
                    Create
                  </Button>
                </Dialog.Close>
              </Flex>
            </Dialog.Content>
          </Dialog.Root>
        </Flex>
      </Flex>

      {currentWorkspace && currentWorkspace ? (
        <Flex
          className="plan-status-wrapper"
          direction={'column'}
          width={'100%'}
          maxWidth={'640px'}
        >
          <Flex direction={'column'} gap={'2'}>
            <Text className="title" mb={'2'}>
              Current Plan - {filterPlanName(currentUser, currentWorkspace)}
            </Text>

            <Text size={'2'}>
              Remaining Credits:{' '}
              {formatNumberWithThousands(
                userRendersData.data?.data[0]?.renders_remaining || 0
              ) || '0'}
              /
              {formatNumberWithThousands(
                userRendersData.data?.data[0]?.total_renders || 0
              ) || '0'}
            </Text>
            <Text size={'2'}>
              Unused Credits roll over as long as you're subscribed.
            </Text>
            {currentWorkspace?.user_id !== 5123 && (
              <Flex
                className="buttons-wrapper"
                direction={'row'}
                align={'center'}
                gap={'3'}
              >
                {currentUser?.subscription_trial.is_available ? (
                  <Button
                    className="action color"
                    variant="solid"
                    onClick={() => {
                      captureEvent('Upgrade your plan modal viewed', {
                        source: 'Api dashboard start free trial button',
                      });
                      dispatch(setIsAppPaymentPopupOpen(true));
                    }}
                  >
                    Start Free Trial
                  </Button>
                ) : (
                  <Button
                    className="action color"
                    variant="solid"
                    onClick={() => navigate('/account')}
                  >
                    Subscription
                  </Button>
                )}

                {(haveTrialProSubscription ||
                  currentUser?.subscription === 'paid') && (
                  <Dialog.Root
                    open={openTopUpDialog}
                    onOpenChange={setOpenTopUpDialog}
                  >
                    <Dialog.Trigger>
                      <Button color="gray" variant="outline">
                        Add one-off credits
                      </Button>
                    </Dialog.Trigger>

                    <Dialog.Content
                      aria-describedby={undefined}
                      style={{ maxWidth: 450 }}
                    >
                      <Flex align={'center'} justify={'between'}>
                        <Dialog.Title mb={'0px'}>
                          Add One-off Credits
                        </Dialog.Title>
                        <Dialog.Close>
                          <Cross1Icon
                            cursor={'pointer'}
                            onClick={() =>
                              captureEvent(' Add one-off renders modal closed')
                            }
                          />
                        </Dialog.Close>
                      </Flex>

                      <Flex direction="column" mt={'40px'} gap="3">
                        <label>
                          <Text size={'1'} color="gray">
                            Add One-off credits for $0.1 per credit
                          </Text>
                          <Flex justify={'between'} gap={'2'}>
                            <TextField.Root
                              style={{ width: '100%' }}
                              type="number"
                              min={5}
                              onChange={(e) =>
                                setTopUpRendersNumber(parseInt(e.target.value))
                              }
                              defaultValue={topUpRendersNumber}
                              placeholder="500"
                            />
                            <Button
                              variant="solid"
                              color="blue"
                              onClick={() => topUpRendersCheckoutRequest()}
                              disabled={
                                !topUpRendersNumber || topUpRendersNumber < 5
                              }
                              loading={isGettingTopUpUrl}
                            >
                              Buy{' '}
                              {!isNaN(topUpRendersNumber as number) &&
                                typeof topUpRendersNumber === 'number' &&
                                `for $` +
                                  Math.round(0.1 * topUpRendersNumber * 100) /
                                    100}
                            </Button>
                          </Flex>
                        </label>
                      </Flex>
                    </Dialog.Content>
                  </Dialog.Root>
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
      ) : (
        <Flex width={'100%'} direction={'column'} maxWidth={'660px'}>
          <Skeleton
            width={'100%'}
            height={'100px'}
            style={{ display: 'flex' }}
          />
        </Flex>
      )}

      <ScrollArea
        className="scroll-area-page-wrapper"
        style={{ maxWidth: '660px' }}
      >
        <Flex
          className="page-content"
          direction={'column'}
          width={'100%'}
          gap={'2'}
          px={'10px'}
        >
          {((apiKeysData.data?.data as any)?.data as ApiKey[])
            ?.slice(0)
            ?.reverse()
            ?.map((key) => (
              <Flex
                key={`api-key-${key.id}`}
                className="full-width-box-item"
                align={'center'}
                justify={'between'}
                gap={'4'}
              >
                <Flex
                  className="content"
                  width={'100%'}
                  gap={'4'}
                  align={'center'}
                >
                  <Flex
                    align={'center'}
                    justify={'center'}
                    width={'40px'}
                    height={'40px'}
                  >
                    <DCLogoPurpleImage className="item-icon" />
                  </Flex>
                  <Flex
                    className="text"
                    direction={'column'}
                    justify={'center'}
                    gap={'1'}
                  >
                    <Text className="title">{key.name}</Text>

                    <Text className="description">
                      {key.api_key.toString().substring(0, 4) +
                        (isMobile ? '*' : '************************') +
                        key.api_key
                          .toString()
                          .substring(
                            key.api_key.length - 4,
                            key.api_key.length
                          )}
                    </Text>
                  </Flex>
                </Flex>
                <Flex className="actions" gap={'1'}>
                  {/* <Button variant="ghost" color="gray" size={'2'}>
                    <TuningLinearIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />{' '}
                    <Text className="button-text">Configure</Text>
                  </Button> */}

                  <Tooltip content="Copy API Key">
                    <Button
                      event-tracker-id="copy-api-key"
                      size={'1'}
                      variant="soft"
                      color="gray"
                      onClick={() => {
                        navigator.clipboard.writeText(key.api_key);
                        toast.success('API Key copied to clipboard.', {
                          toastId: 'api_key_action_error',
                          position: 'bottom-right',
                          autoClose: 1500,
                        });
                      }}
                    >
                      <CopyIcon
                        className="icon"
                        width={'14px'}
                        height={'14px'}
                      />
                    </Button>
                  </Tooltip>
                  <Tooltip content="Edit key name">
                    <Button
                      event-tracker-id="edit-api-key"
                      size={'1'}
                      variant="soft"
                      color="gray"
                      onClick={() => {
                        setOpen(true);
                        setEditedApiKey(key);
                      }}
                    >
                      <PenIcon
                        className="icon"
                        width={'14px'}
                        height={'14px'}
                      />
                    </Button>
                  </Tooltip>

                  <Tooltip content="Analytics">
                    <Button
                      event-tracker-id="open-api-key-analytics"
                      size={'1'}
                      variant="soft"
                      color="gray"
                      onClick={() => {
                        setAnalyticsOpen(true);
                        setEditedApiKey(key);
                        captureEvent('Api analytics modal opened');
                      }}
                    >
                      <BarChartIcon width={'14px'} height={'14px'} />
                    </Button>
                  </Tooltip>
                  <AlertDialog.Root>
                    <Tooltip content="Delete key">
                      <AlertDialog.Trigger>
                        <Button size={'1'} variant="soft" color="gray">
                          <TrashBin
                            className="icon"
                            width={'14px'}
                            height={'14px'}
                          />
                        </Button>
                      </AlertDialog.Trigger>
                    </Tooltip>
                    <AlertDialog.Content style={{ maxWidth: 600 }}>
                      <AlertDialog.Title>
                        Delete API Integration
                      </AlertDialog.Title>
                      <AlertDialog.Description size="2">
                        Are you sure you want to delete API Integration used for
                        '{key.name}'?
                      </AlertDialog.Description>

                      <Flex gap="3" mt="4" justify="end">
                        <AlertDialog.Cancel>
                          <Button variant="soft" color="gray">
                            Cancel
                          </Button>
                        </AlertDialog.Cancel>
                        <AlertDialog.Cancel>
                          <Button
                            event-tracker-id="delete-api-key"
                            variant="solid"
                            color="red"
                            onClick={() => deleteKeyAction(key.uuid)}
                          >
                            Delete
                          </Button>
                        </AlertDialog.Cancel>
                      </Flex>
                    </AlertDialog.Content>
                  </AlertDialog.Root>
                </Flex>
              </Flex>
            ))}

          {apiKeysData.isLoading && (
            <Flex direction={'column'} gap={'1'}>
              <Skeleton style={{ height: '80px' }}></Skeleton>
              <Skeleton style={{ height: '80px' }}></Skeleton>
            </Flex>
          )}

          {!apiKeysData.isLoading &&
            ((apiKeysData.data?.data as any)?.data || []).length === 0 && (
              <Flex
                align={'center'}
                justify={'center'}
                p={'3'}
                width={'100%'}
                height={'100%'}
              >
                <Text size={'1'}>There are no active API Integrations</Text>
              </Flex>
            )}
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

