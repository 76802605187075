import { Box, Button, Flex, Text, Tooltip } from '@radix-ui/themes';
import './create-mockups.scss';
import { ReactComponent as ArrowLeft } from '@/assets/icons/arrow-left.svg';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ChooseDesignOrMockup } from './components/choose-design-or-mockup/ChooseDesignOrMockup';
import { ChooseDesign } from './components/choose-design/ChooseDesign';
import { ChooseMockup } from './components/choose-mockup/ChooseMockup';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { mockupBundlesApi } from '@/services/mockupBundles';
import { LoadingCreatingBatch } from './components/LoadingCreatingBatch';
import { Cross1Icon } from '@radix-ui/react-icons';
import {
  removeSelectedPsdId,
  removeSelectedTemplateId,
  setCreateBundleRestTempData,
} from '@/redux/slices/mockupBundles';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import Stepper from 'react-stepper-horizontal';
import { toast } from 'react-toastify';

export const CreateMockups = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    designs,
    myTemplates,
    publicMockups,
    selectedDesignIds,
    selectedPsdIds,
    selectedTemplateIds,
  } = useAppSelector((state) => state.mockupBundlesReducer);
  const { themeMode } = useAppSelector((state) => state.appReducer);
  const captureEvent = useCaptureEvent();
  const [step, setStep] = useState<1 | 2 | 3 | 4>(1);
  const [stepName, setStepName] = useState<'choose' | 'mockups' | 'designs'>(
    'choose'
  );

  const [createMockupBundle, { isLoading: isCreatingBatch }] =
    mockupBundlesApi.useCreateMockupBundleMutation();

  mockupBundlesApi.useGetMyProjectsQuery(null!, {
    skip: !!myTemplates.length,
    refetchOnMountOrArgChange: true,
  });
  mockupBundlesApi.useGetPublicCatalogsQuery(null!, {
    skip: !!publicMockups.length,
    refetchOnMountOrArgChange: true,
  });

  mockupBundlesApi.useGetDesignsQuery(null!, {
    skip: !!designs.length,
    refetchOnMountOrArgChange: true,
  });

  const getNextButtonText = () => {
    if (step === 2) {
      return 'Next';
    }

    if (step === 3) {
      return 'Create Batch';
    }
  };

  const getPreviousButtonText = () => {
    if (step === 1) {
      return 'Home';
    }

    if (step === 2 || step === 3) {
      return 'Previous';
    }
  };

  const goToNextAction = () => {
    if (stepName === 'designs' && step === 2) {
      captureEvent('Create Mockups: Designs selected', {
        designsCount: selectedDesignIds.length,
      });
      setStepName('mockups');

      setStep(3);
    }
    if (stepName === 'designs' && step === 3) {
      captureEvent('Create Mockups: Designs selected', {
        designsCount: selectedDesignIds.length,
      });
      createBatch();
    }

    if (stepName === 'mockups' && step === 2) {
      captureEvent('Create Mockups: Mockups selected', {
        pubnlicLibraryMockupsCount: selectedPsdIds.length,
        templatesCount: selectedTemplateIds.length,
      });
      setStepName('designs');
      setStep(3);
    }
    if (stepName === 'mockups' && step === 3) {
      captureEvent('Create Mockups: Mockups selected', {
        pubnlicLibraryMockupsCount: selectedPsdIds.length,
        templatesCount: selectedTemplateIds.length,
      });
      createBatch();
    }
  };

  const groupArray = (
    arr: number[],
    size: number,
    forceSingleItems: boolean
  ): number[][] => {
    if (arr.length === 0) {
      return []; // Return empty array for empty input
    }

    if (forceSingleItems) {
      return arr.map((num) => [num]); // Each item in its own array
    }

    return arr.reduce<number[][]>((acc, _, i) => {
      if (i % size === 0) acc.push(arr.slice(i, i + size));
      return acc;
    }, []);
  };

  const calculateBatchSize = (
    selectedPsdIds: number[],
    selectedTemplateIds: number[]
  ) => {
    let firstId: number = 0;
    let firstIdType: 'psdId' | 'templateId' | undefined;
    let restPsdIds: number[] = [...selectedPsdIds];
    let restTemplateIds: number[] = [...selectedTemplateIds];

    // Extract the first ID (either from psd or template)
    if (selectedPsdIds.length > 0) {
      firstId = restPsdIds.shift()!; // Remove and get the first element
      firstIdType = 'psdId';
    } else if (selectedTemplateIds.length > 0) {
      firstId = restTemplateIds.shift()!; // Remove and get the first element
      firstIdType = 'templateId';
    }

    // Calculate total remaining items after removing the first ID
    const totalRemainingCount = restPsdIds.length + restTemplateIds.length;

    // Determine if we should force single-item arrays
    const forceSingleItems = totalRemainingCount < 5;

    // Determine group size
    const groupSize = 3;

    // Group the arrays, with special handling for small total counts
    const groupedPsdIds = groupArray(restPsdIds, groupSize, forceSingleItems);
    const groupedTemplateIds = groupArray(
      restTemplateIds,
      groupSize,
      forceSingleItems
    );

    return { firstId, firstIdType, groupedPsdIds, groupedTemplateIds };
  };

  const createBatch = async () => {
    const { firstId, firstIdType, groupedPsdIds, groupedTemplateIds } =
      calculateBatchSize(selectedPsdIds, selectedTemplateIds);

    try {
      setStep(4);

      const bundle = await createMockupBundle({
        psd_ids: firstIdType === 'psdId' ? [firstId] : [],
        mockup_ids: firstIdType === 'templateId' ? [firstId] : [],
        design_ids: selectedDesignIds,
      }).unwrap();

      dispatch(
        setCreateBundleRestTempData({
          status: 'start',
          numberOfIds: selectedPsdIds.length + selectedTemplateIds.length - 1,
          createdBundleUUID: bundle.data.mockup_bundle.uuid,
          selectedDesignIds: selectedDesignIds,
          restGroupedPsdIds: groupedPsdIds,
          restGroupedTemplateIds: groupedTemplateIds,
        })
      );
      captureEvent('Create Mockups: Batch created', {
        pubnlicLibraryMockupsCount: selectedPsdIds.length,
        designsCount: selectedDesignIds.length,
        templatesCount: selectedTemplateIds.length,
        batchUUID: bundle?.data?.mockup_bundle?.uuid,
        batchMockupsPsdIds:
          bundle?.data?.mockups?.map((mockup) => mockup?.psd_id) || [],
      });
      navigate(
        `/batch/${bundle.data.mockup_bundle.uuid}/mockup/${bundle.data.mockups[0].uuid}/smart-object/${bundle.data.mockups[0].smart_objects[0].uuid}`
      );
    } catch (error: any) {
      const status =
        error?.status || error?.response?.status || 'Unknown Status';

      console.log(error);
      toast.error(error?.data?.message, {
        toastId: 'create_batch_error',
        position: 'bottom-right',
        autoClose: 3000,
      });
      captureEvent('Batch create failed', {
        errorMessage: error?.data?.message,
        errorStatus: status,
      });
    }
  };

  const getSelectedTemlpates = () => {
    return myTemplates.filter((template) =>
      selectedTemplateIds.includes(template.id)
    );
  };
  const getSelectedPublicMockups = () => {
    return publicMockups.filter((mockup) => selectedPsdIds.includes(mockup.id));
  };

  return step === 4 ? (
    <LoadingCreatingBatch />
  ) : (
    <Flex
      className="create-mockups-page-wrapper"
      direction={'column'}
      justify={'between'}
    >
      <Flex
        className="header"
        justify={'between'}
        align={'center'}
        gap={'4'}
        py={'2'}
        px={'4'}
        pl={'5'}
      >
        <Flex className="back-wrapper" gap={'3'} align={'center'}>
          <Button
            variant="soft"
            color="gray"
            size={'2'}
            onClick={() => {
              if (step === 1) {
                navigate(`/my-templates`);
              }
              if (step === 2) {
                setStepName('choose');
                setStep(1);
              }
              if (stepName === 'designs' && step === 3) {
                setStepName('mockups');
                setStep(2);
              }

              if (stepName === 'mockups' && step === 3) {
                setStepName('designs');
                setStep(2);
              }
            }}
          >
            <ArrowLeft width="14px" height="14px" className="icon" />{' '}
            <Text weight={'medium'} size={'2'}>
              {getPreviousButtonText()}
            </Text>
          </Button>
        </Flex>
        {/* <Text weight={'medium'} size={'2'}>
          {step}/3
        </Text> */}
        <Flex
          className="stepper"
          width={'30%'}
          align={'center'}
          justify={'center'}
        >
          <Stepper
            steps={[{}, {}, {}]}
            activeStep={step - 1}
            activeColor={'var(--blue-9)'}
            completeColor={
              themeMode === 'dark' ? 'var(--blue-6)' : 'var(--blue-12)'
            }
            defaultColor={'var(--slate-6)'}
            size={28}
            circleFontSize={14}
            titleFontSize={12}
            circleTop={0}
            titleTop={4}
          />
        </Flex>

        {(step === 2 || step === 3) && (
          <Flex className="selected-mockups">
            {getSelectedPublicMockups().map((mockup) => (
              <Flex key={mockup.id} className="mockup">
                <img src={mockup.thumbnail} />
                <Flex className="content-wrapper">
                  <Tooltip content="Remove Mockup">
                    <Button
                      className="remove-button"
                      size={'1'}
                      color="red"
                      variant="surface"
                      onClick={() => dispatch(removeSelectedPsdId(mockup.id))}
                    >
                      <Cross1Icon width={'10px'} height={'10px'} />
                    </Button>
                  </Tooltip>
                  <img src={mockup.thumbnail} />
                </Flex>
              </Flex>
            ))}
            {getSelectedTemlpates().map((template) => (
              <Flex key={template.id} className="mockup">
                <img src={template.thumbnail} />
                <Flex className="content-wrapper">
                  <Tooltip content="Remove Mockup">
                    <Button
                      className="remove-button"
                      size={'1'}
                      color="red"
                      variant="surface"
                    >
                      <Cross1Icon
                        width={'10px'}
                        height={'10px'}
                        onClick={() =>
                          dispatch(removeSelectedTemplateId(template.id))
                        }
                      />
                    </Button>
                  </Tooltip>
                  <img src={template.thumbnail} />
                </Flex>
              </Flex>
            ))}
          </Flex>
        )}
        {step === 2 || step === 3 ? (
          <Button
            disabled={
              (stepName === 'designs' && selectedDesignIds.length === 0) ||
              (stepName === 'mockups' &&
                selectedPsdIds.length === 0 &&
                selectedTemplateIds.length === 0)
            }
            onClick={() => goToNextAction()}
          >
            <Text weight={'medium'} size={'2'}>
              {getNextButtonText()}
            </Text>
          </Button>
        ) : (
          <Box></Box>
        )}
      </Flex>
      <Flex className="content">
        {step === 1 && stepName === 'choose' && (
          <ChooseDesignOrMockup setStep={setStep} setStepName={setStepName} />
        )}
        {stepName === 'designs' && (
          <ChooseDesign setStep={setStep} setStepName={setStepName} />
        )}
        {stepName === 'mockups' && <ChooseMockup />}
      </Flex>

      {/* <CreateMockupsFooter /> */}
    </Flex>
  );
};
