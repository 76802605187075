import './view-wrapper.scss';
import { Box } from '@radix-ui/themes';
import { pageSlug } from '../../Workspace';
import { WorkspaceSettings } from '../workspace-settings/WorkspaceSettings';
import { MockupCatalog } from '../catalog/MockupCatalog';
import { MockupTemplates } from '../mockup-templates/MockupTemplates';
import { Designs } from '../designs/Designs';
import { useEffect } from 'react';
import { resetCollectionState } from '@/redux/slices/collection';
import { useAppDispatch } from '@/hooks';
import { resetEditorState } from '@/redux/slices/editor';
import { ApiDashboard } from '../api-dashboard/ApiDashboard';
import { Integrations } from '../integrations/Integrations';
import { Shop } from '../shop/Shop';
import { Products } from '../products/Products';
import { Product } from '../product/Product';
import { CustomMockups } from '../custom-mockups/CustomMockups';
import { resetAiBackgroundsState } from '@/redux/slices/aiBackgrounds';
import { MockupEditorEmbedIntegrations } from '../mockup-editor-embed-integrations/MockupEditorEmbedIntegrations';
import { MockupBundles } from '../mockup-bundles/MockupBundles';
import { resetMockupBundlesState } from '@/redux/slices/mockupBundles';
import { Help } from '../help/Help';
import { BrandAsssets } from '../brand-assets/BrandAssets';

interface IViewWrapper {
  activeSection: pageSlug;
}

export const ViewWrapper = ({ activeSection }: IViewWrapper) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetCollectionState());
    dispatch(resetEditorState());
    dispatch(resetAiBackgroundsState());
    dispatch(resetMockupBundlesState());
  }, []);

  return (
    <Box className="view-wrapper">
      {activeSection === 'custom-mockups' && <CustomMockups />}
      {activeSection === 'my-templates' && <MockupTemplates />}
      {activeSection === 'mockup-library' && <MockupCatalog />}
      {activeSection === 'account' && <WorkspaceSettings />}
      {activeSection === 'dashboard-api' && <ApiDashboard />}
      {activeSection === 'designs' && <Designs />}
      {activeSection === 'integrations' && <Integrations />}
      {activeSection === 'shop' && <Shop />}
      {activeSection === 'shop-products' && <Products />}
      {activeSection === 'product' && <Product />}
      {activeSection === 'mockup-editor-embed-integrations' && (
        <MockupEditorEmbedIntegrations />
      )}
      {activeSection === 'mockup-bundles' && <MockupBundles />}
      {activeSection === 'help' && <Help />}
      {activeSection === 'brand-assets' && <BrandAsssets />}
    </Box>
  );
};
