import '../../../components/catalog/components/view-wrapper/view-wrapper.scss';
import { Flex, Grid, ScrollArea } from '@radix-ui/themes';
import { workspaceApi } from '@/services/workspace';
import Skeleton from 'react-loading-skeleton';
import { SingleMockup } from './single-mockup/SingleMockup';

interface ITemplatestList {
  collectionId: number;
}

export const TemplatestList = ({ collectionId = 0 }: ITemplatestList) => {
  const geyAllMyProjects = workspaceApi.useGetMyProjectsQuery(
    { collectionId: collectionId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <Flex
      className="list-wrapper"
      direction={'column'}
      height={'100%'}
      gap={'5'}
    >
      <ScrollArea className="scroll-area-page-wrapper">
        <Grid className="view-wrapper" gap={'2'} p={'2px'} pr={'10px'}>
          {geyAllMyProjects.isLoading
            ? [...Array(12)].map((item, index) => (
                <Skeleton
                  key={`${index}-item`}
                  className="single-template"
                  height={'250px'}
                />
              ))
            : geyAllMyProjects.data?.data
                ?.slice(0)
                ?.reverse()
                ?.map((template) => (
                  <SingleMockup key={template.id} myTemplate={template} />
                ))}
        </Grid>
      </ScrollArea>
    </Flex>
  );
};
