import { Flex } from '@radix-ui/themes';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './view-wrapper.scss';
import { MainPreview } from './components/MainPreview';
import { useAppSelector } from '@/hooks';
import { useMediaQuery } from 'react-responsive';
import { AiBackgroundPreview } from './components/AiBackgroundPreview';

interface IViewWrapper {}

export const ViewWrapper = ({}: IViewWrapper) => {
  const { activeView } = useAppSelector((state) => state.editorReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Flex
      className="view-wrapper"
      direction={'column'}
      gap={'4'}
      justify={'between'}
    >
      {activeView === 'preview' && <MainPreview />}

      {activeView === 'create-ai-background' && <AiBackgroundPreview />}
    </Flex>
  );
};
