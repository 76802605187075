import '../../../components/catalog/components/view-wrapper/view-wrapper.scss';
import { Button, Flex, Grid, ScrollArea, Text } from '@radix-ui/themes';
import Skeleton from 'react-loading-skeleton';
import { UploadPsdDialog } from '../../../components/custom-mockups/components/UploadPsdDialog';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { useEffect, useRef, useState } from 'react';
import { PrivatePhotoshopFile } from '@/services/types';
import { ReactComponent as PhotoshopIcon } from '@/assets/icons/photoshop-linear.svg';
import { PhotoshopFilesSingleTemplate } from '../../../components/custom-mockups/components/view-wrapper/PhotoshopFilesSingleTemplate';

interface ITemplatestList {
  collectionId: number;
  getPrivatePsds: {
    isLoading: boolean;
    privatePsdsData: PrivatePhotoshopFile[] | undefined;
  };
}

export const TemplatestList = ({
  collectionId = 0,
  getPrivatePsds,
}: ITemplatestList) => {
  const uploadPsdDialog = useRef();
  const captureEvent = useCaptureEvent();

  const [privatePsds, setPrivatePsds] = useState<
    PrivatePhotoshopFile[] | undefined
  >([]);

  useEffect(() => {
    if (collectionId === 0) {
      const data = getPrivatePsds.privatePsdsData?.filter(
        (psd) => psd.psd_category_id === 6
      );
      setPrivatePsds(data);
    } else if (collectionId === 6) {
      const data = getPrivatePsds.privatePsdsData?.filter(
        (psd) => psd.psd_category_id === 6
      );
      setPrivatePsds(data);
    } else {
      const data = getPrivatePsds.privatePsdsData?.filter(
        (psd) => psd.psd_category_id === collectionId
      );
      setPrivatePsds(data);
    }
  }, [collectionId, getPrivatePsds.privatePsdsData]);

  return (
    <Flex
      className="list-wrapper"
      direction={'column'}
      height={'100%'}
      gap={'5'}
    >
      <UploadPsdDialog ref={uploadPsdDialog} />
      <Flex className="header" align={'center'} justify={'between'}>
        <Text weight={'bold'} size={'5'}>
          Custom Mockups
        </Text>

        <Button
          event-tracker-id="upload-psd-button"
          onClick={() => {
            (uploadPsdDialog?.current as any)?.openDialog();
            captureEvent('Upload PSD Mockup modal viewed');
          }}
        >
          <PlusIcon width={'20px'} height={'20px'} className="icon white" />
          <Text size="1" weight="medium">
            Upload PSD Mockup
          </Text>
        </Button>
      </Flex>
      <ScrollArea className="scroll-area-page-wrapper">
        <Grid className="view-wrapper" gap={'2'} pr={'10px'}>
          {getPrivatePsds.isLoading
            ? [...Array(12)].map((item, index) => (
                <Skeleton
                  key={`${index}-item`}
                  className="single-template"
                  height={'250px'}
                />
              ))
            : privatePsds
                ?.slice(0)
                ?.reverse()
                ?.map((privatePsd) => (
                  <PhotoshopFilesSingleTemplate
                    key={privatePsd.psd_id}
                    privatePsd={{
                      psd_id: privatePsd.psd_id,
                      thumbnail: privatePsd.thumbnail,
                      name: privatePsd.name,
                      psd_category_id: privatePsd.psd_category_id,
                      psd_url: privatePsd.psd_url,
                      psd_availability_id: privatePsd.psd_availability_id,
                      subscription_type_id: privatePsd.subscription_type_id,
                      is_ai_mockup: privatePsd.is_ai_mockup,
                      source_attribution: privatePsd.source_attribution,
                    }}
                  />
                ))}
        </Grid>

        {!getPrivatePsds.isLoading &&
          getPrivatePsds.privatePsdsData?.length === 0 && (
            <Flex
              direction={'column'}
              align={'center'}
              justify={'center'}
              gap={'4'}
              className="empty-page-state"
            >
              <Flex align={'center'} justify={'center'} className="cercle-wrap">
                <PhotoshopIcon className="svg-icon photoshop" />
              </Flex>
              <Text size="2" weight="regular" align={'center'}>
                Upload your custom Photoshop Template and bulk create Mockups
              </Text>

              <Button
                event-tracker-id="upload-psd-button"
                onClick={() => {
                  (uploadPsdDialog?.current as any)?.openDialog();
                  captureEvent('Upload PSD Mockup modal viewed');
                }}
              >
                <PlusIcon
                  width={'20px'}
                  height={'20px'}
                  className="icon white"
                />

                <Text size="1" weight="medium">
                  Upload PSD Mockup
                </Text>
              </Button>
            </Flex>
          )}
      </ScrollArea>
    </Flex>
  );
};
