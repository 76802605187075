import './view-wrapper.scss';
import {
  Button,
  Flex,
  Text,
  Dialog,
  TextField,
  Skeleton,
} from '@radix-ui/themes';
import { useState } from 'react';
import { PlusIcon } from '@radix-ui/react-icons';
import { useAppDispatch, useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { colorsApi } from '@/services/colors';
import { toast } from 'react-toastify';
import { ReactComponent as PalleteIcon } from '@/assets/icons/pallete-2-linear.svg';
import { SingleColorSet } from './SingleColorSet';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import { useFeatureFlagEnabled } from 'posthog-js/react';

export interface IColorSets {
  isLoadingColorSets: boolean;
}

export const ColorSets = ({ isLoadingColorSets }: IColorSets) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const { colorSets } = useAppSelector((state) => state.colorSetsReducer);

  const [colorSetName, setColorSetName] = useState<string>('');
  const hasColorPresets = useFeatureFlagEnabled('color-presets');

  const [createColorSet, { isLoading: isCreating }] =
    colorsApi.useCreateColorSetMutation();

  const createColorSetAction = async () => {
    try {
      await createColorSet({
        name: colorSetName,
      }).unwrap();
      captureEvent('User Created Color Set', {
        name: colorSetName,
      });
      await dispatch(colorsApi.endpoints.getColorSets.initiate()).unwrap();
    } catch (error) {
      console.log('error: ', error);
      toast.warning((error as unknown as any).data.message, {
        toastId: 'color_set_action_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  return (
    <Flex
      className={`view-wrapper colors-wrapper`}
      gap={'4'}
      px={'6'}
      height={'100%'}
    >
      {(!colorSets || colorSets?.length === 0) && !isLoadingColorSets ? (
        <Flex
          direction={'column'}
          align={'center'}
          justify={'center'}
          gap={'4'}
          className="empty-page-state"
        >
          <Flex align={'center'} justify={'center'} className="cercle-wrap">
            <PalleteIcon className="svg-icon" width={'30px'} height={'30px'} />
          </Flex>
          <Text size="2" weight="regular" align={'center'}>
            Store your Color Sets in a central place so you can reuse them in
            various Mockups
          </Text>

          <Dialog.Root>
            <Dialog.Trigger>
              <Button
                variant="solid"
                onClick={(e) => {
                  if (!hasColorPresets) {
                    e.preventDefault();
                    captureEvent('Upgrade your plan modal viewed', {
                      source: 'New Color Set',
                    });
                    dispatch(setIsAppPaymentPopupOpen(true));
                  }
                }}
              >
                <PlusIcon className="icon white" />
                <Text size="1" weight="medium">
                  New Color Set
                </Text>
              </Button>
            </Dialog.Trigger>

            <Dialog.Content maxWidth="450px">
              <Dialog.Title>Create new Color Set</Dialog.Title>

              <Flex direction="column" gap="3">
                <label>
                  <TextField.Root
                    onChange={(e) => setColorSetName(e.target.value)}
                    value={colorSetName}
                    placeholder="Color Set Name"
                  />
                </label>
              </Flex>

              <Flex gap="3" mt="4" justify="end">
                <Dialog.Close>
                  <Button variant="soft" color="gray">
                    Cancel
                  </Button>
                </Dialog.Close>
                <Dialog.Close>
                  <Button
                    variant="solid"
                    color="blue"
                    onClick={() => createColorSetAction()}
                  >
                    Create
                  </Button>
                </Dialog.Close>
              </Flex>
            </Dialog.Content>
          </Dialog.Root>
        </Flex>
      ) : (
        <Flex
          direction={'column'}
          gap={'4'}
          height={'100%'}
          width={'100%'}
          align={'center'}
        >
          {isLoadingColorSets
            ? [...Array(4)].map((item, index) => (
                <Skeleton
                  key={`${index}-item`}
                  className="single-color-set skeleton"
                />
              ))
            : colorSets?.map((colorSet, index) => (
                <SingleColorSet key={colorSet.id} colorSet={colorSet} />
              ))}
        </Flex>
      )}
    </Flex>
  );
};
