import { Helmet } from 'react-helmet-async';
import './product.scss';
import { Box, Button, Flex, ScrollArea, Text } from '@radix-ui/themes';
import { LeftSideMenu } from '../left-side-menu/LeftSideMenu';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { pageSlug } from '../../Workspace';
import { SyncImages } from './components/SyncImages';
import { shopsProductsApi } from '@/services/shopsProducts';
import { ProductImage } from '@/services/types';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { toast } from 'react-toastify';
import { resetProductListingState } from '@/redux/slices/shopsProducts';
import { shopsIntegrationsApi } from '@/services/shopsIntegrations';
import useCaptureEvent from '@/hooks/useCaptureEvent';

export const Product = () => {
  const { shopId, productId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const captureEvent = useCaptureEvent();
  const [activeSection, setActiveSection] = useState<pageSlug>('shop-products');
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { productListing } = useAppSelector(
    (state) => state.shopsProductsReducer
  );
  const { activeIntegrationDetails } = useAppSelector(
    (state) => state.shopsIntegrations
  );

  const [productImages, setProductImages] = useState<ProductImage[]>([]);
  const [isSyncing, setIsSyncing] = useState(false);

  shopsIntegrationsApi.useGetActiveIntegrationDetailsQuery(
    {
      id: parseInt(shopId || ''),
    },
    {
      skip: !shopId,
      refetchOnMountOrArgChange: true,
    }
  );
  shopsProductsApi.useGetProductListingQuery(
    {
      listing_id: parseInt(productId as string),
      shop_integration_platform_id: parseInt(shopId as string),
    },
    {
      skip: !productId,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    dispatch(resetProductListingState());
    setProductImages([]);
  }, []);

  useEffect(() => {
    setActiveSection(location.pathname.split('/')[1] as pageSlug);
  }, [location.pathname]);

  const syncImages = async () => {
    try {
      setIsSyncing(true);
      captureEvent('Product Sync Initiated', {
        type: 'Etsy',
        shop_name: activeIntegrationDetails?.shop_name,
        shop_url: activeIntegrationDetails?.shop_url,
        product_info: productListing.data.details,
        send_images_count: productImages.filter((img) => img.image_url).length,
      });
      await dispatch(
        shopsProductsApi.endpoints.syncImages.initiate(
          {
            productImages: productImages,
            listing_id: parseInt(productId as string),
            shop_integration_platform_id: parseInt(shopId as string),
          },
          {
            forceRefetch: true,
          }
        )
      ).unwrap();
      captureEvent('Product Sync Successful', {
        type: 'Etsy',
        shop_name: activeIntegrationDetails?.shop_name,
        shop_url: activeIntegrationDetails?.shop_url,
        product_info: productListing.data.details,
        send_images_count: productImages.filter((img) => img.image_url).length,
      });
      await dispatch(
        shopsProductsApi.endpoints.getProductListing.initiate(
          {
            listing_id: parseInt(productId as string),
            shop_integration_platform_id: parseInt(shopId as string),
          },
          {
            forceRefetch: true,
          }
        )
      ).unwrap();
      toast.success(`Product images successfully synced.`, {
        toastId: 'successfull_sync',
        position: 'bottom-right',
        autoClose: 5000,
        closeOnClick: false,
      });
      setIsSyncing(false);
      if (
        productListing.data.details.state === 'active' &&
        productListing.data.details.url
      ) {
        const anchorElement = document.createElement('a');
        anchorElement.target = '_blank';
        anchorElement.href = productListing.data.details.url;
        document.body.appendChild(anchorElement);
        anchorElement.click();
        setTimeout(() => {
          if (document.body.contains(anchorElement)) {
            document.body.removeChild(anchorElement);
          }
        }, 100);
      }
    } catch (error) {
      captureEvent('Product Sync Failed', {
        type: 'Etsy',
        shop_name: activeIntegrationDetails?.shop_name,
        shop_url: activeIntegrationDetails?.shop_url,
        product_info: productListing.data.details,
        send_images_count: productImages.filter((img) => img.image_url).length,
        error: error,
      });
    }
  };

  return (
    <Flex direction={isMobile ? 'column' : 'row'} className="workspace-wrapper">
      <LeftSideMenu activeSection={activeSection} />

      <Box className="view-wrapper">
        <Flex
          className="page-wrapper shop-page"
          direction={'column'}
          align={'center'}
          gap={'5'}
        >
          <Helmet>
            <title>Dynamic Mockups | Product</title>
            <meta name="robots" content="noindex, nofollow" />
          </Helmet>
          <Flex
            className="header"
            align={'center'}
            justify={'between'}
            width={'100%'}
          >
            <Flex>
              <Text
                weight={'regular'}
                size={'5'}
                onClick={() => navigate(`/shop-products/${shopId}`)}
                className="back"
                style={{ cursor: 'pointer' }}
              >
                Products
              </Text>
              <Text weight={'regular'} size={'5'} color="gray" mx={'3'}>
                {'>'}
              </Text>
              <Text weight={'bold'} size={'5'}>
                {productListing.data.details?.title}
              </Text>
            </Flex>

            <Button onClick={() => syncImages()} loading={isSyncing}>
              <Text size="1" weight="medium">
                Sync Images
              </Text>
            </Button>
          </Flex>

          <Flex
            className="page-content"
            direction={'column'}
            width={'100%'}
            gap={'2'}
            px={'10px'}
          >
            <Flex
              direction={'row'}
              align={'center'}
              justify={'start'}
              width={'100%'}
              gap={'2'}
              wrap={'wrap'}
              className="items-card-wrapper"
            >
              <SyncImages setProductImages={setProductImages} />
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
