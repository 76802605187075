import '../../../components/catalog/components/view-wrapper/view-wrapper.scss';
import { Flex, Grid, ScrollArea } from '@radix-ui/themes';
import { workspaceApi } from '@/services/workspace';
import Skeleton from 'react-loading-skeleton';
import { SingleMockup } from './single-mockup/SingleMockup';

interface IMockupsList {
  categoryId: number;
}

export const MockupsList = ({ categoryId = 0 }: IMockupsList) => {
  const publicCatalogs = workspaceApi.useGetPublicCatalogsQuery(
    {
      categoryId,
    },
    { refetchOnMountOrArgChange: true }
  );

  return (
    <Flex
      className="list-wrapper"
      direction={'column'}
      height={'100%'}
      gap={'5'}
    >
      <ScrollArea className="scroll-area-page-wrapper">
        <Grid className="view-wrapper" gap={'2'} pr={'10px'}>
          {publicCatalogs.isLoading
            ? [...Array(12)].map((item, index) => (
                <Skeleton
                  key={`${index}-item`}
                  className="single-template"
                  height={'250px'}
                />
              ))
            : publicCatalogs.data?.data
                ?.slice(0)
                ?.reverse()
                ?.map((catalog) => (
                  <SingleMockup key={catalog.id} publicMockup={catalog} />
                ))}
        </Grid>
      </ScrollArea>
    </Flex>
  );
};
