import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  setActiveDesignAsset,
  setActiveVariation,
} from '@/redux/slices/collection';
import {
  Text,
  Flex,
  Button,
  AlertDialog,
  Box,
  Tooltip,
  IconButton,
  ScrollArea,
} from '@radix-ui/themes';
import { useState, useEffect } from 'react';
import { setActiveView, setActiveAction } from '@/redux/slices/editor';
import { psdEngineApi } from '@/services/psdEngine';
import Skeleton from 'react-loading-skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { toast } from 'react-toastify';
import { ReactComponent as MinusLinearIcon } from '@/assets/icons/minus-linear.svg';
import { ReactComponent as AddLinearIcon } from '@/assets/icons/add-linear.svg';
import '../../../toolbar/accordion.scss';

export const AiBackgrounds = ({}: {}) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const {
    designAssets,
    activeDesignAsset,
    activeSmartObject,
    activeVariation,
    mockupVariations,
    isCreatingVariations,
    isExportingMockups,
  } = useAppSelector((state) => state.collectionReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [numberOfDesignAssetsAdding, setNumberOfDesignAssetsAdding] =
    useState<number>(0);

  useEffect(() => {
    const activeVariationAssetId = activeVariation?.variation_elements.find(
      (ve) => ve.smart_object_id === activeSmartObject?.id
    )?.asset_id;

    dispatch(
      setActiveDesignAsset(
        designAssets[activeSmartObject?.id]?.find(
          (asset) => asset.id === activeVariationAssetId
        )
      )
    );
  }, [activeVariation, activeSmartObject, designAssets]);

  const changeActiveDesignAsset = (id: number) => {
    if (isCreatingVariations) {
      return;
    }

    const variation = mockupVariations.find((mv) =>
      mv.variation_elements.find((ve) => ve?.asset_id === id)
    );

    dispatch(setActiveVariation(variation));
  };

  const [removeAsset, { isLoading: isDeletingAsset }] =
    psdEngineApi.useDeleteAssetVariationMutation();

  const [isAdding, setIsAdding] = useState<boolean>(false);

  const getNumerOfAssociatedVariations = (id: number) => {
    const associatedVariations = mockupVariations.filter((variation) => {
      return variation.variation_elements.some(
        (element) => element.asset_id === id
      );
    });

    return associatedVariations.length;
  };

  const removeDesignAsset = async (id: number) => {
    try {
      await removeAsset({ id }).unwrap();
      captureEvent('Ai background deleted', {
        id,
      });
    } catch (error: any) {
      toast.error(error.data.message, {
        toastId: 'remove_design_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  const canDeleteDesignAsset = (id: number) => {
    let isGeneratingSingleMockupVariation = false;
    mockupVariations.forEach((variation) => {
      variation.variation_elements.find((ve) => {
        if (
          ve.asset_id === id &&
          typeof variation.isGettingSingleMockupVariation !== 'undefined'
        ) {
          isGeneratingSingleMockupVariation =
            variation.isGettingSingleMockupVariation;
        }
      });
    });
    return !isGeneratingSingleMockupVariation && !isDeletingAsset;
  };

  return (
    <Flex className="design-assets" direction={'column'} align={'start'}>
      <Flex
        className="asset-items"
        wrap={'wrap'}
        direction={'column'}
        width={'100%'}
      >
        {!isMobile && designAssets?.[activeSmartObject?.id]?.length > 0 ? (
          <Flex
            className="text-header"
            align={'center'}
            justify={'between'}
            mb={'1'}
          >
            <Text size={'2'}>✨ AI Scenes</Text>
            <IconButton
              className="add-artwork-button"
              variant={'ghost'}
              size={'1'}
              color={'gray'}
              onClick={() => {
                dispatch(setActiveView('create-ai-background'));
                dispatch(setActiveAction('add'));
                captureEvent('Ai modal modal viewed');
              }}
            >
              <AddLinearIcon
                width="28px"
                height="28px"
                className={`gray add icon`}
              />
            </IconButton>
          </Flex>
        ) : (
          !isMobile && (
            <Flex
              className="text-header"
              align={'center'}
              justify={'between'}
              mb={'1'}
            >
              <Text size={'2'}>✨ AI Scenes</Text>
              <Box></Box>
            </Flex>
          )
        )}

        {!isAdding &&
          designAssets?.[activeSmartObject?.id]?.length === 0 &&
          [...Array(1)].map((_, index) => (
            <Button
              className="new"
              variant="surface"
              color="gray"
              onClick={() => {
                dispatch(setActiveView('create-ai-background'));
                dispatch(setActiveAction('add'));
                captureEvent('Ai modal modal viewed');
              }}
            >
              <PlusIcon width={'24px'} height={'24px'} className="icon" />
            </Button>
          ))}

        {!isMobile &&
          designAssets[activeSmartObject.id]
            ?.filter((da) => da.is_ai_generated)
            ?.map((da) => (
              <Flex key={da.id} gap={'2'} align={'center'}>
                <Flex
                  className={`asset-item ${
                    da.id === activeDesignAsset?.id && 'active'
                  }`}
                  align={'center'}
                  justify={'between'}
                  id="asset-item"
                  p={'2'}
                  gap={'2'}
                  onClick={() => changeActiveDesignAsset(da.id)}
                >
                  <Flex gap={'2'} align={'center'}>
                    <LazyLoadImage
                      className="asset"
                      style={{
                        objectFit: 'contain',
                      }}
                      effect="blur"
                      src={da.asset_path}
                      delayTime={0}
                      placeholderSrc={da.asset_path}
                      wrapperProps={{
                        style: { transitionDelay: '0s' },
                      }}
                    />
                    <Tooltip content={da.name}>
                      <Text
                        size={'1'}
                        weight={'regular'}
                        className="asset-name"
                      >
                        {da.name}
                      </Text>
                    </Tooltip>
                  </Flex>
                </Flex>
                <Flex gap={'4'} align={'center'} className="actions">
                  <AlertDialog.Root>
                    <AlertDialog.Trigger>
                      <IconButton
                        className="remove-asset-button"
                        variant={'ghost'}
                        size={'1'}
                        color="gray"
                        onClick={(e) => e.stopPropagation()}
                        loading={
                          !canDeleteDesignAsset(da.id) ||
                          isCreatingVariations ||
                          isExportingMockups
                        }
                      >
                        <MinusLinearIcon
                          width="26px"
                          height="26px"
                          className="icon"
                        />
                      </IconButton>
                    </AlertDialog.Trigger>
                    <AlertDialog.Content style={{ maxWidth: 450 }}>
                      <AlertDialog.Title>Remove aartwork asset</AlertDialog.Title>
                      <AlertDialog.Description size="2">
                        Are you sure you want to proceed? Removing this aartwork
                        asset will{' '}
                        {getNumerOfAssociatedVariations(da.id) ===
                        mockupVariations.length
                          ? 'update'
                          : 'remove'}{' '}
                        {getNumerOfAssociatedVariations(da.id)} associated{' '}
                        {getNumerOfAssociatedVariations(da.id) === 1
                          ? 'variation.'
                          : 'variations.'}
                      </AlertDialog.Description>

                      <Flex gap="3" mt="4" justify="end">
                        <AlertDialog.Cancel>
                          <Button variant="soft" color="gray">
                            Cancel
                          </Button>
                        </AlertDialog.Cancel>
                        <AlertDialog.Cancel>
                          <Button
                            variant="solid"
                            color="red"
                            onClick={() => removeDesignAsset(da.id)}
                          >
                            Remove
                          </Button>
                        </AlertDialog.Cancel>
                      </Flex>
                    </AlertDialog.Content>
                  </AlertDialog.Root>
                </Flex>
              </Flex>
            ))}

        {isMobile && (
          <ScrollArea
            type={'always'}
            scrollbars={'vertical'}
            style={{
              maxHeight: `40px`,
            }}
          >
            <Flex direction={'column'} gap={'1'}>
              {designAssets[activeSmartObject.id]
                ?.filter((da) => da.is_ai_generated)
                ?.map((da) => (
                  <Flex
                    key={da.id}
                    align={'center'}
                    justify={'between'}
                    gap={'2'}
                    width={'100%'}
                    pr={'2'}
                  >
                    <Flex
                      width={'100%'}
                      gap={'2'}
                      p={'2'}
                      align={'center'}
                      className={`item-wrapper ${
                        da.id === activeDesignAsset?.id && 'selected'
                      }`}
                      onClick={() => changeActiveDesignAsset(da.id)}
                    >
                      <img className="artwork" src={`${da.asset_path}`} />

                      <Text
                        className="artwork-name"
                        size={'1'}
                        weight={'regular'}
                        color="gray"
                      >
                        {da.name}
                      </Text>
                    </Flex>
                    <AlertDialog.Root>
                      <AlertDialog.Trigger>
                        <IconButton
                          className="remove-design-button"
                          variant={'ghost'}
                          size={'1'}
                          color="gray"
                          onClick={(e) => e.stopPropagation()}
                          loading={
                            !canDeleteDesignAsset(da.id) ||
                            isCreatingVariations ||
                            isExportingMockups
                          }
                        >
                          <MinusLinearIcon
                            width="26px"
                            height="26px"
                            className="icon"
                          />
                        </IconButton>
                      </AlertDialog.Trigger>
                      <AlertDialog.Content style={{ maxWidth: 450 }}>
                        <AlertDialog.Title>
                          Remove artwork asset
                        </AlertDialog.Title>
                        <AlertDialog.Description size="2">
                          Are you sure you want to proceed? Removing this artwork
                          asset will{' '}
                          {getNumerOfAssociatedVariations(da.id) ===
                          mockupVariations.length
                            ? 'update'
                            : 'remove'}{' '}
                          {getNumerOfAssociatedVariations(da.id)} associated{' '}
                          {getNumerOfAssociatedVariations(da.id) === 1
                            ? 'variation.'
                            : 'variations.'}
                        </AlertDialog.Description>

                        <Flex gap="3" mt="4" justify="end">
                          <AlertDialog.Cancel>
                            <Button variant="soft" color="gray">
                              Cancel
                            </Button>
                          </AlertDialog.Cancel>
                          <AlertDialog.Cancel>
                            <Button
                              variant="solid"
                              color="red"
                              onClick={(e) => removeDesignAsset(da.id)}
                            >
                              Remove
                            </Button>
                          </AlertDialog.Cancel>
                        </Flex>
                      </AlertDialog.Content>
                    </AlertDialog.Root>
                  </Flex>
                ))}
            </Flex>
          </ScrollArea>
        )}
        {isAdding &&
          [...Array(numberOfDesignAssetsAdding)].map((_, index) => (
            <Skeleton
              key={index}
              height={'48px'}
              width={'100%'}
              style={{
                borderRadius: '8px',
              }}
            />
          ))}
      </Flex>
    </Flex>
  );
};
