import { Button, Flex, AlertDialog } from '@radix-ui/themes';
import { useAppDispatch } from '@/hooks';
import { Design } from '@/services/types';
import { useState, forwardRef, useImperativeHandle } from 'react';
import { designsApi } from '@/services/designs';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IDeleteDesign {
  setSelectedDesigns: (x: number[]) => void;
}

export const DeleteDesign = forwardRef(
  ({ setSelectedDesigns }: IDeleteDesign, ref) => {
    const dispatch = useAppDispatch();
    const captureEvent = useCaptureEvent();
    const [open, setOpen] = useState<boolean>(false);
    const [designIds, setDesignIds] = useState<number[]>([]);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const [deleteDesigns] = designsApi.useDeleteDesignsMutation();

    useImperativeHandle(ref, () => ({
      openDialog(dIds: number[]) {
        setDesignIds(dIds);
        setOpen(true);
      },
      closeDialog() {
        setOpen(false);
      },
    }));

    const deleteDesignsAction = async () => {
      try {
        setIsDeleting(true);
        await deleteDesigns({ ids: designIds }).unwrap();

        captureEvent('Designs deleted');
        dispatch(
          designsApi.endpoints.getDesigns.initiate(null!, {
            forceRefetch: true,
          })
        );
        setSelectedDesigns([]);
        setIsDeleting(false);
      } catch (error) {
        console.log(error);
      } finally {
        setOpen(false);
      }
    };

    return (
      <AlertDialog.Root open={open} onOpenChange={setOpen}>
        <AlertDialog.Content style={{ maxWidth: 450 }}>
          <AlertDialog.Title>Delete artwork</AlertDialog.Title>
          <AlertDialog.Description size="2">
            Are you sure you want to delete {designIds.length} artwork?
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <Button variant="soft" color="gray" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="solid"
              color="red"
              onClick={() => deleteDesignsAction()}
              loading={isDeleting}
            >
              Delete
            </Button>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    );
  }
);
