import { SunIcon, MoonIcon } from '@radix-ui/react-icons';
import { Button, IconButton } from '@radix-ui/themes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { setThemeMode } from '@/redux/slices/app';
import { useEffect } from 'react';

export const ToggleThemeButton = () => {
  const { themeMode } = useAppSelector((state) => state.appReducer);

  return (
    <>
      {themeMode === 'dark' && <SunIcon width={'16px'} height={'16px'} />}
      {themeMode === 'light' && <MoonIcon width={'16px'} height={'16px'} />}
    </>
  );
};
