import {
  Dialog,
  Button,
  Flex,
  TextField,
  Text,
  SegmentedControl,
  Select,
} from '@radix-ui/themes';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import './variation-export-options.scss';
import { ReactComponent as Cross1Icon } from '@/assets/icons/Cross1Icon.svg';
import { psdEngineApi } from '@/services/psdEngine';
import { useParams } from 'react-router-dom';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { useForm } from 'react-hook-form';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { paymentsApi } from '@/services/payments';
import { formatNumberOfCredits } from '@/helpers';
import { ReactComponent as TreeSquaresDefaultIcon } from '@/assets/icons/three-squares-linear-default.svg';

type format = 'jpg' | 'png' | 'webp';
type size = '720' | '1080' | '2048' | 'custom';
type download = 'current' | 'all';

interface IForm {
  customSize: string;
}

interface IFormInput {
  mockupName: string;
  format: format;
  size: size;
  download: download;
}

interface IVariationExportOptions {
  setIsGenerating: (data: boolean) => void;
  setIsDownloading: (data: boolean) => void;
  setIsPreparing: (data: boolean) => void;
  setExportData: (x: any) => void;
  setLocalTaskId: (id: string) => void;
}

export const VariationExportOptions = forwardRef(
  (
    {
      setIsGenerating,
      setIsDownloading,
      setIsPreparing,
      setExportData,
      setLocalTaskId,
    }: IVariationExportOptions,
    ref
  ) => {
    const dispatch = useAppDispatch();
    const { collectionId } = useParams();
    const captureEvent = useCaptureEvent();
    const {
      mockupVariations,
      mockup,
      activeVariation,
      smartObjects,
      activeSmartObject,
      designAssets,
      colorCombinations,
    } = useAppSelector((state) => state.collectionReducer);
    const { myProjects } = useAppSelector((state) => state.workspaceReducer);
    const [open, setOpen] = useState<boolean>(false);
    const [downloadVariationsIsLoading, setDownloadVariationsIsLoading] =
      useState(false);
    const isAppPro = useFeatureFlagEnabled('app-pro');

    const [format, setFormat] = useState<format>('jpg');
    const [size, setSize] = useState<size>('2048');
    const [download, setDownload] = useState<download>('current');
    const [selectedSmartObject, setSelectedSmartObject] = useState<number>();

    const [downloadAll] = psdEngineApi.useDownloadAllMutation();
    const userRendersData = paymentsApi.useGetUserRendersQuery(
      { dummy: open } as any,
      { skip: !open, refetchOnMountOrArgChange: true }
    );

    useImperativeHandle(ref, () => ({
      openDialog() {
        setOpen(true);
      },
      closeDialog() {
        setOpen(false);
      },
    }));

    const getBlobImageDimensions = (blob: Blob | MediaSource) => {
      return new Promise((resolve, reject) => {
        // Create a temporary URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create a new Image object
        const img = new Image();

        // Set up the onload event to get the dimensions
        img.onload = () => {
          // Get the dimensions
          const dimensions = {
            width: img.width,
            height: img.height,
            url: url,
          };

          // Clean up the temporary URL
          URL.revokeObjectURL(url);

          // Resolve the promise with the dimensions
          resolve(dimensions);
        };

        // Set up the onerror event to handle any errors
        img.onerror = (error) => {
          // Clean up the temporary URL
          URL.revokeObjectURL(url);

          // Reject the promise with the error
          reject(error);
        };

        // Set the src of the Image object to the temporary URL
        img.src = url;
      });
    };

    const downloadVariationsAction = async () => {
      captureEvent(`Mockup Download Type ${download}`, {
        export_options: {
          id: mockup.id,
          format: format.toUpperCase(),
          max_size:
            size === 'custom'
              ? parseInt(getValues('customSize')) || 1080
              : parseInt(size),
          number_of_variants: mockupVariations.length,
          smart_object_id: selectedSmartObject,
        },
      });
      if (download === 'all') {
        const data = {
          id: mockup.id,
          format: format.toUpperCase(),
          max_size:
            size === 'custom'
              ? parseInt(getValues('customSize')) || 1080
              : parseInt(size),
          number_of_variants: mockupVariations.length,
          smart_object_id: selectedSmartObject,
        };
        try {
          setDownloadVariationsIsLoading(true);
          captureEvent('Mockup Download Started', {
            export_options: { ...data, download_type: 'all variants' },
          });

          setIsPreparing(true);

          toast.info(`We are preparing your variants.`, {
            toastId: 'preparing_info',
            position: 'bottom-right',
            autoClose: 2800,
          });

          setDownloadVariationsIsLoading(false);
          setOpen(false);

          const response = await downloadAll(data).unwrap();
          setLocalTaskId(response.data.task_id);

          setExportData(data);
          setIsPreparing(false);
          setIsGenerating(true);
        } catch (error) {
          setIsPreparing(false);
          setIsGenerating(false);
          console.log(error);
          captureEvent('Mockup Download Failed', {
            export_options: {
              error: 'Variation export DownloadAll failed',
              ...data,
              download_type: 'all variants',
            },
          });
          toast.error(
            `There was an error while generating variants. If this error persists, please contact the support.`,
            {
              toastId: 'download_all_status_failed',
              position: 'bottom-right',
              autoClose: 3000,
            }
          );
        }
      }
      if (download === 'current') {
        const data = {
          mockup_variation_id: activeVariation.id,
          format: format.toUpperCase(),
          max_size:
            size === 'custom'
              ? parseInt(getValues('customSize')) || 1080
              : parseInt(size),
          // subscription_type_id: isAppPro ? 2 : 1,
          subscription_type_id: 2,
          number_of_variants: 1,
        };
        try {
          setDownloadVariationsIsLoading(true);
          captureEvent('Mockup Download Started', {
            export_options: { ...data, download_type: 'current variant' },
          });

          setIsDownloading(true);

          await fetch(`${API_BASE_URL}mockups/download-single-variation`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }),
          })
            .then(async (response) => {
              if (!response.ok) {
                throw new Error(
                  JSON.stringify({
                    status: response.status,
                    error: response.statusText,
                  })
                );
              }
              try {
                return await response.blob();
              } catch (parseError: any) {
                throw new Error(
                  `Failed to parse response on api /download-all-complete: ${parseError.message}`
                );
              }
            })
            .then(async (res) => {
              await getBlobImageDimensions(res).then((dimensions: any) => {
                // Now proceed with the rest of your logic
                const url = URL.createObjectURL(res);
                // const parts = activeVariation.export_path.split('/');

                const mainDesign = activeVariation.variation_elements.find(
                  (ve) => ve.smart_object_id === selectedSmartObject
                )?.asset;

                const aElement = document.createElement('a');
                try {
                  if (mainDesign?.name) {
                    aElement.setAttribute(
                      'download',
                      `${mainDesign?.name?.replace(
                        /\.[^/.]+$/,
                        ''
                      )}.${format.toLowerCase()}`
                    );
                  } else {
                    aElement.setAttribute(
                      'download',
                      `${mockup.name} - ${dimensions.width}x${
                        dimensions.height
                      } - Dynamic Mockups.${format.toLowerCase()}`
                    );
                  }
                } catch (error) {
                  captureEvent('Current variation export file name error', {
                    export_options: {
                      ...data,
                      download_type: 'current variant',
                      error: JSON.stringify(error),
                    },
                  });
                  aElement.setAttribute(
                    'download',
                    `${mockup.name} - ${dimensions.width}x${
                      dimensions.height
                    } - Dynamic Mockups.${format.toLowerCase()}`
                  );
                }

                aElement.href = url;
                aElement.setAttribute('target', '_blank');
                aElement.click();

                // Revoke the object URL after the download is triggered
                URL.revokeObjectURL(url);
                captureEvent('Mockup Download Succeeded', {
                  export_options: {
                    ...data,
                    download_type: 'current variant',
                    weight: (res.size / (1024 * 1024)).toFixed(2),
                  },
                });

                setIsDownloading(false);
              });
            });
        } catch (error: any) {
          setIsDownloading(false);

          const errorMessage = error.message || error.toString();
          console.error('Error caught:', errorMessage);

          let errorData;
          try {
            errorData = JSON.parse(errorMessage); // For fetch-related errors
          } catch (e) {
            errorData = { status: 'Unknown', error: errorMessage }; // For other errors
          }

          captureEvent('Mockup Download Failed', {
            export_options: {
              ...data,
              download_type: 'current variant',
              position: 'mockups/download-single-variation',
              errorData: errorData,
            },
          });

          toast.error(
            `There was an error while downloading variant. If this error persists, please contact the support.`,
            {
              toastId: 'download_single_failed',
              position: 'bottom-right',
              autoClose: 3000,
            }
          );
        } finally {
          setDownloadVariationsIsLoading(false);
          setOpen(false);
        }
      }
    };

    useEffect(() => {
      try {
        const defaultSmartObjectId =
          smartObjects.length > 1
            ? smartObjects?.find((so) => findMatchingIds().includes(so.id))?.id
            : activeSmartObject.id;
        if (defaultSmartObjectId) {
          setSelectedSmartObject(defaultSmartObjectId);
        } else {
          setSelectedSmartObject(activeSmartObject.id);
        }
      } catch (error) {
        console.log(error);
        setSelectedSmartObject(activeSmartObject.id);
      }
    }, [smartObjects, activeSmartObject]);

    const findMatchingIds = () => {
      const keysArray1 = Object.keys(designAssets)
        .filter((key) => designAssets?.[+key]?.length > 0)
        .map(Number);
      const keysArray2 = Object.keys(colorCombinations)
        .filter((key) => colorCombinations?.[+key]?.length > 0)
        .map(Number);
      const combinedArrays = [...keysArray1, ...keysArray2];

      return smartObjects
        .filter((item) => combinedArrays.includes(item.id))
        .map((item) => item.id);
    };

    const [customSize, setCustomSize] = useState<string>();

    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      setValue,
      getValues,
    } = useForm<IForm>({
      mode: 'onBlur',
    });

    return (
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Content
          aria-describedby={undefined}
          className="export-options-dialog"
          style={{ maxWidth: 400 }}
          onPointerDownOutside={() =>
            captureEvent('Export Mockups Modal Closed')
          }
        >
          <Dialog.Title>
            <Flex direction={'row'} align={'center'} justify={'between'}>
              <Text size={'3'}>Export options</Text>
              <Cross1Icon
                cursor={'pointer'}
                width={'8px'}
                height={'8px'}
                className="icon black"
                onClick={() => {
                  captureEvent('Export Mockups Modal Closed');
                  setOpen(false);
                }}
              />
            </Flex>
          </Dialog.Title>
          <Flex direction={'column'}>
            <label>
              <TextField.Slot>
                <Text weight={'medium'} size={'2'} mb={'2'}>
                  Format
                </Text>
              </TextField.Slot>
              <SegmentedControl.Root
                className="segmented-control-style"
                defaultValue={format}
                onValueChange={(e) => {
                  setFormat(e as format);
                }}
              >
                <SegmentedControl.Item value="jpg">
                  <Flex gap={'2'} align={'center'}>
                    <Text size={'1'} weight={'medium'}>
                      JPG
                    </Text>
                  </Flex>
                </SegmentedControl.Item>
                <SegmentedControl.Item value="png">
                  <Flex gap={'2'} align={'center'}>
                    <Text size={'1'} weight={'medium'}>
                      PNG
                    </Text>
                  </Flex>
                </SegmentedControl.Item>
                <SegmentedControl.Item value="webp">
                  <Flex gap={'2'} align={'center'}>
                    <Text size={'1'} weight={'medium'}>
                      WEBP
                    </Text>
                  </Flex>
                </SegmentedControl.Item>
              </SegmentedControl.Root>
              <Flex mt={'2'}>
                {format === 'jpg' && (
                  <Text size={'1'} color="gray">
                    Best for mockups with photos - good quality, smaller file
                    size, no transparency
                  </Text>
                )}
                {format === 'png' && (
                  <Text size={'1'} color="gray">
                    Best for detailed graphics - high quality, larger file size,
                    transparency
                  </Text>
                )}
                {format === 'webp' && (
                  <Text size={'1'} color="gray">
                    Best for web use - good quality, smallest file size,
                    transparency
                  </Text>
                )}
              </Flex>
            </label>
            <label>
              <TextField.Slot>
                <Text weight={'medium'} size={'2'} mb={'2'} mt={'4'}>
                  Size
                </Text>
              </TextField.Slot>
              <SegmentedControl.Root
                className="segmented-control-style"
                defaultValue={size}
                onValueChange={(e) => setSize(e as size)}
              >
                <SegmentedControl.Item value="720">
                  <Flex gap={'2'} align={'center'}>
                    <Text size={'1'} weight={'medium'}>
                      720px
                    </Text>
                  </Flex>
                </SegmentedControl.Item>
                <SegmentedControl.Item value="1080">
                  <Flex gap={'2'} align={'center'}>
                    <Text size={'1'} weight={'medium'}>
                      1080px
                    </Text>
                  </Flex>
                </SegmentedControl.Item>
                <SegmentedControl.Item value="2048">
                  <Flex gap={'2'} align={'center'}>
                    <Text size={'1'} weight={'medium'}>
                      2048px
                    </Text>
                  </Flex>
                </SegmentedControl.Item>
                <SegmentedControl.Item
                  value="custom"
                  onClick={(e) => {
                    if (!isAppPro) {
                      e.preventDefault();
                      captureEvent('Upgrade your plan modal viewed', {
                        source: 'Set custom export size',
                      });
                      dispatch(setIsAppPaymentPopupOpen(true));
                    }
                  }}
                >
                  <Flex gap={'2'} align={'center'}>
                    <Text size={'1'} weight={'medium'}>
                      Custom
                    </Text>
                  </Flex>
                </SegmentedControl.Item>
              </SegmentedControl.Root>
              {size === 'custom' && (
                <form>
                  <TextField.Root
                    mt={'1'}
                    type="number"
                    placeholder="3072"
                    style={{ width: '100%', height: '36px', fontSize: '12px' }}
                    {...register('customSize', {
                      min: {
                        value: 1,
                        message: 'Minimum width is 1px.',
                      },
                      max: {
                        value: 5000,
                        message: 'Maximum width is 5000px.',
                      },
                    })}
                  >
                    <TextField.Slot>
                      <Text weight={'medium'} size={'1'}>
                        W
                      </Text>
                    </TextField.Slot>
                    <TextField.Slot>
                      <Text weight={'medium'} size={'1'}>
                        px
                      </Text>
                    </TextField.Slot>
                  </TextField.Root>
                  {errors.customSize && (
                    <Text role="alert" size={'1'} color="red">
                      {errors.customSize.message}
                    </Text>
                  )}
                </form>
              )}
            </label>
            {smartObjects.filter((so) => !so.is_background_layer).length > 1 ? (
              <label>
                <TextField.Slot>
                  <Text weight={'medium'} size={'2'} mb={'2'} mt={'4'}>
                    Rename exports
                  </Text>
                </TextField.Slot>
                <Select.Root
                  value={(selectedSmartObject || 1).toString()}
                  onValueChange={(val) =>
                    setSelectedSmartObject(parseInt(val) as number)
                  }
                >
                  <Select.Trigger style={{ width: '100%' }} />
                  <Select.Content position="popper" style={{ width: '50%' }}>
                    {smartObjects.map(
                      (so) =>
                        findMatchingIds().includes(so.id) && (
                          <Select.Item key={so.id} value={so.id.toString()}>
                            {so.smart_object_name.replace(
                              'dm:ai:background',
                              ''
                            )}
                          </Select.Item>
                        )
                    )}
                  </Select.Content>
                </Select.Root>
                <Flex mt={'2'}>
                  <Text size={'1'} color="gray">
                    Renames your images based on the artwork asset names from the
                    chosen Smart Object
                  </Text>
                </Flex>
              </label>
            ) : (
              ''
            )}
            {mockupVariations.length > 1 && (
              <label>
                <TextField.Slot>
                  <Text weight={'medium'} size={'2'} mb={'2'} mt={'4'}>
                    Download
                  </Text>
                </TextField.Slot>
                <SegmentedControl.Root
                  className="segmented-control-style"
                  defaultValue={download}
                  onValueChange={(e) => setDownload(e as download)}
                >
                  <SegmentedControl.Item value="current">
                    <Flex gap={'2'} align={'center'}>
                      <Text size={'1'} weight={'medium'}>
                        Current Variant
                      </Text>
                    </Flex>
                  </SegmentedControl.Item>
                  <SegmentedControl.Item value="all">
                    <Flex gap={'2px'} align={'center'} justify={'center'}>
                      <Text size={'1'} weight={'medium'}>
                        All Variants: {mockupVariations.length}
                      </Text>
                      <TreeSquaresDefaultIcon
                        className={`icon  ${
                          download === 'all' ? 'blue' : 'default'
                        }`}
                        width="14"
                        height="14"
                      />
                    </Flex>
                  </SegmentedControl.Item>
                </SegmentedControl.Root>
              </label>
            )}
            <Flex gap="3" mt="7" justify="end">
              <Button
                className="download-button"
                type="submit"
                loading={downloadVariationsIsLoading}
                onClick={(e) => {
                  if (download === 'all') {
                    if (
                      userRendersData.data?.data?.[0]?.renders_remaining ===
                        0 ||
                      (userRendersData.data?.data?.[0]?.renders_remaining ||
                        0) < mockupVariations.length
                    ) {
                      e.preventDefault();
                      captureEvent('Upgrade your plan modal viewed', {
                        source: 'Download all wariants button',
                      });
                      dispatch(setIsAppPaymentPopupOpen(true));
                    } else {
                      downloadVariationsAction();
                    }
                  } else {
                    downloadVariationsAction();
                  }
                }}
              >
                Download
              </Button>
            </Flex>
            {mockupVariations.length > 1 && (
              <Flex
                mt={'4'}
                height={'16px'}
                gap={'2'}
                align={'center'}
                justify={'center'}
              >
                {download === 'all' && (
                  <>
                    <TreeSquaresDefaultIcon
                      className="icon"
                      width="20"
                      height="20"
                    />
                    <Text size={'1'} weight={'medium'}>
                      Available Credits:{' '}
                      {formatNumberOfCredits(
                        userRendersData.data?.data?.[0]?.renders_remaining || 0
                      )}
                    </Text>
                  </>
                )}
              </Flex>
            )}
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    );
  }
);
