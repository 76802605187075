import { Flex, Tooltip } from '@radix-ui/themes';
import { useEffect, useState } from 'react';
import './colors-popover.scss';
import { CheckIcon } from '@radix-ui/react-icons';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { ColorSetColor } from '@/services/colors';
import { ColorCombination } from '@/services/types';
import { setActiveVariation } from '@/redux/slices/collection';
import { psdEngineApi } from '@/services/psdEngine';
import useCaptureEvent from '@/hooks/useCaptureEvent';

export interface IColor {
  color: ColorSetColor;
  type: 'add' | 'update';
  colorToUpdate: ColorCombination | undefined;
}

export const Color = ({ color, type, colorToUpdate }: IColor) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const {
    activeColorCombination,
    activeSmartObject,
    mockup,
    colorCombinations,
  } = useAppSelector((state) => state.collectionReducer);
  const [selected, setSelected] = useState<boolean>(false);

  const [addColor, { isLoading: isAdding }] =
    psdEngineApi.useAddColorCombinationMutation();
  const [updateColor, { isLoading: isUpdating }] =
    psdEngineApi.useUpdateColorCombinationMutation();
  const [removeColor, { isLoading: isDeleting }] =
    psdEngineApi.useDeleteColorVariationMutation();
  const [createMockupVariations, { isLoading: isCreatingMockupVariations }] =
    psdEngineApi.useCreateMockupVariationsMutation();

  useEffect(() => {
    setSelected(
      !!colorCombinations[activeSmartObject.id].find(
        (c) => c.hex_code === color.hex_code
      )
    );
  }, [colorCombinations]);

  const manageColor = () => {
    if (!selected) {
      selectColorAction();
    }
  };

  const selectColorAction = async () => {
    if (type === 'add') {
      // setLocalType('update');
      const addedColor = await addColor({
        smart_object_id: activeSmartObject?.id,
        hex_code: color.hex_code,
      }).unwrap();

      const createdVariations = await createMockupVariations({
        payload: {
          mockup_id: mockup?.id,
          smart_object_id: activeSmartObject.id,
          color_id: addedColor.data.id,
        },
      }).unwrap();
      captureEvent('Color added', {
        hex: color.hex_code,
        mockupId: mockup.id,
      });
      dispatch(
        setActiveVariation({
          ...createdVariations.data[createdVariations.data.length - 1],
          isFetching: true,
        })
      );
    } else {
      await updateColor({
        id: activeColorCombination?.id,
        smart_object_id: activeSmartObject?.id,
        hex_code: color.hex_code,
      });
      captureEvent('Color updated', {
        oldHex: colorToUpdate?.hex_code,
        newHex: color.hex_code,
        mockupId: mockup.id,
      });
    }
  };

  return (
    <Tooltip content={color.name}>
      <Flex
        className={`color ${selected && 'selected'}`}
        style={{ background: color.hex_code }}
        align={'center'}
        justify={'center'}
        onClick={() => manageColor()}
      >
        <CheckIcon
          className="check-icon"
          width={'18px'}
          height={'18px'}
          color="#fff"
        />
      </Flex>
    </Tooltip>
  );
};

