import './view-wrapper.scss';
import {
  Badge,
  Button,
  DropdownMenu,
  Flex,
  Link as RadixLink,
  Text,
  Tooltip,
} from '@radix-ui/themes';
import { Link, useNavigate } from 'react-router-dom';
import { PrivatePhotoshopFile } from '@/services/types';
import { psdEngineApi } from '@/services/psdEngine';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DotsVerticalIcon } from '@radix-ui/react-icons';
import { psdManagementApi } from '@/services/psdManagement';
import { useRef } from 'react';
import { ReactComponent as PhotoshopIcon } from '@/assets/icons/photoshop.svg';
import { EditPhotoshopFile } from './EditPhotoshopFile';
import { DeletePhotoshopFile } from './DeletePhotoshopFile';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { ReprocessPhotoshopFile } from './ReprocessPhotoshopFile';
import { useFeatureFlagEnabled } from 'posthog-js/react';

interface IPhotoshopFilesSingleTemplate {
  privatePsd: PrivatePhotoshopFile;
}

export const PhotoshopFilesSingleTemplate = ({
  privatePsd,
}: IPhotoshopFilesSingleTemplate) => {
  const navigate = useNavigate();
  const captureEvent = useCaptureEvent();
  const editPhotoshopFileDialog = useRef();
  const deletePhotoshopFileDialog = useRef();
  const reprocessPhotoshopFileDialog = useRef();
  const canPublishPrivatePsd = useFeatureFlagEnabled('publish-private-psd'); // just admins

  const [loadMockup, { isLoading }] =
    psdEngineApi.useLoadPublicPsdMockupMutation();
  const psdCategoriesData = psdManagementApi.useGetPsdCategoriesQuery();
  const psdAvailabilities = psdManagementApi.useGetPsdAvailabilitiesQuery();
  const subscriptionTypes = psdManagementApi.useGetSubscriptionTypesQuery();

  const loadPublicMockup = async () => {
    navigate(`/mockup/${privatePsd.psd_id}`, {
      state: { from: location.pathname },
    });
  };

  return (
    <Flex className={'single-template'} align={'end'} direction={'column'}>
      <EditPhotoshopFile
        psdAvailabilities={psdAvailabilities.data?.data}
        subscriptionTypes={subscriptionTypes.data?.data}
        psdCategories={psdCategoriesData.data?.data}
        privatePsd={privatePsd}
        ref={editPhotoshopFileDialog}
      />
      <DeletePhotoshopFile
        privatePsd={privatePsd}
        ref={deletePhotoshopFileDialog}
      />
      <ReprocessPhotoshopFile
        privatePsd={privatePsd}
        ref={reprocessPhotoshopFileDialog}
      />
      <Flex align={'center'} justify={'between'} className="dd-option-wrapper">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button className="ddl-options" variant="surface" size={'1'}>
              <DotsVerticalIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <RadixLink
              href={`${privatePsd.psd_url}`}
              download={`${privatePsd.name}`}
              target="_blank"
              className="download-action"
            >
              <DropdownMenu.Item
                onClick={() => captureEvent('PSD Mockup downloaded')}
              >
                Download
              </DropdownMenu.Item>
            </RadixLink>
            <DropdownMenu.Item
              onClick={() => {
                (editPhotoshopFileDialog?.current as any)?.openDialog();
              }}
            >
              Edit
            </DropdownMenu.Item>
            {canPublishPrivatePsd && (
              <DropdownMenu.Item
                onClick={() => {
                  (reprocessPhotoshopFileDialog?.current as any)?.openDialog();
                }}
              >
                Reprocess
              </DropdownMenu.Item>
            )}
            <DropdownMenu.Separator />
            <DropdownMenu.Item
              color="red"
              onClick={() => {
                (deletePhotoshopFileDialog?.current as any)?.openDialog();
              }}
            >
              Delete
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Flex>
      <LazyLoadImage
        alt={`${privatePsd.name}`}
        className=""
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          objectPosition: 'center',
        }}
        effect="blur"
        src={privatePsd.thumbnail || 'https://placehold.co/300x300?text=MOCKUP'}
        delayTime={0}
        placeholderSrc={
          privatePsd.thumbnail || 'https://placehold.co/300x300?text=MOCKUP'
        }
        wrapperProps={{
          style: { transitionDelay: '0s' },
        }}
      />

      <Flex
        className="head-wrapper"
        direction={'column'}
        align={'center'}
        p={'4'}
        gap={'2'}
      >
        {psdAvailabilities.data?.data?.find(
          (pa) => pa.id === privatePsd.psd_availability_id
        )?.value === 0 && (
          <Badge size={'2'} className="public-badge" variant="solid">
            Public
          </Badge>
        )}
        {subscriptionTypes.data?.data?.find(
          (st) => st.id === privatePsd.subscription_type_id
        )?.name === 'Pro' && (
          <Badge size={'2'} className="paid-badge" variant="solid">
            Pro
          </Badge>
        )}
        {privatePsd.is_ai_mockup === 1 && (
          <Badge size={'2'} className="ai-badge" variant="solid">
            AI
          </Badge>
        )}
      </Flex>
      <Link
        className="text-wrapper"
        to={{
          pathname: `/mockup/${privatePsd.psd_id}`,
        }}
        state={{ from: location.pathname }}
      >
        <Flex align={'end'} p={'4'}>
          <Tooltip content={privatePsd.name}>
            <Flex gap={'2'} align={'center'} maxWidth={'100%'}>
              <PhotoshopIcon />
              <Text size={'2'} weight={'regular'} style={{ color: '#fff' }}>
                {privatePsd.name}
              </Text>
            </Flex>
          </Tooltip>
        </Flex>
      </Link>
    </Flex>
  );
};
