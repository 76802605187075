import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { Text, Button, Spinner } from '@radix-ui/themes';
import '@/features/layout/header/appHeader.scss';
import { useEffect, useRef, useState } from 'react';
import { TimerIcon } from '@radix-ui/react-icons';
import { toast } from 'react-toastify';
import { psdEngineApi } from '@/services/psdEngine';
import { ReactComponent as DownloadIcon } from '@/assets/icons/download-minimalistic-bold.svg';
import { VariationExportOptions } from './VariationExportOptions';
import useDownloader from 'react-use-downloader';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { setIsExportingMockups } from '@/redux/slices/collection';
import { getFileSizeInMB } from '@/helpers';

export const GenerateVariationsButton = () => {
  const dispatch = useAppDispatch();
  const { collectionId } = useParams();
  const { download, percentage } = useDownloader();
  const captureEvent = useCaptureEvent();

  const { mockupVariations, activeSmartObject, mockup } = useAppSelector(
    (state) => state.collectionReducer
  );
  const { currentMockupBundle } = useAppSelector(
    (state) => state.mockupBundlesReducer
  );
  const { myProjects } = useAppSelector((state) => state.workspaceReducer);

  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isPreparing, setIsPreparing] = useState<boolean>(false);
  const [exportData, setExportData] = useState({});

  useEffect(() => {
    dispatch(
      setIsExportingMockups(isGenerating || isDownloading || isPreparing)
    );
  }, [isGenerating, isDownloading, isPreparing]);

  const [localTaskId, setLocalTaskId] = useState<null | string>(null);

  const {
    data: downloadAllStatus,
    isError,
    error: getDownloadAllStatusError,
  } = psdEngineApi.useGetDownloadAllStatusQuery(
    { id: localTaskId || '' },
    {
      skip: !localTaskId,
      pollingInterval: 750,
      refetchOnMountOrArgChange: true,
    }
  );

  const [generatingProgress, setGeneratingProgress] = useState(0);

  useEffect(() => {
    try {
      if (generatingProgress > 0) {
        document.title = `${generatingProgress}% Generating variants - Dynamic Mockups | Collection`;
      } else {
        document.title = 'Dynamic Mockups | Collection';
      }
    } catch (error) {
      console.log(error);
      document.title = 'Dynamic Mockups | Collection';
    }
  }, [generatingProgress]);

  useEffect(() => {
    if (isError) {
      setLocalTaskId(null);

      setIsGenerating(false);
      setGeneratingProgress(0);

      toast.error(
        `There was an error while generating variants. If this error persists, please contact the support.`,
        {
          toastId: 'download_all_status_failed',
          position: 'bottom-right',
          autoClose: 2800,
        }
      );

      // Handle errors consistently
      let errorMessage: any = '';
      let errorData;
      try {
        errorMessage =
          getDownloadAllStatusError ||
          (getDownloadAllStatusError as any).toString();

        errorData = JSON.parse(errorMessage); // For fetch-related errors
      } catch (e) {
        if (errorMessage.status) {
          errorData = errorMessage;
        } else {
          errorData = { status: 'Unknown', error: errorMessage }; // For other errors}
        }
      }

      captureEvent('Mockup Download Failed', {
        export_options: {
          ...exportData,
          download_type: 'all variants',
          position: '/download-all-status',
          errorData: errorData,
        },
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isGenerating) {
      toast.info(`Generating variants started.`, {
        toastId: 'generating_info',
        position: 'bottom-right',
        autoClose: 2800,
      });
    }
  }, [isGenerating]);

  useEffect(() => {
    if (downloadAllStatus?.data.state === 'PENDING') {
      setIsGenerating(true);

      setGeneratingProgress(
        Math.round(
          (downloadAllStatus.data.completed_tasks /
            downloadAllStatus.data.total_tasks) *
            100
        )
      );
    }
    if (downloadAllStatus?.data.state === 'COMPLETED') {
      setLocalTaskId(null);
      setIsGenerating(false);
      setIsDownloading(true);
      setGeneratingProgress(0);

      const downloadVariationsZip = async () => {
        try {
          toast.info(`Downloading variants started.`, {
            toastId: 'download_zip_info',
            position: 'bottom-right',
            autoClose: 2800,
          });
          await fetch(
            `${API_BASE_URL}mockups/variations/${localTaskId}/download-all-complete`,
            {
              headers: new Headers({
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json',
              }),
              method: 'POST',
              body: JSON.stringify({
                smart_object_id: (exportData as any).smart_object_id,
              }),
            }
          )
            .then(async (response) => {
              if (!response.ok) {
                throw new Error(
                  JSON.stringify({
                    status: response.status,
                    error: response.statusText,
                  })
                );
              }
              try {
                return await response.json();
              } catch (parseError: any) {
                throw new Error(
                  `Failed to parse response on api /download-all-complete: ${parseError.message}`
                );
              }
            })
            .then(async (res) => {
              try {
                // await download(
                //   res.data.download_url,
                //   `${mockup.name} (${mockupVariations.length} variants) - Dynamic Mockups.zip`
                // );

                var link = document.createElement('a');
                link.download = `${mockup.name} (${mockupVariations.length} variants) - Dynamic Mockups.zip`;
                link.href = res.data.download_url;
                document.body.appendChild(link);
                link.click();
                setTimeout(() => {
                  if (document.body.contains(link)) {
                    document.body.removeChild(link);
                  }
                }, 100);

                toast.success(`Variants downloaded successfully.`, {
                  toastId: 'download_zip_success_info',
                  position: 'bottom-right',
                  autoClose: 2800,
                });
                await getFileSizeInMB(res.data.download_url).then((size) => {
                  captureEvent('Mockup Download Succeeded', {
                    export_options: {
                      ...exportData,
                      download_type: 'all variants',
                      weight: size,
                    },
                  });
                });
              } catch (domError: any) {
                throw new Error(
                  `Failed to handle DOM while preparing download_url for downloading: ${domError.message}`
                );
              }
            })
            .catch((error) => {
              // Handle errors consistently
              let errorMessage: any = '';
              let errorData;

              try {
                errorMessage = error.message || error.toString();
                console.error('Error caught:', errorMessage);

                errorData = JSON.parse(errorMessage); // For fetch-related errors
              } catch (e) {
                errorData = { status: 'Unknown', error: errorMessage }; // For other errors
              }

              if (error.message.includes('QuotaExceededError')) {
                errorData =
                  'QuotaExceededError - user does not have enough disk space.';
                toast.error(
                  `You do not have enough disk space to store this file.`,
                  {
                    toastId: 'diskspace_status_failed',
                    position: 'bottom-right',
                    autoClose: 2800,
                  }
                );
              }
              captureEvent('Mockup Download Failed', {
                export_options: {
                  ...exportData,
                  download_type: 'all variants',
                  position: 'task_id/download-all-complete',
                  errorData: errorData,
                },
              });
            })
            .finally(() => {
              setIsDownloading(false);
              setLocalTaskId(null);
            });
        } catch (error: any) {
          toast.error(
            `There was an error while generating variants. If this error persists, please contact the support.`,
            {
              toastId: 'download_all_status_failed',
              position: 'bottom-right',
              autoClose: 2800,
            }
          );

          // Handle errors consistently
          let errorMessage: any = '';
          let errorData;

          try {
            errorMessage = error.message || error.toString();
            console.error('Error caught:', errorMessage);
            errorData = JSON.parse(errorMessage); // For fetch-related errors
          } catch (e) {
            errorData = { status: 'Unknown', error: errorMessage }; // For other errors
          }

          captureEvent('Mockup Download Failed', {
            export_options: {
              ...exportData,
              download_type: 'all variants',
              position:
                'general trycatch block for task_id/download-all-complete',
              errorData: errorData,
            },
          });

          setIsDownloading(false);
          setLocalTaskId(null);
        }
      };

      downloadVariationsZip();
    }
    if (downloadAllStatus?.data.state === 'FAILED' || isError) {
      setLocalTaskId(null);
      setIsGenerating(false);
      setGeneratingProgress(0);

      toast.error(
        `There was an error while generating variants. If this error persists, please contact the support.`,
        {
          toastId: 'download_all_status_failed',
          position: 'bottom-right',
          autoClose: 2800,
        }
      );

      // Handle errors consistently
      let errorMessage: any = '';
      let errorData;

      try {
        errorMessage =
          getDownloadAllStatusError ||
          (getDownloadAllStatusError as any).toString();

        errorData = JSON.parse(errorMessage); // For fetch-related errors
      } catch (e) {
        if (errorMessage.status) {
          errorData = errorMessage;
        } else {
          errorData = { status: 'Unknown', error: errorMessage }; // For other errors}
        }
      }

      captureEvent('Mockup Download Failed', {
        export_options: {
          ...exportData,
          download_type: 'all variants',
          position:
            'API task status check returned FAILED status - /download-all-status',
          errorData: errorData,
        },
      });
    }
  }, [downloadAllStatus]);

  const variationExportOptionsDialog = useRef();

  return (
    <>
      {activeSmartObject && (
        <>
          <VariationExportOptions
            ref={variationExportOptionsDialog}
            setIsGenerating={setIsGenerating}
            setIsDownloading={setIsDownloading}
            setIsPreparing={setIsPreparing}
            setExportData={setExportData}
            setLocalTaskId={(id) => setLocalTaskId(id)}
          />
          <Button
            color="blue"
            className="download-all-button"
            onClick={() => {
              captureEvent('Export Mockups Modal Opened');
              (variationExportOptionsDialog?.current as any)?.openDialog();
            }}
            disabled={
              currentMockupBundle
                ? isGenerating || isPreparing || isDownloading
                : !mockupVariations.length ||
                  isGenerating ||
                  isPreparing ||
                  isDownloading
            }
          >
            {!isPreparing && !isGenerating && !isDownloading && (
              <>
                <DownloadIcon className="icon white" />
                <Text size="1" weight="medium">
                  Export Mockups
                </Text>
              </>
            )}
            {isPreparing && (
              <>
                <Spinner />
                <Text size="1" weight="medium">
                  Preparing variants...
                </Text>
              </>
            )}
            {isGenerating && (
              <>
                <Spinner />
                <Text size="1" weight="medium">
                  {`Generating variants - ${generatingProgress}%`}
                </Text>
              </>
            )}
            {isDownloading && (
              <>
                <Spinner />
                <Text size="1" weight="medium">
                  {percentage && percentage > 0
                    ? `Downloading - ${percentage}%`
                    : `Downloading...`}
                </Text>
              </>
            )}
          </Button>
        </>
      )}
    </>
  );
};
