import { Flex } from '@radix-ui/themes';
import './choose-design.scss';
import { useAppDispatch, useAppSelector } from '@/hooks';
import Uppy from '@uppy/core';
import {
  useState,
  useRef,
  useEffect,
  MutableRefObject,
  Dispatch,
  SetStateAction,
} from 'react';
import { addSelectedDesignIds } from '@/redux/slices/mockupBundles';
import { DesignsUploader } from '../../designs/components/DesignsUploader';
import { DeleteDesign } from '../../designs/components/view-wrapper/DeleteDesign';

interface IDesignsPage {
  setUppyDashboardInstance: (
    uppy: Uppy<Record<string, unknown>, Record<string, unknown>>
  ) => void;
  handleUploadClick?: () => void;
  setSelectedDesignIds?: (ids: number[]) => void;
  selectedDesigns: number[];
  deleteDesignsDialog: MutableRefObject<undefined>;
  setSelectedDesigns: Dispatch<SetStateAction<number[]>>;
}

export const DesignsPage = ({
  setUppyDashboardInstance,
  handleUploadClick,
  setSelectedDesignIds,
  selectedDesigns,
  deleteDesignsDialog,
  setSelectedDesigns,
}: IDesignsPage) => {
  const dispatch = useAppDispatch();
  const { selectedDesignIds } = useAppSelector(
    (state) => state.mockupBundlesReducer
  );

  useEffect(() => {
    if (setSelectedDesignIds) {
      setSelectedDesignIds(selectedDesigns || []);
    }
  }, [selectedDesigns]);

  return (
    <Flex
      className="designs-content"
      width={'100%'}
      height={'100%'}
      direction={'column'}
      id="selection-box-container"
      px={'8px'}
    >
      <DeleteDesign
        ref={deleteDesignsDialog}
        setSelectedDesigns={(x) => {
          setSelectedDesigns(x);
          dispatch(addSelectedDesignIds(x));
        }}
      />

      <DesignsUploader
        selectDesign={() => undefined}
        setUppyDashboardInstance={setUppyDashboardInstance}
        handleUploadClick={handleUploadClick}
        setSelectedDesigns={(x) => {
          setSelectedDesigns(x);
          dispatch(addSelectedDesignIds(x));
        }}
        selectedDesigns={
          setSelectedDesignIds ? selectedDesignIds : selectedDesigns
        }
        isDesignsLibrary={true}
        deleteDesignsDialog={deleteDesignsDialog}
      />
    </Flex>
  );
};
