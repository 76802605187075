import { Flex, Text } from '@radix-ui/themes';
import './tools-sidebar.scss';
import { ReactComponent as DynamicMockupsLogoBlue } from '@/assets/dm-logo-blue.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as GalleryAddIcon } from '@/assets/icons/gallery-add-linear.svg';
import { ReactComponent as PaintRollerIcon } from '@/assets/icons/paint-roller-linear.svg';
import { ReactComponent as SparkleIcon } from '@/assets/icons/sparkle-24-regular.svg';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { mockupBundlesApi } from '@/services/mockupBundles';
import { setActiveMockupMenuItem } from '@/redux/slices/collection';
import { useProcessBundles } from '../editor/useProcessBundles';
import { BlendingModeIcon } from '@radix-ui/react-icons';
import Intercom from '@intercom/messenger-js-sdk';
import { SmartObject } from '@/services/types';
import Skeleton from 'react-loading-skeleton';

interface IToolsSidebar {
  smartObjectsData: SmartObject[];
  isLoadingSmartObjects: boolean;
}

export const ToolsSidebar = ({
  smartObjectsData,
  isLoadingSmartObjects,
}: IToolsSidebar) => {
  const dispatch = useAppDispatch();
  const { activeMockupMenuItem, smartObjects, mockupVariations } =
    useAppSelector((state) => state.collectionReducer);
  const { createBundleRestTempData } = useAppSelector(
    (state) => state.mockupBundlesReducer
  );

  useProcessBundles(createBundleRestTempData, mockupBundlesApi);

  Intercom({ app_id: 's2w7quxz', hide_default_launcher: true });

  return (
    <Flex
      className="tools-sidebar"
      direction={'column'}
      width={'100%'}
      height={'100%'}
      p={'4'}
    >
      <Flex direction={'column'} align={'center'} justify={'center'} p={'2'}>
        <Link
          className="app-logo-link"
          to={{
            pathname: `/my-templates`,
          }}
        >
          <DynamicMockupsLogoBlue className="app-logo" />
        </Link>
      </Flex>
      <Flex
        className="items"
        direction={'column'}
        align={'center'}
        gap={'2'}
        py={'4'}
      >
        {!isLoadingSmartObjects && (smartObjectsData || []).length > 0 ? (
          <Flex
            className={`item ${
              activeMockupMenuItem === 'artwork' ? 'active' : ''
            }`}
            direction={'column'}
            align={'center'}
            gap={'1'}
            onClick={() => dispatch(setActiveMockupMenuItem('artwork'))}
          >
            <GalleryAddIcon className="icon" width={'24px'} height={'24px'} />
            <Text className="title">Artwork</Text>
          </Flex>
        ) : (
          <Skeleton width={'72px'} height={'72px'} />
        )}

        {!isLoadingSmartObjects && (smartObjectsData || []).length > 0 ? (
          <Flex
            className={`item ${
              activeMockupMenuItem === 'color' ? 'active' : ''
            }`}
            direction={'column'}
            align={'center'}
            gap={'1'}
            onClick={() => dispatch(setActiveMockupMenuItem('color'))}
          >
            <PaintRollerIcon className="" width={'24px'} height={'24px'} />
            <Text className="title">Color</Text>
          </Flex>
        ) : (
          <Skeleton width={'72px'} height={'72px'} />
        )}

        {mockupVariations?.length > 0 &&
          !mockupVariations?.some((item) =>
            item.variation_elements.every(
              (variation) =>
                variation.color === null && variation.asset === null
            )
          ) && (
            <Flex
              className={`item ${
                activeMockupMenuItem === 'adjustments' ? 'active' : ''
              }`}
              direction={'column'}
              align={'center'}
              gap={'1'}
              onClick={() => dispatch(setActiveMockupMenuItem('adjustments'))}
            >
              <BlendingModeIcon width={'24px'} height={'24px'} />
              <Text className="title">Adjustments</Text>
            </Flex>
          )}
        {smartObjects?.find((so) => so.is_background_layer) && (
          <Flex
            className={`item ${
              activeMockupMenuItem === 'ai-scene' ? 'active' : ''
            }`}
            direction={'column'}
            align={'center'}
            gap={'1'}
            onClick={() => dispatch(setActiveMockupMenuItem('ai-scene'))}
          >
            <SparkleIcon className="ai-icon" width={'24px'} height={'24px'} />
            <Text className="title">AI Scene</Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
