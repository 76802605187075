import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { DataModel, PrintareaPreset } from './types';

export interface PrintareaPresetRequest extends PrintareaPreset {
  reflect_to_psd: boolean;
}

export const printareaPresetsApi = createApi({
  reducerPath: 'printareaPresetsApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getSmartObjectPresets: builder.query<
      DataModel<any>,
      { smartObjectId: number }
    >({
      query: ({ smartObjectId }) => `mockups/${smartObjectId}/smart-objects`,
    }),
    createPrintareaPreset: builder.mutation<
      DataModel<PrintareaPreset>,
      Partial<PrintareaPresetRequest>
    >({
      query: (payload) => ({
        url: `print-area-presets`,
        method: 'POST',
        body: payload,
      }),
    }),
    updatePrintareaPresetName: builder.mutation<
      DataModel<PrintareaPreset>,
      { id: number; name: string }
    >({
      query: ({ id, name }) => ({
        url: `print-area-presets/${id}/rename`,
        method: 'PUT',
        body: { name },
      }),
    }),
    updatePrintareaPreset: builder.mutation<
      DataModel<PrintareaPreset>,
      Partial<PrintareaPresetRequest>
    >({
      query: (payload) => ({
        url: `print-area-presets/${payload.id}/update/print-area`,
        method: 'PUT',
        body: payload,
      }),
    }),
    reflectPrintareaPresetToPsd: builder.mutation<
      DataModel<PrintareaPreset>,
      { presetId: number; reflectToPsd: boolean }
    >({
      query: ({ presetId, reflectToPsd }) => ({
        url: `print-area-presets/${presetId}/update/reflect-to-psd`,
        method: 'PUT',
        body: { reflect_to_psd: reflectToPsd },
      }),
    }),
    deletePrintareaPreset: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `print-area-presets/${id}`,
        method: 'DELETE',
      }),
    }),
    createPresetThumbnail: builder.mutation<
      void,
      {
        print_area_preset_mockup_id?: number;
        thumbnail_url: string;
        psd_id: number;
      }
    >({
      query: (payload) => ({
        url: `print-area-presets/create-thumbnail`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {} = printareaPresetsApi;
