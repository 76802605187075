export const PROD_PRICE_MAP = {
  basic: {
    100: {
      price: 0,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 100,
    },
    300: {
      price: 10,
      price_id: 'price_1R1lDSFy4AZwJXspsqisMYTp',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 300,
    },
    1000: {
      price: 24,
      price_id: 'price_1R1lG5Fy4AZwJXspemUXcVsR',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 1000,
    },
    2500: {
      price: 24,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 2500,
    },
    5000: {
      price: 24,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 5000,
    },
    10000: {
      price: 24,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 10000,
    },
    50000: {
      price: 24,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 50000,
    },
    100000: {
      price: 24,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 100000,
    },
    250000: {
      price: 24,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 250000,
    },
    500000: {
      price: 24,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 500000,
    },
    1000000: {
      price: 24,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 1000000,
    },
  },
  basic_yearly: {
    100: {
      price: 0,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 100,
    },
    300: {
      price: 7,
      price_id: 'price_1R1lETFy4AZwJXspyQnZqucE',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 300,
    },
    1000: {
      price: 18,
      price_id: 'price_1R1lGPFy4AZwJXspUviQU3L8',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 1000,
    },
    2500: {
      price: 18,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 2500,
    },
    5000: {
      price: 18,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 5000,
    },
    10000: {
      price: 18,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 10000,
    },
    50000: {
      price: 18,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 50000,
    },
    100000: {
      price: 18,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 100000,
    },
    250000: {
      price: 18,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 250000,
    },
    500000: {
      price: 18,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 500000,
    },
    1000000: {
      price: 18,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 1000000,
    },
  },
  pro: {
    100: {
      price: 10,
      price_id: 'price_1R1lHgFy4AZwJXspe22alFh9',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 100,
    },
    300: {
      price: 19,
      price_id: 'price_1R1lJ6Fy4AZwJXspBCdvA5tZ',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 300,
    },
    1000: {
      price: 49,
      price_id: 'price_1R1lKAFy4AZwJXspFOmgvPfs',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 1000,
    },
    2500: {
      price: 99,
      price_id: 'price_1R1lLKFy4AZwJXspcS0hvrF6',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 2500,
    },
    5000: {
      price: 149,
      price_id: 'price_1R1lMSFy4AZwJXspwU66LTcE',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 5000,
    },
    10000: {
      price: 249,
      price_id: 'price_1R1lNZFy4AZwJXspu8XaY9mC',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 10000,
    },
    50000: {
      price: 249,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 50000,
    },
    100000: {
      price: 249,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 100000,
    },
    250000: {
      price: 249,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 250000,
    },
    500000: {
      price: 249,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 500000,
    },
    1000000: {
      price: 249,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 1000000,
    },
  },
  pro_yearly: {
    100: {
      price: 7,
      price_id: 'price_1R1lI1Fy4AZwJXspb2d0Fpoj',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 100,
    },
    300: {
      price: 14,
      price_id: 'price_1R1lJPFy4AZwJXspDV59z2Qu',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 300,
    },
    1000: {
      price: 36,
      price_id: 'price_1R1lKTFy4AZwJXspZCxkeZN8',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 1000,
    },
    2500: {
      price: 74,
      price_id: 'price_1R1lLfFy4AZwJXsp6O0H4RPp',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 2500,
    },
    5000: {
      price: 111,
      price_id: 'price_1R1lMkFy4AZwJXsp2gzhUeiX',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 5000,
    },
    10000: {
      price: 186,
      price_id: 'price_1R1lNrFy4AZwJXspD6OtlB6P',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 10000,
    },
    50000: {
      price: 186,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 50000,
    },
    100000: {
      price: 186,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 100000,
    },
    250000: {
      price: 186,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 250000,
    },
    500000: {
      price: 186,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 500000,
    },
    1000000: {
      price: 186,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 1000000,
    },
  },
};

export const DEV_PRICE_MAP = {
  basic: {
    100: {
      price: 0,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 100,
    },
    300: {
      price: 10,
      price_id: 'price_1R17WOFy4AZwJXspvu4eO5dq',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 300,
    },
    1000: {
      price: 24,
      price_id: 'price_1R1R3vFy4AZwJXspZPXoCygt',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 1000,
    },
    2500: {
      price: 24,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 2500,
    },
    5000: {
      price: 24,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 5000,
    },
    10000: {
      price: 24,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 10000,
    },
    50000: {
      price: 24,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 50000,
    },
    100000: {
      price: 24,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 100000,
    },
    250000: {
      price: 24,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 250000,
    },
    500000: {
      price: 24,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 500000,
    },
    1000000: {
      price: 24,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'monthly',
      renders: 1000000,
    },
  },
  basic_yearly: {
    100: {
      price: 0,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 100,
    },
    300: {
      price: 7,
      price_id: 'price_1R1R3AFy4AZwJXspYzQ4Ghqt',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 300,
    },
    1000: {
      price: 18,
      price_id: 'price_1R1R4EFy4AZwJXspwTh25dEC',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 1000,
    },
    2500: {
      price: 18,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 2500,
    },
    5000: {
      price: 18,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 5000,
    },
    10000: {
      price: 18,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 10000,
    },
    50000: {
      price: 18,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 50000,
    },
    100000: {
      price: 18,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 100000,
    },
    250000: {
      price: 18,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 250000,
    },
    500000: {
      price: 18,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 500000,
    },
    1000000: {
      price: 18,
      price_id: '',
      plan_type: 'basic',
      billing_cycle: 'yearly',
      renders: 1000000,
    },
  },
  pro: {
    100: {
      price: 10,
      price_id: 'price_1R1R4lFy4AZwJXsppxkyzGLf',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 100,
    },
    300: {
      price: 19,
      price_id: 'price_1R1R5qFy4AZwJXsp5YTjklSh',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 300,
    },
    1000: {
      price: 49,
      price_id: 'price_1R1R6YFy4AZwJXspvxM4LAFN',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 1000,
    },
    2500: {
      price: 99,
      price_id: 'price_1R1R9LFy4AZwJXspxuBLyKze',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 2500,
    },
    5000: {
      price: 149,
      price_id: 'price_1R1RAVFy4AZwJXspHZUIAw0a',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 5000,
    },
    10000: {
      price: 249,
      price_id: 'price_1R1RBPFy4AZwJXspH4JnEj7a',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 10000,
    },
    50000: {
      price: 249,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 50000,
    },
    100000: {
      price: 249,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 100000,
    },
    250000: {
      price: 249,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 250000,
    },
    500000: {
      price: 249,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 500000,
    },
    1000000: {
      price: 249,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'monthly',
      renders: 1000000,
    },
  },
  pro_yearly: {
    100: {
      price: 7,
      price_id: 'price_1R1R5PFy4AZwJXspqLS918N4',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 100,
    },
    300: {
      price: 14,
      price_id: 'price_1R1R63Fy4AZwJXspGTwEMDwF',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 300,
    },
    1000: {
      price: 36,
      price_id: 'price_1R1R6sFy4AZwJXspdtRGgkzs',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 1000,
    },
    2500: {
      price: 74,
      price_id: 'price_1R1RA7Fy4AZwJXspfGA0WZ5F',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 2500,
    },
    5000: {
      price: 111,
      price_id: 'price_1R1RAzFy4AZwJXspRb1BSpLy',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 5000,
    },
    10000: {
      price: 186,
      price_id: 'price_1R1RBlFy4AZwJXsp6WZ7BzGM',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 10000,
    },
    50000: {
      price: 186,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 50000,
    },
    100000: {
      price: 186,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 100000,
    },
    250000: {
      price: 186,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 250000,
    },
    500000: {
      price: 186,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 500000,
    },
    1000000: {
      price: 186,
      price_id: '',
      plan_type: 'pro',
      billing_cycle: 'yearly',
      renders: 1000000,
    },
  },
};
