import { useAppDispatch, useAppSelector } from '@/hooks';
import { setActiveVariation } from '@/redux/slices/collection';
import '../editor.scss';
import {
  Text,
  Flex,
  Button,
  ScrollArea,
  AlertDialog,
  IconButton,
} from '@radix-ui/themes';
import { useState, useEffect, MutableRefObject } from 'react';
import { setActiveView, setActiveAction } from '@/redux/slices/editor';
import { psdEngineApi } from '@/services/psdEngine';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { AddDesignAssetDialog } from './AddDesignAssetDialog';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { toast } from 'react-toastify';
import { ReactComponent as MinusLinearIcon } from '@/assets/icons/minus-linear.svg';
import { ReactComponent as AddLinearIcon } from '@/assets/icons/add-linear.svg';

interface IDesignAssets {
  abortController: MutableRefObject<AbortController | null>;
}

export const DesignAssetsMobile = ({ abortController }: IDesignAssets) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const {
    designAssets,
    activeDesignAsset,
    activeColorCombination,
    mockupVariations,
    isCreatingVariations,
    isExportingMockups,
    mockup,
    activeSmartObject,
  } = useAppSelector((state) => state.collectionReducer);

  const [numberOfDesignAssetsAdding, setNumberOfDesignAssetsAdding] =
    useState<number>(0);

  const changeActiveDesignAsset = (id: number) => {
    if (isCreatingVariations) {
      return;
    }

    const variation = activeColorCombination?.id
      ? mockupVariations.find((mv) =>
          mv.variation_elements.some(
            (ve) =>
              ve?.asset_id === id &&
              mv.variation_elements.some(
                (innerVe) => innerVe?.color_id === activeColorCombination?.id
              )
          )
        )
      : mockupVariations.find((mv) =>
          mv.variation_elements.some((ve) => ve?.asset_id === id)
        );

    dispatch(setActiveVariation(variation));
  };

  const [removeAsset, { isLoading: isDeletingAsset }] =
    psdEngineApi.useDeleteAssetVariationMutation();

  const [isAdding, setIsAdding] = useState<boolean>(false);

  const designAssetAction = async (action: 'add' | 'edit', daId?: number) => {
    if (isCreatingVariations) {
      return;
    }
    changeActiveDesignAsset(daId!);
    await new Promise((resolve) => setTimeout(resolve, 50));

    dispatch(setActiveView('update-asset'));
    dispatch(setActiveAction(action));
  };

  const getNumerOfAssociatedVariations = (id: number) => {
    const associatedVariations = mockupVariations.filter((variation) => {
      return variation.variation_elements.some(
        (element) => element.asset_id === id
      );
    });

    return associatedVariations.length;
  };

  const canDeleteDesignAsset = (id: number) => {
    let isGeneratingSingleMockupVariation = false;
    mockupVariations.forEach((variation) => {
      variation.variation_elements.find((ve) => {
        if (
          ve.asset_id === id &&
          typeof variation.isGettingSingleMockupVariation !== 'undefined'
        ) {
          isGeneratingSingleMockupVariation =
            variation.isGettingSingleMockupVariation;
        }
      });
    });
    return !isGeneratingSingleMockupVariation && !isDeletingAsset;
  };

  const removeDesignAsset = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => {
    e.stopPropagation();
    try {
      await removeAsset({ id }).unwrap();
      captureEvent('Design asset removed', {
        id,
      });
    } catch (error: any) {
      toast.error(error.data.message, {
        toastId: 'remove_design_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  const [embedAssetEnabled, setEmbedAssetEnabled] = useState<boolean>(
    !!activeSmartObject?.input_options.is_artwork
  );

  useEffect(() => {
    setEmbedAssetEnabled(!!activeSmartObject?.input_options.is_artwork);
  }, [activeSmartObject]);

  const [updateSmartObjectInputOptions] =
    psdEngineApi.useUpdateSmartObjectInputOptionsMutation();

  const updateSmartObjectInputOptionsAction = async (checked: boolean) => {
    setEmbedAssetEnabled(checked);
    await updateSmartObjectInputOptions({
      soId: activeSmartObject.id,
      data: {
        is_artwork: +checked,
        is_color: activeSmartObject.input_options.is_color,
        is_text: activeSmartObject.input_options.is_text,
      },
    });
    toast.success(
      `Embed asset ${
        checked ? 'enabled' : 'disabled'
      } for current smart object.`,
      {
        toastId: 'successfull_payment',
        position: 'bottom-right',
        autoClose: 5000,
      }
    );
    await dispatch(
      psdEngineApi.endpoints.getMockupSmartObjects.initiate(
        {
          mockup_id: mockup.id,
        },
        { forceRefetch: true }
      )
    ).unwrap();
  };

  return (
    <Flex className="artwork-wrapper" direction={'row'} gap={'2'}>
      {!isAdding &&
        [...Array(1)].map((_, index) => (
          <AddDesignAssetDialog
            key={index}
            setNumberOfDesignAssetsAdding={(data) =>
              setNumberOfDesignAssetsAdding(data)
            }
            setIsAdding={(isAdding) => setIsAdding(isAdding)}
            abortController={abortController}
          >
            <Button
              className="new"
              variant="outline"
              size={'1'}
              color="gray"
              event-tracker-id="add-new-asset-action"
              onClick={() => captureEvent('Upload Dessign Mockup modal viewed')}
            >
              <AddLinearIcon
                width="28px"
                height="28px"
                className={`gray add icon`}
              />
            </Button>
          </AddDesignAssetDialog>
        ))}

      <ScrollArea
        type={'always'}
        scrollbars={'vertical'}
        style={{
          maxHeight: `40px`,
        }}
      >
        <Flex direction={'column'} gap={'1'}>
          {designAssets[activeSmartObject.id]
            ?.filter((da) => !da.is_ai_generated)
            ?.map((da) => (
              <Flex
                key={da.id}
                align={'center'}
                justify={'between'}
                gap={'2'}
                width={'100%'}
                pr={'2'}
              >
                <Flex
                  width={'100%'}
                  gap={'2'}
                  p={'2'}
                  align={'center'}
                  className={`item-wrapper ${
                    da.id === activeDesignAsset?.id && 'selected'
                  }`}
                  onClick={() => changeActiveDesignAsset(da.id)}
                >
                  <img className="artwork" src={`${da.asset_path}`} />

                  <Text
                    className="artwork-name"
                    size={'1'}
                    weight={'regular'}
                    color="gray"
                  >
                    {da.name}
                  </Text>
                </Flex>
                <AlertDialog.Root>
                  <AlertDialog.Trigger>
                    <IconButton
                      className="remove-design-button"
                      variant={'ghost'}
                      size={'1'}
                      color="gray"
                      onClick={(e) => e.stopPropagation()}
                      loading={
                        !canDeleteDesignAsset(da.id) ||
                        isCreatingVariations ||
                        isExportingMockups
                      }
                    >
                      <MinusLinearIcon
                        width="26px"
                        height="26px"
                        className="icon"
                      />
                    </IconButton>
                  </AlertDialog.Trigger>
                  <AlertDialog.Content style={{ maxWidth: 450 }}>
                    <AlertDialog.Title>Remove artwork asset</AlertDialog.Title>
                    <AlertDialog.Description size="2">
                      Are you sure you want to proceed? Removing this artwork
                      asset will{' '}
                      {getNumerOfAssociatedVariations(da.id) ===
                      mockupVariations.length
                        ? 'update'
                        : 'remove'}{' '}
                      {getNumerOfAssociatedVariations(da.id)} associated{' '}
                      {getNumerOfAssociatedVariations(da.id) === 1
                        ? 'variation.'
                        : 'variations.'}
                    </AlertDialog.Description>

                    <Flex gap="3" mt="4" justify="end">
                      <AlertDialog.Cancel>
                        <Button variant="soft" color="gray">
                          Cancel
                        </Button>
                      </AlertDialog.Cancel>
                      <AlertDialog.Cancel>
                        <Button
                          variant="solid"
                          color="red"
                          onClick={(e) => removeDesignAsset(e, da.id)}
                        >
                          Remove
                        </Button>
                      </AlertDialog.Cancel>
                    </Flex>
                  </AlertDialog.Content>
                </AlertDialog.Root>
              </Flex>
            ))}
        </Flex>
      </ScrollArea>
    </Flex>
  );
};
