import { Tabs, Box } from '@radix-ui/themes';
import { psdManagementApi } from '@/services/psdManagement';
import { useState } from 'react';
import { ViewWrapper } from '../../../components/catalog/components/view-wrapper/ViewWrapper';
import './choose-public-library.scss';
import { MockupsList } from './MockupsList';

interface IChoosePublicLibrary {
  activePublicLibraryCategoryId: number;
}

export const ChoosePublicLibrary = ({
  activePublicLibraryCategoryId,
}: IChoosePublicLibrary) => {
  return <MockupsList categoryId={activePublicLibraryCategoryId} />;
};
