import useCaptureEvent from '@/hooks/useCaptureEvent';
import { psdEngineApi } from '@/services/psdEngine';
import {
  MockupVariationElement,
  ActiveIntegrationDetails,
  ProductImage,
  Project,
  ProductShortDetails,
} from '@/services/types';
import { Box, Badge } from '@radix-ui/themes';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';

export interface IVariationImage {
  variation: MockupVariationElement;
  activeIntegrationDetails: ActiveIntegrationDetails;
  productImageSlots: ProductImage[];
  project: Project;
  productListing: {
    data: {
      details: ProductShortDetails;
      images: ProductImage[];
    };
    isLoading: boolean;
  };
}

export const VariationImage = ({
  variation,
  activeIntegrationDetails,
  productImageSlots,
  project,
  productListing,
}: IVariationImage) => {
  const captureEvent = useCaptureEvent();

  psdEngineApi.useGetSingleMockupVariationQuery(
    {
      variation_id: variation.id,
      subscription_type_id: 2,
    },
    {
      skip: !!variation?.export_path || !variation?.id,
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <Box className="variation-image">
      {variation?.export_path ? (
        <LazyLoadImage
          draggable={
            !productImageSlots.some(
              (image) => image.image_url === variation.export_path
            )
          }
          onDragEnd={(ev) => {
            ev.currentTarget.parentElement?.parentElement?.classList.remove(
              'is-dragged'
            );
          }}
          onDragStart={(ev) => {
            ev.currentTarget.parentElement?.parentElement?.classList.add(
              'is-dragged'
            );

            const img = document.createElement('img');
            img.classList.add('dragged-ghost-image');
            document.body.append(img);
            img.src = variation.export_path;
            ev.dataTransfer.setDragImage(img, 0, 0);

            ev.dataTransfer.setData(
              'data',
              JSON.stringify({
                id: variation.id,
                image: variation.export_path,
                variation: variation,
                project: project,
              })
            );
            captureEvent('Image Dragged', {
              type: 'Etsy',
              shop_name: activeIntegrationDetails?.shop_name,
              shop_url: activeIntegrationDetails?.shop_url,
              template_name: project.name,
              template_id: project.id,
              template_category: project.psd_category_id,
              product_info: productListing.data.details,
            });
          }}
          style={{
            objectFit: 'cover',
            width: '100%',
            objectPosition: 'center',
          }}
          effect="blur"
          src={variation.export_path}
          delayTime={0}
          placeholderSrc={variation.export_path}
          wrapperProps={{
            style: { transitionDelay: '0s' },
          }}
        />
      ) : (
        <Skeleton width={'100%'} height={'100%'} />
      )}
      {productImageSlots.some(
        (image) => image.image_url === variation.export_path
      ) && (
        <Badge color="green" className="selected-badge">
          Selected
        </Badge>
      )}
      <Box className="hover-outline"></Box>
    </Box>
  );
};
