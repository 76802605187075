import { useAppDispatch, useAppSelector } from '@/hooks';
import { printareaPresetsApi } from '@/services/printareaPresets';
import { psdEngineApi } from '@/services/psdEngine';
import { PrintareaPreset } from '@/services/types';
import { Dialog, Button, Flex, TextField, Text } from '@radix-ui/themes';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IFormInput {
  presetName: string;
  psdApply: boolean;
}

interface IEditPreset {
  preset: PrintareaPreset;
}

export const EditPreset = forwardRef(({ preset }: IEditPreset, ref) => {
  const captureEvent = useCaptureEvent();
  const dispatch = useAppDispatch();
  const { mockup } = useAppSelector((state) => state.collectionReducer);
  const [open, setOpen] = useState<boolean>(false);

  const [updatePresetName, { isLoading: isUpdating }] =
    printareaPresetsApi.useUpdatePrintareaPresetNameMutation();

  const updatePresetAction = async (formInput: IFormInput) => {
    try {
      await updatePresetName({ id: preset.id, name: formInput.presetName });
      captureEvent('Print area preset updated', {
        title: formInput.presetName,
      });
      setOpen(false);
      await dispatch(
        psdEngineApi.endpoints.getMockupSmartObjects.initiate(
          { mockup_id: mockup.id },
          {
            forceRefetch: true,
          }
        )
      ).unwrap();
      toast.success('Preset successfully updated.', {
        toastId: 'preset_action_success_update',
        position: 'bottom-right',
        autoClose: 5000,
      });
    } catch (error: any) {
      console.log(error.data);
      toast.error(error.data.message, {
        toastId: 'preset_action_error_update',
        position: 'bottom-right',
        autoClose: 5000,
      });
    }
  };

  const formRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IFormInput>({
    values: {
      presetName: preset?.name,
      psdApply: !!preset?.reflected_to_psd,
    },
  });
  const onSubmit: SubmitHandler<IFormInput> = (data) =>
    updatePresetAction(data);

  useEffect(() => {
    reset();
  }, [open]);

  useImperativeHandle(ref, () => ({
    openDialog() {
      setOpen(true);
    },
    closeDialog() {
      setOpen(false);
    },
  }));

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content maxWidth={'450px'}>
        <Dialog.Title>
          <Flex align={'center'} justify={'between'}>
            <Text>Rename print area preset</Text>
          </Flex>
        </Dialog.Title>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          ref={formRef}
        >
          <Flex width={'100%'} gap={'1'} direction="column">
            <label>
              <TextField.Slot>
                <Text weight={'medium'} size={'1'} mb={'1'} mt={'3'}>
                  Preset name
                </Text>
              </TextField.Slot>
              <TextField.Root
                size={'2'}
                placeholder="Top right"
                {...register('presetName', {
                  required: {
                    value: true,
                    message: "Preset name field can't be empty",
                  },
                })}
              />
            </label>
            {errors.presetName && (
              <Text role="alert" size={'1'} color="red">
                {errors.presetName.message}
              </Text>
            )}
          </Flex>
          <Flex gap="5" mt="4" justify="between">
            <Flex></Flex>
            <Flex justify="end" gap="3">
              <Dialog.Close>
                <Button variant="soft" color="gray">
                  Cancel
                </Button>
              </Dialog.Close>
              <Button size={'2'} loading={isUpdating}>
                Rename
              </Button>
            </Flex>
          </Flex>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  );
});
