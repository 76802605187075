import { Text, Flex, Button, TextArea, Switch, Box } from '@radix-ui/themes';
import '../editor.scss';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { aiBackgroundsApi } from '@/services/aiBackgrounds';
import { useMediaQuery } from 'react-responsive';
import { AiBackgroundPreview } from '../../view-wrapper/components/AiBackgroundPreview';
import {
  resetAiBackgroundsState,
  setCurrentUserPrompt,
  setIsGeneratingPreviewImage,
  setIsImageGenerated,
} from '@/redux/slices/aiBackgrounds';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import { toast } from 'react-toastify';
import { ReactComponent as ArrowLeft } from '@/assets/icons/arrow-left.svg';
import useAddAiBackgroundToSmartObject from '@/hooks/useAddAiBackgroundToSmartObject';
import { setActiveView } from '@/redux/slices/editor';
import { CheckIcon } from '@radix-ui/react-icons';
import { useParams } from 'react-router-dom';
import { ReactComponent as TreeSquaresDefaultIcon } from '@/assets/icons/three-squares-linear-default.svg';
import { paymentsApi } from '@/services/payments';
import { formatNumberOfCredits } from '@/helpers';

interface IFormInput {
  prompt: string;
  negativePromptEnabled: boolean;
  negativePrompt: string;
}

export const AiBackground = () => {
  const dispatch = useAppDispatch();
  const { collectionId } = useParams();
  const isAppPro = useFeatureFlagEnabled('app-pro');
  const { mockup, activeSmartObject } = useAppSelector(
    (state) => state.collectionReducer
  );
  const { aiBackgroundImage } = useAppSelector(
    (state) => state.aiBackgroundsReducer
  );
  const { activeView } = useAppSelector((state) => state.editorReducer);
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const formRef = useRef(null);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const captureEvent = useCaptureEvent();
  const hasAiBackgrounds = useFeatureFlagEnabled('ai-backgrounds');

  const userRendersData = paymentsApi.useGetUserRendersQuery(
    { dummy: open } as any,
    { skip: !open, refetchOnMountOrArgChange: true }
  );

  const [numberOfCreditsLeft, setNumberOfCreditsLeft] = useState<number>(
    userRendersData.data?.data?.[0]?.renders_remaining || 0
  );

  useEffect(() => {
    setNumberOfCreditsLeft(
      userRendersData.data?.data?.[0]?.renders_remaining || 0
    );
  }, [userRendersData]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    getValues,
    reset,
  } = useForm<IFormInput>({
    defaultValues: {
      prompt: '',
      negativePrompt:
        'oversaturated, ugly, 3d, render, cartoon, grain, low-res, kitsch, text, lens, camera',
      negativePromptEnabled: false,
    },
  });
  const onSubmit: SubmitHandler<IFormInput> = (data) =>
    updateMockupAction(data);

  const updateMockupAction = async (formInput: IFormInput) => {
    if (numberOfCreditsLeft - 10 < 0) {
      captureEvent('Upgrade your plan modal viewed', {
        source: 'AI Background prompt',
      });
      dispatch(setIsAppPaymentPopupOpen(true));
    }

    if (hasAiBackgrounds) {
      try {
        setIsGenerating(true);
        dispatch(setIsGeneratingPreviewImage(true));
        const data = {
          mockup_id: mockup.id,
          prompt: formInput.prompt,
          negative_prompt: formInput.negativePrompt,
          smart_object_id: activeSmartObject.id,
        };
        await dispatch(
          aiBackgroundsApi.endpoints.generateAiBackgroundImage.initiate(data, {
            forceRefetch: true,
          })
        ).unwrap();
        dispatch(setIsImageGenerated(true));
        dispatch(setCurrentUserPrompt(formInput.prompt));
        captureEvent('Ai background image successfully generated', {
          prompt: data,
        });
        setNumberOfCreditsLeft((prevState) => prevState - 10);
      } catch (error: any) {
        console.log(error);
        captureEvent('Error generaing Ai background image', { error });
        toast.error(error.data.message, {
          toastId: 'generate-ai-image-error',
          position: 'bottom-right',
          autoClose: 7000,
        });
      } finally {
        setIsGenerating(false);
        dispatch(setIsGeneratingPreviewImage(false));
      }
    } else {
      captureEvent('Upgrade your plan modal viewed', {
        source: 'AI Background prompt',
      });
      dispatch(setIsAppPaymentPopupOpen(true));
    }
  };

  useEffect(() => {
    dispatch(setIsImageGenerated(false));
    dispatch(resetAiBackgroundsState());
  }, []);

  const getNumOfCreditsLeft = () => {
    if (currentUser?.subscription === 'free') {
      return false;
    }
    try {
      return (
        parseInt((currentUser?.ai_credits as any)?.total || 0) -
          parseInt((currentUser?.ai_credits as any)?.used || 0) <
        1
      );
    } catch (error) {
      return true;
    }
  };
  const { applyOnSmartObject, isApplying } = useAddAiBackgroundToSmartObject();

  const addScene = async () => {
    await applyOnSmartObject(aiBackgroundImage, collectionId as string);
    captureEvent('Ai generated background asset added', {
      collectionId,
      aiBackgroundImage,
    });
    dispatch(setActiveView('preview'));
  };

  const goBack = () => {
    dispatch(setActiveView('preview'));
  };

  return (
    <Flex direction={'column'} gap={'2'} p={'4'} width={'100%'}>
      {isMobile && (
        <Flex align={'center'} justify={'between'} mb={'4'}>
          <Button
            variant="ghost"
            color="gray"
            className="back-button"
            onClick={() => goBack()}
          >
            <ArrowLeft width="14px" height="14px" className="icon" />
            {!isMobile && <Text weight={'medium'}>Back</Text>}
          </Button>
          <Button
            disabled={!aiBackgroundImage}
            loading={isApplying}
            onClick={() => {
              if (isAppPro) {
                addScene();
              } else {
                captureEvent('Upgrade your plan modal viewed', {
                  source: 'Add AI Scene',
                });
                dispatch(setIsAppPaymentPopupOpen(true));
              }
            }}
          >
            <CheckIcon /> Add Scene
          </Button>
        </Flex>
      )}
      {isMobile && activeView === 'create-ai-background' && (
        <Box px={'4'}>
          <AiBackgroundPreview />
        </Box>
      )}
      {!isMobile && (
        <Flex align={'center'} justify={'between'} mb={'4'}>
          <Button
            variant="ghost"
            color="gray"
            className="back-button"
            onClick={() => goBack()}
          >
            <ArrowLeft width="14px" height="14px" className="icon" />
            {!isMobile && <Text weight={'medium'}>Back</Text>}
          </Button>
          <Button
            disabled={!aiBackgroundImage}
            loading={isApplying}
            onClick={() => {
              if (isAppPro) {
                addScene();
              } else {
                captureEvent('Upgrade your plan modal viewed', {
                  source: 'Add AI Scene',
                });
                dispatch(setIsAppPaymentPopupOpen(true));
              }
            }}
          >
            <CheckIcon /> Add Scene
          </Button>
        </Flex>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        ref={formRef}
      >
        <Flex direction="column" gap="4">
          <label>
            <Text as="div" size="1" mb="1" weight="medium">
              Background Prompt
            </Text>
            <TextArea
              size="3"
              resize="vertical"
              style={{ minHeight: '100px' }}
              placeholder="Mountain at sunset"
              {...register('prompt', {
                required: {
                  value: true,
                  message: 'Prompt field is required',
                },
                maxLength: {
                  value: 255,
                  message: 'Maximum Prompt length is 255 characters',
                },
              })}
            />
            {errors.prompt && (
              <Text role="alert" size={'1'} color="red">
                {errors.prompt.message}
              </Text>
            )}
          </label>
          <label>
            <Flex justify={'between'} align={'center'} mb={'1'}>
              <Text as="div" size="1" mb="1" weight="medium">
                Negative Prompt
              </Text>
              <Controller
                name="negativePromptEnabled"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch checked={value} onCheckedChange={onChange} />
                )}
              />
            </Flex>

            <TextArea
              size="3"
              resize="vertical"
              style={{ minHeight: '100px' }}
              placeholder="oversaturated, ugly, 3d, render, cartoon, grain"
              disabled={!watch().negativePromptEnabled}
              {...register('negativePrompt', {
                required: {
                  value: watch().negativePromptEnabled,
                  message: "Negative Prompt field can't be empty",
                },
                maxLength: {
                  value: 255,
                  message: 'Maximum Negative Prompt length is 255 characters',
                },
              })}
            />
            {errors.negativePrompt && (
              <>
                <Text role="alert" size={'1'} color="red">
                  {errors.negativePrompt.message}
                </Text>
                <br />
              </>
            )}
            <Text
              weight="medium"
              color="gray"
              style={{ lineHeight: 'normal', fontSize: '12px' }}
            >
              Refine your generated background by specifying elements our AI
              won’t include in the final image. For example, write “flowers” if
              you want a scene without any flowers in view.
            </Text>
          </label>
        </Flex>
        <Flex gap="3" mt="4" justify="center">
          <Button
            loading={isGenerating}
            disabled={getValues().prompt === '' || getNumOfCreditsLeft()}
            style={{ width: '100%' }}
          >
            <Flex gap={'1'} className="gen-scene-btn">
              <Text>✨ Generate Scene </Text>
              <Text>
                (10
                <TreeSquaresDefaultIcon
                  className={`icon ${
                    getValues().prompt === '' || getNumOfCreditsLeft()
                      ? 'disabled'
                      : 'default'
                  }`}
                  width="12"
                  height="12"
                />
                )
              </Text>
            </Flex>
          </Button>
        </Flex>
      </form>
      <Flex
        mt={'2'}
        gap={'2'}
        align={'center'}
        justify={'center'}
        className="credits-description"
      >
        <TreeSquaresDefaultIcon className="icon" width="14" height="14" />
        <Text size={'1'} weight={'medium'} color="gray">
          Available Credits: {formatNumberOfCredits(numberOfCreditsLeft)}
        </Text>
      </Flex>
    </Flex>
  );
};
