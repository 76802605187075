import { PrivatePhotoshopFile } from '@/services/types';
import './choose-public-library.scss';
import { TemplatestList } from './TemplatestList';

interface IChooseMyTemplates {
  activeMockupCollectionId: number;
  getPrivatePsds: {
    isLoading: boolean;
    privatePsdsData: PrivatePhotoshopFile[] | undefined;
  };
}

export const ChooseMyTemplates = ({
  activeMockupCollectionId,
  getPrivatePsds,
}: IChooseMyTemplates) => {
  return (
    <TemplatestList
      collectionId={activeMockupCollectionId}
      getPrivatePsds={{
        isLoading: getPrivatePsds.isLoading,
        privatePsdsData: getPrivatePsds.privatePsdsData,
      }}
    />
  );
};
