import './view-wrapper.scss';
import {
  Flex,
  Text,
  Button,
  DropdownMenu,
  Box,
  IconButton,
  Tooltip,
} from '@radix-ui/themes';
import { ReactComponent as PenBold } from '@/assets/icons/pen-bold.svg';
import { DotsHorizontalIcon, PlusIcon } from '@radix-ui/react-icons';
import { useRef } from 'react';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { ColorSet } from '@/services/colors';
import { EditColorSet } from './EditColorSet';
import { DeleteColorSet } from './DeleteColorSet';
import { EditColor } from './EditColor';
import { getContrastingColor } from '@/helpers';

interface ISingleColorSet {
  colorSet: ColorSet;
}

export const SingleColorSet = ({ colorSet }: ISingleColorSet) => {
  const captureEvent = useCaptureEvent();

  const editColorSetDialog = useRef();
  const deleteColorSetDialog = useRef();

  const collectionNames = colorSet.collections.map((col) => col.name);

  const formattedText = () => {
    if (collectionNames.length <= 3) {
      return collectionNames.join(', ');
    } else {
      const firstThree = collectionNames.slice(0, 3).join(', ');
      const remainingCount = collectionNames.length - 3;
      return (
        <>
          {firstThree} and{' '}
          <Tooltip content={collectionNames.slice(3).join(', ')}>
            <Text size={'1'} weight={'bold'} style={{ cursor: 'default' }}>
              {remainingCount} other Collections
            </Text>
          </Tooltip>
        </>
      );
    }
  };

  return (
    <Flex
      className="single-color-set"
      direction={'column'}
      px={'4'}
      py={'3'}
      width={'100%'}
      height={'auto'}
    >
      <EditColorSet colorSet={colorSet} ref={editColorSetDialog} />
      <DeleteColorSet colorSet={colorSet} ref={deleteColorSetDialog} />
      <Flex
        className="header"
        align={'center'}
        justify={'between'}
        px={'4'}
        py={'2'}
      >
        <Text className="name" size={'2'} weight={'regular'}>
          {colorSet.name}
        </Text>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger className="ddl-options-trigger">
            <Button variant="ghost" color="gray" size={'2'}>
              <DotsHorizontalIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <DropdownMenu.Item
              onClick={() => {
                captureEvent('Rename Color Set modal opened');
                (editColorSetDialog?.current as any)?.openDialog();
              }}
            >
              Rename
            </DropdownMenu.Item>
            <DropdownMenu.Separator />
            <DropdownMenu.Item
              color="red"
              onClick={() => {
                captureEvent('Delete Color set modal opened');
                (deleteColorSetDialog?.current as any)?.openDialog();
              }}
            >
              Delete
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Flex>
      <Flex className="content" px={'4'} py={'2'} gap={'2'}>
        {colorSet.color_set_colors.map((color) => (
          <Tooltip key={color.id} content={color.name}>
            <Flex
              className={`color-item`}
              align={'center'}
              justify={'center'}
              id="color-item"
              style={{ backgroundColor: color.hex_code }}
            >
              <Box
                className="color"
                style={{ backgroundColor: color.hex_code }}
              ></Box>

              <EditColor type="update" color={color} colorSet={colorSet}>
                <Flex className="edit" align={'center'} justify={'center'}>
                  <PenBold
                    width={'12px'}
                    height={'12px'}
                    style={{ fill: getContrastingColor(color.hex_code) }}
                  />
                </Flex>
              </EditColor>
            </Flex>
          </Tooltip>
        ))}

        <EditColor type="add" colorSet={colorSet}>
          <Button className="new" variant="surface" color="gray">
            <PlusIcon className="icon" width={'28px'} height={'28px'} />
          </Button>
        </EditColor>
      </Flex>
      <Flex className="footer" px={'4'} py={'4'}>
        <Text size={'1'}>
          Shown on:{' '}
          {colorSet.visibility === 'all' ? (
            <Text weight={'bold'}>All Collections</Text>
          ) : (
            formattedText()
          )}
        </Text>
      </Flex>
    </Flex>
  );
};
