import './view-wrapper.scss';
import { DesignsUploader } from '../DesignsUploader';
import { ScrollArea } from '@radix-ui/themes';
import Uppy from '@uppy/core';
import { useEffect, useRef, useState } from 'react';
import { SelectedDesignsOptionsBar } from './SelectedDesignsOptionsBar';
import { DeleteDesign } from './DeleteDesign';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { addSelectedDesignIds } from '@/redux/slices/mockupBundles';

export const ViewWrapper = ({
  setUppyDashboardInstance,
  handleUploadClick,
  setSelectedDesignIds,
}: {
  setUppyDashboardInstance: (
    uppy: Uppy<Record<string, unknown>, Record<string, unknown>>
  ) => void;
  handleUploadClick?: () => void;
  setSelectedDesignIds?: (ids: number[]) => void;
}) => {
  const dispatch = useAppDispatch();
  const { showNotificationBar } = useAppSelector((state) => state.appReducer);
  const { selectedDesignIds } = useAppSelector(
    (state) => state.mockupBundlesReducer
  );
  const [selectedDesigns, setSelectedDesigns] = useState<number[]>([]);
  const deleteDesignsDialog = useRef();

  useEffect(() => {
    if (setSelectedDesignIds) {
      setSelectedDesignIds(selectedDesigns || []);
    }
  }, [selectedDesigns]);

  return (
    <>
      <DeleteDesign
        ref={deleteDesignsDialog}
        setSelectedDesigns={(x) => {
          setSelectedDesigns(x);
          dispatch(addSelectedDesignIds(x));
        }}
      />
      <SelectedDesignsOptionsBar
        selectedDesigns={
          setSelectedDesignIds ? selectedDesignIds : selectedDesigns
        }
        setSelectedDesigns={(x) => {
          setSelectedDesigns(x);
          dispatch(addSelectedDesignIds(x));
        }}
        deleteDesignsDialog={deleteDesignsDialog}
      />
      <ScrollArea id="scroll-container" className="scroll-area-page-wrapper">
        <DesignsUploader
          selectDesign={() => undefined}
          setUppyDashboardInstance={setUppyDashboardInstance}
          handleUploadClick={handleUploadClick}
          setSelectedDesigns={(x) => {
            setSelectedDesigns(x);
            dispatch(addSelectedDesignIds(x));
          }}
          selectedDesigns={
            setSelectedDesignIds ? selectedDesignIds : selectedDesigns
          }
          isDesignsLibrary={true}
          deleteDesignsDialog={deleteDesignsDialog}
        />
      </ScrollArea>
    </>
  );
};
