import { Dialog, Button, Flex, TextField, Text, Box } from '@radix-ui/themes';
import { useAppDispatch } from '@/hooks';
import { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import Colorful from '@uiw/react-color-colorful';
import { ColorSet, ColorSetColor, colorsApi } from '@/services/colors';
import { toast } from 'react-toastify';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';

interface IEditColor {
  colorSet: ColorSet;
  type: 'add' | 'update';
  color?: ColorSetColor;
  children: JSX.Element;
}

interface IFormInput {
  hex: string;
  colorName: string;
}

export const EditColor = forwardRef(
  ({ color, type, children, colorSet }: IEditColor, ref) => {
    const dispatch = useAppDispatch();
    const captureEvent = useCaptureEvent();
    const [open, setOpen] = useState<boolean>(false);

    const [hex, setHex] = useState<string>(
      type === 'add' ? '#FFF' : color?.hex_code || '#FFF'
    );

    useImperativeHandle(ref, () => ({
      openDialog() {
        setOpen(true);
      },
      closeDialog() {
        setOpen(false);
      },
    }));

    useEffect(() => {
      setValue('hex', hex);
    }, [hex]);

    const [addColor] = colorsApi.useAddColorMutation();
    const [updateColor] = colorsApi.useUpdateColorMutation();
    const [deleteColor] = colorsApi.useDeleteColorSetColorMutation();

    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      setValue,
    } = useForm<IFormInput>({
      defaultValues: {
        hex: type === 'add' ? '#FFF' : color?.hex_code,
        colorName: type === 'add' ? '' : color?.name,
      },
    });

    const onSubmit: SubmitHandler<IFormInput> = (data) => hexSubmit(data);

    const hexSubmit = async (data: IFormInput) => {
      try {
        if (type === 'add') {
          await addColor({
            color_set_id: colorSet.id,
            hex_code: data.hex,
            name: data.colorName || data.hex,
          });
          await dispatch(
            colorsApi.endpoints.getColorSets.initiate(null!, {
              forceRefetch: true,
            })
          ).unwrap();
          captureEvent('Preset color added', {
            hex: data.hex,
          });
          toast.success('Color successfully created.', {
            toastId: 'color_action_success',
            position: 'bottom-right',
            autoClose: 5000,
          });
        } else {
          await updateColor({
            id: color?.id as number,
            hex_code: data.hex,
            name: data.colorName || data.hex,
          });
          await dispatch(
            colorsApi.endpoints.getColorSets.initiate(null!, {
              forceRefetch: true,
            })
          ).unwrap();
          captureEvent('Preset color update', {
            oldHex: color?.hex_code,
            newHex: data.hex,
          });
          toast.success('Color successfully updated.', {
            toastId: 'color_action_success_update',
            position: 'bottom-right',
            autoClose: 5000,
          });
        }
        reset();
        setOpen(false);
      } catch (error: any) {
        console.log(error.data);
      }
    };

    const deleteColorAction = async () => {
      await deleteColor({
        id: color?.id as number,
      });
      toast.success('Color successfully deleted.', {
        toastId: 'color_action_success_delete',
        position: 'bottom-right',
        autoClose: 5000,
      });
      captureEvent('Preset color removed', {
        hex: color?.hex_code,
      });
      reset();
      setOpen(false);
      await dispatch(
        colorsApi.endpoints.getColorSets.initiate(null!, {
          forceRefetch: true,
        })
      ).unwrap();
    };

    return (
      <Dialog.Root open={open} onOpenChange={(open) => setOpen(open)}>
        <Dialog.Trigger>{children}</Dialog.Trigger>

        <Dialog.Content
          maxWidth="350px"
          className="add-edit-color-dialog"
          aria-describedby={undefined}
        >
          <Dialog.Title>
            <Flex justify={'between'} align={'center'}>
              {type === 'add' ? 'Choose Color' : 'Edit Color'}
              <Dialog.Close>
                <Button size={'2'} color="gray" variant="ghost">
                  <CloseIcon className="icon black" />
                </Button>
              </Dialog.Close>
            </Flex>
          </Dialog.Title>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '400px',
            }}
          >
            <Flex direction="column" gap="2">
              <Box>
                <label>
                  <Text size={'1'} weight={'bold'}>
                    Color name
                  </Text>
                  <TextField.Root
                    placeholder="Blue"
                    {...register('colorName', {
                      required: {
                        value: false,
                        message: 'Color name field is required',
                      },
                    })}
                  ></TextField.Root>
                  {errors.colorName && (
                    <Text role="alert" size={'1'} color="red">
                      {errors.colorName.message}
                    </Text>
                  )}
                </label>
              </Box>
              <Flex
                className="color-picker-wrapper"
                direction={'column'}
                gap={'2'}
              >
                <Flex gap={'2'} className="previews" width={'100%'}>
                  <Flex
                    className="color-pickers"
                    direction={'column'}
                    gap={'2'}
                    width={'100%'}
                  >
                    <Colorful
                      color={hex || '#0587f0'}
                      disableAlpha={true}
                      onChange={(color) => {
                        setHex(color.hex);
                      }}
                    />
                  </Flex>
                </Flex>
                <Box>
                  <label>
                    <TextField.Root
                      placeholder="#f0fCCC"
                      {...register('hex', {
                        required: {
                          value: true,
                          message: 'Hex field is required',
                        },
                        pattern: {
                          value: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
                          message: `Entered value is not valid hex format`,
                        },
                      })}
                    >
                      <TextField.Slot>
                        <Text weight={'medium'} size={'2'}>
                          HEX
                        </Text>
                      </TextField.Slot>
                    </TextField.Root>
                    {errors.hex && (
                      <Text role="alert" size={'1'} color="red">
                        {errors.hex.message}
                      </Text>
                    )}
                  </label>
                </Box>
              </Flex>
            </Flex>
            <Flex gap="3" mt="4" justify="end">
              {type === 'update' && (
                <Button
                  variant="solid"
                  color="red"
                  onClick={(e) => {
                    e.preventDefault();
                    deleteColorAction();
                  }}
                >
                  Delete
                </Button>
              )}
              {type === 'add' && (
                <Dialog.Close>
                  <Button variant="soft" color="gray">
                    Cancel
                  </Button>
                </Dialog.Close>
              )}
              <Button variant="solid" color="blue" type="submit">
                {type === 'add' ? ' Add Color' : 'Update Color'}
              </Button>
            </Flex>
          </form>
        </Dialog.Content>
      </Dialog.Root>
    );
  }
);
