import { Helmet } from 'react-helmet-async';
import { ViewWrapper } from './components/view-wrapper/ViewWrapper';
import './designs.scss';
import { Button, Flex, Text } from '@radix-ui/themes';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import { useRef, useState } from 'react';
import Uppy from '@uppy/core';
import posthog from 'posthog-js';
import { useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';

export const Designs = () => {
  const captureEvent = useCaptureEvent();
  const { designs } = useAppSelector((state) => state.designsReducer);
  const [uppyDashboardInstance, setUppyDashboardInstance] =
    useState<Uppy<Record<string, unknown>, Record<string, unknown>>>();

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    captureEvent('Upload Design initiated', {
      type: 'button',
    });

    uppyDashboardInstance?.addFiles(event.target.files as any);
  };

  return (
    <Flex
      className="view-wrapper-design-templates"
      direction={'column'}
      gap={'0'}
      id="selection-box-container"
    >
      <Helmet>
        <title>Dynamic Mockups | Artwork</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Flex
        className="header"
        px={'5'}
        py={'3'}
        align={'center'}
        justify={'between'}
      >
        <Text weight={'bold'} size={'5'}>
          Artwork
        </Text>
        {designs?.length > 0 && (
          <Button onClick={() => handleClick()}>
            <PlusIcon className="icon white" />
            <Text size="1" weight="medium">
              Add Artwork
            </Text>
          </Button>
        )}
        <input
          type="file"
          accept=".png, .jpg, .jpeg, .webp" // , .webp, .gif
          multiple
          onChange={(event) => onFileUpload(event)}
          onClick={(event) => {
            (event.target as any).value = null;
          }}
          ref={hiddenFileInput}
          style={{ display: 'none' }}
        />
      </Flex>
      <ViewWrapper
        setUppyDashboardInstance={setUppyDashboardInstance}
        handleUploadClick={() => handleClick()}
      />
    </Flex>
  );
};
