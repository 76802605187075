import './view-wrapper.scss';
import {
  Flex,
  Text,
  Button,
  DropdownMenu,
  Checkbox,
  Badge,
  Tooltip,
  Box,
} from '@radix-ui/themes';
import { Project } from '@/services/types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DotsVerticalIcon } from '@radix-ui/react-icons';
import { useRef } from 'react';
import { DeleteMyTemplate } from './DeleteMyTemplate';
import { EditPhotoshopFile } from './EditPhotoshopFile';
import posthog from 'posthog-js';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { mockupCollectionsApi } from '@/services/mockupCollections';
import { workspaceApi } from '@/services/workspace';
import { toast } from 'react-toastify';
import { CollectionsAddRemove } from './CollectionsAddRemove';

interface ISingleTemplate {
  project: Project;
}

export const SingleTemplate = ({ project }: ISingleTemplate) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const captureEvent = useCaptureEvent();
  const deleteMyTemplateDialog = useRef();
  const editPhotoshopFileDialog = useRef();
  const { mockupCollections, activeMockupCollectionId } = useAppSelector(
    (state) => state.mockupCollectionsReducer
  );

  const addToMockupCollection = async (
    checked: string | boolean,
    mockupCollectionId: number
  ) => {
    const data = {
      mockup_id: project.id,
      mockup_collections: [mockupCollectionId],
      activeMockupCollectionId,
    };
    if (checked) {
      await dispatch(
        mockupCollectionsApi.endpoints.addToMockupCollection.initiate(data)
      ).unwrap();
      toast.success('Template successfully added to collection.', {
        toastId: 'update_collection_success',
        position: 'bottom-right',
        autoClose: 5000,
      });
    } else {
      await dispatch(
        mockupCollectionsApi.endpoints.removeFromMockupCollection.initiate(data)
      ).unwrap();
      toast.success('Template successfully removed to collection.', {
        toastId: 'update_collection_success',
        position: 'bottom-right',
        autoClose: 5000,
      });
    }

    dispatch(
      mockupCollectionsApi.endpoints.getMockupCollections.initiate(null!, {
        forceRefetch: true,
      })
    );
    dispatch(
      workspaceApi.endpoints.getMyProjects.initiate(
        { collectionId: activeMockupCollectionId },
        {
          forceRefetch: true,
        }
      )
    );
  };

  const findNameById = (id: number): string | undefined => {
    const item = mockupCollections.find((col) => col.id === id);
    return item ? item.name : undefined;
  };

  return (
    <Flex className={'single-template'} align={'end'} direction={'column'}>
      <EditPhotoshopFile project={project} ref={editPhotoshopFileDialog} />
      <DeleteMyTemplate project={project} ref={deleteMyTemplateDialog} />

      <Flex align={'center'} justify={'between'} className="dd-option-wrapper">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button className="ddl-options" variant="surface" size={'1'}>
              <DotsVerticalIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <DropdownMenu.Item
              onClick={() => {
                captureEvent('Rename Template modal opened');
                (editPhotoshopFileDialog?.current as any)?.openDialog();
              }}
            >
              Rename
            </DropdownMenu.Item>
            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger>Collection</DropdownMenu.SubTrigger>
              {mockupCollections.length > 0 ? (
                <DropdownMenu.SubContent>
                  {mockupCollections?.map((collection) => (
                    <CollectionsAddRemove
                      key={collection.id}
                      collection={collection}
                      project={project}
                    />
                  ))}
                </DropdownMenu.SubContent>
              ) : (
                <DropdownMenu.SubContent>
                  <Text size={'2'}>No collections found</Text>
                </DropdownMenu.SubContent>
              )}
            </DropdownMenu.Sub>
            <DropdownMenu.Separator />
            <DropdownMenu.Item
              color="red"
              onClick={() => {
                (deleteMyTemplateDialog?.current as any)?.openDialog();
              }}
            >
              Delete
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Flex>

      <LazyLoadImage
        alt={`${project.name}`}
        className=""
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          objectPosition: 'center',
        }}
        effect="blur"
        src={project.thumbnail || 'https://placehold.co/300x300?text=MOCKUP'}
        delayTime={0}
        placeholderSrc={
          project.thumbnail || 'https://placehold.co/300x300?text=MOCKUP'
        }
        wrapperProps={{
          style: { transitionDelay: '0s' },
        }}
      />

      <Flex
        className="head-wrapper"
        direction={'column'}
        align={'center'}
        p={'4'}
        gap={'2'}
      >
        {project.mockup_collections.length !== 0 &&
          (project.mockup_collections.length === 1 ? (
            <Badge
              className="project-collections"
              color="gray"
              variant="surface"
            >
              <Tooltip
                content={
                  <Text>{findNameById(project.mockup_collections[0].id)}</Text>
                }
              >
                <Text>{findNameById(project.mockup_collections[0].id)}</Text>
              </Tooltip>
            </Badge>
          ) : (
            <Badge
              className="project-collections"
              color="gray"
              variant="surface"
            >
              <Tooltip
                content={
                  <>
                    {project.mockup_collections.map((col, index) => (
                      <span key={col.id}>
                        <Text>
                          {findNameById(col.id)}
                          {index < project.mockup_collections.length - 1 &&
                            ', '}
                        </Text>
                      </span>
                    ))}
                  </>
                }
              >
                <Text>
                  {project.mockup_collections.length.toString()} Collections
                </Text>
              </Tooltip>
            </Badge>
          ))}
      </Flex>
      <Link
        className="text-wrapper"
        to={{
          pathname: `/mockup/${project.uuid}`,
        }}
        state={{ from: location.pathname }}
      >
        <Flex align={'end'} p={'4'}>
          <Tooltip content={project.name}>
            <Flex gap={'2'} align={'center'} maxWidth={'100%'}>
              <Text size={'2'} weight={'regular'} style={{ color: '#fff' }}>
                {project.name}
              </Text>
            </Flex>
          </Tooltip>
        </Flex>
      </Link>
    </Flex>
  );
};
