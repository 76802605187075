import './view-wrapper.scss';
import {
  Flex,
  Text,
  Button,
  DropdownMenu,
  Tooltip,
  Checkbox,
} from '@radix-ui/themes';
import { Design } from '@/services/types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { MutableRefObject } from 'react';
import { useAppSelector } from '@/hooks';
import { DotsVerticalIcon } from '@radix-ui/react-icons';
import { useSelectable } from 'react-selectable-box';

interface ISingleTemplate {
  design: Design;
  isDesignsLibrary: boolean;
  onClick: (isSelected: boolean, event: React.MouseEvent) => void;
  deleteDesignsDialog: MutableRefObject<undefined>;
}

export const SingleTemplate = ({
  design,
  isDesignsLibrary,
  onClick,
  deleteDesignsDialog,
}: ISingleTemplate) => {
  const { designAssets, activeSmartObject } = useAppSelector(
    (state) => state.collectionReducer
  );

  const disabled = designAssets[activeSmartObject?.id]?.some(
    (da) => da.original_design_id === design.id
  );
  const { setNodeRef, isSelected, isAdding } = useSelectable({
    value: design.id,
    disabled,
  });

  return (
    <Flex
      ref={setNodeRef}
      className={`element single-design ${
        isSelected || (isAdding && !disabled) ? 'selected' : ''
      }
      ${disabled ? 'used' : ''}`}
      direction={'column'}
      align={'end'}
      p={'2'}
      gap={'2'}
      onClick={(event: React.MouseEvent) => {
        if (!disabled) {
          onClick(isSelected, event);
        }
      }}
    >
      <Flex className="info" direction={'column'} gap={'4'}>
        <Flex justify={'between'} align={'center'}>
          <Flex gap={'1'} align={'center'} maxWidth={'80%'}>
            <Checkbox
              className={!isSelected ? `selectCheckbox` : ``}
              size={'1'}
              checked={isSelected}
            />
            <Tooltip content={design.name}>
              <Text
                className="title"
                title={design.name}
                size={'1'}
                weight={'medium'}
              >
                {design.name}
              </Text>
            </Tooltip>
          </Flex>

          {isDesignsLibrary && (
            <>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                  <Button
                    className="ddl-options"
                    variant="ghost"
                    size={'1'}
                    color="gray"
                  >
                    <DotsVerticalIcon />
                  </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                  <DropdownMenu.Item
                    color="red"
                    onClick={() => {
                      (deleteDesignsDialog?.current as any)?.openDialog([
                        design.id,
                      ]);
                    }}
                  >
                    Delete
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </>
          )}
        </Flex>
      </Flex>

      <LazyLoadImage
        className="thumbnail"
        style={{
          objectFit: 'contain',
          width: '100%',
          objectPosition: 'center',
        }}
        effect="blur"
        src={
          design.thumbnail_image_url ||
          design.full_image_url ||
          'https://placehold.co/300x300?text=DESIGN'
        }
        delayTime={0}
        placeholderSrc={
          design.thumbnail_image_url ||
          design.full_image_url ||
          'https://placehold.co/300x300?text=DESIGN'
        }
        wrapperProps={{
          style: { transitionDelay: '0s' },
        }}
      />
    </Flex>
  );
};
